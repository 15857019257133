import { Checkbox, Col, Collapse, Form, InputNumber, Row, Select, Space } from 'antd';
import { memo } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';

const SELECT_IMAGE_SIZE_OPTIONS = [
  {
    value: '1024x1792',
    label: '1024x1792px',
  },
  {
    value: '1792x1024',
    label: '1792x1024px',
  },
  {
    value: '1024x1024',
    label: '1024px',
  },
  {
    value: '512x512',
    label: '512px',
  },
  {
    value: '256x256',
    label: '256px',
  },
];

const ImageProcessingUserSelection = memo(({ imageInteraction, displayPrompt, onDisplayPromptChange }) => {
  const { control } = useFormContext();
  const { errors } = useFormState({ control });

  return (
    <>
      <Space size="large">
        <Form.Item label="Nombre d'images">
          <InputNumber readOnly={true} value={imageInteraction?.numberImages || 1} min={1} max={10} />
        </Form.Item>
        <Form.Item
          label="Taille"
          validateStatus={errors?.imageSize?.message && 'error'}
          help={errors?.imageSize?.message}
        >
          <Select
            showArrow={false}
            open={false}
            value={imageInteraction?.imageSize || '1024x1024'}
            options={SELECT_IMAGE_SIZE_OPTIONS}
          />
        </Form.Item>
        <Form.Item
          label="Qualité"
          validateStatus={errors?.imageQuality?.message && 'error'}
          help={errors?.imageQuality?.message}
        >
          <Checkbox checked={imageInteraction?.imageQuality === 'hd'}>Image HD</Checkbox>
        </Form.Item>
      </Space>

      <Collapse collapsible="header" ghost>
        <Collapse.Panel header="Options de débogage">
          <Row gutter={(24, 24)}>
            <Col span={12}>
              <Space size="small" direction="vertical" style={{ display: 'flex' }}>
                <Checkbox
                  checked={displayPrompt}
                  onChange={() => {
                    onDisplayPromptChange(!displayPrompt);
                  }}
                >
                  Afficher les prompts de chaque demande
                </Checkbox>
                <Checkbox checked={imageInteraction?.options?.skipAi}>
                  Génère une réponse automatique au lieu d'interroger l'IA
                </Checkbox>
              </Space>
            </Col>
            <Col span={12}>
              <Space size="small" direction="vertical" style={{ display: 'flex' }}>
                <Checkbox checked={imageInteraction?.options?.skipQueue}>Exécution directe de la tâche</Checkbox>
                <Checkbox checked={imageInteraction?.options?.forcePriority}>
                  Force la priorité dans la file d'attente
                </Checkbox>
                <Checkbox checked={imageInteraction?.options?.rejectFromQueue}>
                  Rejette la tâche à l'entrée de la file d'attente
                </Checkbox>
                <Checkbox checked={imageInteraction?.options?.rejectFromThread}>
                  Rejette la tâche à la sortie de la file d'attente
                </Checkbox>
              </Space>
            </Col>
          </Row>
        </Collapse.Panel>
      </Collapse>
    </>
  );
});

ImageProcessingUserSelection.displayName = 'ImageProcessingUserSelection';
export default ImageProcessingUserSelection;
