import { Card, Form, Button, Input, Upload, Typography, message, Col, Row, Tabs, Space } from 'antd';
import { Link, Prompt, useParams, useHistory } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { useForm, Controller, useFormState, useWatch, useFieldArray, FormProvider } from 'react-hook-form';
import useSWR from 'swr';
import useFetch from '../../../hooks/useFetch';
import { aiRoutes } from '../../../lib/routes';
import { getImageUrl, getSignature } from '../../../lib/cloudinary';
import { useAuth } from '../../../authContext';

import ModuleAiLibraryTable from '../components/ModulesAiConstruction/ModulesAiLibraryTable';
import ModuleAiLibraryList from '../components/ModulesAiConstruction/ModulesAiLibraryList';
import ModulePreviewModal from '../components/ModulePreviewModal';
import AiSelect from './components/AiSelect';

const { TabPane } = Tabs;
const { Item } = Form;
const { TextArea } = Input;
const { Dragger } = Upload;
const { Paragraph } = Typography;

const getDefaultValues = (application) => {
  if (!application || !application?.data) {
    return {};
  }

  return {
    ai: (application.data?.ai || []).sort((a, b) => a.priority - b.priority),
    title: application.data.title,
    description: application.data.description,
    image: application.data.image,
    template: application.data.template,
    disabled: application.data?.disabled || false,
  };
};

const ApplicationAiEdit = () => {
  const { id } = useParams();
  const [isBlocking, setIsBlocking] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [modulePreviewModal, setModulePreviewModal] = useState(false);
  const { data: application, isValidating } = useSWR(`${aiRoutes.applications}/${id}`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const defaultValues = useMemo(() => getDefaultValues(application), [application?.data]);
  const methods = useForm({ defaultValues });
  const { handleSubmit, reset, control, setValue, setError, formState } = methods;
  const { data: modules, isValidating: isModulesValidating } = useSWR(`${aiRoutes.modules}?app=general,${id}`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const {
    fields: selectedBefore,
    remove: removeBefore,
    append: appendBefore,
    move: moveBefore,
  } = useFieldArray({ name: 'template.before', control });
  const {
    fields: selectedModel,
    remove: removeModel,
    append: appendModel,
    move: moveModel,
  } = useFieldArray({ name: 'template.activityModel', control });
  const {
    fields: selectedAfter,
    remove: removeAfter,
    append: appendAfter,
    move: moveAfter,
  } = useFieldArray({ name: 'template.after', control });

  const modulesLibrary = useMemo(() => {
    let generalModules = [];
    let applicationModules = [];

    if (modules?.data) {
      for (let mi = 0; mi < modules.data.length; mi++) {
        const module = modules.data[mi];
        const currentBeforeSelection = selectedBefore.map((item) => item._id);
        const currentModelSelection = selectedModel.map((item) => item._id);
        const currentAfterSelection = selectedAfter.map((item) => item._id);

        if (
          !module.disabled &&
          !currentBeforeSelection.includes(module._id) &&
          !currentModelSelection.includes(module._id) &&
          !currentAfterSelection.includes(module._id)
        ) {
          if (module.application && application?.data) {
            if (module.application === application.data._id) {
              applicationModules.push(module);
            }
          } else {
            generalModules.push(module);
          }
        }
      }
    }

    return { general: generalModules, application: applicationModules };
  }, [modules, modules?.data, application, application?.data, selectedBefore, selectedModel, selectedAfter]);

  const image = useWatch({ control, name: 'image' });
  const imageUrl = useMemo(() => (image ? getImageUrl(image) : null), [image]);
  const { errors } = useFormState({ control });
  const { put } = useFetch();
  const { token } = useAuth();
  const history = useHistory();

  // TODO (general) : Penser à mettre la taille recommandée de l'icône à jour

  useEffect(() => {
    if (formState.isDirty) {
      setIsBlocking(true);
    }
  }, [formState.isDirty]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const results = await put(`${aiRoutes.applications}/${id}`, JSON.stringify(form));

    if (results.status === 200) {
      setIsBlocking(false);
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  const onSubmitAndLeave = async (form) => {
    setIsProcessing(true);

    const results = await put(`${aiRoutes.applications}/${id}`, JSON.stringify(form));

    if (results.status === 200) {
      setIsBlocking(false);
      history.push('/ia/applications');
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  return (
    <Card>
      <Prompt
        when={isBlocking}
        message="Vous n'avez pas sauvegardé vos modifications, voulez-vous vraiment quitter cette page ?"
      />
      <ModulePreviewModal
        isOpen={!!modulePreviewModal}
        input={modulePreviewModal}
        onClose={() => setModulePreviewModal(false)}
      />
      <FormProvider {...methods}>
        <Form noValidate layout="vertical">
          <Paragraph type="secondary">
            <blockquote style={{ marginTop: 0 }}>
              <ul>
                <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
              </ul>
            </blockquote>
          </Paragraph>
          <Tabs>
            <TabPane key="description" tab="Description">
              <Row gutter={[24, 24]} align="bottom">
                <Col span={12}>
                  <Item
                    validateStatus={errors?.title?.message && 'error'}
                    help={errors?.title?.message}
                    label="Intitulé"
                    required
                  >
                    <Controller
                      control={control}
                      name="title"
                      render={({ field }) => <Input {...field} disabled={isProcessing || isValidating} />}
                    />
                  </Item>
                  <Item
                    validateStatus={errors?.description?.message && 'error'}
                    help={errors?.description?.message}
                    label="Description"
                    required
                  >
                    <Controller
                      control={control}
                      name="description"
                      render={({ field }) => <TextArea {...field} disabled={isProcessing || isValidating} />}
                    />
                  </Item>
                  <AiSelect disabled={isProcessing || isValidating} />
                  <Item
                    label="Icône"
                    validateStatus={errors?.image && 'error'}
                    help={errors?.image?.message}
                    extra="La taille recommandée est de 150x150px"
                  >
                    <Controller
                      control={control}
                      name="image"
                      render={({ field }) => {
                        return (
                          <Dragger
                            action={`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`}
                            name={field.name}
                            maxCount={1}
                            showUploadList={false}
                            data={(file) => getSignature(file, token)}
                            height={100}
                            disabled={isProcessing || isValidating}
                            accept="image/*"
                            style={{ border: errors?.image?.message && '1px solid #ff4d4f' }}
                            onChange={({ file }) => {
                              if (file.status === 'done') {
                                field.onChange(file.response.public_id);
                              }
                            }}
                          >
                            {imageUrl ? (
                              <img
                                style={{
                                  maxWidth: 'calc(100% - 16px)',
                                  maxHeight: 60,
                                  display: 'block',
                                  margin: '0 auto',
                                }}
                                src={imageUrl}
                                alt=""
                              />
                            ) : (
                              <p className="ant-upload-hint">Sélectionnez ou déposez une image</p>
                            )}
                          </Dragger>
                        );
                      }}
                    />
                  </Item>
                </Col>
              </Row>
            </TabPane>
            <TabPane key="modules" tab="Modules">
              <Row gutter={[24, 24]} align="top">
                <Col span={6}>
                  <Typography.Text>Bibliothèques de modules</Typography.Text>
                  <ModuleAiLibraryTable
                    modules={modulesLibrary}
                    onPreview={setModulePreviewModal}
                    disabled={isValidating || isModulesValidating}
                  />
                </Col>
                <Col span={6}>
                  <Item
                    label="Module(s) obligatoire(s) (Avant)"
                    validateStatus={errors?.modules?.message && 'error'}
                    help={errors?.modules?.message}
                  >
                    <ModuleAiLibraryList
                      listName="template.before"
                      modules={selectedBefore}
                      onMove={moveBefore}
                      onAppend={appendBefore}
                      onDelete={removeBefore}
                      onPreview={setModulePreviewModal}
                      disabled={isValidating || isModulesValidating}
                    />
                  </Item>
                  <Item
                    label="Modèle pour le contenu"
                    validateStatus={errors?.modules?.message && 'error'}
                    help={errors?.modules?.message}
                  >
                    <ModuleAiLibraryList
                      listName="template.activityModel"
                      modules={selectedModel}
                      onMove={moveModel}
                      onAppend={appendModel}
                      onDelete={removeModel}
                      onPreview={setModulePreviewModal}
                      disabled={isValidating || isModulesValidating}
                    />
                  </Item>
                  <Item
                    label="Module(s) obligatoire(s) (Après)"
                    validateStatus={errors?.modules?.message && 'error'}
                    help={errors?.modules?.message}
                  >
                    <ModuleAiLibraryList
                      listName="template.after"
                      modules={selectedAfter}
                      onMove={moveAfter}
                      onAppend={appendAfter}
                      onDelete={removeAfter}
                      onPreview={setModulePreviewModal}
                      disabled={isValidating || isModulesValidating}
                    />
                  </Item>
                </Col>
              </Row>
            </TabPane>
          </Tabs>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button className="gray-text" disabled={isProcessing}>
              <Link to="/ia/applications">Annuler</Link>
            </Button>
            <Space>
              <Button onClick={handleSubmit(onSubmit)} loading={isProcessing}>
                Enregistrer
              </Button>
              <Button type="primary" onClick={handleSubmit(onSubmitAndLeave)} loading={isProcessing}>
                Enregistrer et quitter
              </Button>
            </Space>
          </div>
        </Form>
      </FormProvider>
    </Card>
  );
};

export default ApplicationAiEdit;
