import { Card, Space, Typography } from 'antd';

import ApplicationAiSelection from '../components/ApplicationAiSelection';

const ActivitiesAiAppSelection = () => {

  return (
    <Card>
      <Space direction="vertical" style={{ display: 'flex' }}>
        <Typography.Title level={4}>Sélectionnez un espace de travail pour consulter les activités liées</Typography.Title>
        <ApplicationAiSelection prefixUrl="/ia/activites" />
      </Space>
    </Card>
  );
};

export default ActivitiesAiAppSelection;
