import { memo, useState } from 'react';
import { Card, Button, Table, Tabs, Popconfirm, Space, Image, Dropdown, Menu } from 'antd';
import { PlusOutlined, PictureOutlined, MoreOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import { snakeCase } from 'lodash';

import { aiRoutes } from '../../../lib/routes';
import tablePagination from '../../../lib/tablePagination';
import { checkAuthorization, sort } from '../../../shared/utils';
import useColumnSearch from '../../../hooks/useColumnSearch';
import useFetch from '../../../hooks/useFetch';
import { useAuth } from '../../../authContext';
import { getImageUrl } from '../../../lib/cloudinary';

import ImportPersonaAiModal from './components/ImportPersonaAiModal';

const { TabPane } = Tabs;

const popconfirmProps = {
  title: 'Êtes-vous sûr ?',
  cancelText: 'Non',
  okText: 'Oui',
  placement: 'left',
};

const PersonaImage = memo(({ url }) => {
  const [display, setDisplay] = useState(false);

  return (
    <>
      <Button icon={<PictureOutlined />} onClick={() => setDisplay(true)} />
      <Image
        style={{ display: 'none' }}
        src={url}
        preview={{
          visible: display,
          onVisibleChange: (value) => {
            setDisplay(value);
          },
        }}
      />
    </>
  );
});
PersonaImage.displayName = 'PersonaImage';

const PersonasList = () => {
  const { user } = useAuth();
  const [promptPersonaImportOpen, setPromptPersonaImportOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const { data: personas, isValidating, mutate } = useSWR(aiRoutes.personas);
  const enabledPersonas = personas?.data?.filter((personas) => !personas.disabled) || [];
  const disabledPersonas = personas?.data?.filter((personas) => personas.disabled) || [];
  const { getColumnSearchProps } = useColumnSearch();
  const { patch, remove, post } = useFetch();

  const disablePersona = async (personaId) => {
    setIsProcessing(true);

    const results = await patch(`${aiRoutes.personas}/disable/${personaId}`);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const enablePersona = async (personaId) => {
    setIsProcessing(true);

    const results = await patch(`${aiRoutes.personas}/enable/${personaId}`);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const duplicatePersona = async (personaId) => {
    setIsProcessing(true);

    // Récupération du persona
    const persona = (personas?.data || []).find((item) => item._id === personaId);

    if (!!persona) {
      const results = await post(
        aiRoutes.personas + '/' + personaId + '/copy',
        JSON.stringify({ name: persona.name + ' (copie)' }),
      );

      if (results.status === 201) {
        mutate();
      } else {
        if (results.message) {
          message.error(results.message);
        }
      }
    }

    setIsProcessing(false);
  };

  const exportPersona = async (personaId) => {
    // Récupération du persona
    const persona = (personas?.data || []).find((item) => item._id === personaId);

    if (!!persona) {
      // Création du fichier
      const filename = snakeCase(persona.name);
      const blob = new Blob(
        [
          JSON.stringify(
            {
              name: persona.name,
              category: persona.category,
              description: persona.description,
              behaviour: persona.behaviour,
            },
            null,
            2,
          ),
        ],
        { type: 'application/json' },
      );

      // Construction du lien
      const href = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = href;
      link.target = '_blank';
      link.download = `${filename}.json`;

      // Démarrer le téléchargement
      document.body.appendChild(link);
      link.click();

      // Nettoyer
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    }
  };

  const deletePersona = async (personaId) => {
    setIsProcessing(true);

    const results = await remove(`${aiRoutes.personas}/${personaId}`);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const Actions = memo(({ record }) => {
    return (
      <Menu key={`menu-${record._id}`}>
        {record.disabled
          ? checkAuthorization(user, 'ai', 'personas', 'enable') && (
              <Menu.Item key="enable" className="gray-text">
                <Popconfirm {...popconfirmProps} onConfirm={() => enablePersona(record._id)}>
                  Activer
                </Popconfirm>
              </Menu.Item>
            )
          : checkAuthorization(user, 'ai', 'personas', 'disable') && (
              <Menu.Item key="disable" className="gray-text">
                <Popconfirm {...popconfirmProps} onConfirm={() => disablePersona(record._id)}>
                  Désactiver
                </Popconfirm>
              </Menu.Item>
            )}
        {checkAuthorization(user, 'ai', 'personas', 'update') && (
          <Menu.Item key="edit" className="gray-text">
            <Link to={`/ia/personas/modifier-un-persona/${record._id}`}>Modifier</Link>
          </Menu.Item>
        )}
        {checkAuthorization(user, 'ai', 'personas', 'create') && (
          <Menu.Item key="duplicate" className="gray-text">
            <Popconfirm {...popconfirmProps} onConfirm={() => duplicatePersona(record._id)}>
              Dupliquer
            </Popconfirm>
          </Menu.Item>
        )}
        {checkAuthorization(user, 'ai', 'personas', 'export') && (
          <Menu.Item key="export" className="gray-text" onClick={() => exportPersona(record._id)}>
            Exporter
          </Menu.Item>
        )}
        {checkAuthorization(user, 'ai', 'personas', 'delete') && (
          <Menu.Item key="delete" className="gray-text">
            <Popconfirm {...popconfirmProps} onConfirm={() => deletePersona(record._id)}>
              Supprimer
            </Popconfirm>
          </Menu.Item>
        )}
      </Menu>
    );
  });

  const columns = [
    {
      width: 50,
      dataIndex: 'image',
      key: 'image',
      render: (value) => {
        if (value) {
          const url = getImageUrl(value);
          return <PersonaImage url={url} />;
        }
      },
    },
    {
      title: 'Nom',
      width: 250,
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => sort(a, b, 'name'),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Catégorie',
      width: 250,
      dataIndex: 'category',
      key: 'category',
      sorter: (a, b) => sort(a, b, 'category'),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('category'),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ...getColumnSearchProps('description'),
    },
    {
      title: 'Comportement',
      dataIndex: 'behaviour',
      key: 'behaviour',
      ...getColumnSearchProps('behaviour'),
    },
    {
      title: '',
      key: 'actions',
      width: 50,
      align: 'center',
      fixed: 'right',
      render: (record) => (
        <Dropdown overlay={<Actions record={record} />} placement="bottomRight" trigger={['click']}>
          <Button icon={<MoreOutlined />} disabled={isProcessing} />
        </Dropdown>
      ),
    },
  ];

  return (
    <Card>
      <ImportPersonaAiModal
        isOpen={promptPersonaImportOpen}
        onClose={() => setPromptPersonaImportOpen(false)}
        onFinish={mutate}
      />
      <div style={{ textAlign: 'right', marginBottom: 20 }}>
        <Space>
          {checkAuthorization(user, 'ai', 'personas', 'import') && (
            <Button onClick={() => setPromptPersonaImportOpen(true)}>Importer</Button>
          )}
          {checkAuthorization(user, 'ai', 'personas', 'create') && (
            <Link to="/ia/personas/nouveau-persona">
              <Button icon={<PlusOutlined />}>Nouveau persona</Button>
            </Link>
          )}
        </Space>
      </div>
      <Tabs>
        <TabPane key="enabled" tab={`Actifs (${enabledPersonas.length})`}>
          <Table
            loading={!personas || isValidating || isProcessing}
            dataSource={enabledPersonas}
            columns={columns}
            bordered
            size="small"
            rowKey={(row) => row._id}
            pagination={tablePagination(enabledPersonas)}
          />
        </TabPane>
        <TabPane key="disabled" tab={`Inactifs (${disabledPersonas.length})`}>
          <Table
            loading={!personas || isValidating || isProcessing}
            dataSource={disabledPersonas}
            columns={columns}
            bordered
            size="small"
            rowKey={(row) => row._id}
            pagination={tablePagination(disabledPersonas)}
          />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default PersonasList;
