import { Layout, Menu } from 'antd';
import {
  UserOutlined,
  AlertOutlined,
  EnvironmentOutlined,
  CodeOutlined,
  ReadOutlined,
  BookOutlined,
  PictureOutlined,
  PercentageOutlined,
  CalculatorOutlined,
  AppstoreOutlined,
  CalendarOutlined,
  FolderOpenOutlined,
  DatabaseOutlined,
  AuditOutlined,
  ToolOutlined,
  SettingOutlined,
  TeamOutlined,
  FormOutlined,
  TagOutlined,
  FileOutlined,
  QuestionCircleOutlined,
  CheckSquareOutlined,
  SolutionOutlined,
  RobotOutlined,
  KeyOutlined,
  ImportOutlined,
  SwapOutlined,
  ApartmentOutlined,
  MessageOutlined,
  ClusterOutlined,
  FundViewOutlined,
  ContactsOutlined,
} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../authContext';

const { Sider } = Layout;
const { SubMenu, Item } = Menu;

const reportsAccess = (permissions) =>
  !permissions?.formao?.formationReports.includes('search') &&
  !permissions?.formao?.companyReports.includes('search') &&
  !permissions?.formao?.trainingReports.includes('search') &&
  !permissions?.formao?.monthlyReports.includes('search') &&
  !permissions?.formao?.satisfactionReports.includes('search');

const LINKS = [
  {
    label: 'Utilisateurs',
    url: '/utilisateurs',
    icon: <UserOutlined />,
    disabled: (permissions) => !permissions?.global?.users.includes('search'),
  },
  {
    label: 'Rôles',
    url: '/roles',
    icon: <AlertOutlined />,
    disabled: (permissions) => !permissions?.global?.roles.includes('search'),
  },
  {
    label: "Clés d'accès",
    url: '/cles',
    icon: <KeyOutlined />,
    disabled: (permissions) => !permissions?.global?.keys.includes('search'),
  },
  {
    label: 'Interventions externes',
    url: '/interventions-externes',
    icon: <SolutionOutlined />,
    disabled: (permissions) => !permissions?.global?.externalInterventions.includes('search'),
  },
  {
    label: 'Centres',
    url: '/centres',
    icon: <EnvironmentOutlined />,
    disabled: (permissions) => !permissions?.global?.agencies.includes('search'),
  },
  {
    label: 'Gestion des pré-inscriptions',
    url: '/gestion-des-pre-inscriptions',
    icon: <FormOutlined />,
    disabled: (permissions) => !permissions?.arinfo?.registrations.includes('search'),
  },
  {
    label: 'Arinfo',
    key: '/arinfo',
    disabled: (permissions) => false,
    children: [
      {
        label: 'Actualités',
        url: '/arinfo/actualites',
        icon: <ReadOutlined />,
        disabled: (permissions) => !permissions?.arinfo?.posts.includes('search'),
      },
      {
        label: 'Ateliers',
        url: '/arinfo/ateliers',
        icon: <ToolOutlined />,
        disabled: (permissions) => !permissions?.arinfo?.workshops?.includes('search'),
      },
      {
        label: 'Foire aux questions',
        url: '/arinfo/faq',
        icon: <QuestionCircleOutlined />,
        disabled: (permissions) => !permissions?.arinfo?.faq.includes('search'),
      },
      {
        label: 'Formations',
        url: '/arinfo/formations',
        icon: <BookOutlined />,
        disabled: (permissions) => !permissions?.arinfo?.trainings.includes('search'),
      },
      {
        label: 'Galerie des projets',
        url: '/arinfo/galerie',
        icon: <PictureOutlined />,
        disabled: (permissions) => !permissions?.arinfo?.projects.includes('search'),
      },
      {
        label: 'Inter/Intra',
        url: '/arinfo/inter-intra',
        icon: <SwapOutlined />,
        disabled: (permissions) => !permissions?.arinfo?.interFormations.includes('access-formations'),
      },
      {
        label: 'Pré-inscription des ateliers',
        url: '/arinfo/pre-inscriptions-ateliers',
        icon: <FormOutlined />,
        disabled: (permissions) => !permissions?.arinfo?.workshops?.includes('search-registration'),
      },
      {
        label: 'Statistiques des centres',
        url: '/arinfo/statistiques',
        icon: <PercentageOutlined />,
        disabled: (permissions) => !permissions?.arinfo?.statistics.includes('search'),
      },
    ],
  },
  {
    label: 'Formao',
    key: '/formao',
    disabled: (permissions) => false,
    children: [
      {
        label: 'Apprenants',
        url: '/formao/apprenants',
        icon: <UserOutlined />,
        disabled: (permissions) => !permissions?.formao?.students.includes('search'),
      },
      {
        label: 'Sessions',
        url: '/formao/sessions',
        icon: <TeamOutlined />,
        disabled: (permissions) => !permissions?.formao?.groups.includes('search'),
      },
      {
        label: 'Formations',
        url: '/formao/formations',
        icon: <BookOutlined />,
        disabled: (permissions) => !permissions?.formao?.formations.includes('search'),
      },
      {
        label: 'Cours',
        url: '/formao/cours',
        icon: <AppstoreOutlined />,
        disabled: (permissions) => !permissions?.formao?.modules.includes('search'),
      },
      {
        label: 'Documents',
        url: '/formao/documents',
        icon: <FolderOpenOutlined />,
        disabled: (permissions) => !permissions?.formao?.documents.includes('search'),
      },
      {
        label: 'Évènements',
        url: '/formao/evenements',
        icon: <CalendarOutlined />,
        disabled: (permissions) => !permissions?.formao?.events.includes('search'),
      },
      {
        label: 'Bilans',
        url: '/formao/bilans',
        icon: <ReadOutlined />,
        disabled: (permissions) => reportsAccess(permissions),
      },
      {
        label: 'Formulaires de bilans',
        url: '/formao/formulaires-de-bilans',
        icon: <FormOutlined />,
        disabled: (permissions) => !permissions?.formao?.reportForms.includes('search'),
      },
    ],
  },
  {
    label: 'I-maginelab',
    key: '/imaginelab',
    disabled: (permissions) => false,
    children: [
      // {
      //   label: 'Blog',
      //   url: '/imaginelab/blog',
      //   icon: <ReadOutlined />,
      //   disabled: (permissions) => false,
      // },
      {
        label: 'Réservations',
        url: '/imaginelab/reservations',
        icon: <FormOutlined />,
        disabled: (permissions) => !permissions?.imaginelab?.bookings.includes('search'),
      },
      {
        label: 'Ateliers',
        url: '/imaginelab/ateliers',
        icon: <ToolOutlined />,
        disabled: (permissions) => !permissions?.imaginelab?.workshops.includes('search'),
      },
      {
        label: 'Documents',
        url: '/imaginelab/documents',
        icon: <FileOutlined />,
        disabled: (permissions) => !permissions?.imaginelab?.documents.includes('search'),
      },
    ],
  },
  {
    label: 'Posiao',
    key: '/posiao',
    disabled: (permissions) => false,
    children: [
      {
        label: 'Auto-évaluations',
        url: '/posiao/auto-evaluations',
        icon: <CheckSquareOutlined />,
        disabled: (permissions) => !permissions?.posiao?.evaluations.includes('search'),
      },
      {
        label: 'Catégories de quiz',
        url: '/posiao/categories-de-quiz',
        icon: <TagOutlined />,
        disabled: (permissions) => !permissions?.posiao?.types.includes('search'),
      },
      {
        label: 'Banque de questions',
        url: '/posiao/banque-de-question',
        icon: <DatabaseOutlined />,
        disabled: (permissions) => !permissions?.posiao?.questions.includes('search'),
      },
      {
        label: 'Suivi des tests',
        url: '/posiao/suivi-des-tests',
        icon: <AuditOutlined />,
        disabled: (permissions) => !permissions?.posiao?.quizzes.includes('search'),
      },
    ],
  },
  {
    label: 'Intelligences Artificielles',
    key: '/ia',
    icon: <RobotOutlined />,
    disabled: (permissions) => false,
    children: [
      {
        label: 'Systèmes',
        url: '/ia/systemes',
        icon: <SettingOutlined />,
        disabled: (permissions) => !permissions?.ai?.systems.includes('search'),
      },
      {
        label: 'API',
        url: '/ia/api',
        icon: <ClusterOutlined />,
        disabled: (permissions) => !permissions?.ai?.api.includes('viewQueue'),
      },
      {
        label: "Suivi d'utilisation",
        url: '/ia/usage',
        icon: <FundViewOutlined />,
        disabled: (permissions) => !permissions?.ai?.logs.includes('search'),
      },
      {
        label: 'Applications',
        url: '/ia/applications',
        icon: <AppstoreOutlined />,
        disabled: (permissions) => !permissions?.ai?.applications.includes('search'),
      },
      {
        label: 'Activités',
        url: '/ia/activites',
        icon: <ApartmentOutlined />,
        disabled: (permissions) => !permissions?.ai?.activities.includes('search'),
      },
      {
        label: 'Modules',
        url: '/ia/modules',
        icon: <CodeOutlined />,
        disabled: (permissions) => !permissions?.ai?.modules.includes('search'),
      },
      {
        label: 'Personas',
        url: '/ia/personas',
        icon: <ContactsOutlined />,
        disabled: (permissions) => !permissions?.ai?.personas.includes('search'),
      },
      {
        label: 'Interactions',
        url: '/ia/interactions',
        icon: <SwapOutlined />,
        disabled: (permissions) => !permissions?.ai?.interactions.includes('search'),
      },
    ],
  },
  {
    label: 'Outils',
    key: '/outils',
    icon: <ToolOutlined />,
    disabled: (permissions) => false,
    children: [],
  },
  {
    label: 'Développement',
    key: '/developpement',
    icon: <SettingOutlined />,
    disabled: (permissions) => false,
    children: [
      {
        label: 'Import/Export Formao',
        url: '/developpement/import-export-formao',
        icon: <ImportOutlined />,
        disabled: (permissions) => !permissions?.devTools?.importExportService.includes('viewQueue'),
      },
    ],
  },
];

const getOpenKeys = (pathname) => {
  let result = pathname.split('/');
  result.shift();

  result = '/' + result[0];

  return [result];
};

const getSelectedKeys = (pathname) => {
  const ROOT_LINKS = LINKS.filter((link) => !link.children).map((link) => link.url.split('/').pop());
  let result = pathname.split('/');
  result.shift();

  if (result.length > 2) {
    result = result.splice(0, 2);
  }

  if (ROOT_LINKS.includes(result[0])) {
    result.splice(1, result.length - 1);
  }

  result = '/' + result.join('/');

  return [result];
};

const Sidebar = () => {
  const { user } = useAuth();
  const { pathname } = useLocation();

  return (
    <Sider
      theme="light"
      width={256}
      style={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        height: '100vh',
        paddingTop: 48,
        boxShadow: '2px 0 8px 0 rgba(29,35,41,.05)',
      }}
    >
      <div style={{ height: '100%', width: '100%', overflow: 'auto' }}>
        <Menu mode="inline" defaultOpenKeys={getOpenKeys(pathname)} selectedKeys={getSelectedKeys(pathname)}>
          {LINKS.map((link) => {
            if (!link.children) {
              return (
                <Item key={link.url} icon={link.icon} disabled={link.disabled(user.role.permissions)}>
                  <Link to={link.url}>{link.label}</Link>
                </Item>
              );
            }

            return (
              <SubMenu
                key={link.key}
                title={link.label}
                icon={link.icon}
                disabled={link.disabled(user.role.permissions)}
              >
                {link.children.map((child) => (
                  <Item key={child.url} icon={child.icon} disabled={child.disabled(user.role.permissions)}>
                    <Link to={child.url}>{child.label}</Link>
                  </Item>
                ))}
              </SubMenu>
            );
          })}
        </Menu>
      </div>
    </Sider>
  );
};

export default Sidebar;
