import { useState, useMemo } from 'react';
import { Input, Row, Col, Space, Select, Typography, List, Divider, Button, Dropdown, Tag } from 'antd';
import useSWR from 'swr';
import qs from 'qs';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import { useAuth } from '../../../../authContext';
import { aiRoutes } from '../../../../lib/routes';
import { checkAuthorization } from '../../../../shared/utils';
import ApplicationAiSelectionCard from './ApplicationAiSelectionCard';
import { searchInString } from '../../../../lib/helpers';

const aiTagColors = {
  chatgpt: '#74AA9C',
  mistral: '#ff7404',
};

const getQuery = (filters) => {
  return qs.stringify(
    {
      search: filters.query,
      versions: filters.versions,
    },
    { skipNulls: true, encoreValuesOnly: true, addQueryPrefix: true, arrayFormat: 'comma' },
  );
};

const getSortedApps = (data, sortBy) => {
  const clone = [...data];
  switch (sortBy) {
    case 'default':
      return clone;
    case 'titleUp':
      return clone.sort((a, b) => a.title.localeCompare(b.title));
    case 'titleDown':
      return clone.sort((a, b) => b.title.localeCompare(a.title));
    default:
      return clone;
  }
};

const ApplicationAiSelection = ({ prefixUrl, showGeneral }) => {
  const [values, setValues] = useState({
    query: null,
    versions: [],
  });
  const [sortBy, setSortBy] = useState('default');
  const query = useMemo(() => getQuery(values), [values]);
  const { data: applications } = useSWR(aiRoutes.applications + query);
  const { data: aiVersions } = useSWR(aiRoutes.versions);
  const sortedApplications = useMemo(() => getSortedApps(applications?.data || [], sortBy), [applications, sortBy]);
  const { user } = useAuth();

  const items = [
    {
      key: 'sortBy',
      label: <span onClick={() => setSortBy('default')}>Trier par</span>,
    },
    {
      key: 'titleUp',
      label: <span onClick={() => setSortBy('titleUp')}>Intitulé (croissant)</span>,
    },
    {
      key: 'titleDown',
      label: <span onClick={() => setSortBy('titleDown')}>Intitulé (décroissant)</span>,
    },
  ];

  return (
    <Space
      direction="vertical"
      size="small"
      style={{
        display: 'flex',
      }}
    >
      {showGeneral && checkAuthorization(user, 'ai', 'generalModules', 'search') && (
        <>
          <Row gutter={16}>
            <Col span={8}>
              <ApplicationAiSelectionCard
                title="Général"
                description="Modules utilisables par toutes les applications"
                href={`${prefixUrl}/general`}
              />
            </Col>
          </Row>
          <Divider>
            <Typography.Title level={4}>Modules d'applications</Typography.Title>
          </Divider>
        </>
      )}
      <div className="activities-search-container">
        <Input
          placeholder="Rechercher"
          style={{ width: 300 }}
          value={values?.query}
          onChange={(e) => setValues({ ...values, query: e.target.value })}
          suffix={<SearchOutlined />}
          allowClear={true}
        />
        <Select
          mode="multiple"
          placeholder="Filtrer les IA"
          value={values?.versions}
          filterOption={(input, option) => searchInString(input, option.name)}
          onChange={(e) => setValues({ ...values, versions: e })}
          style={{ width: 200 }}
          allowClear
        >
          {(aiVersions?.data || []).map((version) => (
            <Select.Option name={version.name} value={version._id}>
              <Tag color={aiTagColors[version.ai]}>{version.value}</Tag>
              {version.name}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div style={{ textAlign: 'right' }}>
        <Dropdown menu={{ items }} placement="bottomRight" trigger={['click']}>
          <Button>
            {sortBy === 'default'
              ? 'Trier par'
              : sortBy === 'titleUp'
              ? 'Intitulé (croissant)'
              : 'Intitulé (décroissant)'}
            <DownOutlined />
          </Button>
        </Dropdown>
      </div>
      <List
        className="application-activity-list"
        grid={{ column: 3, gutter: [24, 24] }}
        pagination={{
          size: 'small',
          pageSize: 6,
        }}
        size="small"
        dataSource={sortedApplications || []}
        renderItem={(app) => (
          <List.Item style={{ height: '100%', padding: 0 }}>
            <ApplicationAiSelectionCard
              aiTagColors={aiTagColors}
              title={app.title}
              versions={app.ai}
              description={app.description}
              image={app.image}
              href={`${prefixUrl}/${app._id}`}
              disabled={app?.disabled}
            />
          </List.Item>
        )}
      />
    </Space>
  );
};

export default ApplicationAiSelection;
