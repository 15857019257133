import { Button, Form, Input, Modal, Select, Typography, message } from 'antd';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { useEffect } from 'react';

import { aiRoutes } from '../../../lib/routes';
import useFetch from '../../../hooks/useFetch';

const HaltModal = ({ onHalt, ...props }) => {
  const { control, handleSubmit, setError, reset } = useForm({ defaultValues: { status: null, text: '' } });
  const { errors } = useFormState({ control });
  const { post } = useFetch();

  useEffect(() => {
    if (props.visible) {
      reset();
    }
  }, [props.visible]);

  const onSubmit = async (form) => {
    try {
      const response = await post(`${aiRoutes.queue}/force-stop`, JSON.stringify(form));

      if (response.status !== 200) {
        message.error(
          response?.data?.message || "Une erreur est survenue, la file d'attente n'a pas pu être interrompue",
        );
      } else {
        onHalt();
        props.onCancel();
        message.success("La file d'attente a été interrompue");
      }
    } catch (err) {
      if (Object.keys(err?.response?.data?.data).length !== 0) {
        Object.entries(err?.response?.data?.data).forEach(([key, value]) => {
          setError(key, { message: value, type: 'manual' });
        });
      } else {
        console.log(err.response.data);
        console.log(err?.response?.data?.message || err?.message || err);
        message.error(
          err?.response?.data?.message ||
            err?.message ||
            "Une erreur est survenue, la file d'attente n'a pas pu être interrompue",
        );
      }
    }
  };

  return (
    <Modal {...props} title="Interrompre la file d'attente" footer={false}>
      <Typography.Paragraph type="secondary">
        <blockquote style={{ marginTop: 0 }}>
          <ul>
            <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
          </ul>
        </blockquote>
      </Typography.Paragraph>
      <Form noValidate layout="vertical">
        <Form.Item label="Statut" required validateStatus={errors?.status && 'error'} help={errors?.status?.message}>
          <Controller
            {...{ control }}
            name="status"
            render={({ field }) => (
              <Select
                {...field}
                placeholder="Sélectionner dans la liste"
                options={[
                  { label: 'Information', value: 'INFO' },
                  { label: 'Avertissement', value: 'WARNING' },
                  { label: 'Danger', value: 'DANGER' },
                ]}
              />
            )}
          />
        </Form.Item>
        <Form.Item label="Message" required validateStatus={errors?.text && 'error'} help={errors?.text?.message}>
          <Controller {...{ control }} name="text" render={({ field }) => <Input.TextArea {...field} />} />
        </Form.Item>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Button type="primary" onClick={handleSubmit(onSubmit)}>
            Confirmer
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default HaltModal;
