import { useState } from 'react';

/**
 * Permet de gérer le chargement de plusieurs clés simultanément
 */
const useLoading = () => {
  const [keys, setKeys] = useState([]);

  /**
   * Débute le chargement d'une clé
   * @param {string} key Clé de chargement devant démarrer.
   */
  const startLoading = (key) => {
    return setKeys((keys) => {
      const newKeys = [...keys];
      const index = newKeys.indexOf(key);

      if (index === -1) {
        newKeys.push(key);
      }

      return newKeys;
    });
  };

  /**
   * Arrête le chargement d'une clé
   * @param {string} key Clé de chargement devant s'arrêter.
   */
  const endLoading = (key) => {
    return setKeys((keys) => {
      const newKeys = [...keys];
      const index = newKeys.indexOf(key);

      if (index !== -1) {
        newKeys.splice(index, 1);
      }

      return newKeys;
    });
  };

  /**
   * Permet de vérifier si une clé est déjà en chargement.
   * @param {string} key Clé à vérifier.
   * @returns `true` si la clé est en chargement sinon `false`.
   */
  const isLoading = (key) => {
    return keys.indexOf(key) !== -1;
  };

  return { isLoading, startLoading, endLoading };
};

export default useLoading;
