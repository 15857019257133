import { Button, Card, Form, Select, Space } from 'antd';
import { Controller, useFormContext, useFormState } from 'react-hook-form';
import { DeleteOutlined } from '@ant-design/icons';
import useSWR from 'swr';
import { aiRoutes } from '../../../../lib/routes';

const AIApplicationBlock = ({ index, onRemove, authorizations }) => {
  const { control, disabled } = useFormContext();
  const { errors } = useFormState({ control: control });
  const { data, isValidating } = useSWR(`${aiRoutes.applications}/`);

  return (
    <Card
      size="small"
      title="Accès à une application IA"
      headStyle={{ background: '#fafafa' }}
      extra={[<Button size="small" icon={<DeleteOutlined />} onClick={onRemove} disabled={disabled} />]}
    >
      <Space direction="vertical" style={{ display: 'flex', width: '100%' }}>
        <Form.Item
          label="Application"
          required
          validateStatus={errors?.authorizations?.[index]?.application?.message && 'error'}
          help={errors?.authorizations?.[index]?.application?.message}
        >
          <Controller
            control={control}
            name={`authorizations.${index}.application`}
            render={({ field }) => (
              <Select
                {...field}
                placeholder="Sélectionnez dans la liste"
                loading={isValidating}
                options={data?.data.map((item) => {
                  return {
                    label: item.title,
                    value: item._id,
                    disabled: authorizations.includes(item._id) || disabled,
                  };
                })}
              />
            )}
          />
        </Form.Item>
      </Space>
    </Card>
  );
};

export default AIApplicationBlock;
