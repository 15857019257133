import { Alert, Card, Space, Spin, Tabs, Typography } from 'antd';
import useSWR from 'swr';
import { useState } from 'react';

import { useAuth } from '../../../authContext';
import { aiRoutes } from '../../../lib/routes';

import AiQueueTable from './AiQueueTable';
import AiThreadsTable from './AiThreadsTable';
import { ApiOutlined } from '@ant-design/icons';
import AiRejectedTable from './AiRejectedTable';
const { TabPane } = Tabs;

const SWROptions = {
  revalidateOnReconnect: true,
  revalidateOnFocus: true,
  revalidateOnRefresh: false,
  refreshInterval: 5000,
};

const haltedQueueMessageStatus = {
  INFO: 'info',
  WARNING: 'warning',
  DANGER: 'error',
};

const ApiAiStatus = () => {
  const { token, user } = useAuth();
  const [isProcessing, setIsProcessing] = useState(false);
  const { data: queueData, isValidating, mutate } = useSWR(`${aiRoutes.queue}`, SWROptions);
  const queueStatus = queueData?.data?.data?.status || 'OFFLINE';
  const threads = queueData?.data?.data?.threads || [];
  const queueLastStartTime = queueData?.data?.data?.elapsedTime || null;

  // TODO: Pinger le serveur pour vérifier que l'API IA est en ligne

  return (
    <Space size="middle" direction="vertical" style={{ display: 'flex' }}>
      {['HALTED', 'ERRORED'].includes(queueStatus) && (
        <Alert
          showIcon
          message={queueData?.data?.data?.message?.text || null}
          type={haltedQueueMessageStatus[queueData?.data?.data?.message?.status] || 'error'}
        />
      )}
      <Spin
        className="api-ai-status-spin"
        spinning={!queueData?.data?.data?.status}
        size="large"
        style={{ left: 0 }}
        indicator={
          <Space style={{ width: 'auto' }}>
            <Alert
              showIcon
              icon={
                <ApiOutlined
                  style={{
                    fontSize: 24,
                  }}
                />
              }
              message="L'API IA est actuellement hors ligne"
              type="error"
            />
          </Space>
        }
      >
        <Card size="small">
          <Tabs>
            <TabPane key="threads" tab={`Processus (${Object.keys(threads).length})`}>
              <AiThreadsTable
                threads={threads}
                isValidating={isValidating}
                isProcessing={isProcessing}
                onProcessing={setIsProcessing}
                onFinish={mutate}
              />
            </TabPane>
            <TabPane key="queue" tab={`File d'attente (${queueData?.data?.data?.queue.length || 0})`}>
              <AiQueueTable
                queue={queueData?.data?.data?.queue || []}
                status={queueStatus}
                elapsedTime={queueLastStartTime}
                isValidating={isValidating}
                isProcessing={isProcessing}
                onProcessing={setIsProcessing}
                onFinish={mutate}
              />
            </TabPane>
            <TabPane
              key="rejected"
              tab={`Tâche${(queueData?.data?.data?.rejectedJobs.length || 0) > 1 ? 's' : ''} bloquée${
                (queueData?.data?.data?.rejectedJobs.length || 0) > 1 ? 's' : ''
              } (${queueData?.data?.data?.rejectedJobs.length || 0})`}
            >
              <AiRejectedTable
                queue={queueData?.data?.data?.rejectedJobs || []}
                isValidating={isValidating}
                isProcessing={isProcessing}
                onProcessing={setIsProcessing}
                onFinish={mutate}
              />
            </TabPane>
          </Tabs>
        </Card>
      </Spin>
    </Space>
  );
};

export default ApiAiStatus;
