import { Card, Form, Button, Input, Space, Select, Typography, message, Col, Row, Tabs, TreeSelect } from 'antd';
import { Link, Prompt, useParams, useHistory } from 'react-router-dom';
import { useEffect, useState, useMemo } from 'react';
import { useForm, Controller, useFormState, useFieldArray, FormProvider } from 'react-hook-form';
import useSWR from 'swr';

import useFetch from '../../../hooks/useFetch';
import { aiRoutes, formationRoutes } from '../../../lib/routes';
import { searchInString } from '../../../lib/helpers';
import ModuleAiLibraryTable from '../components/ModulesAiConstruction/ModulesAiLibraryTable';
import ModuleAiLibraryList from '../components/ModulesAiConstruction/ModulesAiLibraryList';
import ModulePreviewModal from '../components/ModulePreviewModal';

import PromptTestModal from './components/PromptTestModal';

const { TabPane } = Tabs;
const { Item } = Form;
const { TextArea } = Input;
const { Paragraph } = Typography;

const defaultValues = {
  title: '',
  description: '',
  application: '',
  formations: [],
  modules: [],
  disabled: false,
  additional: null,
};

const ActivityAiEdit = () => {
  const { workspace: workspaceId, id } = useParams();
  const [isBlocking, setIsBlocking] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [promptTestModalOpen, setPromptTestModalOpen] = useState(false);
  const [modulePreviewModal, setModulePreviewModal] = useState(false);
  const { data: formations, isValidating: isFormationsValidating } = useSWR(formationRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: workspace } = useSWR(`${aiRoutes.applications}/${workspaceId}`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: activity, isValidating } = useSWR(`${aiRoutes.activities}/${id}`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: modules } = useSWR(`${aiRoutes.modules}?app=general,${workspaceId}`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: activities, isValidating: isActivitiesValidating } = useSWR(aiRoutes.activities);
  const { data: applications, isValidating: isApplicationsValidating } = useSWR(aiRoutes.applications);
  const methods = useForm({ defaultValues });
  const { handleSubmit, control, setValue, setError, formState } = methods;
  const { fields: selectedModules, remove, append, move } = useFieldArray({ name: 'modules', control });

  const formattedApplications = useMemo(() => {
    if (activities?.data && applications?.data) {
      return applications.data.map((application) => {
        const applicationChildren = activities.data.filter(
          (activity) => activity.application.toString() === application._id.toString(),
        );

        return {
          label: application.title,
          key: application._id,
          value: application._id,
          selectable: false,
          disabled: applicationChildren.length === 0,
          children:
            (applicationChildren || []).map((activity) => ({
              label: activity.title,
              key: activity._id,
              value: activity._id,
            })) || [],
        };
      });
    } else {
      return [];
    }
  }, [activities, activities?.data, applications, applications?.data]);

  // Construction de la bibliothèque de modules disponibles
  const modulesLibrary = useMemo(() => {
    const beforeModules = workspace?.data?.template?.before ? workspace.data.template.before : [];
    const afterModules = workspace?.data?.template?.after ? workspace.data.template.after : [];

    let generalModules = [];
    let applicationModules = [];

    if (modules?.data) {
      for (let mi = 0; mi < modules.data.length; mi++) {
        const module = modules.data[mi];

        // Liste les modules déjà utilisés
        const currentSelection = [...selectedModules, ...beforeModules, ...afterModules].map((item) => item._id);

        if (!module.disabled && !currentSelection.includes(module._id)) {
          if (module.application) {
            applicationModules.push(module);
          } else {
            generalModules.push(module);
          }
        }
      }
    }

    return { general: generalModules, application: applicationModules };
  }, [workspace, modules, modules?.data, workspaceId, selectedModules]);

  const { errors } = useFormState({ control });
  const { put } = useFetch();
  const history = useHistory();

  useEffect(() => {
    if (formState.isDirty) {
      setIsBlocking(true);
    }
  }, [formState.isDirty]);

  useEffect(() => {
    if (activity?.data) {
      setValue('title', activity.data?.title || defaultValues.title);
      setValue('description', activity.data?.description || defaultValues.description);
      setValue('application', activity.data?.application || defaultValues.application);
      setValue('formations', activity.data?.formations || defaultValues.formations);
      setValue('modules', activity.data?.modules || defaultValues.modules);
      setValue('disabled', activity.data?.disabled || defaultValues.disabled);
      setValue('additional', activity.data?.additional || defaultValues.additional);
    }
  }, [activity?.data]);

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const results = await put(`${aiRoutes.activities}/${id}`, JSON.stringify(form));

    if (results.status === 200) {
      setIsBlocking(false);
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          setError(key, { type: 'manual', message: value });
        });
      }
    }
    setIsProcessing(false);
  };

  const onSubmitAndLeave = async (form) => {
    setIsProcessing(true);

    const results = await put(`${aiRoutes.activities}/${id}`, JSON.stringify(form));

    if (results.status === 200) {
      setIsBlocking(false);
      history.push(`/ia/activites/${workspaceId}`);
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          setError(key, { type: 'manual', message: value });
        });
      }
    }
    setIsProcessing(false);
  };

  return (
    <Card>
      <Prompt
        when={isBlocking}
        message="Vous n'avez pas sauvegardé vos modifications, voulez-vous vraiment quitter cette page ?"
      />
      <FormProvider {...methods}>
        <PromptTestModal isOpen={promptTestModalOpen} onClose={() => setPromptTestModalOpen(false)} />
        <ModulePreviewModal
          isOpen={!!modulePreviewModal}
          input={modulePreviewModal}
          onClose={() => setModulePreviewModal(false)}
        />
        <Form noValidate layout="vertical">
          <Paragraph type="secondary">
            <blockquote style={{ marginTop: 0 }}>
              <ul>
                <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
              </ul>
            </blockquote>
          </Paragraph>
          <Tabs>
            <TabPane key="description" tab="Description">
              <Row gutter={[24, 24]} align="bottom">
                <Col span={12}>
                  <Item
                    validateStatus={errors?.title?.message && 'error'}
                    help={errors?.title?.message}
                    label="Intitulé"
                    required
                  >
                    <Controller
                      control={control}
                      name="title"
                      render={({ field }) => <Input {...field} disabled={isProcessing || isValidating} />}
                    />
                  </Item>
                  <Item
                    validateStatus={errors?.description?.message && 'error'}
                    help={errors?.description?.message}
                    label="Description"
                    required
                  >
                    <Controller
                      control={control}
                      name="description"
                      render={({ field }) => <TextArea {...field} disabled={isProcessing || isValidating} />}
                    />
                  </Item>
                  <Item
                    label="Formation(s) liée(s)"
                    tooltip="Formations pouvant utiliser l'activité. Si aucune formation n'est listée, toutes les formations auront accès à l'activité"
                    validateStatus={errors?.formations?.message && 'error'}
                    help={errors?.formations?.message}
                  >
                    <Controller
                      name="formations"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          mode="multiple"
                          loading={isFormationsValidating}
                          filterOption={(input, option) => searchInString(input, option.label)}
                          options={formations?.data.map((item) => ({
                            value: item._id,
                            label: item.title,
                          }))}
                          allowClear
                          placeholder="Cette activité n'est liée à aucune formation"
                          disabled={isProcessing || isValidating}
                        />
                      )}
                    />
                  </Item>
                  <Item
                    label="Activité additionnelle"
                    validateStatus={errors?.additional?.message && 'error'}
                    help={errors?.additional?.message}
                    tooltip="Activité liée à cette activité"
                  >
                    <Controller
                      control={methods.control}
                      name="additional"
                      render={({ field }) => (
                        <TreeSelect
                          {...field}
                          placeholder="Cette activité n'est liée à aucune autre activité"
                          treeExpandAction="click"
                          loading={isActivitiesValidating || isApplicationsValidating}
                          multiple={false}
                          allowClear
                          treeData={formattedApplications || []}
                          disabled={isProcessing || isValidating}
                        />
                      )}
                    />
                  </Item>
                </Col>
              </Row>
            </TabPane>
            <TabPane key="modules" tab="Modules">
              <Row gutter={[24, 24]} align="top" style={{ marginBottom: '20px' }}>
                <Col span={6}>
                  <Typography.Text>Bibliothèques de modules</Typography.Text>
                  <ModuleAiLibraryTable
                    modules={modulesLibrary}
                    onPreview={setModulePreviewModal}
                    disabled={isProcessing || isValidating}
                  />
                </Col>
                <Col span={6}>
                  <Item
                    label="Modules de l'activité"
                    validateStatus={errors?.modules?.message && 'error'}
                    help={errors?.modules?.message}
                  >
                    <ModuleAiLibraryList
                      modules={selectedModules}
                      onMove={move}
                      onAppend={append}
                      onDelete={remove}
                      onPreview={setModulePreviewModal}
                      disabled={isProcessing || isValidating}
                    />
                  </Item>
                </Col>
              </Row>
            </TabPane>
          </Tabs>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Link to={`/ia/activites/${workspaceId}`}>
              <Button disabled={isProcessing}>Annuler</Button>
            </Link>
            <Space size="small">
              <Button onClick={() => setPromptTestModalOpen(true)} disabled={isProcessing || isValidating}>
                Tester
              </Button>
              <Button onClick={handleSubmit(onSubmit)} disabled={isProcessing || isValidating} loading={isProcessing}>
                Enregistrer
              </Button>
              <Button
                type="primary"
                onClick={handleSubmit(onSubmitAndLeave)}
                disabled={isProcessing || isValidating}
                loading={isProcessing}
              >
                Enregistrer et quitter
              </Button>
            </Space>
          </div>
        </Form>
      </FormProvider>
    </Card>
  );
};

export default ActivityAiEdit;
