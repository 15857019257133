import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Space, Typography, Skeleton } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';

const Layout = ({ children, title, titleLoading, url, urlLoading, previewUrl }) => {
  const { pathname } = useLocation();
  const history = useHistory();

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Card>
          <Space direction="vertical">
            <Space>
              {pathname !== '/arinfo/inter-intra' && (
                <Button icon={<ArrowLeftOutlined />} onClick={() => history.push('/arinfo/inter-intra')} />
              )}
              <Skeleton
                active
                loading={!!titleLoading}
                title={{ width: 450, style: { margin: 0, height: 32.4 } }}
                paragraph={false}
              >
                <Typography.Title level={3} style={{ margin: 0 }}>
                  {title}
                </Typography.Title>
              </Skeleton>
            </Space>
            <Skeleton
              active
              loading={!!urlLoading}
              title={{ width: 450, style: { margin: 0, height: 21.6 } }}
              paragraph={false}
            >
              {previewUrl ? (
                <Typography.Text type="secondary">
                  Prévisualisation de l'URL : {`https://arinfo.fr/inter-intra${url ?? ''}`}
                </Typography.Text>
              ) : (
                <Typography.Link
                  copyable
                  href={`https://arinfo.fr/inter-intra${url}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {`https://arinfo.fr/inter-intra${url ?? ''}`}
                </Typography.Link>
              )}
            </Skeleton>
          </Space>
        </Card>
      </Col>
      {children}
    </Row>
  );
};

export default Layout;
