import { Button, Popconfirm, Space, Tooltip, message, Tag, Dropdown, Menu } from 'antd';
import { EyeOutlined, LinkOutlined, MoreOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { memo, useState } from 'react';

import { aiRoutes } from '../../../../lib/routes';
import useFetch from '../../../../hooks/useFetch';
import { checkAuthorization } from '../../../../shared/utils';
import { useAuth } from '../../../../authContext';

import PromptTestModal from './PromptTestModal';

import Item, { Meta } from 'antd/lib/list/Item';

const popconfirmProps = {
  title: 'Êtes-vous sûr ?',
  cancelText: 'Non',
  okText: 'Oui',
  placement: 'left',
};

const ModuleAiItem = ({ item, onMutate, workspace }) => {
  const [promptTestModalOpen, setPromptTestModalOpen] = useState(false);
  const { user } = useAuth();
  const { post, patch, remove } = useFetch();
  const [isProcessing, setIsProcessing] = useState(false);

  const enableActivity = async () => {
    setIsProcessing(true);

    const results = await patch(aiRoutes.activities + '/enable/' + item._id);

    if (results.status === 200) {
      onMutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const disableActivity = async () => {
    setIsProcessing(true);

    const results = await patch(aiRoutes.activities + '/disable/' + item._id);

    if (results.status === 200) {
      onMutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const duplicateActivity = async () => {
    setIsProcessing(true);

    const results = await post(
      aiRoutes.activities + '/' + item._id + '/copy',
      JSON.stringify({ title: item.title + ' (copie)' }),
    );

    if (results.status === 201) {
      onMutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const deleteActivity = async () => {
    setIsProcessing(true);

    const results = await remove(aiRoutes.activities + '/' + item._id);

    if (results.status === 200) {
      onMutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const Actions = memo(({ item }) => {
    return (
      <Menu key={`menu-${item._id}`}>
        {item.disabled
          ? checkAuthorization(user, 'ai', 'activities', 'enable') && (
              <Menu.Item key="enable" className="gray-text">
                <Popconfirm {...popconfirmProps} onConfirm={enableActivity}>
                  Activer
                </Popconfirm>
              </Menu.Item>
            )
          : checkAuthorization(user, 'ai', 'activities', 'disable') && (
              <Menu.Item key="disable" className="gray-text">
                <Popconfirm {...popconfirmProps} onConfirm={disableActivity}>
                  Désactiver
                </Popconfirm>
              </Menu.Item>
            )}
        {checkAuthorization(user, 'ai', 'activities', 'update') && (
          <Menu.Item key="edit" className="gray-text">
            <Link to={`/ia/activites/${workspace}/modifier-une-activite/${item._id}`}>Modifier</Link>
          </Menu.Item>
        )}
        {checkAuthorization(user, 'ai', 'activities', 'create') && (
          <Menu.Item key="duplicate" className="gray-text">
            <Popconfirm {...popconfirmProps} onConfirm={duplicateActivity}>
              Dupliquer
            </Popconfirm>
          </Menu.Item>
        )}
        {checkAuthorization(user, 'ai', 'activities', 'delete') && (
          <Menu.Item key="delete" className="gray-text">
            <Popconfirm {...popconfirmProps} onConfirm={deleteActivity}>
              Supprimer
            </Popconfirm>
          </Menu.Item>
        )}
      </Menu>
    );
  });

  return (
    <Item
      actions={[
        <Space size="small">
          <Tooltip title="Copier l'ID de l'activité" destroyTooltipOnHide={{ keepParent: false }}>
            <Button
              icon={<LinkOutlined />}
              onClick={() => {
                navigator.clipboard.writeText(item._id);
              }}
            />
          </Tooltip>
          <Tooltip title="Visualiser le prompt de l'activité" destroyTooltipOnHide={{ keepParent: false }}>
            <Button icon={<EyeOutlined />} disabled={isProcessing} onClick={() => setPromptTestModalOpen(item)} />
          </Tooltip>
          <Dropdown overlay={<Actions item={item} />} placement="bottomRight" trigger={['click']}>
            <Button icon={<MoreOutlined />} disabled={isProcessing} />
          </Dropdown>
        </Space>,
      ]}
    >
      <PromptTestModal
        isOpen={!!promptTestModalOpen}
        activity={promptTestModalOpen}
        onClose={() => setPromptTestModalOpen(false)}
      />
      <Meta
        title={item.title}
        description={
          <Space direction="vertical">
            {item.description}
            <span>
              {item.formations?.map((formation) => (
                <Tag>{formation.acronym}</Tag>
              ))}
            </span>
          </Space>
        }
      />
    </Item>
  );
};

export default ModuleAiItem;
