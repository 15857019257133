import { Button, Tabs, Popconfirm, Space, Tooltip, message, Menu, Dropdown } from 'antd';
import { CodeOutlined, EyeOutlined, MessageOutlined, MoreOutlined } from '@ant-design/icons';
import { memo, useState } from 'react';
import { Link } from 'react-router-dom';
import { snakeCase } from 'lodash';

import { aiRoutes } from '../../../../lib/routes';
import useFetch from '../../../../hooks/useFetch';

import Item, { Meta } from 'antd/lib/list/Item';
import { useAuth } from '../../../../authContext';
import { checkAuthorization } from '../../../../shared/utils';

import PromptTestModal from './PromptTestModal';

const popconfirmProps = {
  title: 'Êtes-vous sûr ?',
  cancelText: 'Non',
  okText: 'Oui',
  placement: 'left',
};

const ModuleAiItem = ({ item, workspace, isProcessing, onProcessing, onFinish }) => {
  const [promptTestModalOpen, setPromptTestModalOpen] = useState(false);
  const { user } = useAuth();
  const { post, patch, remove } = useFetch();

  const enableModule = async () => {
    onProcessing(true);

    const results = await patch(aiRoutes.modules + '/enable/' + item._id);

    if (results.status === 200) {
      onFinish();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    onProcessing(false);
  };

  const disableModule = async () => {
    onProcessing(true);

    const results = await patch(aiRoutes.modules + '/disable/' + item._id);

    if (results.status === 200) {
      onFinish();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    onProcessing(false);
  };

  const duplicateModule = async () => {
    onProcessing(true);

    const results = await post(
      aiRoutes.modules + '/' + item._id + '/copy',
      JSON.stringify({ title: item.title + ' (copy)' }),
    );

    if (results.status === 201) {
      onFinish();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    onProcessing(false);
  };

  const exportModule = async () => {
    // Exportation au format JSON
    const extractToJson = ({ title, content, variables }) => {
      const formattedVariables = variables.map((item) => {
        delete item._id;
        return item;
      });
      return { title, content, variables: formattedVariables };
    };

    // Création du fichier
    const filename = snakeCase(item.title);
    const blob = new Blob([JSON.stringify(extractToJson(item), null, 2)], { type: 'application/json' });

    // Construction du lien
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.target = '_blank';
    link.download = `${filename}.json`;

    // Démarrer le téléchargement
    document.body.appendChild(link);
    link.click();

    // Nettoyer
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  const deleteModule = async () => {
    onProcessing(true);

    const results = await remove(aiRoutes.modules + '/' + item._id);

    if (results.status === 200) {
      onFinish();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    onProcessing(false);
  };

  const Actions = memo(({ item }) => {
    return (
      <Menu key={`menu-${item._id}`}>
        {workspace === 'general' ? (
          <>
            {/* MENU POUR MODULES GENERAUX */}
            {item.disabled
              ? checkAuthorization(user, 'ai', 'generalModules', 'enable') && (
                  <Menu.Item key="enable" className="gray-text">
                    <Popconfirm {...popconfirmProps} onConfirm={enableModule}>
                      Activer
                    </Popconfirm>
                  </Menu.Item>
                )
              : checkAuthorization(user, 'ai', 'generalModules', 'disable') && (
                  <Menu.Item key="disable" className="gray-text">
                    <Popconfirm {...popconfirmProps} onConfirm={disableModule}>
                      Désactiver
                    </Popconfirm>
                  </Menu.Item>
                )}
            {checkAuthorization(user, 'ai', 'generalModules', 'update') && (
              <Menu.Item key="edit" className="gray-text">
                <Link to={`/ia/modules/${workspace}/modifier-un-module/${item._id}`}>Modifier</Link>
              </Menu.Item>
            )}
            {checkAuthorization(user, 'ai', 'generalModules', 'create') && (
              <Menu.Item key="duplicate" className="gray-text">
                <Popconfirm {...popconfirmProps} onConfirm={duplicateModule}>
                  Dupliquer
                </Popconfirm>
              </Menu.Item>
            )}
            {checkAuthorization(user, 'ai', 'generalModules', 'export') && (
              <Menu.Item key="export" className="gray-text" onClick={() => exportModule()}>
                Exporter
              </Menu.Item>
            )}
            {checkAuthorization(user, 'ai', 'generalModules', 'delete') && (
              <Menu.Item key="delete" className="gray-text">
                <Popconfirm {...popconfirmProps} onConfirm={deleteModule}>
                  Supprimer
                </Popconfirm>
              </Menu.Item>
            )}
          </>
        ) : (
          <>
            {/* MENU POUR MODULES D'APPLICATION */}
            {item.disabled
              ? checkAuthorization(user, 'ai', 'modules', 'enable') && (
                  <Menu.Item key="enable" className="gray-text">
                    <Popconfirm {...popconfirmProps} onConfirm={enableModule}>
                      Activer
                    </Popconfirm>
                  </Menu.Item>
                )
              : checkAuthorization(user, 'ai', 'modules', 'disable') && (
                  <Menu.Item key="disable" className="gray-text">
                    <Popconfirm {...popconfirmProps} onConfirm={disableModule}>
                      Désactiver
                    </Popconfirm>
                  </Menu.Item>
                )}
            {checkAuthorization(user, 'ai', 'modules', 'update') && (
              <Menu.Item key="edit" className="gray-text">
                <Link to={`/ia/modules/${workspace}/modifier-un-module/${item._id}`}>Modifier</Link>
              </Menu.Item>
            )}
            {checkAuthorization(user, 'ai', 'modules', 'create') && (
              <Menu.Item key="duplicate" className="gray-text">
                <Popconfirm {...popconfirmProps} onConfirm={duplicateModule}>
                  Dupliquer
                </Popconfirm>
              </Menu.Item>
            )}
            {checkAuthorization(user, 'ai', 'modules', 'export') && (
              <Menu.Item key="export" className="gray-text" onClick={() => exportModule()}>
                Exporter
              </Menu.Item>
            )}
            {checkAuthorization(user, 'ai', 'modules', 'delete') && (
              <Menu.Item key="delete" className="gray-text">
                <Popconfirm {...popconfirmProps} onConfirm={deleteModule}>
                  Supprimer
                </Popconfirm>
              </Menu.Item>
            )}
          </>
        )}
      </Menu>
    );
  });

  return (
    <Item
      actions={[
        <Space>
          <Tooltip title="Visualiser le prompt du module" destroyTooltipOnHide={{ keepParent: false }}>
            <Button icon={<EyeOutlined />} disabled={isProcessing} onClick={() => setPromptTestModalOpen(item)} />
          </Tooltip>
          <Dropdown overlay={<Actions item={item} />} placement="bottomRight" trigger={['click']}>
            <Button icon={<MoreOutlined />} disabled={isProcessing} />
          </Dropdown>
        </Space>,
      ]}
    >
      <PromptTestModal
        isOpen={!!promptTestModalOpen}
        module={promptTestModalOpen}
        onClose={() => setPromptTestModalOpen(false)}
      />
      <Meta
        avatar={
          item?.variables?.length ? (
            <CodeOutlined style={{ fontSize: '48px' }} />
          ) : (
            <MessageOutlined style={{ fontSize: '48px' }} />
          )
        }
        title={item.title}
        description={
          item?.activities?.length
            ? `Ce module est utilisé dans ${item.activities.length} activité(s)`
            : "Ce module n'est utilisé dans aucune activité"
        }
      />
    </Item>
  );
};

export default ModuleAiItem;
