import { Card, Form, List, Space, TreeSelect } from 'antd';
import useSWR from 'swr';
import { useMemo } from 'react';
import { useFieldArray, useFormContext, useFormState } from 'react-hook-form';
import { closestCenter, DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

import { aiRoutes } from '../../../../lib/routes';
import AiSelectItem from './AiSelectItem';

const getTreeData = (systems, versions) => {
  if (systems.length === 0 || versions.length === 0) {
    return [];
  }

  return systems.reduce((acc, curr) => {
    const systemVersions = versions.filter((item) => item.ai === curr.id);

    return acc.concat({
      value: curr.id,
      label: curr.name,
      children: systemVersions.map((item) => ({
        value: item._id,
        label: item.name,
        isLeaf: true,
      })),
      isLeaf: systemVersions.length === 0,
      disabled: systemVersions.length === 0,
      selectable: false,
    });
  }, []);
};

const AiSelect = ({ disabled }) => {
  const sensors = useSensors(useSensor(PointerSensor));
  const modifiers = [restrictToVerticalAxis, restrictToWindowEdges];
  const { control } = useFormContext();
  const { errors } = useFormState({ control });
  const { fields, append, remove, move } = useFieldArray({ control, name: 'ai' });
  const { data: aiSystems, isValidating } = useSWR(aiRoutes.default, {
    evalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: aiVersions, isValidating: isVersionsValidating } = useSWR(aiRoutes.versions, {
    evalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const treeData = useMemo(
    () => getTreeData(aiSystems?.data || [], aiVersions?.data || []),
    [aiSystems?.data, aiVersions?.data],
  );

  const onSelect = (value) => {
    const index = fields.findIndex((item) => item.version === value);

    if (index === -1) {
      append({
        version: value,
      });
    }
  };

  const onDragEnd = (event) => {
    const { active, over } = event;

    if (active === null || over === null) {
      return;
    }

    if (active?.id !== over?.id) {
      const activeIndex = fields.findIndex((item) => item.id === active.id);
      const overIndex = fields.findIndex((item) => item.id === over.id);

      return move(activeIndex, overIndex);
    }
  };

  return (
    <Form.Item
      label="IA autorisée(s)"
      tooltip="Si aucune IA n'est sélectionnée, l'application aura accès à toutes les IA"
      validateStatus={errors?.ai?.message && 'error'}
      help={errors?.ai?.message}
    >
      <Card size="small">
        <Space direction="vertical" style={{ display: 'flex' }}>
          <TreeSelect
            {...{ treeData, onSelect }}
            treeExpandAction="click"
            loading={isValidating}
            disabled={isValidating || disabled}
            placeholder="Sélectionner dans la liste"
            style={{ width: '100%' }}
            value={null}
          />
          <DndContext {...{ sensors, modifiers, onDragEnd }} collisionDetection={closestCenter}>
            <SortableContext items={fields} strategy={verticalListSortingStrategy}>
              <List
                bordered
                dataSource={fields}
                size="small"
                pagination={false}
                style={{ overflow: 'hidden' }}
                renderItem={(item, index) => (
                  <AiSelectItem
                    {...{ item, index }}
                    key={item.id}
                    versions={aiVersions?.data || []}
                    onDelete={() => remove(index)}
                    onNotFound={() => remove(index)}
                    disabled={isValidating || disabled}
                  />
                )}
              />
            </SortableContext>
          </DndContext>
        </Space>
      </Card>
    </Form.Item>
  );
};

export default AiSelect;
