import { DeleteOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, List, Row, Space, TreeSelect, Typography } from 'antd';
import { memo, useMemo } from 'react';
import { Controller, useFieldArray, useFormContext, useFormState } from 'react-hook-form';
import useSWR from 'swr';

import { aiRoutes } from '../../../../../lib/routes';

const PersonaVariableItem = memo(({ item, index, personas, onDelete, disabled }) => {
  const persona = personas?.data.find((value) => value._id === item.value);

  return (
    <List.Item style={{ width: '100%' }}>
      <Space style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <Typography.Text>{persona?.name}</Typography.Text>
        <Button onClick={() => onDelete(index)} size="small" icon={<DeleteOutlined />} disabled={disabled} />
      </Space>
    </List.Item>
  );
});
PersonaVariableItem.displayName = 'PersonaVariableItem';

const getPersonasTreeData = (personas, values) => {
  if (personas.length === 0) {
    return [];
  }

  // Liste toutes les catégories
  const categories =
    personas?.reduce((acc, curr) => {
      if (!acc.some((item) => item === curr?.category) && !!curr?.category) {
        acc.push(curr.category);
      }
      return acc;
    }, []) || [];

  const categoryList = [];

  // Filtre les personas par catégorie
  if (categories.length !== 0) {
    for (let i = 0; i < categories.length; i++) {
      const categoryName = categories[i];
      const categoryChildren = personas.filter((item) => item?.category === categoryName);

      categoryList.push({
        value: categoryName,
        label: categoryName,
        children: categoryChildren.map((item) => ({
          value: item._id,
          label: item.name,
          isLeaf: true,
          disabled: true,
          disabled: values.findIndex((vItem) => vItem?.value === item._id) !== -1,
        })),
        isLeaf: categoryChildren.length === 0,
        disabled: categoryChildren.length === 0,
        selectable: false,
      });
    }
  }

  // Ajoute tous les personas sans catégorie
  const parentlessChildren = personas.filter((item) => !item?.category);

  if (parentlessChildren.length !== 0) {
    categoryList.push({
      value: 'nocategory',
      label: 'Non catégorisés',
      children: parentlessChildren.map((item) => ({
        value: item._id,
        label: item.name,
        isLeaf: true,
      })),
      isLeaf: false,
      disabled: false,
      selectable: false,
    });
  }

  return categoryList;
};

const PersonaVariable = ({ index, disabled }) => {
  const { control, clearErrors } = useFormContext();
  const { errors } = useFormState({ control });
  const { fields, append, remove } = useFieldArray({ control, name: `variables.${index}.personas` });
  const { data: personas, isValidating } = useSWR(aiRoutes.personas);

  const treeData = useMemo(() => getPersonasTreeData(personas?.data || [], fields), [personas?.data, fields]);

  const onSelect = (value) => {
    const index = fields.findIndex((item) => item.persona === value);

    if (index === -1) {
      append({ value });
    }
  };

  return (
    <Row gutter={(24, 24)}>
      <Col span={12}>
        <Space direction="vertical" style={{ display: 'flex' }}>
          <Form.Item
            validateStatus={errors?.variables?.[index]?.personas?.message && 'error'}
            help={errors?.variables?.[index]?.personas?.message}
            label="Sélection des personas"
            required
          >
            <Space direction="vertical" style={{ display: 'flex' }}>
              <TreeSelect
                {...{ treeData }}
                onSelect={(value) => {
                  onSelect(value);
                  clearErrors(`variables.${index}.personas`);
                }}
                treeExpandAction="click"
                loading={isValidating}
                disabled={disabled}
                placeholder="Sélectionner dans la liste"
                style={{ width: '100%' }}
                value={null}
              />
              <List
                bordered
                dataSource={fields}
                size="small"
                pagination={false}
                style={{ overflow: 'hidden' }}
                renderItem={(item, index) => (
                  <PersonaVariableItem
                    {...{ item, index, personas }}
                    disabled={disabled || (fields?.length || []) <= 1}
                    onDelete={() => {
                      remove(index);
                      clearErrors(`variables.${index}.personas`);
                    }}
                  />
                )}
              />
            </Space>
          </Form.Item>
        </Space>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Champs à importer"
          validateStatus={errors?.variables?.[index]?.personaFields?.message && 'error'}
          help={errors?.variables?.[index]?.personaFields?.message}
          required
        >
          <Controller
            name={`variables.[${index}].personaFields`}
            control={control}
            render={({ field }) => (
              <Checkbox.Group {...field} disabled={disabled}>
                <Space direction="vertical" style={{ display: 'flex' }}>
                  <Checkbox value="name">Nom</Checkbox>
                  <Checkbox value="category">Catégorie</Checkbox>
                  <Checkbox value="description">Description</Checkbox>
                  <Checkbox value="behaviour">Comportement</Checkbox>
                </Space>
              </Checkbox.Group>
            )}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default PersonaVariable;
