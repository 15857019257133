import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, message } from 'antd';
import { memo, useEffect, useMemo, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import useFetch from '../../../../hooks/useFetch';
import { aiRoutes } from '../../../../lib/routes';

const getDefaultValues = (version) => {
  if (!version) {
    return {};
  }

  return {
    name: version.name,
    value: version.value,
  };
};

const EditVersionModal = memo(({ id, ai, data, isOpen, onClose }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const defaultValues = useMemo(() => getDefaultValues(data), [data]);
  const { control, handleSubmit, setError, reset } = useForm({ defaultValues });
  const { errors } = useFormState({ control });
  const { put } = useFetch();

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const results = await put(aiRoutes.versions + `/${data._id}`, JSON.stringify({ ...form, ai: id.toString() }));

    if (results.status === 200) {
      onClose();
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  return (
    <>
      <Modal
        visible={isOpen}
        title={`Modifier la version pour ${ai}`}
        onCancel={onClose}
        onOk={handleSubmit(onSubmit)}
        okButtonProps={{ loading: isProcessing }}
        cancelButtonProps={{ disabled: isProcessing }}
        destroyOnClose
      >
        <Form layout="vertical">
          <Form.Item
            label="Intitulé"
            required
            validateStatus={errors?.name?.message && 'error'}
            help={errors?.name?.message}
          >
            <Controller
              {...{ control }}
              name="name"
              render={({ field }) => <Input {...field} disabled={isProcessing} />}
            />
          </Form.Item>
          <Form.Item
            label="Valeur"
            required
            validateStatus={errors?.value?.message && 'error'}
            help={errors?.value?.message}
            tooltip="Cette valeur est utilisée dans les requêtes sur l'API de l'IA."
          >
            <Controller
              {...{ control }}
              name="value"
              render={({ field }) => <Input {...field} disabled={isProcessing} />}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
});

EditVersionModal.displayName = 'EditVersionModal';
export default EditVersionModal;
