import { Col, Form, InputNumber, Row, Space, Switch, Typography } from 'antd';
import { Controller, useFormContext, useFormState } from 'react-hook-form';

const RandomNumberVariable = ({ index, disabled }) => {
  const { control, watch, setValue } = useFormContext();
  const minValue = watch(`variables.[${index}].min`);
  const maxValue = watch(`variables.[${index}].max`);
  const isDecimal = watch(`variables.[${index}].isDecimal`);
  const digits = watch(`variables.[${index}].digits`);
  const { errors } = useFormState({ control });

  const onMinChange = (value, onChange) => {
    if (value < maxValue) {
      onChange(value);
    }
  };

  const onMaxChange = (value, onChange) => {
    if (value > minValue) {
      onChange(value);
    }
  };

  const onToggle = (value, onChange) => {
    if (!value) {
      let roundedMin = Math.round(minValue);
      const roundedMax = Math.round(maxValue);

      if (roundedMin >= roundedMax) {
        roundedMin = roundedMax - 1;
      }

      setValue(`variables.[${index}].min`, roundedMin);
      setValue(`variables.[${index}].max`, roundedMax);
    }
    onChange(value);
  };

  const onDigitChange = (value, onChange) => {
    const factor = Math.pow(10, value);

    let roundedMin = Math.round(minValue * factor);
    const roundedMax = Math.round(maxValue * factor);

    if (roundedMin >= roundedMax) {
      roundedMin = roundedMax - 1;
    }

    setValue(`variables.[${index}].min`, roundedMin / factor);
    setValue(`variables.[${index}].max`, roundedMax / factor);

    onChange(value);
  };

  return (
    <Row gutter={(24, 24)} align="bottom">
      <Col span={12}>
        <Space direction="vertical" style={{ display: 'flex' }}>
          <Form.Item
            validateStatus={errors?.variables?.[index]?.min?.message && 'error'}
            help={errors?.variables?.[index]?.min?.message}
            label="Valeur minimale"
            required
            style={{ marginBottom: 0 }}
          >
            <Controller
              name={`variables.[${index}].min`}
              control={control}
              render={({ field }) => (
                <InputNumber
                  {...field}
                  precision={isDecimal ? digits : 0}
                  disabled={disabled}
                  onChange={(value) => onMinChange(value, field.onChange)}
                  step={isDecimal ? Math.pow(10, -digits) : 1}
                  style={{ width: '200px' }}
                />
              )}
            />
          </Form.Item>
          <Form.Item
            validateStatus={errors?.variables?.[index]?.max?.message && 'error'}
            help={errors?.variables?.[index]?.max?.message}
            label="Valeur maximale"
            required
            style={{ marginBottom: 0 }}
          >
            <Controller
              name={`variables.[${index}].max`}
              control={control}
              render={({ field }) => (
                <InputNumber
                  {...field}
                  precision={isDecimal ? digits : 0}
                  disabled={disabled}
                  onChange={(value) => onMaxChange(value, field.onChange)}
                  step={isDecimal ? Math.pow(10, -digits) : 1}
                  style={{ width: '200px' }}
                />
              )}
            />
          </Form.Item>
        </Space>
      </Col>
      <Col span={12}>
        <Space direction="vertical" style={{ display: 'flex' }}>
          <Space>
            <Controller
              name={`variables.[${index}].isDecimal`}
              control={control}
              render={({ field }) => (
                <Switch
                  checked={field.value}
                  disabled={disabled}
                  onChange={(value) => onToggle(value, field.onChange)}
                  size="small"
                />
              )}
            />
            <Typography.Text>Accepter les valeurs décimales ?</Typography.Text>
          </Space>
          <Form.Item
            validateStatus={errors?.variables?.[index]?.personas?.message && 'error'}
            help={errors?.variables?.[index]?.personas?.message}
            label="Nombre de chiffres après la virgule"
            required={isDecimal}
            style={{ marginTop: '5px', marginBottom: 0 }}
          >
            <Controller
              name={`variables.[${index}].digits`}
              control={control}
              render={({ field }) => (
                <InputNumber
                  {...field}
                  disabled={!isDecimal}
                  disabled={disabled}
                  onChange={(value) => onDigitChange(value, field.onChange)}
                  style={{ width: '200px' }}
                  min={1}
                  max={10}
                />
              )}
            />
          </Form.Item>
        </Space>
      </Col>
    </Row>
  );
};

export default RandomNumberVariable;
