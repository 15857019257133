import { Form, Input, Modal, Select, message } from 'antd';
import { memo, useEffect, useMemo, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import useSWR from 'swr';
import useFetch from '../../../../hooks/useFetch';
import { aiRoutes } from '../../../../lib/routes';

const getDefaultValues = (apiKey) => {
  if (!apiKey) {
    return {};
  }

  return {
    name: apiKey.name,
    value: apiKey.value,
    restrictions: apiKey?.restrictions || [],
  };
};

const EditApiKeyModal = memo(({ id, ai, data, isOpen, onClose }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const defaultValues = useMemo(() => getDefaultValues(data), [data]);
  const { control, handleSubmit, setError, reset } = useForm({ defaultValues });
  const { errors } = useFormState({ control });
  const { data: versions, isValidating } = useSWR(aiRoutes.default + '/' + id + '/versions', {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { put } = useFetch();

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const results = await put(aiRoutes.keys + `/${data._id}`, JSON.stringify({ ...form, ai: id.toString() }));

    if (results.status === 200) {
      onClose();
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  return (
    <Modal
      visible={isOpen}
      title={`Modifier la clé API pour ${ai}`}
      onCancel={onClose}
      onOk={handleSubmit(onSubmit)}
      okButtonProps={{ loading: isProcessing }}
      cancelButtonProps={{ disabled: isProcessing }}
      destroyOnClose
    >
      <Form layout="vertical">
        <Form.Item
          label="Intitulé (10 caractères max)"
          required
          validateStatus={errors?.name?.message && 'error'}
          help={errors?.name?.message}
        >
          <Controller {...{ control }} name="name" render={({ field }) => <Input {...field} maxLength={10} />} />
        </Form.Item>
        <Form.Item
          label="Clé"
          tooltip="Laissez le champ libre pour conserver la clé existante. Entrer une nouvelle valeur remplacera la clé existante définitivement"
          validateStatus={errors?.value?.message && 'error'}
          help={errors?.value?.message}
        >
          <Controller
            {...{ control }}
            name="value"
            render={({ field }) => <Input.Password {...field} placeholder={'*'.repeat(50)} />}
          />
        </Form.Item>
        <Form.Item
          label="Restrictions"
          tooltip="Seulement les versions sélectionnées seront utilisables avec cette clé. Laissez vide pour n'appliquer aucune restriction."
          validateStatus={errors?.restrictions?.message && 'error'}
          help={errors?.restrictions?.message}
        >
          <Controller
            {...{ control }}
            name="restrictions"
            render={({ field }) => (
              <Select
                {...field}
                mode="multiple"
                options={versions?.data?.map((item) => ({ label: item.name, value: item._id }))}
                loading={isValidating}
                placeholder="Sélectionnez dans la liste"
              />
            )}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
});

EditApiKeyModal.displayName = 'EditApiKeyModal';
export default EditApiKeyModal;
