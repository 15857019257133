import { Form, Modal } from 'antd';
import { useState } from 'react';
import useSWR from 'swr';
import { FormProvider, useForm } from 'react-hook-form';

import { aiRoutes } from '../../../../lib/routes';
import { useAuth } from '../../../../authContext';
import { checkAuthorization } from '../../../../shared/utils';
import ImageProcessingViewer from './components/ImageProcessingViewer';
import ImageProcessingUserSelection from './components/imageProcessingUserSelection';

const SWROptions = {
  revalidateOnReconnect: false,
  revalidateOnFocus: false,
  revalidateOnRefresh: false,
};

const ViewImageProcessingModal = ({ conversationId, isOpen, onClose, onFinish }) => {
  const [displayPrompt, setDisplayPrompt] = useState(false);
  const { data: conversation } = useSWR(conversationId ? `${aiRoutes.images}/${conversationId}` : '', SWROptions);
  const methods = useForm({ defaultValues: { activity: null, prompt: '', numberImages: 1, imageSize: '1024x1024' } });

  const { user } = useAuth();

  return (
    <Modal
      width={800}
      open={isOpen}
      onCancel={() => {
        methods.reset();
        setDisplayPrompt(false);
        onClose();
      }}
      footer={null}
      destroyOnClose
    >
      <FormProvider {...methods}>
        <Form noValidate layout="vertical">
          <Form.Item label="Traitement d'image">
            <ImageProcessingViewer conversation={conversation?.data || {}} displayPrompt={displayPrompt} />
          </Form.Item>

          {checkAuthorization(user, 'ai', 'api', 'debug') && (
            <ImageProcessingUserSelection
              imageInteraction={conversation?.data || {}}
              displayPrompt={displayPrompt}
              onDisplayPromptChange={setDisplayPrompt}
            />
          )}
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default ViewImageProcessingModal;
