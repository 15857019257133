import React from 'react';
import { HashRouter, Switch, Route } from 'react-router-dom';
import Login from './pages/auth/Login';
import PrivateRoute from './privateRoute';
import CustomFormaoModulesPrivateRoute from './customFormaoModulesPrivateRoute';
import PageLayout from './layout';
import Dashboard from './pages/Dashboard';
import UsersList from './pages/global/users/UsersList';
import RolesList from './pages/global/roles/RolesList';
import RoleCreate from './pages/global/roles/RoleCreate';
import RoleUpdate from './pages/global/roles/RoleUpdate';
import AgenciesList from './pages/global/agencies/AgenciesList';
import KeysList from './pages/global/keys/KeysList';
import KeyCreate from './pages/global/keys/keyCreate';
import KeyUpdate from './pages/global/keys/keyUpdate';
import InterventionsList from './pages/global/externalInterventions/InterventionsList';
// Arinfo
import PostsList from './pages/arinfo/posts/PostsList';
import PostCreate from './pages/arinfo/posts/PostCreate';
import PostUpdate from './pages/arinfo/posts/PostUpdate';
import TrainingsList from './pages/arinfo/trainings/TrainingsList';
import TrainingCreate from './pages/arinfo/trainings/TrainingCreate';
import TrainingUpdate from './pages/arinfo/trainings/TrainingUpdate';
import TrainingMoreCreate from './pages/arinfo/trainings/TrainingMoreCreate';
import TrainingMoreUpdate from './pages/arinfo/trainings/TrainingMoreUpdate';
import ProjectsList from './pages/arinfo/projects/ProjectsList';
import ProjectCreate from './pages/arinfo/projects/ProjectCreate';
import ProjectUpdate from './pages/arinfo/projects/ProjectUpdate';
import StatisticsList from './pages/arinfo/statistics/StatisticsList';
import StatisticsCreate from './pages/arinfo/statistics/StatisticsCreate';
import StatisticsUpdate from './pages/arinfo/statistics/StatisticsUpdate';
import RegistrationsList from './pages/arinfo/registrations/RegistrationsList';
import WorkshopsList from './pages/arinfo/workshops/WorkshopsList';
import WorkshopCreate from './pages/arinfo/workshops/WorkshopCreate';
import WorkshopEdit from './pages/arinfo/workshops/WorkshopEdit';
import WorkshopRegistrations from './pages/arinfo/workshops/WorkshopRegistrations';
import FAQList from './pages/arinfo/faq/FAQList';
// Posiao (tests de positionnement)
import QuestionsList from './pages/posiao/questions/QuestionsList';
import CreateCheckboxQuestion from './pages/posiao/questions/CreateCheckboxQuestion';
import CreateOrderingQuestion from './pages/posiao/questions/CreateOrderingQuestion';
import CreateOrganizeQuestion from './pages/posiao/questions/CreateOrganizeQuestion';
import CreateIllustratedQuestion from './pages/posiao/questions/CreateIllustratedQuestion';
import CreateLegendQuestion from './pages/posiao/questions/CreateLegendQuestion';
import CreateTrueFalseQuestion from './pages/posiao/questions/CreateTrueFalseQuestion';
import CreateCompletionQuestion from './pages/posiao/questions/CreateCompletionQuestion';
import CreateConnectQuestion from './pages/posiao/questions/CreateConnectQuestion';
import UpdateCheckboxQuestion from './pages/posiao/questions/UpdateCheckboxQuestion';
import UpdateOrderingQuestion from './pages/posiao/questions/UpdateOrderingQuestion';
import UpdateOrganizeQuestion from './pages/posiao/questions/UpdateOrganizeQuestion';
import UpdateIllustratedQuestion from './pages/posiao/questions/UpdateIllustratedQuestion';
import UpdateLegendQuestion from './pages/posiao/questions/UpdateLegendQuestion';
import UpdateTrueFalseQuestion from './pages/posiao/questions/UpdateTrueFalseQuestion';
import UpdateCompletionQuestion from './pages/posiao/questions/UpdateCompletionQuestion';
import UpdateConnectQuestion from './pages/posiao/questions/UpdateConnectQuestion';
import QuizzesList from './pages/posiao/quizzes/QuizzesList';
import TypesList from './pages/posiao/types/TypesList';
import EvaluationsList from './pages/posiao/evaluations/EvaluationsList';
import EvaluationCreate from './pages/posiao/evaluations/EvaluationCreate';
import EvaluationUpdate from './pages/posiao/evaluations/EvaluationUpdate';
// I-maginelab
// import ImaginelabPostsList from './pages/imaginelab/posts/ImaginelabPostsList';
// import ImaginelabPostCreate from './pages/imaginelab/posts/ImaginelabPostCreate';
// import ImaginelabPostEdit from './pages/imaginelab/posts/ImaginelabPostEdit';
import ImaginelabWorkshopsList from './pages/imaginelab/workshops/ImaginelabWorkshopsList';
import ImaginelabWorkshopCreate from './pages/imaginelab/workshops/WorkshopCreate';
import ImaginelabWorkshopEdit from './pages/imaginelab/workshops/WorkshopEdit';
import ImaginelabBookingsList from './pages/imaginelab/bookings/BookingsList';
import ImaginelabDocumentsList from './pages/imaginelab/documents/ImaginelabDocumentsList';
// Formao
import StudentsList from './pages/formao/students/StudentsList';
import StudentCreate from './pages/formao/students/StudentCreate';
import StudentEdit from './pages/formao/students/StudentEdit';
import StudentImport from './pages/formao/students/StudentImport';
import GroupsList from './pages/formao/groups/GroupsList';
import GroupCreate from './pages/formao/groups/GroupCreate';
import GroupEdit from './pages/formao/groups/GroupEdit';
import ModulesList from './pages/formao/modules/ModulesList';
import ModuleCreate from './pages/formao/modules/ModuleCreate';
import ModuleEdit from './pages/formao/modules/ModuleEdit';
import ModuleImportPackages from './pages/formao/modules/ModuleImportExport/ModuleImportPackages';
import FormationsList from './pages/formao/formations/FormationsList';
import ProgramCreate from './pages/formao/formations/FormationCreate';
import ProgramEdit from './pages/formao/formations/FormationEdit';
import DocumentsList from './pages/formao/documents/DocumentsList';
import EventsList from './pages/formao/events/EventsList';
import FormationReportCreate from './pages/formao/bilans/formation/FormationReportCreate';
import CompanyReportCreate from './pages/formao/bilans/company/CompanyReportCreate';
import MonthlyReportCreate from './pages/formao/bilans/mensuel/MonthlyReportCreate';
import AlternationMonthlyReportCreate from './pages/formao/bilans/mensuel/AlternationMonthlyReportCreate';
import TrainingReportCreate from './pages/formao/bilans/stage/TrainingReportCreate';
import SatisfactionReportCreate from './pages/formao/bilans/satisfaction/SatisfactionReportCreate';
import FormationReportUpdate from './pages/formao/bilans/formation/FormationReportUpdate';
import CompanyReportUpdate from './pages/formao/bilans/company/CompanyReportUpdate';
import MonthlyReportUpdate from './pages/formao/bilans/mensuel/MonthlyReportUpdate';
import TrainingReportUpdate from './pages/formao/bilans/stage/TrainingReportUpdate';
import FormList from './pages/formao/formulairesBilans/FormList';
import FormCreate from './pages/formao/formulairesBilans/FormCreate';
import FormUpdate from './pages/formao/formulairesBilans/FormUpdate';
import ReportsList from './pages/formao/bilans/ReportsList';
// IA
import SystemsList from './pages/ai/systems/SystemsList';
import ApiAi from './pages/ai/api/ApiAi';
import ApplicationsAiList from './pages/ai/applications/ApplicationsAiList';
import ApplicationAiCreate from './pages/ai/applications/ApplicationAiCreate';
import ApplicationAiEdit from './pages/ai/applications/ApplicationAiEdit';
import ActivitiesAiAppSelection from './pages/ai/activities/ActivitiesAiAppSelection';
import ActivitiesAiList from './pages/ai/activities/ActivitiesAiList';
import ActivityAiCreate from './pages/ai/activities/ActivityAiCreate';
import ActivityAiEdit from './pages/ai/activities/ActivityAiEdit';
import ModuleAiAppSelection from './pages/ai/modules/ModuleAiAppSelection';
import ModulesAiList from './pages/ai/modules/ModulesAiList';
import ModuleAiCreate from './pages/ai/modules/ModuleAiCreate';
import ModuleAiEdit from './pages/ai/modules/ModuleAiEdit';
import PersonasList from './pages/ai/personas/PersonasList';
import PersonaCreate from './pages/ai/personas/PersonaCreate';
import PersonaEdit from './pages/ai/personas/PersonaEdit';
import InteractionsAiList from './pages/ai/interactions/InteractionsAiList';
import UsageAiDisplay from './pages/ai/logs/UsageAiDisplay';
// Développement
import ApiScorm from './pages/global/apis/scorm/ApiScorm';
// Account
import Settings from './pages/account/Settings';
// 404
import NoMatch from './pages/NoMatch';
import Home from './pages/arinfo/enterprise/home';
import CreateOrUpdateFormation from './pages/arinfo/enterprise/create-or-update';

const Router = () => (
  <HashRouter>
    <PageLayout>
      <Switch>
        <Route path="/connexion" exact component={Login} />
        <PrivateRoute path="/" exact component={Dashboard} />
        <PrivateRoute
          path="/utilisateurs"
          exact
          component={UsersList}
          database="global"
          collection="users"
          authorization="search"
        />
        <PrivateRoute
          path="/roles"
          exact
          component={RolesList}
          database="global"
          collection="roles"
          authorization="search"
        />
        <PrivateRoute
          path="/roles/nouveau-role"
          exact
          component={RoleCreate}
          database="global"
          collection="roles"
          authorization="create-role"
        />
        <PrivateRoute
          path="/roles/modifier-un-role/:id"
          exact
          component={RoleUpdate}
          database="global"
          collection="roles"
          authorization="update-role"
        />
        <PrivateRoute
          path="/cles"
          exact
          component={KeysList}
          database="global"
          collection="keys"
          authorization="search"
        />
        <PrivateRoute
          path="/cles/nouvelle-cle"
          exact
          component={KeyCreate}
          database="global"
          collection="keys"
          authorization="create"
        />
        <PrivateRoute
          path="/cles/modifier-une-cle/:id"
          exact
          component={KeyUpdate}
          database="global"
          collection="keys"
          authorization="update"
        />
        <PrivateRoute path="/centres" exact component={AgenciesList} />
        <PrivateRoute
          path="/gestion-des-pre-inscriptions"
          exact
          component={RegistrationsList}
          database="arinfo"
          collection="registrations"
          authorization="search"
        />
        <PrivateRoute
          path="/interventions-externes"
          exact
          component={InterventionsList}
          database="global"
          collection="externalInterventions"
          authorization="search"
        />
        {/* Pages d'Arinfo */}
        <PrivateRoute path="/arinfo/actualites" exact component={PostsList} />
        <PrivateRoute
          path="/arinfo/actualites/nouvelle-actualite"
          exact
          component={PostCreate}
          database="arinfo"
          collection="posts"
          authorization="create-post"
        />
        <PrivateRoute
          path="/arinfo/actualites/modifier-une-actualite/:id"
          exact
          component={PostUpdate}
          database="arinfo"
          collection="posts"
          authorization="update-post"
        />
        <PrivateRoute path="/arinfo/formations" exact component={TrainingsList} />
        <PrivateRoute
          path="/arinfo/formations/nouvelle-formation"
          exact
          component={TrainingCreate}
          database="arinfo"
          collection="trainings"
          authorization="create-training"
        />
        <PrivateRoute
          path="/arinfo/formations/modifier-une-formation/:id"
          exact
          component={TrainingUpdate}
          database="arinfo"
          collection="trainings"
          authorization="update-training"
        />
        <PrivateRoute
          path="/arinfo/formations/nouvelle-page-en-savoir-plus/:id"
          exact
          component={TrainingMoreCreate}
          database="arinfo"
          collection="trainings"
          authorization="create-training-learn-more"
        />
        <PrivateRoute
          path="/arinfo/formations/modifier-page-en-savoir-plus/:id"
          exact
          component={TrainingMoreUpdate}
          database="arinfo"
          collection="trainings"
          authorization="update-training-learn-more"
        />
        <PrivateRoute path="/arinfo/galerie" exact component={ProjectsList} />
        <PrivateRoute
          path="/arinfo/galerie/nouveau-projet"
          exact
          component={ProjectCreate}
          database="arinfo"
          collection="projects"
          authorization="create-project"
        />
        <PrivateRoute
          path="/arinfo/galerie/modifier-un-projet/:id"
          exact
          component={ProjectUpdate}
          database="arinfo"
          collection="projects"
          authorization="update-project"
        />
        <PrivateRoute path="/arinfo/statistiques" exact component={StatisticsList} />
        <PrivateRoute
          path="/arinfo/statistiques/nouvelles-statistiques"
          exact
          component={StatisticsCreate}
          database="arinfo"
          collection="statistics"
          authorization="create-statistics"
        />
        <PrivateRoute
          path="/arinfo/statistiques/modifier-les-statistiques/:id"
          exact
          component={StatisticsUpdate}
          database="arinfo"
          collection="statistics"
          authorization="update-statistics"
        />
        <PrivateRoute
          path="/arinfo/ateliers"
          exact
          component={WorkshopsList}
          database="arinfo"
          collection="workshops"
          authorization="search"
        />
        <PrivateRoute
          path="/arinfo/ateliers/nouvel-atelier"
          exact
          component={WorkshopCreate}
          database="arinfo"
          collection="workshops"
          authorization="create"
        />
        <PrivateRoute
          path="/arinfo/ateliers/modifier-un-atelier/:id"
          exact
          component={WorkshopEdit}
          database="arinfo"
          collection="workshops"
          authorization="update"
        />
        <PrivateRoute
          path="/arinfo/pre-inscriptions-ateliers"
          exact
          component={WorkshopRegistrations}
          database="arinfo"
          collection="workshops"
          authorization="search-registration"
        />
        <PrivateRoute
          path="/arinfo/faq"
          exact
          component={FAQList}
          // database="arinfo"
          // collection="faq"
          // authorization="search-registration"
        />
        <PrivateRoute
          path="/arinfo/inter-intra"
          exact
          component={Home}
          database="arinfo"
          collection="interFormations"
          authorization="access-formations"
        />
        <PrivateRoute
          path="/arinfo/inter-intra/:categoryId/formations/:formationId"
          exact
          component={CreateOrUpdateFormation}
          // database="arinfo"
          // collection="faq"
          // authorization="search-registration"
        />
        {/* Pages de Posiao */}
        <PrivateRoute
          path="/posiao/banque-de-question"
          exact
          component={QuestionsList}
          database="posiao"
          collection="questions"
          authorization="search"
        />
        <PrivateRoute
          path="/posiao/banque-de-question/nouvelle-question/cases-a-cocher"
          exact
          component={CreateCheckboxQuestion}
          database="posiao"
          collection="questions"
          authorization="create"
        />
        <PrivateRoute
          path="/posiao/banque-de-question/nouvelle-question/points-a-reliers"
          exact
          component={CreateConnectQuestion}
          database="posiao"
          collection="questions"
          authorization="create"
        />
        <PrivateRoute
          path="/posiao/banque-de-question/nouvelle-question/texte-a-completer"
          exact
          component={CreateCompletionQuestion}
          database="posiao"
          collection="questions"
          authorization="create"
        />
        <PrivateRoute
          path="/posiao/banque-de-question/nouvelle-question/liste-a-ordonner"
          exact
          component={CreateOrderingQuestion}
          database="posiao"
          collection="questions"
          authorization="create"
        />
        <PrivateRoute
          path="/posiao/banque-de-question/nouvelle-question/elements-a-trier"
          exact
          component={CreateOrganizeQuestion}
          database="posiao"
          collection="questions"
          authorization="create"
        />
        <PrivateRoute
          path="/posiao/banque-de-question/nouvelle-question/cases-a-cocher-illustrees"
          exact
          component={CreateIllustratedQuestion}
          database="posiao"
          collection="questions"
          authorization="create"
        />
        <PrivateRoute
          path="/posiao/banque-de-question/nouvelle-question/legendes-a-completer"
          exact
          component={CreateLegendQuestion}
          database="posiao"
          collection="questions"
          authorization="create"
        />
        <PrivateRoute
          path="/posiao/banque-de-question/nouvelle-question/vrai-ou-faux"
          exact
          component={CreateTrueFalseQuestion}
          database="posiao"
          collection="questions"
          authorization="create"
        />
        <PrivateRoute
          path="/posiao/banque-de-question/modifier-une-question/cases-a-cocher/:id"
          exact
          component={UpdateCheckboxQuestion}
          database="posiao"
          collection="questions"
          authorization="update"
        />
        <PrivateRoute
          path="/posiao/banque-de-question/modifier-une-question/points-a-reliers/:id"
          exact
          component={UpdateConnectQuestion}
          database="posiao"
          collection="questions"
          authorization="update"
        />
        <PrivateRoute
          path="/posiao/banque-de-question/modifier-une-question/texte-a-completer/:id"
          exact
          component={UpdateCompletionQuestion}
          database="posiao"
          collection="questions"
          authorization="update"
        />
        <PrivateRoute
          path="/posiao/banque-de-question/modifier-une-question/liste-a-ordonner/:id"
          exact
          component={UpdateOrderingQuestion}
          database="posiao"
          collection="questions"
          authorization="update"
        />
        <PrivateRoute
          path="/posiao/banque-de-question/modifier-une-question/elements-a-trier/:id"
          exact
          component={UpdateOrganizeQuestion}
          database="posiao"
          collection="questions"
          authorization="update"
        />
        <PrivateRoute
          path="/posiao/banque-de-question/modifier-une-question/cases-a-cocher-illustrees/:id"
          exact
          component={UpdateIllustratedQuestion}
          database="posiao"
          collection="questions"
          authorization="update"
        />
        <PrivateRoute
          path="/posiao/banque-de-question/modifier-une-question/legendes-a-completer/:id"
          exact
          component={UpdateLegendQuestion}
          database="posiao"
          collection="questions"
          authorization="update"
        />
        <PrivateRoute
          path="/posiao/banque-de-question/modifier-une-question/vrai-ou-faux/:id"
          exact
          component={UpdateTrueFalseQuestion}
          database="posiao"
          collection="questions"
          authorization="update"
        />
        <PrivateRoute
          path="/posiao/suivi-des-tests"
          exact
          component={QuizzesList}
          database="posiao"
          collection="quizzes"
          authorization="search"
        />
        <PrivateRoute
          path="/posiao/categories-de-quiz"
          exact
          component={TypesList}
          database="posiao"
          collection="types"
          authorization="search"
        />
        <PrivateRoute
          path="/posiao/auto-evaluations"
          exact
          component={EvaluationsList}
          database="posiao"
          collection="evaluations"
          authorization="search"
        />
        <PrivateRoute
          path="/posiao/auto-evaluations/nouvelle-auto-evaluation"
          exact
          component={EvaluationCreate}
          database="posiao"
          collection="evaluations"
          authorization="create"
        />
        <PrivateRoute
          path="/posiao/auto-evaluations/modifier-une-auto-evaluation/:id"
          exact
          component={EvaluationUpdate}
          database="posiao"
          collection="evaluations"
          authorization="update"
        />
        {/* Pages d'I-maginelab */}
        {/* <Route path="/imaginelab/blog/" exact component={ImaginelabPostsList} />
        <Route path="/imaginelab/blog/nouveau-post" exact component={ImaginelabPostCreate} />
        <Route path="/imaginelab/blog/modifier-un-post/:id" exact component={ImaginelabPostEdit} /> */}
        <PrivateRoute
          path="/imaginelab/ateliers/"
          exact
          component={ImaginelabWorkshopsList}
          database="imaginelab"
          collection="workshops"
          authorization="search"
        />
        <PrivateRoute
          path="/imaginelab/ateliers/nouvel-atelier"
          exact
          component={ImaginelabWorkshopCreate}
          database="imaginelab"
          collection="workshops"
          authorization="create-workshop"
        />
        <PrivateRoute
          path="/imaginelab/ateliers/modifier-un-atelier/:id"
          exact
          component={ImaginelabWorkshopEdit}
          database="imaginelab"
          collection="workshops"
          authorization="update-workshop"
        />
        <PrivateRoute
          path="/imaginelab/reservations"
          exact
          component={ImaginelabBookingsList}
          database="imaginelab"
          collection="bookings"
          authorization="search"
        />
        <PrivateRoute
          path="/imaginelab/documents"
          exact
          component={ImaginelabDocumentsList}
          database="imaginelab"
          collection="documents"
          authorization="search"
        />
        {/* Pages de Formao */}
        <PrivateRoute
          path="/formao/apprenants"
          exact
          component={StudentsList}
          database="formao"
          collection="students"
          authorization="search"
        />
        <PrivateRoute
          path="/formao/apprenants/nouvel-apprenant"
          exact
          component={StudentCreate}
          database="formao"
          collection="students"
          authorization="create-student"
        />
        <PrivateRoute
          path="/formao/apprenants/modifier-un-apprenant/:id"
          exact
          component={StudentEdit}
          database="formao"
          collection="students"
          authorization="update-student"
        />
        <PrivateRoute
          path="/formao/apprenants/import"
          exact
          component={StudentImport}
          database="formao"
          collection="students"
          authorization="create-student"
        />
        <PrivateRoute
          path="/formao/sessions"
          exact
          component={GroupsList}
          database="formao"
          collection="groups"
          authorization="search"
        />
        <PrivateRoute
          path="/formao/sessions/nouvelle-session"
          exact
          component={GroupCreate}
          database="formao"
          collection="groups"
          authorization="create-group"
        />
        <PrivateRoute
          path="/formao/sessions/modifier-une-session/:id"
          exact
          component={GroupEdit}
          database="formao"
          collection="groups"
          authorization="update-group"
        />
        <PrivateRoute
          path="/formao/cours"
          exact
          component={ModulesList}
          database="formao"
          collection="modules"
          authorization="search"
        />
        <PrivateRoute
          path="/formao/cours/nouveau-cours"
          exact
          component={ModuleCreate}
          database="formao"
          collection="modules"
          authorization="create-module"
        />
        <CustomFormaoModulesPrivateRoute path="/formao/cours/modifier-un-cours/:id" exact component={ModuleEdit} />
        <PrivateRoute
          path="/formao/cours/importer-un-package/:packageType"
          exact
          component={ModuleImportPackages}
          database="formao"
          collection="packages"
          authorization="import"
        />
        <PrivateRoute
          path="/formao/formations"
          exact
          component={FormationsList}
          database="formao"
          collection="formations"
          authorization="search"
        />
        <PrivateRoute
          path="/formao/formations/nouvelle-formation"
          exact
          component={ProgramCreate}
          database="formao"
          collection="formations"
          authorization="create-formation"
        />
        <PrivateRoute
          path="/formao/formations/modifier-une-formation/:id"
          exact
          component={ProgramEdit}
          database="formao"
          collection="formations"
          authorization="update-formation"
        />
        <PrivateRoute
          path="/formao/documents"
          exact
          component={DocumentsList}
          database="formao"
          collection="documents"
          authorization="search"
        />
        <PrivateRoute
          path="/formao/evenements"
          exact
          component={EventsList}
          database="formao"
          collection="events"
          authorization="search"
        />
        <PrivateRoute
          path="/formao/bilans/bilans-de-formation/nouveau-bilan"
          exact
          component={FormationReportCreate}
          database="formao"
          collection="formationReports"
          authorization="create"
        />
        <PrivateRoute
          path="/formao/bilans/bilans-entreprise/nouveau-bilan"
          exact
          component={CompanyReportCreate}
          database="formao"
          collection="companyReports"
          authorization="create"
        />
        <PrivateRoute
          path="/formao/bilans/bilans-de-stage/nouveau-bilan"
          exact
          component={TrainingReportCreate}
          database="formao"
          collection="trainingReports"
          authorization="create"
        />
        <PrivateRoute
          path="/formao/bilans/suivis-mensuels/nouveau-bilan-alt"
          exact
          component={AlternationMonthlyReportCreate}
          database="formao"
          collection="monthlyReports"
          authorization="create"
        />
        <PrivateRoute
          path="/formao/bilans/suivis-mensuels/nouveau-bilan-tc"
          exact
          component={MonthlyReportCreate}
          database="formao"
          collection="monthlyReports"
          authorization="create"
        />
        <PrivateRoute
          path="/formao/bilans/bilans-de-formation/modifier-un-bilan/:id"
          exact
          component={FormationReportUpdate}
          database="formao"
          collection="formationReports"
          authorization="update"
        />
        <PrivateRoute
          path="/formao/bilans/bilans-entreprise/modifier-un-bilan/:id"
          exact
          component={CompanyReportUpdate}
          database="formao"
          collection="companyReports"
          authorization="update"
        />
        <PrivateRoute
          path="/formao/bilans/bilans-de-stage/modifier-un-bilan/:id"
          exact
          component={TrainingReportUpdate}
          database="formao"
          collection="trainingReports"
          authorization="update"
        />
        <PrivateRoute
          path="/formao/bilans/suivis-mensuels/modifier-un-bilan/:id"
          exact
          component={MonthlyReportUpdate}
          database="formao"
          collection="monthlyReports"
          authorization="update"
        />
        <PrivateRoute
          path="/formao/bilans/bilans-de-satisfaction/nouveau-bilan"
          exact
          component={SatisfactionReportCreate}
          database="formao"
          collection="satisfactionReports"
          authorization="create"
        />
        <PrivateRoute
          path="/formao/formulaires-de-bilans"
          exact
          component={FormList}
          database="formao"
          collection="reportForms"
          authorization="search"
        />
        <PrivateRoute
          path="/formao/formulaires-de-bilans/nouveau-formulaire"
          exact
          component={FormCreate}
          database="formao"
          collection="reportForms"
          authorization="create"
        />
        <PrivateRoute
          path="/formao/formulaires-de-bilans/modifier-un-formulaire/:id"
          exact
          component={FormUpdate}
          database="formao"
          collection="reportForms"
          authorization="update"
        />
        <PrivateRoute path="/formao/bilans" exact component={ReportsList} /> // TODO: autorisation multiple
        {/* IA */}
        <PrivateRoute
          path="/ia/systemes"
          exact
          component={SystemsList}
          database="ai"
          collection="systems"
          authorization="search"
        />
        <PrivateRoute
          path="/ia/api"
          exact
          component={ApiAi}
          database="ai"
          collection="systems"
          authorization="search"
        />
        <PrivateRoute
          path="/ia/usage"
          exact
          component={UsageAiDisplay}
          database="ai"
          collection="logs"
          authorization="search"
        />
        <PrivateRoute
          path="/ia/applications"
          exact
          component={ApplicationsAiList}
          database="ai"
          collection="applications"
          authorization="search"
        />
        <PrivateRoute
          path="/ia/applications/nouvelle-application"
          exact
          component={ApplicationAiCreate}
          database="ai"
          collection="applications"
          authorization="create"
        />
        <PrivateRoute
          path="/ia/applications/modifier-une-application/:id"
          exact
          component={ApplicationAiEdit}
          database="ai"
          collection="applications"
          authorization="update"
        />
        <PrivateRoute
          path="/ia/activites"
          exact
          component={ActivitiesAiAppSelection}
          database="ai"
          collection="activities"
          authorization="search"
        />
        <PrivateRoute
          path="/ia/activites/:workspace"
          exact
          component={ActivitiesAiList}
          database="ai"
          collection="activities"
          authorization="search"
        />
        <PrivateRoute
          path="/ia/activites/:workspace/nouvelle-activite"
          exact
          component={ActivityAiCreate}
          database="ai"
          collection="activities"
          authorization="create"
        />
        <PrivateRoute
          path="/ia/activites/:workspace/modifier-une-activite/:id"
          exact
          component={ActivityAiEdit}
          database="ai"
          collection="activities"
          authorization="update"
        />
        <PrivateRoute
          path="/ia/modules"
          exact
          component={ModuleAiAppSelection}
          database="ai"
          collection="modules"
          authorization="search"
        />
        <PrivateRoute
          path="/ia/modules/:workspace"
          exact
          component={ModulesAiList}
          database="ai"
          collection="modules"
          authorization="search"
        />
        <PrivateRoute
          path="/ia/modules/:workspace/nouveau-module"
          exact
          component={ModuleAiCreate}
          database="ai"
          collection="modules"
          authorization="create"
        />
        <PrivateRoute
          path="/ia/modules/:workspace/modifier-un-module/:id"
          exact
          component={ModuleAiEdit}
          database="ai"
          collection="modules"
          authorization="update"
        />
        <PrivateRoute
          path="/ia/interactions"
          exact
          component={InteractionsAiList}
          database="ai"
          collection="interactions"
          authorization="search"
        />
        <PrivateRoute
          path="/ia/personas"
          exact
          component={PersonasList}
          database="ai"
          collection="interactions"
          authorization="search"
        />
        <PrivateRoute
          path="/ia/personas/nouveau-persona"
          exact
          component={PersonaCreate}
          database="ai"
          collection="interactions"
          authorization="search"
        />
        <PrivateRoute
          path="/ia/personas/modifier-un-persona/:id"
          exact
          component={PersonaEdit}
          database="ai"
          collection="interactions"
          authorization="search"
        />
        {/* Développement */}
        <PrivateRoute
          path="/developpement/import-export-formao"
          exact
          component={ApiScorm}
          database="devTools"
          collection="importExportService"
          authorization="viewQueue"
        />
        {/* Account */}
        <PrivateRoute path="/parametres" exact component={Settings} />
        {/* Page 404 */}
        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
    </PageLayout>
  </HashRouter>
);

export default Router;
