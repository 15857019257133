import { Button, Space, Tag, Table, Popconfirm, Dropdown, Menu, message } from 'antd';
import { ClockCircleOutlined, CloseCircleOutlined, SyncOutlined, MoreOutlined } from '@ant-design/icons';
import { formatDistanceToNowStrict } from 'date-fns';
import fr from 'date-fns/locale/fr';
import { memo } from 'react';

import tablePagination from '../../../lib/tablePagination';
import { useAuth } from '../../../authContext';
import { aiRoutes } from '../../../lib/routes';
import { checkAuthorization, sort, sortDate } from '../../../shared/utils';
import useFetch from '../../../hooks/useFetch';
import InteractionExpandable from './InteractionExpandable';
import AiUser from './AiUser';
const { Item } = Menu;

const popconfirmProps = {
  title: 'Êtes-vous sûr ?',
  cancelText: 'Non',
  okText: 'Oui',
  placement: 'left',
};

const threadStatusTag = {
  EMPTY: {
    label: 'En attente',
    color: 'default',
    icon: null,
  },
  RUNNING: {
    label: 'En cours',
    color: 'success',
    icon: <SyncOutlined spin />,
  },
  ERRORED: {
    label: 'Processus arrêté',
    color: 'error',
    icon: <CloseCircleOutlined />,
  },
  READY: {
    label: 'Tâche assignée',
    color: 'processing',
    icon: <ClockCircleOutlined />,
  },
};

const jobAiTag = {
  chatgpt: {
    label: 'ChatGPT',
    color: '#74AA9C',
  },
  mistral: {
    label: 'Mistral AI',
    color: '#ff7404',
  },
};

const AiThreadsTable = memo(({ threads, isValidating, isProcessing, onProcessing, onFinish }) => {
  const { user } = useAuth();
  const threadsStatus = {
    empty: Object.values(threads)?.filter((item) => item.status === 'EMPTY').length || 0,
    ready: Object.values(threads)?.filter((item) => item.status === 'READY').length || 0,
    running: Object.values(threads)?.filter((item) => item.status === 'RUNNING').length || 0,
    errored: Object.values(threads)?.filter((item) => item.status === 'ERRORED').length || 0,
  };
  const aiStatus = {
    chatgpt: Object.values(threads)?.filter((item) => item?.job?.data?.ai.id === 'chatgpt').length || 0,
    mistral: Object.values(threads)?.filter((item) => item?.job?.data?.ai.id === 'mistral').length || 0,
  };
  const { post, remove } = useFetch();

  const requeueJob = async (threadSlot) => {
    onProcessing(true);

    const results = await post(`${aiRoutes.queue}/threads/${threadSlot}/requeue`);

    if (results.status === 200) {
      onFinish();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    onProcessing(false);
  };

  const retryJob = async (threadSlot) => {
    onProcessing(true);

    const results = await post(`${aiRoutes.queue}/threads/${threadSlot}`);

    if (results.status === 200) {
      onFinish();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    onProcessing(false);
  };

  const dropOneThread = async (threadSlot) => {
    onProcessing(true);

    const results = await remove(`${aiRoutes.queue}/threads/${threadSlot}`);

    if (results.status === 200) {
      onFinish();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    onProcessing(false);
  };

  const dropThreads = async () => {
    try {
      const response = await remove(`${aiRoutes.queue}/threads`);

      if (response.status !== 200) {
        message.error(response?.data?.message || 'Une erreur est survenue, les processus ne peuvent pas être vidés');
      } else {
        onFinish();
        message.success('Les processus ont été vidés');
      }
    } catch (err) {
      console.log(err?.response?.data?.message || err?.message || err);
      message.error(
        err?.response?.data?.message ||
          err?.message ||
          'Une erreur est survenue, les processus ne peuvent pas être vidés',
      );
    }
  };

  const Actions = memo(({ record }) => {
    // const tempAccess = record?.tempAccesses.find((item) => item.user.toString() === user._id.toString());

    return (
      <Menu key={`menu-${record.id}`}>
        {checkAuthorization(user, 'ai', 'api', 'requeueItemFromThread') && (
          <Item key="requeue" onClick={() => requeueJob(record.id)}>
            Remet la tâche dans la queue
          </Item>
        )}
        {checkAuthorization(user, 'ai', 'api', 'restartThread') && (
          <Item key="retry" onClick={() => retryJob(record.id)}>
            Redémarre la tâche
          </Item>
        )}
        {checkAuthorization(user, 'ai', 'api', 'dropItemFromThread') && (
          <Item key="delete">
            <Popconfirm {...popconfirmProps} onConfirm={() => dropOneThread(record.id)}>
              Supprime la tâche
            </Popconfirm>
          </Item>
        )}
      </Menu>
    );
  });

  const columns = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      filters: [
        { text: 'En attente', value: 'EMPTY' },
        { text: 'Tâche assignée', value: 'READY' },
        { text: 'En cours', value: 'RUNNING' },
        { text: 'Processus arrêté', value: 'ERRORED' },
      ],
      onFilter: (value, record) => record.status === value,
      render: (record) => {
        if (!!record) {
          return (
            <Tag
              style={{ width: 150, textAlign: 'center' }}
              icon={threadStatusTag[record].icon}
              color={threadStatusTag[record].color}
            >
              {threadStatusTag[record].label}
            </Tag>
          );
        }
      },
    },
    {
      title: 'IA',
      dataIndex: ['job', 'data', 'ai', 'id'],
      key: 'ia',
      width: 100,
      filters: [
        { text: 'ChatGPT', value: 'chatgpt' },
        { text: 'Mistral AI', value: 'mistral' },
      ],
      onFilter: (value, record) => record.job?.data?.ai?.id === value,
      render: (value) => {
        if (!value) {
          return <>--</>;
        } else {
          return <Tag color={jobAiTag[value].color}>{jobAiTag[value].label}</Tag>;
        }
      },
    },
    {
      title: "Temps d'exécution",
      dataIndex: 'elapsedTime',
      key: 'elapsedTime',
      width: 200,
      sorter: (a, b) => sortDate(a, b, 'elapsedTime'),
      sortDirections: ['ascend', 'descend'],
      render: (value) => {
        if (value < 0) {
          return <>--</>;
        } else {
          return <>{formatDistanceToNowStrict(new Date(value), { locale: fr })}</>;
        }
      },
    },
    {
      title: 'Utilisateur',
      dataIndex: ['job', 'item'],
      key: 'user',
      render: (value) => {
        return <AiUser data={value} />;
      },
    },
    {
      title: 'Temps depuis soumission',
      dataIndex: ['job', 'elapsedTime'],
      key: 'submittedAt',
      sorter: (a, b) => sortDate(a, b, 'job.elapsedTime'),
      sortDirections: ['ascend', 'descend'],
      render: (value) => {
        if (value === null) {
          return <>--</>;
        } else {
          return <>{formatDistanceToNowStrict(new Date(value), { locale: fr })}</>;
        }
      },
    },
    {
      title: 'Tentatives',
      dataIndex: ['job', 'attempts'],
      key: 'attempts',
      sorter: (a, b) => Number(a || 0) - Number(b || 0),
      sortDirections: ['ascend', 'descend'],
      render: (value) => {
        if (value === null) {
          return <>--</>;
        } else {
          return <>{value}</>;
        }
      },
    },
    {
      title: '',
      key: 'actions',
      width: 50,
      align: 'center',
      fixed: 'right',
      render: (record) => {
        if (record.job !== null && record.status !== 'RUNNING') {
          return (
            <>
              {(checkAuthorization(user, 'ai', 'api', 'requeueItemFromThread') ||
                checkAuthorization(user, 'ai', 'api', 'restartThread') ||
                checkAuthorization(user, 'ai', 'api', 'dropItemFromThread')) && (
                <Dropdown overlay={<Actions record={record} />} placement="bottomRight" trigger={['click']}>
                  <Button icon={<MoreOutlined />} disabled={isProcessing} />
                </Dropdown>
              )}
            </>
          );
        }
      },
    },
  ];

  return (
    <Space size="small" direction="vertical" style={{ display: 'flex' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Space size="small">
          {threadsStatus.running !== 0 && (
            <Tag icon={threadStatusTag['RUNNING'].icon} color={threadStatusTag['RUNNING'].color}>
              {threadStatusTag['RUNNING'].label} ({threadsStatus.running})
            </Tag>
          )}
          {threadsStatus.ready !== 0 && (
            <Tag icon={threadStatusTag['READY'].icon} color={threadStatusTag['READY'].color}>
              {threadStatusTag['READY'].label} ({threadsStatus.ready})
            </Tag>
          )}
          {threadsStatus.errored !== 0 && (
            <Tag icon={threadStatusTag['ERRORED'].icon} color={threadStatusTag['ERRORED'].color}>
              {threadStatusTag['ERRORED'].label} ({threadsStatus.errored})
            </Tag>
          )}
          {threadsStatus.empty !== 0 && (
            <Tag icon={threadStatusTag['EMPTY'].icon} color={threadStatusTag['EMPTY'].color}>
              {threadStatusTag['EMPTY'].label} ({threadsStatus.empty})
            </Tag>
          )}
        </Space>
        <Space size="small">
          {checkAuthorization(user, 'ai', 'api', 'dropThreads') && (
            <Popconfirm
              {...popconfirmProps}
              onConfirm={dropThreads}
              disabled={threadsStatus.empty === threads?.length || threadsStatus.running !== 0}
            >
              <Button
                type="primary"
                danger
                disabled={threadsStatus.empty === threads?.length || threadsStatus.running !== 0}
              >
                Vider les processus
              </Button>
            </Popconfirm>
          )}
        </Space>
      </div>
      {aiStatus.chatgpt !== 0 || aiStatus.mistral !== 0 ? (
        <Space size="small">
          {aiStatus.chatgpt !== 0 && (
            <Tag color={jobAiTag['chatgpt'].color}>
              {jobAiTag['chatgpt'].label} ({aiStatus.chatgpt})
            </Tag>
          )}
          {aiStatus.mistral !== 0 && (
            <Tag color={jobAiTag['mistral'].color}>
              {jobAiTag['mistral'].label} ({aiStatus.mistral})
            </Tag>
          )}
        </Space>
      ) : null}
      <Table
        loading={!threads || isValidating || isProcessing}
        columns={columns}
        dataSource={threads || []}
        bordered
        size="small"
        rowKey={(row) => row.id}
        pagination={tablePagination(threads)}
        expandable={{
          rowExpandable: (record) => record.job !== null,
          expandedRowRender: (record, index, indent, expanded) => {
            if (record?.job?.item?.interaction) {
              return <InteractionExpandable interactionId={record.job.item.interaction} isExpanded={expanded} />;
            }
          },
        }}
      />
    </Space>
  );
});

AiThreadsTable.displayName = 'AiThreadsTable';
export default AiThreadsTable;
