import { message } from 'antd';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { format as dateFormat } from 'date-fns';
import fr from 'date-fns/locale/fr';
import { format } from '@i-maginexr/js';

const alignLeft = {
  vertical: 'middle',
  horizontal: 'left',
};

const alignCenter = {
  vertical: 'middle',
  horizontal: 'center',
};

const alignRight = {
  vertical: 'middle',
  horizontal: 'right',
};

const modeColors = {
  Inter: 'ffea4335',
  Intra: 'ff108ee9',
  Tutorat: 'ff5b8c00',
};

const statusColors = {
  Confirmée: 'fff6ffed',
  'Non-traitée': 'ffe6f4ff',
  Annulée: 'fffff1f0',
};

const headerStyle = {
  font: {
    bold: true,
    size: 10,
    color: {
      argb: 'ff315397',
    },
  },
  background: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'fff3f3f3',
    },
  },
};

const borders = {
  top: { style: 'thin', color: { argb: '00000000' } },
  bottom: { style: 'thin', color: { argb: '00000000' } },
  left: { style: 'thin', color: { argb: '00000000' } },
  right: { style: 'thin', color: { argb: '00000000' } },
};

const renderDays = (days) => {
  const groups = days.reduce((acc, curr) => {
    const group = dateFormat(new Date(curr), 'MM/yyyy');
    const day = dateFormat(new Date(curr), 'dd');

    if (group in acc) {
      acc[group].push(day);
    } else {
      acc[group] = [day];
    }

    return acc;
  }, {});

  return Object.entries(groups || {})
    .sort((a, b) => {
      const aDate = a?.[0]?.split('/');
      const bDate = b?.[0]?.split('/');

      return new Date(aDate[1], aDate[0] - 1, 1) - new Date(bDate[1], bDate[0] - 1, 1);
    })
    .map(([key, value], index) => {
      const [month, year] = key.split('/');
      const monthName = fr.localize.month(month - 1, { width: 'abbreviated' });

      return `${value
        .sort((a, b) => Number(a) - Number(b))
        .join(', ')
        .replace(/, ([^,]*)$/, ' et $1')} ${monthName} ${year}${
        index < Object.keys(groups || {}).length - 1 ? ' | ' : ''
      }`;
    })
    .join('');
};

const renderDate = (item) => {
  if (item.config.date.type === 'Récurrent') {
    return `Tous les ${item.config.date.config.day.toLowerCase()}s`;
  }

  if (item.config.date.type === 'Période') {
    return `Du ${dateFormat(new Date(item.config.date.config.range[0]), 'dd/MM/yyyy')} au ${dateFormat(
      new Date(item.config.date.config.range[1]),
      'dd/MM/yyyy',
    )}`;
  }

  if (item.config.date.type === 'Jours datés') {
    return `${renderDays(item.config.date.config.days)}`;
  }

  if (item.config.date.type === 'Personnalisé') {
    return `${item.config.date.config.custom}`;
  }

  return '';
};

const exportRegistrations = async (data) => {
  const workbook = new ExcelJS.Workbook();

  const sheet = workbook.addWorksheet('Pré-inscriptions');

  /* Configuration de la feuille */
  sheet.views = [{ showGridLines: false }];
  sheet.properties.defaultRowHeight = 25;

  sheet.getColumn('A').width = 20;
  sheet.getColumn('B').width = 20;
  sheet.getColumn('C').width = 40;
  sheet.getColumn('D').width = 20;
  sheet.getColumn('E').width = 30;
  sheet.getColumn('F').width = 40;
  sheet.getColumn('G').width = 30;
  sheet.getColumn('H').width = 30;
  sheet.getColumn('I').width = 40;
  sheet.getColumn('J').width = 20;
  sheet.getColumn('K').width = 40;

  let nextIndex = 1;

  sheet.mergeCells(`A${nextIndex}:A${nextIndex + 1}`);
  sheet.getCell(`A${nextIndex}`).value = 'Statut';
  sheet.getCell(`A${nextIndex}`).alignment = alignCenter;
  sheet.getCell(`A${nextIndex}`).font = headerStyle.font;
  sheet.getCell(`A${nextIndex}`).fill = headerStyle.background;
  sheet.getCell(`A${nextIndex}`).border = borders;

  sheet.mergeCells(`B${nextIndex}:B${nextIndex + 1}`);
  sheet.getCell(`B${nextIndex}`).value = 'Date de pré-inscription';
  sheet.getCell(`B${nextIndex}`).alignment = alignCenter;
  sheet.getCell(`B${nextIndex}`).font = headerStyle.font;
  sheet.getCell(`B${nextIndex}`).fill = headerStyle.background;
  sheet.getCell(`B${nextIndex}`).border = borders;

  sheet.mergeCells(`C${nextIndex}:F${nextIndex}`);

  sheet.getCell(`C${nextIndex}`).value = 'Programmation';
  sheet.getCell(`C${nextIndex}`).alignment = alignCenter;
  sheet.getCell(`C${nextIndex}`).font = headerStyle.font;
  sheet.getCell(`C${nextIndex}`).fill = headerStyle.background;
  sheet.getCell(`C${nextIndex}`).border = borders;

  sheet.getCell(`C${nextIndex + 1}`).value = 'Univers';
  sheet.getCell(`C${nextIndex + 1}`).alignment = alignCenter;
  sheet.getCell(`C${nextIndex + 1}`).font = headerStyle.font;
  sheet.getCell(`C${nextIndex + 1}`).fill = headerStyle.background;
  sheet.getCell(`C${nextIndex + 1}`).border = borders;

  sheet.getCell(`D${nextIndex + 1}`).value = 'Mode';
  sheet.getCell(`D${nextIndex + 1}`).alignment = alignCenter;
  sheet.getCell(`D${nextIndex + 1}`).font = headerStyle.font;
  sheet.getCell(`D${nextIndex + 1}`).fill = headerStyle.background;
  sheet.getCell(`D${nextIndex + 1}`).border = borders;

  sheet.getCell(`E${nextIndex + 1}`).value = 'Formation';
  sheet.getCell(`E${nextIndex + 1}`).alignment = alignCenter;
  sheet.getCell(`E${nextIndex + 1}`).font = headerStyle.font;
  sheet.getCell(`E${nextIndex + 1}`).fill = headerStyle.background;
  sheet.getCell(`E${nextIndex + 1}`).border = borders;

  sheet.getCell(`F${nextIndex + 1}`).value = 'Date';
  sheet.getCell(`F${nextIndex + 1}`).alignment = alignCenter;
  sheet.getCell(`F${nextIndex + 1}`).font = headerStyle.font;
  sheet.getCell(`F${nextIndex + 1}`).fill = headerStyle.background;
  sheet.getCell(`F${nextIndex + 1}`).border = borders;

  sheet.mergeCells(`G${nextIndex}:K${nextIndex}`);
  sheet.getCell(`G${nextIndex}`).value = 'Formulaire';
  sheet.getCell(`G${nextIndex}`).alignment = alignCenter;
  sheet.getCell(`G${nextIndex}`).font = headerStyle.font;
  sheet.getCell(`G${nextIndex}`).fill = headerStyle.background;
  sheet.getCell(`G${nextIndex}`).border = borders;

  sheet.getCell(`G${nextIndex + 1}`).value = 'Contact';
  sheet.getCell(`G${nextIndex + 1}`).alignment = alignCenter;
  sheet.getCell(`G${nextIndex + 1}`).font = headerStyle.font;
  sheet.getCell(`G${nextIndex + 1}`).fill = headerStyle.background;
  sheet.getCell(`G${nextIndex + 1}`).border = borders;

  sheet.getCell(`H${nextIndex + 1}`).value = 'Entreprise';
  sheet.getCell(`H${nextIndex + 1}`).alignment = alignCenter;
  sheet.getCell(`H${nextIndex + 1}`).font = headerStyle.font;
  sheet.getCell(`H${nextIndex + 1}`).fill = headerStyle.background;
  sheet.getCell(`H${nextIndex + 1}`).border = borders;

  sheet.getCell(`I${nextIndex + 1}`).value = 'Email';
  sheet.getCell(`I${nextIndex + 1}`).alignment = alignCenter;
  sheet.getCell(`I${nextIndex + 1}`).font = headerStyle.font;
  sheet.getCell(`I${nextIndex + 1}`).fill = headerStyle.background;
  sheet.getCell(`I${nextIndex + 1}`).border = borders;

  sheet.getCell(`J${nextIndex + 1}`).value = 'Téléphone';
  sheet.getCell(`J${nextIndex + 1}`).alignment = alignCenter;
  sheet.getCell(`J${nextIndex + 1}`).font = headerStyle.font;
  sheet.getCell(`J${nextIndex + 1}`).fill = headerStyle.background;
  sheet.getCell(`J${nextIndex + 1}`).border = borders;

  sheet.getCell(`K${nextIndex + 1}`).value = 'Code postal + Ville';
  sheet.getCell(`K${nextIndex + 1}`).alignment = alignCenter;
  sheet.getCell(`K${nextIndex + 1}`).font = headerStyle.font;
  sheet.getCell(`K${nextIndex + 1}`).fill = headerStyle.background;
  sheet.getCell(`K${nextIndex + 1}`).border = borders;

  nextIndex += 2;

  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    const contact = format.toFullName(item.form.contact.firstName, item.form.contact.lastName);
    const rowColor = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: statusColors[item.status] },
    };

    sheet.getCell(`A${nextIndex}`).value = item.status;
    sheet.getCell(`A${nextIndex}`).border = borders;
    sheet.getCell(`A${nextIndex}`).alignment = alignCenter;
    sheet.getCell(`A${nextIndex}`).fill = rowColor;
    sheet.getCell(`A${nextIndex}`).font = { bold: true, size: 10 };

    sheet.getCell(`B${nextIndex}`).value = dateFormat(new Date(item.createdAt), 'dd/MM/yyyy HH:mm');
    sheet.getCell(`B${nextIndex}`).border = borders;
    sheet.getCell(`B${nextIndex}`).alignment = alignLeft;
    sheet.getCell(`B${nextIndex}`).fill = rowColor;
    sheet.getCell(`B${nextIndex}`).font = { bold: true, size: 10 };

    sheet.getCell(`C${nextIndex}`).value = item.config.category.name;
    sheet.getCell(`C${nextIndex}`).border = borders;
    sheet.getCell(`C${nextIndex}`).alignment = alignLeft;
    sheet.getCell(`C${nextIndex}`).fill = rowColor;
    sheet.getCell(`C${nextIndex}`).font = { bold: true, size: 10 };

    sheet.getCell(`D${nextIndex}`).value = item.config.date.mode;
    sheet.getCell(`D${nextIndex}`).border = borders;
    sheet.getCell(`D${nextIndex}`).alignment = alignCenter;
    sheet.getCell(`D${nextIndex}`).font = { bold: true, size: 10, color: { argb: 'ffffffff' } };
    sheet.getCell(`D${nextIndex}`).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: modeColors[item.config.date.mode] },
    };

    sheet.getCell(`E${nextIndex}`).value = item.config.formation.formation.title;
    sheet.getCell(`E${nextIndex}`).border = borders;
    sheet.getCell(`E${nextIndex}`).alignment = alignLeft;
    sheet.getCell(`E${nextIndex}`).fill = rowColor;
    sheet.getCell(`E${nextIndex}`).font = { bold: true, size: 10 };

    sheet.getCell(`F${nextIndex}`).value = renderDate(item);
    sheet.getCell(`F${nextIndex}`).border = borders;
    sheet.getCell(`F${nextIndex}`).alignment = alignLeft;
    sheet.getCell(`F${nextIndex}`).fill = rowColor;
    sheet.getCell(`F${nextIndex}`).font = { bold: true, size: 10 };

    sheet.getCell(`G${nextIndex}`).value = `${contact.lastName} ${contact.firstName}`;
    sheet.getCell(`G${nextIndex}`).border = borders;
    sheet.getCell(`G${nextIndex}`).alignment = alignLeft;
    sheet.getCell(`G${nextIndex}`).fill = rowColor;
    sheet.getCell(`G${nextIndex}`).font = { bold: true, size: 10 };

    sheet.getCell(`H${nextIndex}`).value = item.form.company.name.toUpperCase();
    sheet.getCell(`H${nextIndex}`).border = borders;
    sheet.getCell(`H${nextIndex}`).alignment = alignLeft;
    sheet.getCell(`H${nextIndex}`).fill = rowColor;
    sheet.getCell(`H${nextIndex}`).font = { bold: true, size: 10 };

    sheet.getCell(`I${nextIndex}`).value = item.form.company.email;
    sheet.getCell(`I${nextIndex}`).border = borders;
    sheet.getCell(`I${nextIndex}`).alignment = alignLeft;
    sheet.getCell(`I${nextIndex}`).fill = rowColor;
    sheet.getCell(`I${nextIndex}`).font = { bold: true, size: 10 };

    sheet.getCell(`J${nextIndex}`).value = item.form.company.phone;
    sheet.getCell(`J${nextIndex}`).border = borders;
    sheet.getCell(`J${nextIndex}`).alignment = alignLeft;
    sheet.getCell(`J${nextIndex}`).fill = rowColor;
    sheet.getCell(`J${nextIndex}`).font = { bold: true, size: 10 };

    sheet.getCell(`K${nextIndex}`).value = item.form.company.location;
    sheet.getCell(`K${nextIndex}`).border = borders;
    sheet.getCell(`K${nextIndex}`).alignment = alignLeft;
    sheet.getCell(`K${nextIndex}`).fill = rowColor;
    sheet.getCell(`K${nextIndex}`).font = { bold: true, size: 10 };

    nextIndex++;
  }

  try {
    const buffer = await workbook.xlsx.writeBuffer();
    const options = { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' };

    saveAs(new Blob([buffer], options), 'pré-inscriptions.xlsx');
  } catch (error) {
    message.error(error.message);
  }
};

export default exportRegistrations;
