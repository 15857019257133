import { Card, Button, Typography, Result, Drawer, Space } from 'antd';
import { WarningFilled, CloseCircleFilled, CheckCircleFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { memo, useState } from 'react';
import useSWR from 'swr';
import ModuleImportLogs from './ModuleLogsCollapse';

const { Meta } = Card;

const ModuleLogsModal = memo(({ visible, data, onClose }) => {
  return (
    <Drawer
      open={visible}
      onClose={onClose}
      title={data?.package || ''}
      placement="top"
      height="100vh"
      bodyStyle={{ padding: 0 }}
      destroyOnClose
    >
      <ModuleImportLogs logFile={data.logFile} />
    </Drawer>
  );
});

export default ModuleLogsModal;
