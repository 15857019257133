import { Fragment } from 'react';
import { Card, Col, Divider, Row, Skeleton, Space, Spin, Typography } from 'antd';
import useSWR from 'swr';
import Category from './Category';

const PAGE_URL = `${process.env.REACT_APP_BASE_URL_API_URL}/next/private/arinfo/pages/inter-intra`;

const SWROptions = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

const Categories = () => {
  const { data: categories, isValidating, mutate } = useSWR(`${PAGE_URL}/categories`, SWROptions);

  return (
    <Card size="small" title={<Typography.Title level={5}>Univers & formations</Typography.Title>}>
      {isValidating && !categories?.data ? (
        <>
          {Array.from(Array(5).keys()).map((item, index) => (
            <Fragment key={item}>
              <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                <Row gutter={[12, 12]} wrap={false}>
                  <Col flex="none">
                    <Skeleton
                      style={{ marginBottom: 0, marginTop: 2.3 }}
                      title={{ width: 150, style: { margin: 0, height: 132 } }}
                      paragraph={false}
                      active
                    />
                  </Col>
                  <Col flex="auto">
                    <Space direction="vertical" style={{ display: 'flex' }}>
                      <Skeleton
                        style={{ marginBottom: 0, marginTop: 2.3 }}
                        title={{ width: 250, style: { margin: 0, height: 21.6 } }}
                        paragraph={false}
                        active
                      />
                      <Skeleton
                        style={{ marginBottom: 0, marginTop: 2.3 }}
                        title={{ width: 72.6667, style: { margin: 0, height: 25 } }}
                        paragraph={false}
                        active
                      />
                      <Skeleton
                        style={{ marginBottom: 0, marginTop: 2.3 }}
                        title={{ width: 176, style: { margin: 0, height: 37 } }}
                        paragraph={false}
                        active
                      />
                    </Space>
                  </Col>
                </Row>
                <Skeleton
                  style={{ marginBottom: 0, marginTop: 2.3 }}
                  title={{ width: '100%', style: { margin: 0, height: 48 } }}
                  paragraph={false}
                  active
                />
              </Space>
              {index < 4 && <Divider />}
            </Fragment>
          ))}
        </>
      ) : (
        <Spin spinning={isValidating}>
          {(categories?.data || []).map((category, index) => (
            <Fragment key={category.value}>
              <Category data={category} onChange={mutate} />
              {index < categories?.data?.length - 1 && <Divider />}
            </Fragment>
          ))}
        </Spin>
      )}
    </Card>
  );
};

export default Categories;
