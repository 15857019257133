import { memo } from 'react';
import useSWR from 'swr';
import { format } from '@i-maginexr/js';

import { aiRoutes } from '../../../lib/routes';
import { LoadingOutlined } from '@ant-design/icons';

const ADMIN_URL = `${process.env.REACT_APP_BASE_URL_API_URL}/next/private/global/users`;
const STUDENT_URL = `${process.env.REACT_APP_BASE_URL_API_URL}/next/private/formao/students`;

const AiUser = memo(({ data }) => {
  const { data: interaction, isValidating: isInteractionValidating } = useSWR(
    data?.interaction ? aiRoutes.interactions + '/' + data.interaction : null,
  );
  const { data: user, isValidating: isUserValidating } = useSWR(
    interaction?.data?.user
      ? interaction.data.userType === 'ADMIN'
        ? `${ADMIN_URL}/${interaction.data.user}`
        : `${STUDENT_URL}/${interaction.data.user}`
      : null,
  );

  if (isInteractionValidating && isUserValidating) {
    return <LoadingOutlined />;
  }

  if (!interaction || !user) {
    return null;
  }

  if (interaction.data.userType === 'ADMIN') {
    const { firstName, lastName } = format.toFullName(user.data.first_name, user.data.last_name);

    return `${firstName} ${lastName}`;
  }

  if (interaction.data.userType === 'STUDENT') {
    const { firstName, lastName } = format.toFullName(user.data.first_name, user.data.last_name);

    return `${firstName} ${lastName}`;
  }

  return null;
});

AiUser.displayName = 'AiUser';
export default AiUser;
