import axios from 'axios';
import { Cloudinary } from '@cloudinary/url-gen';

const cld = new Cloudinary({
  cloud: {
    cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
  },
  url: {
    secure: true,
  },
});

export const getImageUrl = (publicId) => {
  const image = cld.image(publicId);

  if (image) {
    return image.toURL();
  }
};

/**
 * @async
 * @function getSignature
 * @description Génère une signature d'autorisation d'upload
 * @param {string} file Fichier lié à la signature
 */
export const getSignature = async (file, token) => {
  let fileName = file.name.split('.');
  fileName = fileName.slice(0, fileName.length - 1).join('.');

  // Récupère une signature d'upload
  const url = `${process.env.REACT_APP_BASE_URL_API_URL}/next/private/global/medias/signature/image`;
  const response = await axios.post(
    url,
    { context: `caption=${fileName}` },
    { headers: { Authorization: `Bearer ${token}` } },
  );

  if (response.status === 200) {
    const { timestamp, signature } = response.data.data;

    return {
      file,
      context: `caption=${fileName}`,
      api_key: process.env.REACT_APP_CLOUDINARY_API_KEY,
      timestamp,
      signature,
    };
  }
};
