import { memo, useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Dropdown,
  message,
  Modal,
  Popover,
  Row,
  Space,
  Spin,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import useSWR from 'swr';
import { DeleteOutlined, EditOutlined, MoreOutlined, PlusOutlined, WarningFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { format } from '@i-maginexr/js';
import useFetch from '../../../../../hooks/useFetch';
import ProgramItemComment from './ProgramItemComment';
import useLoading from '../../../../../hooks/useLoading';
import { useAuth } from '../../../../../authContext';
import { checkAuthorization } from '../../../../../shared/utils';

const PAGE_URL = `${process.env.REACT_APP_BASE_URL_API_URL}/next/private/arinfo/pages/inter-intra`;
const LOCATIONS_URL = `${process.env.REACT_APP_BASE_URL_API_URL}/next/private/arinfo/agencies`;
const USERS_URL = `${process.env.REACT_APP_BASE_URL_API_URL}/next/private/global/users`;

const goalColors = {
  Opérationnel: 'blue',
  Perfectionnement: 'gold',
  Complet: 'green',
  Spécialisation: 'red',
  'Remise à niveau': 'purple',
  'Entrée/Sortie': 'cyan',
};

const modeColors = {
  Inter: '#ea4335',
  Intra: '#108ee9',
  Tutorat: '#5b8c00',
};

const SWROptions = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

const ProgramItem = memo(({ data, onCreate, onEdit, onNoteUpdate, onPlacesUpdate, onCancel, onDelete, ...props }) => {
  const { isLoading, endLoading, startLoading } = useLoading();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const { data: users, isValidating: isUsersValidating } = useSWR(USERS_URL, SWROptions);
  const { data: locations, isValidating: isLocationsValidating } = useSWR(LOCATIONS_URL, SWROptions);
  const { remove, patch } = useFetch();
  const { user } = useAuth();

  const onPlacesChange = async (record) => {
    startLoading(record._id);
    const results = await patch(`${PAGE_URL}/formations/${data._id}/schedule/${record._id}/places`);

    if (results.status === 200) {
      endLoading(record._id);

      message.success('Statut modifié');

      return onPlacesUpdate();
    } else {
      endLoading(record._id);

      if (results.message) {
        message.error(results.message);
      } else {
        message.error('Une erreur est survenue');
      }
    }
  };

  return (
    <Collapse.Panel
      {...props}
      key={data.formationId}
      header={
        <Typography.Title level={5} style={{ marginBottom: 0 }}>
          {data.title} ({data.schedule.length})
        </Typography.Title>
      }
    >
      <Table
        bordered
        size="small"
        dataSource={data.schedule.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))}
        rowKey={(row) => row._id}
        pagination={{
          hideOnSinglePage: true,
        }}
        columns={[
          {
            key: 'mode',
            dataIndex: 'mode',
            title: 'Mode',
            width: 10,
            align: 'center',
            filters: [
              {
                text: 'Inter',
                value: 'Inter',
              },
              {
                text: 'Intra',
                value: 'Intra',
              },
              {
                text: 'Tutorat',
                value: 'Tutorat',
              },
            ],
            onFilter: (value, record) => record.mode === value,
            render: (value, record) => {
              if (!!record?.disabled) {
                return (
                  <Popover
                    title={
                      <Space>
                        <WarningFilled style={{ color: '#ff4d4f', fontSize: 18 }} />
                        <Typography.Text strong>Ce mode n'est pas activé sur cette formation</Typography.Text>
                      </Space>
                    }
                    content={
                      <Space direction="vertical" style={{ display: 'flex' }}>
                        <Typography.Text>
                          Cette programmation est actuellement indisponible depuis arinfo.fr.
                        </Typography.Text>
                        {checkAuthorization(user, 'arinfo', 'interFormations', 'update-formation') && (
                          <Link to={`/arinfo/inter-intra/${data.mode.category}/formations/${data.formationId}`}>
                            <Button block type="primary">
                              Configurer le mode
                            </Button>
                          </Link>
                        )}
                      </Space>
                    }
                  >
                    <Row gutter={[6, 6]} wrap={false}>
                      <Col flex="none" style={{ paddingTop: 1 }}>
                        <WarningFilled style={{ color: '#ff4d4f', fontSize: 18 }} />
                      </Col>
                      <Col flex="auto">
                        <Tag color={modeColors[value]} style={{ width: '100%' }}>
                          {value}
                        </Tag>
                      </Col>
                    </Row>
                  </Popover>
                );
              }

              return (
                <Tag color={modeColors[value]} style={{ width: '100%' }}>
                  {value}
                </Tag>
              );
            },
          },
          {
            key: 'date',
            dataIndex: 'date',
            title: 'Dates',
            filters: [
              {
                text: 'Jours datés',
                value: 'Jours datés',
              },
              {
                text: 'Période',
                value: 'Période',
              },
              {
                text: 'Personnalisé',
                value: 'Personnalisé',
              },
              {
                text: 'Récurrent',
                value: 'Récurrent',
              },
            ],
            onFilter: (value, record) => record.type === value,
          },
          {
            key: 'former',
            dataIndex: 'former',
            title: 'Formateur',
            width: 150,
            filters: [
              ...(users?.data || [])
                .sort((a, b) => a.last_name.localeCompare(b.last_name, 'fr'))
                .map((user) => {
                  const { firstName, lastName } = format.toFullName(user.first_name, user.last_name);

                  return {
                    text: `${lastName} ${firstName}`,
                    value: user._id,
                  };
                }),
            ],
            sorter: (a, b) => a.former.localeCompare(b.former, 'fr'),
            sortDirections: ['ascend', 'descend'],
            onFilter: (value, record) => record.originalItem.former === value,
            render: (value) => {
              return <span style={{ whiteSpace: 'nowrap' }}>{value}</span>;
            },
          },
          {
            key: 'goal',
            title: 'Objectif',
            width: 10,
            filters: [
              {
                text: 'Complet',
                value: 'Complet',
              },
              {
                text: 'Entrée/Sortie',
                value: 'Entrée/Sortie',
              },
              {
                text: 'Opérationnel',
                value: 'Opérationnel',
              },
              {
                text: 'Perfectionnement',
                value: 'Perfectionnement',
              },
              {
                text: 'Remise à niveau',
                value: 'Remise à niveau',
              },
              {
                text: 'Spécialisation',
                value: 'Spécialisation',
              },
            ],
            onFilter: (value, record) => record.goal.type === value,
            render: (record) => {
              const style = { width: '100%', textAlign: 'center' };

              if (record.goal.type !== 'Spécialisation') {
                return (
                  <Tag {...{ style }} color={goalColors[record.goal.type]}>
                    {record.goal.type}
                  </Tag>
                );
              }

              return (
                <Tag {...{ style }} color={goalColors[record.goal.type]}>
                  {`${record.goal.type} : ${record.goal.specialization}`}
                </Tag>
              );
            },
          },
          {
            key: 'duration',
            dataIndex: 'duration',
            title: 'Durée',
            width: 10,
            align: 'center',
            sorter: (a, b) => Number(a.originalItem.config.duration) - Number(b.originalItem.config.duration),
            sortDirections: ['ascend', 'descend'],
            render: (value) => {
              return <span style={{ whiteSpace: 'nowrap' }}>{value}</span>;
            },
          },
          {
            key: 'location',
            title: 'Lieu',
            dataIndex: 'location',
            width: 10,
            filters: [
              {
                text: 'À distance',
                value: 'À distance',
              },
              ...(locations?.data || [])
                .map((location) => ({
                  text: location.city,
                  value: location.city,
                }))
                .sort((a, b) => a.text.localeCompare(b.text, 'fr')),
            ],
            onFilter: (value, record) => record.location === value,
            render: (value) => {
              return <span style={{ whiteSpace: 'nowrap' }}>{value}</span>;
            },
          },
          {
            key: 'price',
            title: <span style={{ whiteSpace: 'nowrap' }}>Tarif TTC</span>,
            width: 10,
            align: 'center',
            sorter: (a, b) => Number(a.price.includingTaxes) - Number(b.price.includingTaxes),
            sortDirections: ['ascend', 'descend'],
            render: (record) => {
              if (record.price.type === 'startAt') {
                if (record.price?.mode === 'day') {
                  return (
                    <span style={{ whiteSpace: 'nowrap' }}>À partir de {record.price.includingTaxes} € / jour</span>
                  );
                }

                return <span style={{ whiteSpace: 'nowrap' }}>À partir de {record.price.includingTaxes} €</span>;
              }

              if (record.price?.mode === 'day') {
                return <span style={{ whiteSpace: 'nowrap' }}>{record.price.includingTaxes} € / jour</span>;
              }

              return <span style={{ whiteSpace: 'nowrap' }}>{`${record.price.includingTaxes} €`}</span>;
            },
          },
          {
            key: 'full',
            title: <span style={{ whiteSpace: 'nowrap' }}>Complet ?</span>,
            width: 10,
            sorter: (a, b) => {
              const aStatus = !!a.originalItem.places?.full;
              const bStatus = !!b.originalItem.places?.full;

              return aStatus === bStatus ? 0 : aStatus ? -1 : 1;
            },
            sortDirections: ['ascend', 'descend'],
            render: (record) => (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Spin size="small" spinning={isLoading(record._id)}>
                  <Checkbox
                    checked={!!record.originalItem.places?.full}
                    onChange={() => {
                      if (
                        checkAuthorization(user, 'arinfo', 'interFormations', 'update-schedule-remaining-places') ||
                        checkAuthorization(user, 'arinfo', 'interFormations', 'update-schedule')
                      ) {
                        onPlacesChange(record);
                      } else {
                        message.error("Vous n'avez pas la permission de modifier cet élément.");
                      }
                    }}
                  />
                </Spin>
              </div>
            ),
          },
          ...(checkAuthorization(user, 'arinfo', 'interFormations', 'create-schedule') ||
          checkAuthorization(user, 'arinfo', 'interFormations', 'update-schedule') ||
          checkAuthorization(user, 'arinfo', 'interFormations', 'delete-schedule')
            ? [
                {
                  key: 'actions',
                  title: checkAuthorization(user, 'arinfo', 'interFormations', 'create-schedule') ? (
                    <Button icon={<PlusOutlined />} onClick={onCreate} size="small" />
                  ) : null,
                  width: 10,
                  align: 'center',
                  render: (record) => {
                    if (
                      !checkAuthorization(user, 'arinfo', 'interFormations', 'update-schedule') &&
                      !checkAuthorization(user, 'arinfo', 'interFormations', 'delete-catalog')
                    ) {
                      return null;
                    }
                    return (
                      <Dropdown
                        trigger={['click']}
                        menu={{
                          items: [
                            ...(checkAuthorization(user, 'arinfo', 'interFormations', 'update-schedule')
                              ? [
                                  {
                                    icon: <EditOutlined />,
                                    label: 'Modifier',
                                    onClick: () => onEdit({ ...record.originalItem, mode: record.mode }),
                                  },
                                ]
                              : []),
                            ...(checkAuthorization(user, 'arinfo', 'interFormations', 'delete-schedule')
                              ? [
                                  {
                                    icon: <DeleteOutlined />,
                                    label: 'Supprimer',
                                    onClick: () => {
                                      return Modal.confirm({
                                        title: 'Voulez-vous vraiment supprimer cette programmation ?',
                                        content: 'Cette action est irréversible.',
                                        okText: 'Confirmer',
                                        okType: 'danger',
                                        onOk: async () => {
                                          await remove(`${PAGE_URL}/formations/${data._id}/schedule/${record._id}`);

                                          message.success('Programmation supprimée');

                                          onDelete();
                                        },
                                      });
                                    },
                                  },
                                ]
                              : []),
                          ],
                        }}
                      >
                        <Button icon={<MoreOutlined />} size="small" />
                      </Dropdown>
                    );
                  },
                },
              ]
            : []),
        ]}
        expandable={
          checkAuthorization(user, 'arinfo', 'interFormations', 'access-schedule-note') ||
          checkAuthorization(user, 'arinfo', 'interFormations', 'manage-schedule-note')
            ? {
                expandedRowKeys,
                onExpand: (expanded, record) => {
                  if (expanded) {
                    setExpandedRowKeys((prevExpandedRowKeys) => [...prevExpandedRowKeys, record._id]);
                  } else {
                    setExpandedRowKeys((prevExpandedRowKeys) =>
                      prevExpandedRowKeys.filter((key) => key !== record._id),
                    );
                  }
                },
                expandedRowRender: (record, index, indent, expanded) => (
                  <ProgramItemComment
                    {...{ record, expanded }}
                    formationId={data._id}
                    onFinish={onNoteUpdate}
                    onCreateCancel={() => {
                      setExpandedRowKeys((prevExpandedRowKeys) =>
                        prevExpandedRowKeys.filter((key) => key !== record._id),
                      );
                      onNoteUpdate();
                    }}
                    onDelete={() => {
                      setExpandedRowKeys((prevExpandedRowKeys) =>
                        prevExpandedRowKeys.filter((key) => key !== record._id),
                      );
                      onNoteUpdate();
                    }}
                  />
                ),
                expandIcon: ({ expandable, expanded, onExpand, record }) => {
                  const size = 18;
                  const strokeWidth = 1.5;
                  const style = { marginTop: 1.5, marginLeft: 1.5 };

                  if (!expandable) {
                    return null;
                  }

                  if (!record?.note) {
                    if (checkAuthorization(user, 'arinfo', 'interFormations', 'manage-schedule-note')) {
                      if (expanded) {
                        return (
                          <Tooltip title="Annuler l'ajout de la note">
                            <Button
                              size="small"
                              icon={
                                <svg
                                  {...{ style, strokeWidth }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={size}
                                  height={size}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path d="M7.9 20A9 9 0 1 0 4 16.1L2 22Z" />
                                  <path d="m15 9-6 6" />
                                  <path d="m9 9 6 6" />
                                </svg>
                              }
                              onClick={(e) => onExpand(record, e)}
                            />
                          </Tooltip>
                        );
                      }

                      return (
                        <Tooltip title="Ajouter une note">
                          <Button
                            size="small"
                            icon={
                              <svg
                                {...{ style, strokeWidth }}
                                xmlns="http://www.w3.org/2000/svg"
                                width={size}
                                height={size}
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path d="M7.9 20A9 9 0 1 0 4 16.1L2 22Z" />
                                <path d="M8 12h8" />
                                <path d="M12 8v8" />
                              </svg>
                            }
                            onClick={(e) => {
                              if (!expanded) {
                                onExpand(record, e);
                              }
                            }}
                          />
                        </Tooltip>
                      );
                    } else {
                      return null;
                    }
                  }

                  if (expanded) {
                    return (
                      <Tooltip title="Cacher la note">
                        <Button
                          size="small"
                          icon={
                            <svg
                              {...{ style, strokeWidth }}
                              xmlns="http://www.w3.org/2000/svg"
                              width={size}
                              height={size}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M7.9 20A9 9 0 1 0 4 16.1L2 22Z" />
                              <path d="m15 9-6 6" />
                              <path d="m9 9 6 6" />
                            </svg>
                          }
                          onClick={(e) => onExpand(record, e)}
                        />
                      </Tooltip>
                    );
                  }

                  return (
                    <Tooltip title="Voir la note">
                      <Button
                        size="small"
                        icon={
                          <svg
                            {...{ style, strokeWidth }}
                            xmlns="http://www.w3.org/2000/svg"
                            width={size}
                            height={size}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M7.9 20A9 9 0 1 0 4 16.1L2 22Z" />
                            <path d="M8 12h.01" />
                            <path d="M12 12h.01" />
                            <path d="M16 12h.01" />
                          </svg>
                        }
                        onClick={(e) => onExpand(record, e)}
                      />
                    </Tooltip>
                  );
                },
              }
            : {}
        }
      />
    </Collapse.Panel>
  );
});

ProgramItem.displayName = 'ProgramItem';
export default ProgramItem;
