import { Card, Form, Checkbox, Typography } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';

export default function GoalsConfig() {
  const { control, disabled } = useFormContext();

  return (
    <Card size="small" title={<Typography.Title level={5}>Objectifs</Typography.Title>}>
      <Form.Item style={{ marginBottom: 0 }}>
        <Controller
          {...{ control }}
          name="goals"
          render={({ field }) => (
            <Checkbox.Group {...{ ...field, disabled }}>
              <Checkbox value="Opérationnel">Opérationnel</Checkbox>
              <br />
              <Checkbox value="Perfectionnement">Perfectionnement</Checkbox>
              <br />
              <Checkbox value="Complet">Complet</Checkbox>
              <br />
              <Checkbox value="Spécialisation">Spécialisation</Checkbox>
              <br />
              <Checkbox value="Remise à niveau">Remise à niveau (Tutorat uniquement)</Checkbox>
              <br />
              <Checkbox value="Entrée/Sortie">Entrée/Sortie (Tutorat uniquement)</Checkbox>
            </Checkbox.Group>
          )}
        />
      </Form.Item>
    </Card>
  );
}
