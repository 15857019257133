import { Button, Space, Popconfirm, Tag, message, Typography, Table, Dropdown, Menu } from 'antd';
import { MoreOutlined, StarFilled } from '@ant-design/icons';
import { formatDistanceToNowStrict } from 'date-fns';
import fr from 'date-fns/locale/fr';
import { memo, useState } from 'react';

import tablePagination from '../../../lib/tablePagination';
import { useAuth } from '../../../authContext';
import { aiRoutes } from '../../../lib/routes';
import { checkAuthorization, sortDate } from '../../../shared/utils';
import useFetch from '../../../hooks/useFetch';

import HaltModal from './HaltModal';
import InteractionExpandable from './InteractionExpandable';
import AiUser from './AiUser';
const { Item } = Menu;

const popconfirmProps = {
  title: 'Êtes-vous sûr ?',
  cancelText: 'Non',
  okText: 'Oui',
  placement: 'left',
};

const queueStatusTag = {
  OFFLINE: {
    label: 'Hors-ligne',
    color: 'error',
  },
  EMPTY: {
    label: 'Aucune tâche',
    color: 'default',
  },
  RUNNING: {
    label: 'En cours',
    color: 'success',
  },
  STOPPED: {
    label: 'Interrompu',
    color: 'warning',
  },
  HALTED: {
    label: 'Interrompu manuellement',
    color: 'error',
  },
  ERRORED: {
    label: "Erreur d'exécution",
    color: 'error',
  },
};

const AiQueueStatus = memo(({ status, elapsedTime }) => {
  return (
    <Space>
      <Tag color={queueStatusTag[status].color}>{queueStatusTag[status].label}</Tag>
      {status === 'RUNNING' && elapsedTime > 0 ? (
        <Typography.Text>
          Temps écoulé depuis le dernier job : {formatDistanceToNowStrict(new Date(elapsedTime), { locale: fr })}
        </Typography.Text>
      ) : null}
    </Space>
  );
});

const AiQueueTable = memo(({ queue, status, elapsedTime, isValidating, isProcessing, onProcessing, onFinish }) => {
  const { user } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { patch, remove, post } = useFetch();

  const restartQueue = async () => {
    if (status === 'HALTED') {
      message.error("La queue ne peut être redémarrée directement lors d'une interruption manuelle");
      return;
    }

    try {
      const response = await post(`${aiRoutes.queue}/start`);

      if (response.status !== 200) {
        message.error(
          response?.data?.message || "Une erreur est survenue, la file d'attente n'a pas pu être redémarrée",
        );
      } else {
        onFinish();
        message.success("La file d'attente a été redémarrée");
      }
    } catch (err) {
      console.log(err?.response?.data?.message || err?.message || err);
      message.error(
        err?.response?.data?.message ||
          err?.message ||
          "Une erreur est survenue, la file d'attente n'a pas pu être redémarrée",
      );
    }
  };

  const forceStartQueue = async () => {
    try {
      const response = await post(`${aiRoutes.queue}/force-start`);

      if (response.status !== 200) {
        message.error(
          response?.data?.message || "Une erreur est survenue, la file d'attente n'a pas pu être redémarrée",
        );
      } else {
        onFinish();
        message.success("La file d'attente a été redémarrée");
      }
    } catch (err) {
      console.log(err?.response?.data?.message || err?.message || err);
      message.error(
        err?.response?.data?.message ||
          err?.message ||
          "Une erreur est survenue, la file d'attente n'a pas pu être redémarrée",
      );
    }
  };

  const pauseQueue = async () => {
    try {
      const response = await post(`${aiRoutes.queue}/stop`);

      console.log(response);

      if (response.status !== 200) {
        message.error(
          response?.data?.message || "Une erreur est survenue, la file d'attente n'a pas pu être mise en pause",
        );
      } else {
        onFinish();
        message.success("La file d'attente a été redémarrée");
      }
    } catch (err) {
      console.log(err?.response?.data?.message || err?.message || err);
      message.error(
        err?.response?.data?.message ||
          err?.message ||
          "Une erreur est survenue, la file d'attente n'a pas pu être mise en pause",
      );
    }
  };

  const dropQueue = async () => {
    try {
      const response = await remove(`${aiRoutes.queue}`);

      if (response.status !== 200) {
        message.error(response?.data?.message || "Une erreur est survenue, la file d'attente ne peut pas être vidée");
      } else {
        onFinish();
        message.success("La file d'attente a été vidée");
      }
    } catch (err) {
      console.log(err?.response?.data?.message || err?.message || err);
      message.error(
        err?.response?.data?.message ||
          err?.message ||
          "Une erreur est survenue, la file d'attente ne peut pas être vidée",
      );
    }
  };

  const moveJobPriorityUp = async (jobId) => {
    onProcessing(true);

    const results = await patch(`${aiRoutes.queue}/priority-up/${jobId}`);

    if (results.status === 200) {
      onFinish();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    onProcessing(false);
  };

  const moveJobPriorityDown = async (jobId) => {
    onProcessing(true);

    const results = await patch(`${aiRoutes.queue}/priority-down/${jobId}`);

    if (results.status === 200) {
      onFinish();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    onProcessing(false);
  };

  const dropJob = async (jobId) => {
    onProcessing(true);

    const results = await remove(`${aiRoutes.queue}/${jobId}`);

    if (results.status === 200) {
      onFinish();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    onProcessing(false);
  };

  const Actions = memo(({ record }) => {
    // const tempAccess = record?.tempAccesses.find((item) => item.user.toString() === user._id.toString());

    return (
      <Menu key={`menu-${record.id}`}>
        {checkAuthorization(user, 'ai', 'api', 'togglePriority') ? (
          record.priority ? (
            <Item key="disablePriority" onClick={() => moveJobPriorityDown(record.id)}>
              Retire la priorité
            </Item>
          ) : (
            <Item key="enablePriority" onClick={() => moveJobPriorityUp(record.id)}>
              Traite en priorité
            </Item>
          )
        ) : null}
        {checkAuthorization(user, 'ai', 'api', 'dropItemFromQueue') && (
          <Item key="delete">
            <Popconfirm {...popconfirmProps} onConfirm={() => dropJob(record.id)}>
              Supprime la tâche
            </Popconfirm>
          </Item>
        )}
      </Menu>
    );
  });

  const columns = [
    {
      title: 'Priorité',
      dataIndex: 'priority',
      key: 'priority',
      width: 125,
      filters: [
        { text: 'Prioritaire', value: true },
        { text: 'Non prioritaire', value: false },
      ],
      onFilter: (value, record) => (record?.priority || false) === value,
      render: (value) => {
        if (value) {
          return (
            <Tag color="yellow" icon={<StarFilled />}>
              Prioritaire
            </Tag>
          );
        }
      },
    },
    {
      title: 'Utilisateur',
      dataIndex: 'item',
      key: 'user',
      render: (value) => {
        return <AiUser data={value} />;
      },
    },
    {
      title: 'Temps depuis soumission',
      dataIndex: 'elapsedTime',
      key: 'elapsedTime',
      sorter: (a, b) => sortDate(a, b, 'elapsedTime'),
      sortDirections: ['ascend', 'descend'],
      render: (record) => {
        if (record === null) {
          return <>--</>;
        } else {
          return <>{formatDistanceToNowStrict(new Date(record), { locale: fr })}</>;
        }
      },
    },
    {
      title: 'Tentatives',
      dataIndex: 'attempts',
      key: 'attempts',
      sorter: (a, b) => Number(a || 0) - Number(b || 0),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: '',
      key: 'actions',
      width: 50,
      align: 'center',
      fixed: 'right',
      render: (record) => (
        <Dropdown overlay={<Actions record={record} />} placement="bottomRight" trigger={['click']}>
          <Button icon={<MoreOutlined />} disabled={isProcessing} />
        </Dropdown>
      ),
    },
  ];

  return (
    <Space size="small" direction="vertical" style={{ display: 'flex' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <AiQueueStatus status={status} elapsedTime={elapsedTime} />
        <Space size="small">
          {status === 'RUNNING' && (
            <>
              {checkAuthorization(user, 'ai', 'api', 'pauseQueue') && (
                <Popconfirm {...popconfirmProps} onConfirm={pauseQueue}>
                  <Button disabled={!queue.length} type="link">
                    Mettre la file d'attente en pause
                  </Button>
                </Popconfirm>
              )}
              {checkAuthorization(user, 'ai', 'api', 'haltQueue') && (
                <>
                  <Button type="link" onClick={() => setIsModalOpen(true)}>
                    Interrompre la file d'attente
                  </Button>
                  <HaltModal visible={isModalOpen} onHalt={onFinish} onCancel={() => setIsModalOpen(false)} />
                </>
              )}
            </>
          )}
          {status === 'STOPPED' && checkAuthorization(user, 'ai', 'api', 'startQueue') ? (
            <Button type="link" onClick={restartQueue}>
              Redémarrer la file d'attente
            </Button>
          ) : null}
          {['HALTED', 'ERRORED'].includes(status) && checkAuthorization(user, 'ai', 'api', 'forceStartQueue') ? (
            <Popconfirm {...popconfirmProps} onConfirm={forceStartQueue}>
              <Button type="link">Forcer le redémarrage de la file d'attente</Button>
            </Popconfirm>
          ) : null}
          {checkAuthorization(user, 'ai', 'api', 'dropQueue') && (
            <Popconfirm {...popconfirmProps} onConfirm={dropQueue} disabled={['OFFLINE', 'EMPTY'].includes(status)}>
              <Button type="primary" danger disabled={['OFFLINE', 'EMPTY'].includes(status) || !queue.length}>
                Vider la file d'attente
              </Button>
            </Popconfirm>
          )}
        </Space>
      </div>
      <Table
        loading={!queue || isValidating || isProcessing}
        columns={columns}
        dataSource={queue || []}
        size="small"
        bordered
        rowKey={(row) => row.id}
        pagination={tablePagination(queue)}
        expandable={{
          expandedRowRender: (record, index, indent, expanded) => {
            if (record?.item?.interaction) {
              return <InteractionExpandable interactionId={record.item.interaction} isExpanded={expanded} />;
            }
          },
        }}
      />
    </Space>
  );
});

AiQueueTable.displayName = 'AiQueueTable';
export default AiQueueTable;
