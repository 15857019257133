import { Col, Form, Row, Select, Space, Typography } from 'antd';
import { Controller, useFormContext, useFormState } from 'react-hook-form';

const USER_FIELD_SELECTION = [
  {
    label: 'Nom complet',
    value: 'USER_NAME',
  },
  {
    label: 'Formation',
    value: 'USER_FORMATION',
    disabled: true,
  },
];

const UserFieldVariable = ({ index, disabled }) => {
  const { control } = useFormContext();
  const { errors } = useFormState({ control });

  return (
    <Row gutter={(24, 24)}>
      <Col span={12}>
        <Space direction="vertical" style={{ display: 'flex' }}>
          <Form.Item
            validateStatus={errors?.variables?.[index]?.field?.message && 'error'}
            help={errors?.variables?.[index]?.field?.message}
            label="Nature du champ"
            required
          >
            <Controller
              name={`variables.[${index}].field`}
              control={control}
              render={({ field }) => (
                <Select {...field} disabled={disabled} style={{ width: '200px' }} options={USER_FIELD_SELECTION} />
              )}
            />
          </Form.Item>
        </Space>
      </Col>
    </Row>
  );
};

export default UserFieldVariable;
