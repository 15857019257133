import { Card, Typography } from 'antd';
import ApiAiStatus from './ApiAiStatus';

const ApiAi = () => {
  return (
    <Card>
      <Typography.Title level={4}>API Intelligence Artificielle</Typography.Title>
      <ApiAiStatus />
    </Card>
  );
};

export default ApiAi;
