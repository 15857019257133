import {
  Button,
  Calendar,
  Checkbox,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import classnames from 'classnames';
import { isSameDay } from 'date-fns';
import moment from 'moment';
import { search, validator, format } from '@i-maginexr/js';
import useSWR from 'swr';
import { memo, useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm, useFormContext, useFormState, useWatch } from 'react-hook-form';
import useFetch from '../../../../../hooks/useFetch';

const PAGE_URL = `${process.env.REACT_APP_BASE_URL_API_URL}/next/private/arinfo/pages/inter-intra`;
const LOCATIONS_URL = `${process.env.REACT_APP_BASE_URL_API_URL}/next/private/arinfo/agencies`;
const USERS_URL = `${process.env.REACT_APP_BASE_URL_API_URL}/next/private/global/users`;

const defaultValues = {
  mode: null,
  type: null,
  config: {
    range: [], // Pour le mode période
    days: [], // pour le mode Jours datés
    day: null, // Pour le mode récurrent
    custom: '', // Pour le mode personnalisé
    duration: null, // Pour les modes Jours datés, Récurrent, Personnalisé
  },
  goal: {
    type: null,
    specialization: '',
  },
  former: null,
  accessMode: null,
  location: null,
  price: {
    type: 'fixed',
    mode: null,
    excludingTaxes: null,
    includingTaxes: null,
  },
  places: {
    type: 'infinite',
    value: null,
  },
};

const SWROptions = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

const DateItem = memo(({ date, onSelect }) => {
  const day = moment(date).format('DD');
  const { control } = useFormContext();
  const days = useWatch({ control, name: 'config.days' });
  const selected = days.findIndex((item) => isSameDay(new Date(date), new Date(item))) !== -1;

  const style = {
    height: '100%',
    width: '100%',
  };

  return (
    <div
      className={classnames('ant-picker-cell-custom ant-picker-cell-inner ant-picker-calendar-date', {
        'ant-picker-cell-custom-selected': selected,
      })}
      style={style}
      onClick={onSelect}
    >
      <div className="ant-picker-calendar-date-value">{day}</div>
      <div className="ant-picker-calendar-date-content"></div>
    </div>
  );
});

DateItem.displayName = 'DateItem';

const CreateOrUpdateSchedule = ({ open, action, onFinish, onCancel, item }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const { data: users, isValidating: isUsersValidating } = useSWR(open ? USERS_URL : null, SWROptions);
  const { data: locations, isValidating: isLocationsValidating } = useSWR(open ? LOCATIONS_URL : null, SWROptions);
  const { post, put } = useFetch();
  const methods = useForm({ defaultValues });
  const { control, handleSubmit, reset, setError, trigger, setValue } = methods;
  const { errors } = useFormState({ control });
  const [accessMode, type, goalType, mode] = useWatch({
    control,
    name: ['accessMode', 'type', 'goal.type', 'mode'],
  });

  useEffect(() => {
    if (open) {
      reset(action === 'EDIT' ? item.schedule : defaultValues);
    }
  }, [open, item]);

  const validate = (values) => {
    const errors = {};

    if (action === 'CREATE') {
      if (!('mode' in values) || values.mode === defaultValues.mode) {
        errors.mode = 'Ce champ est requis';
      } else {
        if (typeof values.mode !== 'string') {
          errors.mode = 'Format invalide';
        } else {
          if (!['Inter', 'Intra', 'Tutorat'].includes(values.mode)) {
            errors.mode = 'Valeur invalide';
          }
        }
      }
    }

    if (!('type' in values) || values.type === defaultValues.type) {
      errors.type = 'Ce champ est requis';
    }

    if (!'config' in values || !validator.isStrictObject(values?.config)) {
      errors.type = 'Format invalide';
    } else {
      if (
        !('duration' in values.config) ||
        (!values.config.duration && values.config.duration !== 0) ||
        values.config.duration === defaultValues.config.duration
      ) {
        errors['config.duration'] = 'Ce champ est requis';
      } else {
        if (!['string', 'number'].includes(typeof values.config.duration) || isNaN(values.config.duration)) {
          errors['config.duration'] = 'Valeur invalide';
        }
      }

      if (values.type === 'Jours datés') {
        if (!'days' in values.config) {
          errors['config.days'] = 'Ce champ est requis';
        } else {
          if (!Array.isArray(values.config.days) || values.config.days.length < 1) {
            errors['config.days'] = 'Ce champ est requis';
          } else {
            if (values.config.days.some((value) => !validator.isValidDate(new Date(value)))) {
              errors['config.days'] = 'Valeur invalide';
            }
          }
        }
      }

      if (values.type === 'Récurrent') {
        if (!'day' in values.config) {
          errors['config.day'] = 'Ce champ est requis';
        } else {
          if (typeof values.config.day !== 'string') {
            errors['config.day'] = 'Format invalide';
          } else {
            if (!['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi'].includes(values.config.day)) {
              errors['config.day'] = 'Valeur invalide';
            }
          }
        }
      }

      if (values.type === 'Période') {
        if (!'range' in values.config) {
          errors['config.range'] = 'Ce champ est requis';
        } else {
          if (!Array.isArray(values.config.range) || values.config.range.length !== 2) {
            errors['config.range'] = 'Ce champ est requis';
          } else {
            if (values.config.range.some((value) => !validator.isValidDate(new Date(value)))) {
              errors['config.range'] = 'Valeur invalide';
            }
          }
        }
      }

      if (values.type === 'Personnalisé') {
        if (!'custom' in values.config) {
          errors['config.custom'] = 'Ce champ est requis';
        } else {
          if (typeof values.config.custom !== 'string') {
            errors['config.custom'] = 'Format invalide';
          } else {
            if (values.config.custom.trim() === '') {
              errors['config.custom'] = 'Ce champ est requis';
            }
          }
        }
      }
    }

    if (!('goal' in values) || values.goal === defaultValues.goal) {
      errors.goal = 'Ce champ est requis';
    } else {
      if (!validator.isStrictObject(values.goal)) {
        errors.goal = 'Format invalide';
      } else {
        if (!('type' in values.goal) || values.goal.type === defaultValues.goal.type) {
          errors['goal.type'] = 'Ce champ est requis';
        } else {
          if (!item.formation.mode.goals.includes(values.goal.type)) {
            errors['goal.type'] = 'Valeur invalide';
          } else {
            if (values.goal.type === 'Spécialisation') {
              if (
                !('specialization' in values.goal) ||
                values.goal.specialization === defaultValues.goal.specialization
              ) {
                errors['goal.specialization'] = 'Ce champ est requis';
              } else {
                if (typeof values.goal.specialization !== 'string') {
                  errors['goal.specialization'] = 'Format invalide';
                } else {
                  if (values.goal.specialization.trim() === '') {
                    errors['goal.specialization'] = 'Ce champ est requis';
                  }
                }
              }
            }
          }
        }
      }
    }

    if (!('accessMode' in values) || values.accessMode === defaultValues.accessMode) {
      errors.accessMode = 'Ce champ est requis';
    } else {
      if (values.accessMode === 'Présentiel') {
        if (!('location' in values) || values.location === defaultValues.location) {
          errors.location = 'Ce champ est requis';
        }
      }
    }

    if (!('price' in values) || values.price === defaultValues.price) {
      errors.price = 'Ce champ est requis';
    } else {
      if (!validator.isStrictObject(values.price)) {
        errors.price = 'Valeur invalide';
      } else {
        if (!('type' in values.price)) {
          errors.price = 'Ce champ est requis';
        } else {
          if (!['startAt', 'fixed'].includes(values.price.type)) {
            errors.price = 'Valeur invalide';
          }
        }

        if (
          !('excludingTaxes' in values.price) ||
          (!values.price.excludingTaxes && values.price.excludingTaxes !== 0) ||
          values.price.excludingTaxes === defaultValues.price.excludingTaxes
        ) {
          errors.price = 'Ce champ est requis';
        } else {
          if (
            !['string', 'number'].includes(typeof values.price.excludingTaxes) ||
            isNaN(values.price.excludingTaxes)
          ) {
            errors.price = 'Valeur invalide';
          }
        }

        if (
          !('includingTaxes' in values.price) ||
          (!values.price.includingTaxes && values.price.includingTaxes !== 0) ||
          values.price.includingTaxes === defaultValues.price.includingTaxes
        ) {
          errors.price = 'Ce champ est requis';
        } else {
          if (
            !['string', 'number'].includes(typeof values.price.includingTaxes) ||
            isNaN(values.price.includingTaxes)
          ) {
            errors.price = 'Valeur invalide';
          }
        }
      }
    }

    if (!('places' in values) || values.places === defaultValues.places) {
      errors.places = 'Ce champ est requis';
    } else {
      if (!validator.isStrictObject(values.places)) {
        errors.places = 'Valeur invalide';
      } else {
        if (!('type' in values.places)) {
          errors.places = 'Ce champ est requis';
        } else {
          if (!['fixed', 'infinite'].includes(values.places.type)) {
            errors.price = 'Valeur invalide';
          } else {
            if (values.places.type === 'fixed') {
              if (
                !('value' in values.places) ||
                (!values.places.value && values.places.value !== 0) ||
                values.places.value === defaultValues.places.value
              ) {
                errors.places = 'Ce champ est requis';
              } else {
                if (!['string', 'number'].includes(typeof values.places.value) || isNaN(values.places.value)) {
                  errors.places = 'Valeur invalide';
                }
              }
            }
          }
        }
      }
    }

    return errors;
  };

  const onSubmit = async (form) => {
    setIsProcessing(true);
    const errors = validate(form);

    if (Object.keys(errors).length !== 0) {
      setIsProcessing(false);

      return Object.entries(errors).forEach(([key, value]) => {
        setError(key, { type: 'manual', message: value });
      });
    }

    if (action === 'CREATE') {
      const results = await post(`${PAGE_URL}/formations/${item.formation._id}/schedule`, JSON.stringify(form));

      if (results.status === 201) {
        setIsProcessing(false);
        message.success('Programmation créée');
        return onFinish();
      } else {
        setIsProcessing(false);

        if (results.message) {
          message.error(results.message);
        } else {
          Object.entries(results.errors).forEach(([key, value]) => {
            setError(key, { type: 'manual', message: value });
          });
        }
      }
    }

    if (action === 'EDIT') {
      const results = await put(
        `${PAGE_URL}/formations/${item.formation._id}/schedule/${item.schedule._id}`,
        JSON.stringify(form),
      );

      if (results.status === 200) {
        setIsProcessing(false);
        message.success('Programmation modifiée');
        return onFinish();
      } else {
        setIsProcessing(false);

        if (results.message) {
          message.error(results.message);
        } else {
          Object.entries(results.errors).forEach(([key, value]) => {
            setError(key, { type: 'manual', message: value });
          });
        }
      }
    }

    setIsProcessing(false);
  };

  const groupOptions = useMemo(() => {
    const options = [];

    if (item?.formation?.mode?.goals) {
      if (item.formation.mode.goals.includes('Opérationnel')) {
        options.push({ label: 'Opérationnel', value: 'Opérationnel' });
      }

      if (item.formation.mode.goals.includes('Perfectionnement')) {
        options.push({ label: 'Perfectionnement', value: 'Perfectionnement' });
      }

      if (item.formation.mode.goals.includes('Complet')) {
        options.push({ label: 'Complet', value: 'Complet' });
      }

      if (item.formation.mode.goals.includes('Spécialisation')) {
        options.push({ label: 'Spécialisation', value: 'Spécialisation' });
      }

      if (item.formation.mode.goals.includes('Remise à niveau') && mode === 'Tutorat') {
        options.push({ label: 'Remise à niveau', value: 'Remise à niveau' });
      }

      if (item.formation.mode.goals.includes('Entrée/Sortie') && mode === 'Tutorat') {
        options.push({ label: 'Entrée/Sortie', value: 'Entrée/Sortie' });
      }
    }

    return options;
  }, [action, item, mode]);

  return (
    <Drawer width={500} visible={open} onClose={onCancel}>
      <Typography.Paragraph type="secondary">
        <blockquote style={{ marginTop: 0 }}>
          <ul>
            <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
          </ul>
        </blockquote>
      </Typography.Paragraph>
      <FormProvider {...methods}>
        <Form noValidate layout="vertical">
          <Form.Item
            label="Mode"
            required
            validateStatus={errors?.mode?.message && 'error'}
            help={errors?.mode?.message}
            tooltip={action === 'EDIT' && "Ce champ n'est pas modifiable."}
          >
            <Controller
              {...{ control }}
              name="mode"
              render={({ field }) => (
                <Select
                  {...field}
                  placeholder="Sélectionnez dans la liste"
                  disabled={action === 'EDIT' || isProcessing}
                  options={[
                    { label: 'Inter', value: 'Inter' },
                    { label: 'Intra', value: 'Intra' },
                    { label: 'Tutorat', value: 'Tutorat' },
                  ]}
                />
              )}
            />
          </Form.Item>
          <Form.Item
            label="Type de programmation"
            required
            validateStatus={errors?.type?.message && 'error'}
            help={errors?.type?.message}
          >
            <Controller
              {...{ control }}
              name="type"
              render={({ field }) => (
                <Select
                  {...field}
                  disabled={isProcessing}
                  placeholder="Sélectionnez dans la liste"
                  options={[
                    { label: 'Jours datés', value: 'Jours datés' },
                    { label: 'Récurrent', value: 'Récurrent' },
                    { label: 'Période', value: 'Période' },
                    { label: 'Personnalisé', value: 'Personnalisé' },
                  ]}
                />
              )}
            />
          </Form.Item>
          {type === 'Jours datés' && (
            <Form.Item
              label="Sélection des jours"
              required
              validateStatus={errors?.config?.days?.message && 'error'}
              help={errors?.config?.days?.message}
            >
              <Controller
                {...{ control }}
                name="config.days"
                render={({ field }) => {
                  const handleSelect = (date) => {
                    if (isProcessing) {
                      return;
                    }

                    const newDates = [...field.value];
                    const index = field.value.findIndex((item) => isSameDay(new Date(date), new Date(item)));

                    if (index === -1) {
                      newDates.push(date);
                    } else {
                      newDates.splice(index, 1);
                    }

                    return field.onChange(newDates);
                  };

                  return (
                    <Calendar
                      fullscreen={false}
                      style={{ border: '1px solid #d9d9d9' }}
                      dateFullCellRender={(momentDate) => {
                        const date = moment(momentDate).startOf('day').toDate();

                        return <DateItem {...{ date }} onSelect={() => handleSelect(date)} />;
                      }}
                    />
                  );
                }}
              />
            </Form.Item>
          )}
          {type === 'Récurrent' && (
            <Form.Item
              label="Jour"
              required
              validateStatus={errors?.config?.day?.message && 'error'}
              help={errors?.config?.day?.message}
            >
              <Controller
                {...{ control }}
                name="config.day"
                render={({ field }) => (
                  <Select
                    {...field}
                    disabled={isProcessing}
                    placeholder="Sélectionnez dans la liste"
                    options={[
                      { label: 'Lundi', value: 'Lundi' },
                      { label: 'Mardi', value: 'Mardi' },
                      { label: 'Mercredi', value: 'Mercredi' },
                      { label: 'Jeudi', value: 'Jeudi' },
                      { label: 'Vendredi', value: 'Vendredi' },
                    ]}
                  />
                )}
              />
            </Form.Item>
          )}
          {type === 'Personnalisé' && (
            <Form.Item
              label="Raison"
              required
              validateStatus={errors?.config?.custom?.message && 'error'}
              help={errors?.config?.custom?.message}
              tooltip="Par exemple : « Nous contacter »."
            >
              <Controller
                {...{ control }}
                name="config.custom"
                render={({ field }) => <Input disabled={isProcessing} {...field} />}
              />
            </Form.Item>
          )}
          {type === 'Période' && (
            <Form.Item
              label="Période"
              required
              validateStatus={errors?.config?.range?.message && 'error'}
              help={errors?.config?.range?.message}
            >
              <Controller
                {...{ control }}
                name="config.range"
                render={({ field }) => (
                  <DatePicker.RangePicker
                    {...field}
                    disabled={isProcessing}
                    format="DD/MM/YYYY"
                    allowClear={false}
                    value={field?.value?.length === 2 ? [moment(field.value[0]), moment(field.value[1])] : []}
                    onChange={(momentRange) => {
                      const startDate = moment(momentRange[0]).startOf('day').toDate();
                      const endDate = moment(momentRange[1]).startOf('day').toDate();

                      return field.onChange([startDate, endDate]);
                    }}
                  />
                )}
              />
            </Form.Item>
          )}
          <Form.Item
            label="Durée (en jours)"
            required
            validateStatus={errors?.config?.duration?.message && 'error'}
            help={errors?.config?.duration?.message}
          >
            <Controller
              {...{ control }}
              name="config.duration"
              render={({ field }) => (
                <InputNumber {...field} disabled={isProcessing} style={{ width: '100%' }} min={1} addonAfter="jours" />
              )}
            />
          </Form.Item>
          <Form.Item
            label="Formateur"
            validateStatus={errors?.former?.message && 'error'}
            help={errors?.former?.message}
          >
            <Controller
              {...{ control }}
              name="former"
              render={({ field }) => (
                <Select
                  {...field}
                  disabled={isProcessing}
                  showSearch
                  allowClear
                  filterOption={(input, option) => search.inString(input, option.label)}
                  placeholder="Sélectionnez dans la liste"
                  loading={isUsersValidating}
                  options={(users?.data || [])
                    .sort((a, b) => a.last_name.localeCompare(b.last_name, 'fr'))
                    .map((user) => {
                      const { firstName, lastName } = format.toFullName(user.first_name, user.last_name);

                      return {
                        label: `${lastName} ${firstName}`,
                        value: user._id,
                      };
                    })}
                />
              )}
            />
          </Form.Item>
          <Form.Item
            label="Objectif"
            required
            validateStatus={errors?.goal?.type?.message && 'error'}
            help={
              !groupOptions || groupOptions.length === 0
                ? 'Aucun objectif disponible. Veuillez sélectionner les objectifs disponibles depuis les paramètres de la formation.'
                : errors?.goal?.type?.message || null
            }
          >
            <Controller
              {...{ control }}
              name="goal.type"
              render={({ field }) => (
                <Select
                  {...field}
                  placeholder="Sélectionnez dans la liste"
                  options={groupOptions}
                  disabled={!groupOptions || groupOptions.length === 0 || isProcessing}
                />
              )}
            />
          </Form.Item>
          {goalType === 'Spécialisation' && (
            <Form.Item
              label="Spécialisation"
              required
              validateStatus={errors?.goal?.specialization?.message && 'error'}
              help={errors?.goal?.specialization?.message}
            >
              <Controller
                {...{ control }}
                name="goal.specialization"
                render={({ field }) => <Input disabled={isProcessing} {...field} />}
              />
            </Form.Item>
          )}
          <Form.Item
            label="Modalité"
            required
            validateStatus={errors?.accessMode?.message && 'error'}
            help={errors?.accessMode?.message}
          >
            <Controller
              {...{ control }}
              name="accessMode"
              render={({ field }) => (
                <Select
                  {...field}
                  disabled={isProcessing}
                  placeholder="Sélectionnez dans la liste"
                  options={[
                    {
                      label: 'Distanciel',
                      value: 'Distanciel',
                    },
                    {
                      label: 'Présentiel',
                      value: 'Présentiel',
                    },
                  ]}
                />
              )}
            />
          </Form.Item>
          {accessMode === 'Présentiel' && (
            <Form.Item
              label="Centre pour le présentiel"
              required
              validateStatus={errors?.location?.message && 'error'}
              help={errors?.location?.message}
            >
              <Controller
                {...{ control }}
                name="location"
                render={({ field }) => (
                  <Select
                    {...field}
                    disabled={isProcessing}
                    showSearch
                    filterOption={(input, option) => search.inString(input, option.label)}
                    placeholder="Sélectionnez dans la liste"
                    loading={isLocationsValidating}
                    options={(locations?.data || [])
                      .sort((a, b) => a.city.localeCompare(b.city, 'fr'))
                      .map((location) => ({
                        label: location.city,
                        value: location._id,
                      }))}
                  />
                )}
              />
            </Form.Item>
          )}
          <Form.Item
            label="Places disponibles"
            required
            tooltip="Indiquez le nombre de places disponibles pour cette période. Si le nombre de places est illimité ou inconnu, cochez la case « illimité » pour désactiver la saisie d'un nombre et faire apparaître la mention « Places disponibles »."
            validateStatus={errors?.places?.message && 'error'}
            help={errors?.places?.message}
          >
            <Controller
              {...{ control }}
              name="places"
              render={({ field }) => (
                <Controller
                  {...{ control }}
                  name="places.type"
                  render={({ field }) => (
                    <Controller
                      {...{ control }}
                      name="places.value"
                      render={({ field: nestedField }) => (
                        <InputNumber
                          {...nestedField}
                          disabled={field.value === 'infinite' || isProcessing}
                          style={{ width: '100%' }}
                          onChange={(value) => {
                            trigger('places');

                            return nestedField.onChange(value);
                          }}
                          addonBefore={
                            <div style={{ width: 150, textAlign: 'left' }}>
                              <Checkbox
                                {...field}
                                disabled={isProcessing}
                                checked={field.value === 'infinite'}
                                onChange={(event) => {
                                  trigger('places');

                                  if (event.target.checked) {
                                    return field.onChange('infinite');
                                  }

                                  return field.onChange('fixed');
                                }}
                              >
                                Illimité
                              </Checkbox>
                            </div>
                          }
                        />
                      )}
                    />
                  )}
                />
              )}
            />
          </Form.Item>
          <Form.Item
            label="Tarif HT/TTC"
            required
            tooltip="Saisissez le tarif de la formation HT et TTC. Si vous souhaitez indiquer un prix variable, cochez la case « Prix de départ ». Si la case n'est pas cochée, le montant sera considéré comme un prix fixe."
            validateStatus={errors?.price?.message && 'error'}
            help={errors?.price?.message}
          >
            <Controller
              {...{ control }}
              name="price"
              render={({ field }) => (
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                  <Space>
                    <Controller
                      {...{ control }}
                      name="price.type"
                      render={({ field: nestedField }) => (
                        <Checkbox
                          {...field}
                          disabled={isProcessing}
                          checked={nestedField.value === 'startAt'}
                          onChange={(event) => {
                            trigger('price');

                            if (event.target.checked) {
                              return nestedField.onChange('startAt');
                            }

                            return nestedField.onChange('fixed');
                          }}
                        >
                          Prix de départ
                        </Checkbox>
                      )}
                    />
                    <Controller
                      {...{ control }}
                      name="price.mode"
                      render={({ field: nestedField }) => (
                        <Checkbox
                          {...field}
                          disabled={isProcessing}
                          checked={nestedField.value === 'day'}
                          onChange={(event) => {
                            trigger('price');

                            if (event.target.checked) {
                              return nestedField.onChange('day');
                            }

                            return nestedField.onChange(null);
                          }}
                        >
                          Prix journalier
                        </Checkbox>
                      )}
                    />
                  </Space>

                  <Space.Compact className="ant-input-number-group-custom">
                    <Controller
                      {...{ control }}
                      name="price.includingTaxes"
                      render={({ field }) => (
                        <InputNumber
                          {...field}
                          disabled={isProcessing}
                          style={{ width: '100%' }}
                          className="ant-input-number-group-custom-input"
                          addonAfter="€ TTC"
                          onChange={(value) => {
                            trigger('price');

                            setValue('price.excludingTaxes', parseFloat(value / 1.2).toFixed(2));
                            return field.onChange(value);
                          }}
                        />
                      )}
                    />
                    <Controller
                      {...{ control }}
                      name="price.excludingTaxes"
                      render={({ field }) => (
                        <InputNumber
                          {...field}
                          disabled={isProcessing}
                          className="ant-input-number-group-custom-input"
                          style={{ width: '100%' }}
                          addonAfter="€ HT"
                          onChange={() => {}}
                        />
                      )}
                    />
                  </Space.Compact>
                </Space>
              )}
            />
          </Form.Item>
          <Row gutter={[24, 24]} justify="space-between">
            <Col flex="none">
              <Button type="primary" onClick={handleSubmit(onSubmit)} loading={isProcessing}>
                Enregistrer
              </Button>
            </Col>
            <Col flex="none">
              <Button onClick={onCancel}>Annuler</Button>
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </Drawer>
  );
};

export default CreateOrUpdateSchedule;
