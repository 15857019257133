import { useState } from 'react';
import { DeleteOutlined, DownloadOutlined, FilePdfOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Card, Col, Empty, message, Modal, Row, Space, Spin, Typography, Upload } from 'antd';
import useSWR from 'swr';
import { format } from 'date-fns';
import { useAuth } from '../../../../../authContext';
import useFetch from '../../../../../hooks/useFetch';
import { checkAuthorization } from '../../../../../shared/utils';

const CATALOG_URL = `${process.env.REACT_APP_BASE_URL_API_URL}/next/private/arinfo/pages/inter-intra/catalog`;
const FILE_MANAGER_URL = `${process.env.REACT_APP_BASE_URL_API_URL}/next/private/global/files`;
const DOWNLOAD_URL = `${process.env.REACT_APP_BASE_URL_API_URL}/next/public/global/files/download`;

const SWROptions = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

const Catalog = () => {
  const [downloading, setDownloading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const { token, user } = useAuth();
  const { data, isValidating, mutate } = useSWR(CATALOG_URL, SWROptions);
  const { _id, originalName, name, createdAt } = data?.data || {};
  const { get, patch, remove } = useFetch();

  const onCatalogDownload = async () => {
    setDownloading(true);

    // Récupère le token de téléchargement
    const response = await get(`${FILE_MANAGER_URL}/token/${_id}`);

    // Télécharge le catalogue
    if (response.status === 200) {
      const { downloadToken } = response.data;

      const link = document.createElement('a');

      link.href = `${DOWNLOAD_URL}/${downloadToken}`;
      link.download = originalName || name;

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    }

    setDownloading(false);
  };

  const onCatalogUpload = async (file) => {
    // Supprime le fichier précédent du serveur
    await remove(`${FILE_MANAGER_URL}/${_id}`, false);

    // Ajoute le nouveau catalogue à la page
    const response = await patch(CATALOG_URL, JSON.stringify({ file: file._id }));

    if (response.status === 200) {
      // Met à jour le bloc
      mutate();
    }
  };

  const onCatalogDelete = () => {
    return Modal.confirm({
      title: 'Voulez-vous vraiment supprimer le catalogue ?',
      content:
        'Cette action est irréversible, le bouton de téléchargement sera également retiré de la page du site Arinfo.',
      okText: 'Confirmer',
      okType: 'danger',
      onOk: async () => {
        // Supprime le fichier du serveur
        await remove(`${FILE_MANAGER_URL}/${_id}`, false);
        // Supprime le catalogue de la page
        await remove(CATALOG_URL, false);

        // Met à jour le bloc
        mutate();
      },
    });
  };

  return (
    <Card
      size="small"
      title={<Typography.Title level={5}>Catalogue</Typography.Title>}
      extra={
        <Space size="small">
          {createdAt && (
            <Typography.Text type="secondary">
              MàJ le {format(new Date(createdAt), 'dd/MM/yyyy à HH:mm')}
            </Typography.Text>
          )}
          {checkAuthorization(user, 'arinfo', 'interFormations', 'update-catalog') && (
            <Upload
              name="catalog"
              showUploadList={false}
              action={FILE_MANAGER_URL}
              multiple={false}
              headers={{ Authorization: `Bearer ${token}` }}
              data={(file) => ({
                file,
                folder: 'arinfo',
              })}
              beforeUpload={(file) => {
                const fileSize = file.size / 1024 / 1024;
                const maxUploadSize = 5;

                if (fileSize > maxUploadSize) {
                  message.error('Fichier trop lourd, maximum autorisé : 5 Mo');
                  return false;
                }

                if (file.type !== 'application/pdf') {
                  message.error('Fichier invalide, autorisé : PDF');
                  return false;
                }
              }}
              onChange={({ file }) => {
                if (file.status === 'uploading') {
                  setUploading(true);
                } else {
                  setUploading(false);
                }

                if (file.status === 'done' && file?.response) {
                  return onCatalogUpload(file.response.data);
                }
              }}
              accept="application/pdf"
              disabled={isValidating}
            >
              <Button size="small" icon={<UploadOutlined />} disabled={isValidating || uploading} />
            </Upload>
          )}
          {checkAuthorization(user, 'arinfo', 'interFormations', 'update-catalog') && (
            <Button size="small" icon={<DeleteOutlined />} disabled={isValidating || !_id} onClick={onCatalogDelete} />
          )}
        </Space>
      }
    >
      <Spin spinning={uploading}>
        {!!_id ? (
          <Row gutter={[12, 12]} wrap={false}>
            <Col flex="none">
              <FilePdfOutlined style={{ color: '#f5222d', fontSize: 55 }} />
            </Col>
            <Col flex="auto">
              <Space direction="vertical" style={{ display: 'flex' }}>
                <Typography.Text strong ellipsis>
                  {originalName || name}
                </Typography.Text>
                <Button
                  size="small"
                  type="primary"
                  icon={<DownloadOutlined />}
                  loading={downloading}
                  onClick={onCatalogDownload}
                >
                  Télécharger
                </Button>
              </Space>
            </Col>
          </Row>
        ) : (
          <Empty description="Aucun fichier" />
        )}
      </Spin>
    </Card>
  );
};

export default Catalog;
