import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm, Controller, useFormState } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { CirclePicker } from 'react-color';
import { Form, Input, Typography, Button, Space, Tooltip, Checkbox, message, Card, Tabs } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import randomColor from 'randomcolor';
import useSWR from 'swr';
import rolesData from '../../../datasets/roles.json';
import { roleRoutes } from '../../../lib/routes';
import useFetch from '../../../hooks/useFetch';

const { TabPane } = Tabs;

const RoleUpdate = () => {
  const { handleSubmit, control, setValue, setError, getValues } = useForm();
  const { errors } = useFormState({ control });
  const { id } = useParams();
  const [colors, setColors] = useState([]);
  const history = useHistory();
  const [isProcessing, setIsProcessing] = useState(false);
  const { put } = useFetch();
  const { data: role, isValidating } = useSWR(roleRoutes.findOne + '/' + id, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const getColors = () => {
    const selectedColor = getValues('color');
    setColors([]);

    for (let i = 0; i < 10; i++) {
      const color = randomColor();
      setColors((rest) => [...rest, color]);
    }

    if (selectedColor) {
      setColors((rest) => [selectedColor, ...rest.filter((el, i) => i !== 0)]);
    }
  };

  useEffect(() => {
    if (role && role.data) {
      setValue('name', role.data.name);
      setValue('color', role.data.color);
      setValue('globalBeta', role.data.permissions.global.beta);
      setValue('globalUsers', role.data.permissions.global.users);
      setValue('globalRoles', role.data.permissions.global.roles);
      setValue('globalAgencies', role.data.permissions.global.agencies);
      setValue('globalKeys', role.data.permissions.global.keys);
      setValue('globalExternalInterventions', role.data.permissions.global.externalInterventions);
      setValue('arinfoPosts', role.data.permissions.arinfo.posts);
      setValue('arinfoTrainings', role.data.permissions.arinfo.trainings);
      setValue('arinfoStatistics', role.data.permissions.arinfo.statistics);
      setValue('arinfoProjects', role.data.permissions.arinfo.projects);
      setValue('arinfoRegistrations', role.data.permissions.arinfo.registrations);
      setValue('arinfoWorkshops', role.data.permissions.arinfo.workshops);
      setValue('arinfoFAQ', role.data.permissions.arinfo.faq);
      setValue('arinfoInterFormations', role.data.permissions.arinfo.interFormations);
      setValue('posiaoQuestions', role.data.permissions.posiao.questions);
      setValue('posiaoQuizzes', role.data.permissions.posiao.quizzes);
      setValue('posiaoTypes', role.data.permissions.posiao.types);
      setValue('posiaoEvaluations', role.data.permissions.posiao.evaluations);
      setValue('formaoStudents', role.data.permissions.formao.students);
      setValue('formaoGroups', role.data.permissions.formao.groups);
      setValue('formaoFormations', role.data.permissions.formao.formations);
      setValue('formaoModules', role.data.permissions.formao.modules);
      setValue('formaoEvents', role.data.permissions.formao.events);
      setValue('formaoDocuments', role.data.permissions.formao.documents);
      setValue('formaoFormationReports', role.data.permissions.formao.formationReports);
      setValue('formaoCompanyReports', role.data.permissions.formao.companyReports);
      setValue('formaoMonthlyReports', role.data.permissions.formao.monthlyReports);
      setValue('formaoTrainingReports', role.data.permissions.formao.trainingReports);
      setValue('formaoReportForms', role.data.permissions.formao.reportForms);
      setValue('formaoSatisfactionReports', role.data.permissions.formao.satisfactionReports);
      setValue('formaoPackages', role.data.permissions.formao.packages);
      setValue('imaginelabBookings', role.data.permissions.imaginelab.bookings);
      setValue('imaginelabWorkshops', role.data.permissions.imaginelab.workshops);
      setValue('imaginelabDocuments', role.data.permissions.imaginelab.documents);
      setValue('aiSystems', role.data.permissions.ai.systems);
      setValue('aiVersions', role.data.permissions.ai.versions);
      setValue('aiKeys', role.data.permissions.ai.keys);
      setValue('aiApi', role.data.permissions.ai.api);
      setValue('aiApplications', role.data.permissions.ai.applications);
      setValue('aiActivities', role.data.permissions.ai.activities);
      setValue('aiGeneralModules', role.data.permissions.ai.generalModules);
      setValue('aiModules', role.data.permissions.ai.modules);
      setValue('aiPersonas', role.data.permissions.ai.personas);
      setValue('aiInteractions', role.data.permissions.ai.interactions);
      setValue('aiLogs', role.data.permissions.ai.logs);
      setValue('devToolsImportExportService', role.data.permissions.devTools.importExportService);

      setColors((rest) => [role.data.color, ...rest.filter((el, i) => i !== 0)]);
    }

    if (colors.length === 0) {
      getColors();
    }
  }, [role]);

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const results = await put(roleRoutes.update + '/' + id, JSON.stringify(form));

    if (results.status === 200) {
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  const onSubmitAndLeave = async (form) => {
    setIsProcessing(true);

    const results = await put(roleRoutes.update + '/' + id, JSON.stringify(form));

    if (results.status === 200) {
      history.push('/roles');
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  return (
    <Card>
      <Form noValidate layout="vertical">
        <Typography.Paragraph type="secondary">
          <blockquote style={{ marginTop: 0 }}>
            <ul>
              <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
            </ul>
          </blockquote>
        </Typography.Paragraph>
        <Form.Item
          label="Intitulé du rôle"
          required
          validateStatus={errors?.name?.message && 'error'}
          help={errors?.name?.message}
        >
          <Controller
            control={control}
            name="name"
            render={({ field }) => <Input {...field} disabled={!role || isValidating || isProcessing} />}
          />
        </Form.Item>
        <Form.Item
          required
          label={
            <span className="gray-text">
              Couleur du rôle
              <Tooltip title="Générer de nouvelles couleurs">
                <Button
                  className="gray-text"
                  style={{ marginLeft: 10 }}
                  icon={<ReloadOutlined />}
                  onClick={getColors}
                  shape="circle"
                />
              </Tooltip>
            </span>
          }
          style={{ marginBottom: 0 }}
          validateStatus={errors?.color?.message && 'error'}
          help={errors?.color?.message}
        >
          <Controller
            control={control}
            name="color"
            render={({ field }) => (
              <CirclePicker
                {...field}
                className={isProcessing ? 'color-picker-disabled' : null}
                width="100%"
                color={field.value}
                colors={colors}
                onChangeComplete={({ hex }) => field.onChange(hex)}
              />
            )}
          />
        </Form.Item>
        <Tabs>
          <TabPane tab="Administration" key="admin">
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur les utilisateurs
              </Typography.Title>
              <Controller
                control={control}
                name="globalUsers"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.global.users}
                  />
                )}
              />
            </Form.Item>
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur les rôles
              </Typography.Title>
              <Controller
                control={control}
                name="globalRoles"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.global.roles}
                  />
                )}
              />
            </Form.Item>
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur les centres
              </Typography.Title>
              <Controller
                control={control}
                name="globalAgencies"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.global.agencies}
                  />
                )}
              />
            </Form.Item>
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur les clés d'accès
              </Typography.Title>
              <Controller
                control={control}
                name="globalKeys"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.global.keys}
                  />
                )}
              />
            </Form.Item>
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur les interventions externes
              </Typography.Title>
              <Controller
                control={control}
                name="globalExternalInterventions"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.global.externalInterventions}
                  />
                )}
              />
            </Form.Item>
          </TabPane>
          <TabPane tab="Espace Arinfo" key="arinfo">
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur les actualités
              </Typography.Title>
              <Controller
                control={control}
                name="arinfoPosts"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.arinfo.posts}
                  />
                )}
              />
            </Form.Item>
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur les formations
              </Typography.Title>
              <Controller
                control={control}
                name="arinfoTrainings"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.arinfo.trainings}
                  />
                )}
              />
            </Form.Item>
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur la galerie des projets
              </Typography.Title>
              <Controller
                control={control}
                name="arinfoProjects"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.arinfo.projects}
                  />
                )}
              />
            </Form.Item>
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur les statistiques des centres
              </Typography.Title>
              <Controller
                control={control}
                name="arinfoStatistics"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.arinfo.statistics}
                  />
                )}
              />
            </Form.Item>
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur les pré-inscriptions
              </Typography.Title>
              <Controller
                control={control}
                name="arinfoRegistrations"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.arinfo.registrations}
                  />
                )}
              />
            </Form.Item>
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur les ateliers découverte
              </Typography.Title>
              <Controller
                control={control}
                name="arinfoWorkshops"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.arinfo.workshops}
                  />
                )}
              />
            </Form.Item>
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur la foire aux questions
              </Typography.Title>
              <Controller
                control={control}
                name="arinfoFAQ"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.arinfo.faq}
                  />
                )}
              />
            </Form.Item>
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur la gestion Inter/Intra/Tutorat
              </Typography.Title>
              <Controller
                control={control}
                name="arinfoInterFormations"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.arinfo.interFormations}
                  />
                )}
              />
            </Form.Item>
          </TabPane>
          <TabPane tab="Espace Posiao" key="posiao">
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur les auto-évaluations
              </Typography.Title>
              <Controller
                control={control}
                name="posiaoEvaluations"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.posiao.evaluations}
                  />
                )}
              />
            </Form.Item>
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur les catégories de quiz
              </Typography.Title>
              <Controller
                control={control}
                name="posiaoTypes"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.posiao.types}
                  />
                )}
              />
            </Form.Item>
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur la banque de questions
              </Typography.Title>
              <Controller
                control={control}
                name="posiaoQuestions"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.posiao.questions}
                  />
                )}
              />
            </Form.Item>
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur les tests de positionnement
              </Typography.Title>
              <Controller
                control={control}
                name="posiaoQuizzes"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.posiao.quizzes}
                  />
                )}
              />
            </Form.Item>
          </TabPane>
          <TabPane tab="Espace Formao" key="formao">
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur les apprenants
              </Typography.Title>
              <Controller
                control={control}
                name="formaoStudents"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.formao.students}
                  />
                )}
              />
            </Form.Item>
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur les sessions
              </Typography.Title>
              <Controller
                control={control}
                name="formaoGroups"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.formao.groups}
                  />
                )}
              />
            </Form.Item>
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur les formations
              </Typography.Title>
              <Controller
                control={control}
                name="formaoFormations"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.formao.formations}
                  />
                )}
              />
            </Form.Item>
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur les cours
              </Typography.Title>
              <Controller
                control={control}
                name="formaoModules"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.formao.modules}
                  />
                )}
              />
            </Form.Item>
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur les évènements
              </Typography.Title>
              <Controller
                control={control}
                name="formaoEvents"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.formao.events}
                  />
                )}
              />
            </Form.Item>
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur les documents
              </Typography.Title>
              <Controller
                control={control}
                name="formaoDocuments"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.formao.documents}
                  />
                )}
              />
            </Form.Item>
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur les bilans de formation en continu
              </Typography.Title>
              <Controller
                control={control}
                name="formaoFormationReports"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.formao.formationReports}
                  />
                )}
              />
            </Form.Item>
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur les bilans de formation en alternance
              </Typography.Title>
              <Controller
                control={control}
                name="formaoCompanyReports"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.formao.companyReports}
                  />
                )}
              />
            </Form.Item>
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur les suivis mensuels
              </Typography.Title>
              <Controller
                control={control}
                name="formaoMonthlyReports"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.formao.monthlyReports}
                  />
                )}
              />
            </Form.Item>
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur les bilans de stage
              </Typography.Title>
              <Controller
                control={control}
                name="formaoTrainingReports"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.formao.trainingReports}
                  />
                )}
              />
            </Form.Item>
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur les formulaires de bilans
              </Typography.Title>
              <Controller
                control={control}
                name="formaoReportForms"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.formao.reportForms}
                  />
                )}
              />
            </Form.Item>
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur les bilans de satisfaction
              </Typography.Title>
              <Controller
                control={control}
                name="formaoSatisfactionReports"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.formao.satisfactionReports}
                  />
                )}
              />
            </Form.Item>
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur l'import de packages
              </Typography.Title>
              <Controller
                control={control}
                name="formaoPackages"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.formao.packages}
                  />
                )}
              />
            </Form.Item>
          </TabPane>
          <TabPane tab="Espace I-magineLab" key="imaginelab">
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur les réservations
              </Typography.Title>
              <Controller
                control={control}
                name="imaginelabBookings"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.imaginelab.bookings}
                  />
                )}
              />
            </Form.Item>
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur les ateliers
              </Typography.Title>
              <Controller
                control={control}
                name="imaginelabWorkshops"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.imaginelab.workshops}
                  />
                )}
              />
            </Form.Item>
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur les documents
              </Typography.Title>
              <Controller
                control={control}
                name="imaginelabDocuments"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.imaginelab.documents}
                  />
                )}
              />
            </Form.Item>
          </TabPane>
          <TabPane tab="Intelligences artificielles" key="ai">
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur les systèmes
              </Typography.Title>
              <Controller
                control={control}
                name="aiSystems"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.ai.systems}
                  />
                )}
              />
            </Form.Item>
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur les versions
              </Typography.Title>
              <Controller
                control={control}
                name="aiVersions"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.ai.versions}
                  />
                )}
              />
            </Form.Item>
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur les clés API
              </Typography.Title>
              <Controller
                control={control}
                name="aiKeys"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.ai.keys}
                  />
                )}
              />
            </Form.Item>
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur la file d'attente des tâches IA
              </Typography.Title>
              <Controller
                control={control}
                name="aiApi"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.ai.api}
                  />
                )}
              />
            </Form.Item>
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur les applications
              </Typography.Title>
              <Controller
                control={control}
                name="aiApplications"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.ai.applications}
                  />
                )}
              />
            </Form.Item>
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur les activités
              </Typography.Title>
              <Controller
                control={control}
                name="aiActivities"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.ai.activities}
                  />
                )}
              />
            </Form.Item>
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur les modules généraux
              </Typography.Title>
              <Controller
                control={control}
                name="aiGeneralModules"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.ai.generalModules}
                  />
                )}
              />
            </Form.Item>
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur les modules
              </Typography.Title>
              <Controller
                control={control}
                name="aiModules"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.ai.modules}
                  />
                )}
              />
            </Form.Item>
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur les personas
              </Typography.Title>
              <Controller
                control={control}
                name="aiPersonas"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.ai.personas}
                  />
                )}
              />
            </Form.Item>
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur les interactions
              </Typography.Title>
              <Controller
                control={control}
                name="aiInteractions"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.ai.interactions}
                  />
                )}
              />
            </Form.Item>
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur les logs
              </Typography.Title>
              <Controller
                control={control}
                name="aiLogs"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.ai.logs}
                  />
                )}
              />
            </Form.Item>
          </TabPane>
          <TabPane tab="Outils de développement" key="devTools">
            <Form.Item>
              <Typography.Title level={5} className="gray-text">
                Permissions sur la file d'attente d'import/export
              </Typography.Title>
              <Controller
                control={control}
                name="devToolsImportExportService"
                render={({ field }) => (
                  <Checkbox.Group
                    {...field}
                    className="vertical-group"
                    disabled={!role || isValidating || isProcessing}
                    options={rolesData.devTools.importExportService}
                  />
                )}
              />
            </Form.Item>
          </TabPane>
        </Tabs>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button className="gray-text" disabled={!role || isValidating || isProcessing}>
            <Link to="/roles">Annuler</Link>
          </Button>
          <Space>
            <Button onClick={handleSubmit(onSubmit)} loading={!role || isValidating || isProcessing}>
              Enregistrer
            </Button>
            <Button
              onClick={handleSubmit(onSubmitAndLeave)}
              type="primary"
              loading={!role || isValidating || isProcessing}
            >
              Enregistrer et quitter
            </Button>
          </Space>
        </div>
      </Form>
    </Card>
  );
};

export default RoleUpdate;
