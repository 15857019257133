import { format } from '@i-maginexr/js';
import { Button, Descriptions, Modal, Skeleton } from 'antd';
import { useMemo, useState } from 'react';
import useSWR from 'swr';

const LOCATIONS_URL = `${process.env.REACT_APP_BASE_URL_API_URL}/next/private/arinfo/agencies`;
const USERS_URL = `${process.env.REACT_APP_BASE_URL_API_URL}/next/private/global/users`;

const SWROptions = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

const RegistrationsInfo = ({ item, children }) => {
  const [open, setOpen] = useState(false);
  const { data: users, isValidating: isUsersValidating } = useSWR(open ? USERS_URL : null, SWROptions);
  const { data: locations, isValidating: isLocationsValidating } = useSWR(open ? LOCATIONS_URL : null, SWROptions);

  const former = useMemo(() => {
    if (!item || !users?.data || !item?.config?.date?.former) {
      return null;
    }

    return users.data.find((userItem) => userItem._id === item.config.date.former);
  }, [users, item]);

  const location = useMemo(() => {
    if (!item || !locations?.data || item.config.date.accessMode !== 'Présentiel') {
      return null;
    }

    return locations.data.find((locationItem) => locationItem._id === item.config.date.location);
  }, [locations, item]);

  return (
    <>
      <Modal
        visible={open}
        footer={false}
        onCancel={() => setOpen(false)}
        onOk={() => setOpen(false)}
        title="Informations sur la pré-inscription"
        bodyStyle={{ padding: 0 }}
      >
        <Descriptions column={2} bordered size="small">
          <Descriptions.Item label="Objectif" span={2}>
            {item.config.date.goal.type === 'Spécialisation'
              ? `${item.config.date.goal.type} ${record.goal.specialization.toLowerCase()}`
              : item.config.date.goal.type}
          </Descriptions.Item>
          <Descriptions.Item label="Formateur" span={2}>
            {!item.config.date.former ? null : (
              <Skeleton
                style={{ marginBottom: 0, marginTop: 2.3 }}
                title={{ width: 150, style: { margin: 0, height: 18.4 } }}
                paragraph={false}
                active
                loading={isUsersValidating}
              >
                {!former ? null : `${format.toLastName(former.last_name)} ${format.toFirstName(former.first_name)}`}
              </Skeleton>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Durée" span={2}>
            {item.config.date.config.duration} jour{item.config.date.config.duration > 1 ? 's' : ''}
          </Descriptions.Item>
          <Descriptions.Item label="Lieu" span={2}>
            {item.config.date.accessMode === 'Distanciel' ? (
              'À distance'
            ) : (
              <Skeleton
                style={{ marginBottom: 0, marginTop: 2.3 }}
                title={{ width: 150, style: { margin: 0, height: 18.4 } }}
                paragraph={false}
                active
                loading={isLocationsValidating}
              >
                {!location ? null : location.city}
              </Skeleton>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Tarif HT">
            {item.config.date.price.type === 'startAt' ? 'À partir de ' : ''}
            {item.config.date.price.excludingTaxes}&nbsp;€ HT
            {item.config.date.price?.mode === 'day' ? ' / jour' : ''}
          </Descriptions.Item>
          <Descriptions.Item label="Tarif TTC">
            {item.config.date.price.type === 'startAt' ? 'À partir de ' : ''}
            {item.config.date.price.includingTaxes}&nbsp;€ TTC
            {item.config.date.price?.mode === 'day' ? ' / jour' : ''}
          </Descriptions.Item>
          <Descriptions.Item label="Complet" span={2}>
            {item.config.date.places.full ? 'Oui' : 'Non'}
          </Descriptions.Item>
          <Descriptions.Item label="Note" span={2}>
            {item.config.date?.note || ''}
          </Descriptions.Item>
        </Descriptions>
      </Modal>
      <Button
        size="small"
        type="link"
        disabled={!item}
        style={{ paddingLeft: 0, paddingRight: 0 }}
        onClick={() => setOpen(true)}
      >
        {children}
      </Button>
    </>
  );
};

export default RegistrationsInfo;
