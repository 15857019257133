import { Collapse, Modal, Typography } from 'antd';
import useSWR from 'swr';

import { aiRoutes } from '../../../lib/routes';
import { useAuth } from '../../../authContext';
import { useMemo } from 'react';

/**
 * Formatte le contenu afficher le nom des variables
 * @param {object} input
 * @param {string} input.content Contenu textuel du module
 * @param {object[]} input.variables Liste des variables associées au module
 * @returns {string}
 */
const formatInputContent = (input) => {
  // Extrait le contenu du texte
  let content = input?.content || '';
  content = content.replaceAll('\n', '<br/>');

  // Remplace les variables par leurs noms
  for (let i = 0; i < (input?.variables || []).length; i++) {
    content = content.replaceAll(`{${input.variables[i].variableId}}`, `[${input.variables[i].key}]`);
  }

  return content;
};

const listInText = (index, maxIndex) => {
  switch (index) {
    case 0:
      return '';
    case maxIndex:
      return ', et';
    default:
      return ',';
  }
};

/**
 * Formatte le texte pour afficher le nombre de décimales
 * @param {boolean} isDecimal
 * @param {number} digits
 * @returns {string}
 */
const getDigitsText = (isDecimal, digits) => {
  if (!isDecimal) {
    return '';
  }

  switch (digits) {
    case 0:
      return (
        <>
          &nbsp;<b>sans décimale</b>
        </>
      );
    case 1:
      return (
        <>
          &nbsp;avec <b>1 décimale</b>
        </>
      );
    default:
      return (
        <>
          &nbsp;avec <b>{digits} décimales</b>
        </>
      );
  }
};

const getUserField = (field) => {
  switch (field) {
    case 'USER_NAME':
      return (
        <>
          : <b>Nom</b> de l'utilisateur
        </>
      );
    default:
      return <></>;
  }
};

const getPersonaSelection = (personas, fields, infos) => {
  // Initialise le texte
  let personaText = 'Champ';

  // Récupère les informations
  const activeFields = Object.keys(Object.fromEntries(Object.entries(fields).filter(([key, value]) => value)));

  const activePersonas = personas.map((id) => (infos || []).find((info) => info._id === id)?.name || id);

  // Ajoute les champs
  if (activeFields.length > 1) {
    personaText += 's';
  }

  for (let i = 0; i < activeFields.length; i++) {
    personaText += listInText(i, activeFields.length - 1);

    switch (activeFields[i]) {
      case 'name':
        personaText += ' Nom';
        break;
      case 'category':
        personaText += ' Catégorie';
        break;
      case 'description':
        personaText += ' Description';
        break;
      case 'behaviour':
        personaText += ' Comportement';
        break;
    }
  }

  //TODO : INtégrer le nom des personas

  // Ajoute les personas
  if (activePersonas.length > 1) {
    personaText += " d'un persona aléatoire parmi";

    for (let i = 0; i < activePersonas.length; i++) {
      personaText += listInText(i, activePersonas.length - 1);

      personaText += ` ${activePersonas[i]}`;
    }
  } else {
    personaText += ` du persona ${activePersonas[0]}`;
  }

  return personaText;
};

const ModulePreviewModal = ({ input, isOpen, onClose }) => {
  const { token } = useAuth();

  const listPersonas = useMemo(
    () => (input?.variables || []).filter((item) => item.type === 'PERSONAS').flatMap((item) => item.personas),
    [input?.variables],
  );

  const { data: savedPersonas } = useSWR(
    isOpen ? listPersonas.map((mod) => `${aiRoutes.personas}/${mod}`) : null,
    (...urls) => {
      return Promise.all(
        urls.map((url) =>
          fetch(url, {
            headers: { Authorization: `Bearer ${token}` },
          }).then((res) => res.json()),
        ),
      );
    },
  );

  const personaInfos = useMemo(() => {
    if (Array.isArray(savedPersonas)) {
      return savedPersonas.reduce((acc, curr) => {
        return [...acc, curr?.data];
      }, []);
    }
  }, [savedPersonas]);

  return (
    <>
      <Modal
        title={`Prévisualisation du module « ${input?.title} »`}
        visible={isOpen}
        onCancel={onClose}
        width={750}
        footer={false}
      >
        <Typography.Title level={5}>Contenu</Typography.Title>
        <Typography.Paragraph>
          <span dangerouslySetInnerHTML={{ __html: formatInputContent(input) }} />
        </Typography.Paragraph>

        <Typography.Title level={5}>Variable(s)</Typography.Title>
        {input?.variables?.length ? (
          <Collapse className="module-preview-modal-collapse" ghost>
            {input.variables.map((variable, index) => (
              <Collapse.Panel header={variable.key} key={variable.key}>
                <div style={{ marginLeft: 20, borderLeft: '2px solid #D9D9D9', paddingLeft: 20 }}>
                  {variable.type === 'RANDOM_NUMBER' && (
                    <Typography.Text>
                      Nombre aléatoire entre <b>{variable.min}</b> et <b>{variable.max}</b>
                      {getDigitsText(variable.isDecimal, variable.digits)}
                    </Typography.Text>
                  )}

                  {variable.type === 'LIST' && (
                    <>
                      <Typography.Text>Valeur aléatoire parmis :</Typography.Text>
                      <ul>
                        {variable.list.map((item) => (
                          <li>{item}</li>
                        ))}
                      </ul>
                    </>
                  )}

                  {variable.type === 'USER_PROFILE' && (
                    <Typography.Text>Champ du profil de l'utilisateur {getUserField(variable.field)}</Typography.Text>
                  )}

                  {variable.type === 'PERSONAS' && (
                    <Typography.Text>
                      {getPersonaSelection(variable.personas, variable.personaFields, personaInfos)}
                    </Typography.Text>
                  )}

                  {variable.type === 'USER_INPUT' && <Typography.Text>Champ d'entrée utilisateur</Typography.Text>}
                </div>
              </Collapse.Panel>
            ))}
          </Collapse>
        ) : (
          <Typography.Text type="secondary">Aucune variable n'est utilisée dans ce module</Typography.Text>
        )}
      </Modal>
    </>
  );
};

export default ModulePreviewModal;
