import { Button, Dropdown, Menu, Tooltip } from 'antd';
import { findChildren } from '@tiptap/core';
import { useCallback, useMemo, useState } from 'react';
import { getSelectedText } from './helpers';
import { DownOutlined, LinkOutlined, PlusOutlined, NumberOutlined, DeleteOutlined } from '@ant-design/icons';

const Variable = ({ editor, disabled, isActive }) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectedText = getSelectedText(editor);

  const variables = useMemo(() => {
    const items = [];

    findChildren(editor.view.state.doc, (node) => {
      if (node?.marks?.some((mark) => mark.type.name === 'variable' && !mark.attrs?.ref)) {
        items.push(node);
      }
    });

    return items;
  }, [isOpen, editor]);

  const setVariable = useCallback(() => {
    const variableId = `${Math.floor(Math.random() * 1000000000)}${Date.now()}`;

    editor.commands.setVariable({ id: variableId });
  }, [editor]);

  const refVariable = useCallback(
    (ref) => {
      const variableId = `${Math.floor(Math.random() * 1000000000)}${Date.now()}`;

      editor.commands.setVariable({ id: variableId, ref });
    },
    [editor],
  );

  const unsetVariable = useCallback(() => {
    editor.commands.unsetVariable();
  }, [editor]);

  return (
    <>
      <Tooltip key="variable" title={isActive ? 'Supprimer la variable' : 'Nouvelle variable'}>
        <Dropdown
          open={isOpen}
          onOpenChange={setIsOpen}
          disabled={disabled || (isActive ? false : selectedText === '' && !isActive)}
          placement="bottomRight"
          trigger={['click']}
          overlay={
            <Menu>
              <Menu.Item key="add" icon={<PlusOutlined />} onClick={setVariable} disabled={isActive}>
                Nouvelle variable
              </Menu.Item>
              <Menu.SubMenu
                key="ref"
                icon={<LinkOutlined />}
                disabled={!variables || variables?.length === 0 || isActive}
                title="Référence à une variable existante"
              >
                {variables.map((item) => {
                  const mark = item.marks.find((mark) => mark.type.name === 'variable');

                  return (
                    <Menu.Item key={mark.attrs.id} onClick={() => refVariable(mark.attrs.id)}>
                      {mark.attrs.id} - {item.text}
                    </Menu.Item>
                  );
                })}
              </Menu.SubMenu>
              <Menu.Item key="delete" icon={<DeleteOutlined />} onClick={unsetVariable} disabled={!isActive}>
                Supprimer la variable
              </Menu.Item>
            </Menu>
          }
        >
          <Button
            icon={<NumberOutlined />}
            // onClick={isActive ? unsetVariable : setVariable}
            disabled={disabled || (isActive ? false : selectedText === '' && !isActive)}
          >
            Variable
            <DownOutlined />
          </Button>
        </Dropdown>
      </Tooltip>
    </>
  );
};
export default Variable;
