import { Card, Typography, Collapse, Switch, Space, Form, InputNumber, Row, Col, Input } from 'antd';
import { Controller, useFormContext, useFormState, useWatch } from 'react-hook-form';
import DateScheduleList from './DateScheduleList';
import { useState } from 'react';

export default function ModesConfig() {
  const [activeKeys, setActiveKeys] = useState([]);
  const { control, disabled, clearErrors, trigger, setValue } = useFormContext();
  const { errors } = useFormState({ control });
  const [interDisabled, intraDisabled, tutoratDisabled] = useWatch({
    control,
    name: ['modes.Inter.disabled', 'modes.Intra.disabled', 'modes.Tutorat.disabled'],
  });

  const onCollapseChange = (collapseKeys) => {
    /* Annule l'action si le mode n'est pas activé */
    if (collapseKeys.length > activeKeys.length) {
      const newCollapseKey = collapseKeys.filter((collapseKey) => !activeKeys.includes(collapseKey))[0];

      if (newCollapseKey === 'Inter' && interDisabled) {
        return;
      }

      if (newCollapseKey === 'Intra' && intraDisabled) {
        return;
      }

      if (newCollapseKey === 'Tutorat' && tutoratDisabled) {
        return;
      }
    }

    return setActiveKeys(collapseKeys);
  };

  return (
    <Card size="small" title={<Typography.Title level={5}>Configuration Inter/Intra/Tutorat</Typography.Title>}>
      <Collapse activeKey={activeKeys} onChange={onCollapseChange}>
        <Collapse.Panel
          key="Inter"
          header={
            <Typography.Text type={Object.keys(errors?.modes?.Inter ?? {})?.length !== 0 && 'danger'}>
              Inter
            </Typography.Text>
          }
          disabled={interDisabled || disabled}
          extra={
            <div onClick={(event) => event.stopPropagation()}>
              <Controller
                {...{ control }}
                name="modes.Inter.disabled"
                render={({ field }) => (
                  <Switch
                    {...{ ...field, disabled }}
                    size="small"
                    checkedChildren="Activé"
                    unCheckedChildren="Désactivé"
                    checked={!field.value}
                    onChange={(checked) => {
                      field.onChange(!checked);

                      /* Supprime les erreurs liées */
                      Object.keys(errors?.modes?.Inter ?? {}).forEach((errorKey) => {
                        clearErrors(`modes.Inter.${errorKey}`);
                      });

                      /* Ferme le collapse lié si ouvert */
                      if (activeKeys.includes('Inter')) {
                        return setActiveKeys((values) => {
                          values.splice(values.indexOf('Inter', 1));

                          return values;
                        });
                      }
                    }}
                  />
                )}
              />
            </div>
          }
        >
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <Form.Item
                label="Durée"
                required
                validateStatus={errors?.modes?.Inter?.minMaxDays && 'error'}
                help={errors?.modes?.Inter?.minMaxDays?.message}
              >
                <Controller
                  {...{ control }}
                  name="modes.Inter.minMaxDays"
                  render={({ field }) => {
                    const isValidFormat = Array.isArray(field.value) && field.value.length === 2;
                    const min = isValidFormat ? field.value[0] : null;
                    const max = isValidFormat ? field.value[1] : null;

                    const handleMinChange = (value) => {
                      field.onChange([value, max]);
                    };

                    const handleMaxChange = (value) => {
                      field.onChange([min, value]);
                    };

                    return (
                      <Space.Compact>
                        <InputNumber
                          {...{ disabled }}
                          min={0}
                          max={max === null ? null : max - 1}
                          value={min}
                          onChange={handleMinChange}
                          addonBefore="De"
                        />
                        <InputNumber
                          {...{ disabled }}
                          min={min === null || min === 0 ? null : min + 1}
                          value={max}
                          onChange={handleMaxChange}
                          addonBefore="à"
                          addonAfter="jours"
                          className="input-number-group"
                        />
                      </Space.Compact>
                    );
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Controller
                {...{ control }}
                name="modes.Inter.price"
                render={({ field }) => (
                  <Form.Item
                    label="Prix de départ"
                    required
                    validateStatus={errors?.modes?.Inter?.price.includingTaxes && 'error'}
                    help={errors?.modes?.Inter?.price?.includingTaxes?.message}
                  >
                    <Controller
                      {...{ control }}
                      name="modes.Inter.price.includingTaxes"
                      render={({ field: nestedField }) => (
                        <InputNumber
                          {...{ ...nestedField, disabled }}
                          addonBefore="À partir de"
                          style={{ width: '100%' }}
                          addonAfter="€ TTC"
                          onChange={(value) => {
                            trigger('modes.Inter.price');

                            setValue('modes.Inter.price.excludingTaxes', parseFloat(value / 1.2).toFixed(2));
                            nestedField.onChange(value);
                          }}
                        />
                      )}
                    />
                  </Form.Item>
                )}
              />
            </Col>
          </Row>
          <DateScheduleList mode="Inter" name="modes.Inter.schedules" />
        </Collapse.Panel>
        <Collapse.Panel
          key="Intra"
          header={
            <Typography.Text type={Object.keys(errors?.modes?.Intra ?? {})?.length !== 0 && 'danger'}>
              Intra
            </Typography.Text>
          }
          disabled={intraDisabled || disabled}
          extra={
            <div onClick={(event) => event.stopPropagation()}>
              <Controller
                {...{ control }}
                name="modes.Intra.disabled"
                render={({ field }) => (
                  <Switch
                    {...{ disabled }}
                    size="small"
                    checkedChildren="Activé"
                    unCheckedChildren="Désactivé"
                    checked={!field.value}
                    onChange={(checked) => {
                      field.onChange(!checked);

                      /* Supprime les erreurs liées */
                      Object.keys(errors?.modes?.Intra ?? {}).forEach((errorKey) => {
                        clearErrors(`modes.Intra.${errorKey}`);
                      });

                      /* Ferme le collapse lié si ouvert */
                      if (activeKeys.includes('Intra')) {
                        return setActiveKeys((values) => {
                          values.splice(values.indexOf('Intra', 1));

                          return values;
                        });
                      }
                    }}
                  />
                )}
              />
            </div>
          }
        >
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <Form.Item
                label="Durée"
                required
                validateStatus={errors?.modes?.Intra?.minMaxDays && 'error'}
                help={errors?.modes?.Intra?.minMaxDays?.message}
              >
                <Controller
                  {...{ control }}
                  name="modes.Intra.minMaxDays"
                  render={({ field }) => {
                    const isValidFormat = Array.isArray(field.value) && field.value.length === 2;
                    const min = isValidFormat ? field.value[0] : null;
                    const max = isValidFormat ? field.value[1] : null;

                    const handleMinChange = (value) => {
                      field.onChange([value, max]);
                    };

                    const handleMaxChange = (value) => {
                      field.onChange([min, value]);
                    };

                    return (
                      <Space.Compact>
                        <InputNumber
                          {...{ disabled }}
                          min={0}
                          max={max === null ? null : max - 1}
                          value={min}
                          onChange={handleMinChange}
                          addonBefore="De"
                        />
                        <InputNumber
                          {...{ disabled }}
                          min={min === null || min === 0 ? null : min + 1}
                          value={max}
                          onChange={handleMaxChange}
                          addonBefore="à"
                          addonAfter="jours"
                          className="input-number-group"
                        />
                      </Space.Compact>
                    );
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Controller
                {...{ control }}
                name="modes.Intra.price"
                render={({ field }) => (
                  <Form.Item
                    label="Prix de départ"
                    required
                    validateStatus={errors?.modes?.Intra?.price.includingTaxes && 'error'}
                    help={errors?.modes?.Intra?.price?.includingTaxes?.message}
                  >
                    <Controller
                      {...{ control }}
                      name="modes.Intra.price.includingTaxes"
                      render={({ field: nestedField }) => (
                        <InputNumber
                          {...{ ...nestedField, disabled }}
                          addonBefore="À partir de"
                          style={{ width: '100%' }}
                          addonAfter="€ TTC"
                          onChange={(value) => {
                            trigger('modes.Intra.price');

                            setValue('modes.Intra.price.excludingTaxes', parseFloat(value / 1.2).toFixed(2));
                            nestedField.onChange(value);
                          }}
                        />
                      )}
                    />
                  </Form.Item>
                )}
              />
            </Col>
          </Row>
          <DateScheduleList mode="Intra" name="modes.Intra.schedules" />
        </Collapse.Panel>
        <Collapse.Panel
          key="Tutorat"
          header={
            <Typography.Text type={Object.keys(errors?.modes?.Tutorat ?? {})?.length !== 0 && 'danger'}>
              Tutorat groupe
            </Typography.Text>
          }
          disabled={tutoratDisabled || disabled}
          extra={
            <div onClick={(event) => event.stopPropagation()}>
              <Controller
                {...{ control }}
                name="modes.Tutorat.disabled"
                render={({ field }) => (
                  <Switch
                    {...{ disabled }}
                    size="small"
                    checkedChildren="Activé"
                    unCheckedChildren="Désactivé"
                    checked={!field.value}
                    onChange={(checked) => {
                      field.onChange(!checked);

                      /* Supprime les erreurs liées */
                      Object.keys(errors?.modes?.Tutorat ?? {}).forEach((errorKey) => {
                        clearErrors(`modes.Tutorat.${errorKey}`);
                      });

                      /* Ferme le collapse lié si ouvert */
                      if (activeKeys.includes('Tutorat')) {
                        return setActiveKeys((values) => {
                          values.splice(values.indexOf('Tutorat', 1));

                          return values;
                        });
                      }
                    }}
                  />
                )}
              />
            </div>
          }
        >
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <Form.Item
                label="Durée"
                required
                validateStatus={errors?.modes?.Tutorat?.minMaxDays && 'error'}
                help={errors?.modes?.Tutorat?.minMaxDays?.message}
              >
                <Controller
                  {...{ control }}
                  name="modes.Tutorat.minMaxDays"
                  render={({ field }) => {
                    const isValidFormat = Array.isArray(field.value) && field.value.length === 2;
                    const min = isValidFormat ? field.value[0] : null;
                    const max = isValidFormat ? field.value[1] : null;

                    const handleMinChange = (value) => {
                      field.onChange([value, max]);
                    };

                    const handleMaxChange = (value) => {
                      field.onChange([min, value]);
                    };

                    return (
                      <Space.Compact>
                        <InputNumber
                          {...{ disabled }}
                          min={0}
                          max={max === null ? null : max - 1}
                          value={min}
                          onChange={handleMinChange}
                          addonBefore="De"
                        />
                        <InputNumber
                          {...{ disabled }}
                          min={min === null || min === 0 ? null : min + 1}
                          value={max}
                          onChange={handleMaxChange}
                          addonBefore="à"
                          addonAfter="jours"
                          className="input-number-group"
                        />
                      </Space.Compact>
                    );
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Controller
                {...{ control }}
                name="modes.Tutorat.price"
                render={({ field }) => (
                  <Form.Item
                    label="Prix de départ"
                    required
                    validateStatus={errors?.modes?.Tutorat?.price.includingTaxes && 'error'}
                    help={errors?.modes?.Tutorat?.price?.includingTaxes?.message}
                  >
                    <Controller
                      {...{ control }}
                      name="modes.Tutorat.price.includingTaxes"
                      render={({ field: nestedField }) => (
                        <InputNumber
                          {...{ ...nestedField, disabled }}
                          addonBefore="À partir de"
                          style={{ width: '100%' }}
                          addonAfter="€ TTC"
                          onChange={(value) => {
                            trigger('modes.Tutorat.price');

                            setValue('modes.Tutorat.price.excludingTaxes', parseFloat(value / 1.2).toFixed(2));
                            nestedField.onChange(value);
                          }}
                        />
                      )}
                    />
                  </Form.Item>
                )}
              />
            </Col>
          </Row>
          <DateScheduleList mode="Tutorat" name="modes.Tutorat.schedules" />
        </Collapse.Panel>
      </Collapse>
    </Card>
  );
}
