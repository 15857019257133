import { PDFDocument, PageSizes, StandardFonts } from 'pdf-lib';
import { saveAs } from 'file-saver';
import { message } from 'antd';
import { format as dateFormat } from 'date-fns';
import fr from 'date-fns/locale/fr';
import { format } from '@i-maginexr/js';

const renderDays = (days) => {
  const groups = days.reduce((acc, curr) => {
    const group = dateFormat(new Date(curr), 'MM/yyyy');
    const day = dateFormat(new Date(curr), 'dd');

    if (group in acc) {
      acc[group].push(day);
    } else {
      acc[group] = [day];
    }

    return acc;
  }, {});

  return Object.entries(groups || {})
    .sort((a, b) => {
      const aDate = a?.[0]?.split('/');
      const bDate = b?.[0]?.split('/');

      return new Date(aDate[1], aDate[0] - 1, 1) - new Date(bDate[1], bDate[0] - 1, 1);
    })
    .map(([key, value], index) => {
      const [month, year] = key.split('/');
      const monthName = fr.localize.month(month - 1, { width: 'abbreviated' });

      return `${value
        .sort((a, b) => Number(a) - Number(b))
        .join(', ')
        .replace(/, ([^,]*)$/, ' et $1')} ${monthName} ${year}${
        index < Object.keys(groups || {}).length - 1 ? ' | ' : ''
      }`;
    })
    .join('');
};

const renderDate = (item) => {
  if (item.config.date.type === 'Récurrent') {
    return `Tous les ${item.config.date.config.day.toLowerCase()}s`;
  }

  if (item.config.date.type === 'Période') {
    return `Du ${dateFormat(new Date(item.config.date.config.range[0]), 'dd/MM/yyyy')} au ${dateFormat(
      new Date(item.config.date.config.range[1]),
      'dd/MM/yyyy',
    )}`;
  }

  if (item.config.date.type === 'Jours datés') {
    return `${renderDays(item.config.date.config.days)}`;
  }

  if (item.config.date.type === 'Personnalisé') {
    return `${item.config.date.config.custom}`;
  }

  return '';
};

const exportRegistration = async (item) => {
  const contact = format.toFullName(item.form.contact.firstName, item.form.contact.lastName);

  const pdfDoc = await PDFDocument.create();

  const page = pdfDoc.addPage(PageSizes.A4); // [595.28, 841.89]
  const { width, height } = page.getSize();

  const margin = 50;
  const minLeft = margin;
  const minTop = height - margin;

  let topPosition = minTop;

  topPosition -= 18;
  page.drawText('Pré-inscription Inter/Intra/Tutorat', {
    x: minLeft,
    y: topPosition,
    size: 18,
    maxWidth: width - margin * 2,
    lineHeight: 18,
  });

  topPosition -= 15;
  page.drawText(`Réalisée le ${dateFormat(new Date(item.createdAt), 'dd/MM/yyyy à HH:mm')}`, {
    x: minLeft,
    y: topPosition,
    size: 10,
    maxWidth: width - margin * 2,
    lineHeight: 10,
  });

  topPosition -= 22;
  page.drawText('Formulaire', {
    x: minLeft,
    y: topPosition,
    size: 12,
    maxWidth: width - margin * 2,
    lineHeight: 12,
  });

  topPosition -= 15;
  page.drawText(`Contact : ${contact.lastName} ${contact.firstName}`, {
    x: minLeft,
    y: topPosition,
    size: 10,
    maxWidth: width - margin * 2,
    lineHeight: 10,
  });

  topPosition -= 10;
  page.drawText(`Entreprise : ${item.form.company.name.toUpperCase()}`, {
    x: minLeft,
    y: topPosition,
    size: 10,
    maxWidth: width - margin * 2,
    lineHeight: 10,
  });

  topPosition -= 10;
  page.drawText(`Email : ${item.form.company.email}`, {
    x: minLeft,
    y: topPosition,
    size: 10,
    maxWidth: width - margin * 2,
    lineHeight: 10,
  });

  topPosition -= 10;
  page.drawText(`Téléphone : ${item.form.company.phone}`, {
    x: minLeft,
    y: topPosition,
    size: 10,
    maxWidth: width - margin * 2,
    lineHeight: 10,
  });

  topPosition -= 10;
  page.drawText(`Code postal + Ville : ${item.form.company.location}`, {
    x: minLeft,
    y: topPosition,
    size: 10,
    maxWidth: width - margin * 2,
    lineHeight: 10,
  });

  topPosition -= 22;
  page.drawText('Date sélectionnée', {
    x: minLeft,
    y: topPosition,
    size: 12,
    maxWidth: width - margin * 2,
    lineHeight: 12,
  });

  topPosition -= 15;
  page.drawText(`Univers : ${item.config.category.name}`, {
    x: minLeft,
    y: topPosition,
    size: 10,
    maxWidth: width - margin * 2,
    lineHeight: 10,
  });

  topPosition -= 10;
  page.drawText(`Formation : ${item.config.formation.formation.title}`, {
    x: minLeft,
    y: topPosition,
    size: 10,
    maxWidth: width - margin * 2,
    lineHeight: 10,
  });

  topPosition -= 10;
  page.drawText(`Mode : ${item.config.date.mode}`, {
    x: minLeft,
    y: topPosition,
    size: 10,
    maxWidth: width - margin * 2,
    lineHeight: 10,
  });

  topPosition -= 10;
  page.drawText(`Date : ${renderDate(item)}`, {
    x: minLeft,
    y: topPosition,
    size: 10,
    maxWidth: width - margin * 2,
    lineHeight: 10,
  });

  topPosition -= 22;
  page.drawText('Description du projet', {
    x: minLeft,
    y: topPosition,
    size: 12,
    maxWidth: width - margin * 2,
    lineHeight: 12,
  });

  topPosition -= 15;
  page.drawText(item.form.message, {
    x: minLeft,
    y: topPosition,
    size: 10,
    maxWidth: width - margin * 2,
    lineHeight: 10,
  });

  try {
    const buffer = await pdfDoc.save();
    const options = { type: '	application/pdf' };

    saveAs(new Blob([buffer], options), 'pré-inscription.pdf');
  } catch (error) {
    message.error(error.message);
  }
};

export default exportRegistration;
