import { Button, Card, Form, Input, Modal, Space, Typography } from 'antd';
import { useState } from 'react';
import { Controller, useFormContext, useFormState } from 'react-hook-form';

const MetaConfig = () => {
  const [open, setOpen] = useState(false);
  const { control, disabled } = useFormContext();
  const { errors } = useFormState({ control });

  return (
    <>
      <Modal
        visible={open}
        destroyOnClose
        title="Configurer les balises META"
        onCancel={() => setOpen(false)}
        onOk={() => setOpen(false)}
      >
        <Form noValidate layout="vertical">
          <Controller
            {...{ control }}
            name="seo.meta.description"
            render={({ field }) => (
              <Form.Item
                label="Description"
                required
                tooltip="Résumez le contenu de la page en une phrase impactante (150 caractères max) pour améliorer la visibilité dans les moteurs de recherche."
                validateStatus={errors?.seo?.meta?.description?.message && 'error'}
                help={errors?.seo?.meta?.description?.message}
              >
                <Input.TextArea {...{ ...field, disabled }} autoSize maxLength={150} showCount />
              </Form.Item>
            )}
          />
        </Form>
      </Modal>
      <Button
        {...{ disabled }}
        block
        onClick={() => setOpen(true)}
        danger={errors?.seo?.meta && Object.keys(errors?.seo?.meta).length !== 0}
      >
        Configurer les balises META
      </Button>
    </>
  );
};

const SeoConfig = () => {
  return (
    <Card size="small" title={<Typography.Title level={5}>Référencement (SEO)</Typography.Title>}>
      <Space direction="vertical" style={{ display: 'flex' }}>
        <MetaConfig />
        <Button disabled block>
          Configurer les données structurées
        </Button>
      </Space>
    </Card>
  );
};

export default SeoConfig;
