import { Card, Form, Button, Input, Typography, Space, message, Row, Col } from 'antd';
import { Link, Prompt, useParams, useHistory } from 'react-router-dom';
import { useEffect, useState, useMemo } from 'react';
import { useForm, Controller, useFormState, FormProvider } from 'react-hook-form';
import useSWR from 'swr';

import useFetch from '../../../hooks/useFetch';
import { aiRoutes } from '../../../lib/routes';
import PromptTestModal from './components/PromptTestModal';
import ModuleContent from './components/ModuleContent';

const { Item } = Form;
const { Paragraph } = Typography;

const getDefaultValues = (module) => {
  if (!module || !module?.data) {
    return {};
  }

  const variables = (module.data?.variables || []).map((item) => {
    // Formatte le champ personaFields
    let personaFields = [];
    if (item?.personaFields) {
      personaFields = [
        ...(item?.personaFields?.name ? ['name'] : []),
        ...(item?.personaFields?.category ? ['category'] : []),
        ...(item?.personaFields?.description ? ['description'] : []),
        ...(item?.personaFields?.behaviour ? ['behaviour'] : []),
      ];
    }

    return {
      key: item.key,
      variableId: item.variableId,
      type: item.type,
      // Défauts pour les nombres aléatoires
      min: item?.min || 0,
      max: item?.max || 10,
      isDecimal: item?.isDecimal || false,
      digits: item?.digits || 2,
      // Défaut pour les listes d'éléments
      list: item?.list || ['Premier élément', 'Second élément'],
      // Défaut pour les champs utilisateurs
      field: item?.field || 'USER_NAME',
      // Défaut pour les entrées utilisateurs
      userInput: item?.userInput || null,
      // Défaut pour les personas
      personas: (item?.personas || []).map((item) => ({ value: item })),
      personaFields,
    };
  });

  return {
    title: module.data.title,
    content: module.data.content, // contentToHtml(module.data.content, module.data?.variables || []),
    application: module.data?.application,
    variables,
    disabled: module.data?.disabled || false,
  };
};

const ModuleAiEdit = () => {
  const { workspace, id } = useParams();
  const [isBlocking, setIsBlocking] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [promptTestModalOpen, setPromptTestModalOpen] = useState(false);
  const {
    data: module,
    isValidating,
    mutate,
  } = useSWR(`${aiRoutes.modules}/${id}`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    keepPreviousData: false,
  });
  const defaultValues = useMemo(() => getDefaultValues(module), [module?.data]);
  const methods = useForm({ defaultValues });
  const { handleSubmit, reset, control, setError, formState } = methods;
  const { errors } = useFormState({ control });
  const { put } = useFetch();
  const history = useHistory();

  useEffect(() => {
    if (formState.isDirty) {
      setIsBlocking(true);
    }
  }, [formState.isDirty]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const onSubmit = async (form) => {
    setIsProcessing(true);

    for (let i = 0; i < (form?.variables || []).length; i++) {
      if (form.variables[i].type === 'PERSONAS') {
        form.variables[i].personas = form.variables[i].personas.map((item) => item.value);
      }
    }

    const results = await put(`${aiRoutes.modules}/${id}`, JSON.stringify(form));

    if (results.status === 200) {
      setIsBlocking(false);
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  const onSubmitAndLeave = async (form) => {
    setIsProcessing(true);

    for (let i = 0; i < (form?.variables || []).length; i++) {
      if (form.variables[i].type === 'PERSONAS') {
        form.variables[i].personas = form.variables[i].personas.map((item) => item.value);
      }
    }

    const results = await put(`${aiRoutes.modules}/${id}`, JSON.stringify(form));

    if (results.status === 200) {
      setIsBlocking(false);
      history.push(`/ia/modules/${workspace}`);
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  return (
    <Card>
      <Prompt
        when={isBlocking}
        message="Vous n'avez pas sauvegardé vos modifications, voulez-vous vraiment quitter cette page ?"
      />
      <FormProvider {...methods}>
        <PromptTestModal isOpen={promptTestModalOpen} onClose={() => setPromptTestModalOpen(false)} />
        <Form noValidate layout="vertical" onFinish={handleSubmit(onSubmit)}>
          <Paragraph type="secondary">
            <blockquote style={{ marginTop: 0 }}>
              <ul>
                <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
              </ul>
            </blockquote>
          </Paragraph>
          <Row gutter={(24, 24)}>
            <Col span={12}>
              <Item
                validateStatus={errors?.title?.message && 'error'}
                help={errors?.title?.message}
                label="Intitulé"
                required
              >
                <Controller
                  control={control}
                  name="title"
                  render={({ field }) => (
                    <Input {...field} placeholder="Entrez le titre du module" disabled={isProcessing} />
                  )}
                />
              </Item>
              <ModuleContent processing={isProcessing} />
            </Col>
          </Row>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Link to={`/ia/modules/${workspace}`}>
              <Button disabled={isProcessing}>Annuler</Button>
            </Link>
            <Space size="small">
              <Button onClick={() => setPromptTestModalOpen(true)}>Tester</Button>
              <Button onClick={handleSubmit(onSubmit)} loading={isProcessing}>
                Enregistrer
              </Button>
              <Button type="primary" onClick={handleSubmit(onSubmitAndLeave)} loading={isProcessing}>
                Enregistrer et quitter
              </Button>
            </Space>
          </div>
        </Form>
      </FormProvider>
    </Card>
  );
};

export default ModuleAiEdit;
