import React, { useState } from 'react';
import { Table, Button, Tooltip, message, Card, Space, Select } from 'antd';
import { FilePdfOutlined, MailOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import useSWR from 'swr';
import * as XLSX from 'xlsx';
import { sort, checkAuthorization } from '../../../shared/utils';
import tablePagination from '../../../lib/tablePagination';
import { useAuth } from '../../../authContext';
import { registrationsRoutes, agencyRoutes, trainingRoutes } from '../../../lib/routes';
import useFetch from '../../../hooks/useFetch';
import { format } from 'date-fns';

const RegistrationsList = () => {
  const [loadingExport, setLoadingExport] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const { data: registrations, isValidating, mutate } = useSWR(registrationsRoutes.default);
  const { data: agencies } = useSWR(agencyRoutes.default);
  const { data: trainings } = useSWR(trainingRoutes.default);
  const [isProcessing, setIsProcessing] = useState(false);
  const { put, get } = useFetch();
  const { user } = useAuth();

  const setStatus = async (record, newState) => {
    setIsProcessing(true);

    const results = await put(registrationsRoutes.default + '/' + record._id, JSON.stringify({ state: newState }));

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const exportSelectedData = () => {
    setLoadingExport('selection');

    const data =
      registrations?.data
        .filter((item) => selectedRows.includes(item._id))
        .map((item) => ({
          'Date de réception': format(new Date(item.createdAt), 'dd/MM/yyyy HH:mm'),
          Public: '',
          Provenance: 'Site Arinfo',
          Url: '',
          Nom: item.last_name,
          Prénom: item.first_name,
          Téléphone: item.phone,
          Email: item.email,
          'Intitulé de formation': item.training.title,
        })) || [];

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, 'feuille 1');

    XLSX.writeFile(wb, 'pre-inscriptions.xlsx');

    setLoadingExport(null);
  };

  const exportData = () => {
    setLoadingExport('all');

    const data =
      registrations?.data.map((item) => ({
        'Date de réception': format(new Date(item.createdAt), 'dd/MM/yyyy HH:mm'),
        Public: '',
        Provenance: 'Site Arinfo',
        Url: '',
        Nom: item.last_name,
        Prénom: item.first_name,
        Téléphone: item.phone,
        Email: item.email,
        'Intitulé de formation': item.training.title,
      })) || [];

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, 'feuille 1');

    XLSX.writeFile(wb, 'pre-inscriptions.xlsx');

    setLoadingExport(null);
  };

  const createPDF = async (record) => {
    setIsProcessing(true);

    const results = await get(registrationsRoutes.pdf + '/' + record._id);

    if (results.status === 200) {
      const blob = new Blob([Buffer.from(results.data)], {
        type: 'application/pdf',
      });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.target = '_blank';

      const lastName = record.last_name.toUpperCase();
      // prettier-ignore
      const firstName = record.first_name.charAt(0).toUpperCase() + record.first_name.slice(1)

      link.download = `pre-inscription-${lastName}-${firstName}.pdf`;
      link.click();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const columns = [
    {
      title: 'Nom',
      dataIndex: 'last_name',
      key: 'last_name',
      width: 200,
      sorter: (a, b) => sort(a, b, 'last_name'),
      sortDirections: ['ascend', 'descend'],
      render: (value) => value.toUpperCase(),
    },
    {
      title: 'Prénom',
      dataIndex: 'first_name',
      key: 'first_name',
      width: 200,
      sorter: (a, b) => sort(a, b, 'first_name'),
      sortDirections: ['ascend', 'descend'],
      render: (value) => value.charAt(0).toUpperCase() + value.slice(1),
    },
    {
      title: 'Formation',
      dataIndex: 'training',
      key: 'training',
      sorter: (a, b) => sort(a, b, 'training.title'),
      sortDirections: ['ascend', 'descend'],
      filters: trainings?.data.map((training) => ({ text: training.title, value: training._id })) || [],
      onFilter: (value, record) => record.training._id === value,
      render: (value) => value.title,
    },
    {
      title: 'Centre',
      dataIndex: 'agency',
      key: 'agency',
      width: 200,
      sorter: (a, b) => sort(a, b, 'agency.city', 'Centre introuvable'),
      sortDirections: ['ascend', 'descend'],
      filters: agencies?.data.map((agency) => ({ text: agency.city, value: agency._id })) || [],
      onFilter: (value, record) => record.agency?._id === value,
      render: (value) => {
        if (!!value?.city) {
          if (value?.city === 'remote') {
            return 'Distanciel';
          }

          return value?.city;
        }

        return 'Centre introuvable';
      },
    },
    {
      title: "Date d'envoi",
      key: 'createdAt',
      dataIndex: 'createdAt',
      width: 150,
      align: 'center',
      sorter: (a, b) => sort(a, b, 'createdAt'),
      sortDirections: ['ascend', 'descend'],
      render: (value) => format(new Date(value), 'dd/MM/yyyy'),
    },
    ...(checkAuthorization(user, 'arinfo', 'registrations', 'update-registration')
      ? [
          {
            title: 'Statut',
            dataIndex: 'state',
            key: 'state',
            width: 150,
            filters: [
              { text: 'Traitée', value: 'Traitée' },
              { text: 'Non-traitée', value: 'Non-traitée' },
            ],
            onFilter: (value, record) => record.state === value,
            render: (value, record) => (
              <Select
                style={{ width: '100%' }}
                value={value}
                onChange={(newValue) => setStatus(record, newValue)}
                options={[
                  { label: 'Traitée', value: 'Traitée' },
                  { label: 'Non-traitée', value: 'Non-traitée' },
                ]}
              />
            ),
          },
        ]
      : []),
    {
      title: '',
      key: 'actions',
      width: 100,
      align: 'center',
      fixed: 'right',
      render: (record) => (
        <Space>
          <Tooltip title="Envoyer un email" destroyTooltipOnHide={{ keepParent: false }}>
            <a href={`mailto:${record.email}`}>
              <Button className="gray-text" icon={<MailOutlined />} />
            </a>
          </Tooltip>
          <Tooltip title="Télécharger le PDF" destroyTooltipOnHide={{ keepParent: false }}>
            <Button className="gray-text" icon={<FilePdfOutlined />} onClick={() => createPDF(record)} />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <Card>
      {checkAuthorization(user, 'arinfo', 'registrations', 'export') && (
        <div style={{ textAlign: 'right', marginBottom: 20 }}>
          <Space>
            <Button
              className="gray-text"
              onClick={exportSelectedData}
              disabled={selectedRows.length === 0 || loadingExport === 'all'}
              loading={loadingExport === 'selection'}
            >
              Exporter la sélection ({selectedRows.length})
            </Button>
            <Button
              className="gray-text"
              onClick={exportData}
              disabled={loadingExport === 'selection'}
              loading={loadingExport === 'all'}
            >
              Tout exporter
            </Button>
          </Space>
        </div>
      )}

      <Table
        loading={!registrations || isValidating || isProcessing || loadingExport !== null}
        dataSource={registrations?.data || []}
        columns={columns}
        bordered
        size="small"
        rowSelection={
          checkAuthorization(user, 'arinfo', 'registrations', 'export') && {
            selectedRowKeys: selectedRows,
            onChange: setSelectedRows,
          }
        }
        rowKey={(row) => row._id}
        pagination={tablePagination(registrations?.data)}
        rowClassName={(record) => {
          if (record.state === 'Traitée') return 'ant-table-row-success';
          return 'ant-table-row-warning';
        }}
      />
    </Card>
  );
};

export default RegistrationsList;
