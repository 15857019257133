import { Fragment, useMemo, useState, memo, useCallback } from 'react';
import { Alert, Avatar, Button, Col, Divider, Empty, Form, Modal, Row, Space, Tooltip, Typography, Upload } from 'antd';
import { DeleteOutlined, EnterOutlined, LoadingOutlined, SettingOutlined, UploadOutlined } from '@ant-design/icons';
import { Cloudinary } from '@cloudinary/url-gen';
import useFetch from '../../../../../hooks/useFetch';
import useLoading from '../../../../../hooks/useLoading';
import { useAuth } from '../../../../../authContext';
import { checkAuthorization } from '../../../../../shared/utils';

const PAGE_URL = `${process.env.REACT_APP_BASE_URL_API_URL}/next/private/arinfo/pages/inter-intra`;
const CLOUDINARY_URL = `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`;
const MEDIAS_URL = `${process.env.REACT_APP_BASE_URL_API_URL}/next/private/global/medias`;

const cld = new Cloudinary({
  cloud: { cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME },
  url: { secure: true },
});

const EditableText = memo(({ category, data, onFinish }) => {
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [errored, setErrored] = useState(false);
  const { patch } = useFetch();

  const handleStart = () => {
    if (!loading) {
      setEditing(true);
    }
  };

  const handleCancel = () => {
    if (!loading) {
      setErrored(false);
      setEditing(false);
    }
  };

  const handleChange = async (newValue) => {
    if (!loading) {
      setLoading(true);

      if (typeof newValue !== 'string' || newValue.trim() === '') {
        setErrored(true);
        setLoading(false);
        return;
      }

      /**
       * Sauvegarde l'icône dans la DB
       */
      const body = JSON.stringify({ text: newValue.trim() });
      const response = await patch(`${PAGE_URL}/categories/${category._id}/icons/${data._id}`, body);

      /**
       * Met à jour l'affichage
       */
      if (response.status === 200) {
        setErrored(false);
        setEditing(false);
        onFinish();
      }

      setLoading(false);
    }
  };

  return (
    <div
      style={{ margin: 0, width: '100%' }}
      onKeyDown={(event) => {
        /**
         * Annule la propagation si l'utilisateur quitte l'édition en appuyant sur Échap
         */
        if (event.key === 'Escape' || event.code === 'Escape') {
          event.stopPropagation();
        }
      }}
    >
      <Form.Item validateStatus={errored && 'error'} style={{ margin: 0, width: '100%' }}>
        <Typography.Text
          className="inline-editable"
          ellipsis
          editable={{
            editing: errored || editing,
            onChange: handleChange,
            onStart: handleStart,
            onCancel: handleCancel,
            enterIcon: loading ? <LoadingOutlined /> : <EnterOutlined />,
          }}
        >
          {data.alt}
        </Typography.Text>
      </Form.Item>
    </div>
  );
});

EditableText.displayName = 'EditableText';

const Icons = ({ data, onFinish, getSignature, validateFile }) => {
  const { startLoading, endLoading, isLoading } = useLoading();
  const [open, setOpen] = useState(false);
  const { remove, patch } = useFetch();
  const { user } = useAuth();

  /**
   * Transforme les images en URL
   */
  const icons = useMemo(() => {
    const icons = data?.icons
      ? data.icons.map(({ image, ...rest }) => ({ ...rest, image: cld.image(image)?.toURL() }))
      : [];

    return icons;
  }, [data]);

  /**
   * Sauvegarde l'icône téléversée dans la DB
   * @param {any} file Données de l'image téléversée reçues par Cloudinary
   */
  const onCreate = async (file) => {
    /**
     * Sauvegarde l'icône dans la DB
     */
    const body = JSON.stringify({ image: file.public_id, alt: file.original_filename });
    const response = await patch(`${PAGE_URL}/categories/${data._id}/icons`, body);

    /**
     * Met à jour l'affichage
     */
    if (response.status === 200) {
      onFinish();
    }

    endLoading('icon_upload');
  };

  /**
   * Supprime l'icône de la DB
   * @param {string} id ID de l'icône
   */
  const onDelete = async (id) => {
    return Modal.confirm({
      title: "Voulez-vous vraiment supprimer l'icône ?",
      content: 'Cette action est irréversible.',
      okText: 'Confirmer',
      okType: 'danger',
      onOk: async () => {
        /**
         * Recherche l'icône à supprimer
         */
        const icon = data.icons.find((icon) => icon._id === id);

        /**
         * Si l'icône à supprimer n'est pas trouvée
         */
        if (!icon) {
          return;
        }

        /**
         * Supprime l'image de Cloudinary
         */
        await remove(`${MEDIAS_URL}/${icon.image}`, false);

        /**
         * Supprime l'icône de la DB
         */
        const response = await remove(`${PAGE_URL}/categories/${data._id}/icons/${id}`, false);

        /**
         * Met à jour l'affichage
         */
        if (response.status === 200) {
          onFinish();
        }
      },
    });
  };

  return (
    <>
      {checkAuthorization(user, 'arinfo', 'interFormations', 'update-icons') && (
        <Modal visible={open} title="Modifier les icônes" footer={false} destroyOnClose onCancel={() => setOpen(false)}>
          <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Alert type="info" showIcon message="La taille d'icône optimale est de 32x32px." />
            <Space direction="vertical" style={{ display: 'flex' }}>
              {icons.length === 0 ? (
                <Empty />
              ) : (
                icons.map((icon, index) => (
                  <Fragment key={`icon_avatar_${icon._id}`}>
                    <Row gutter={[12, 12]} wrap={false}>
                      <Col flex="none" style={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar src={icon.image} shape="square" />
                      </Col>
                      <Col flex="auto" style={{ display: 'flex', alignItems: 'center' }}>
                        <EditableText {...{ onFinish }} category={data} data={icon} />
                      </Col>
                      <Col flex="none" style={{ display: 'flex', alignItems: 'center' }}>
                        <Button icon={<DeleteOutlined />} onClick={() => onDelete(icon._id)} />
                      </Col>
                    </Row>
                    {index !== icons.length - 1 && <Divider style={{ margin: 0 }} />}
                  </Fragment>
                ))
              )}
            </Space>
            <Upload
              name="icon"
              className="ant-upload-block"
              action={CLOUDINARY_URL}
              showUploadList={false}
              multiple={false} // TODO : Gérer le multiple
              accept="image/*"
              disabled={isLoading('icon_upload')}
              data={getSignature}
              beforeUpload={(file) => validateFile(file, { maxSize: 1 })}
              onChange={({ file }) => {
                if (file.status === 'uploading') {
                  /**
                   * Passe le statut de la clé en chargement
                   */
                  startLoading('icon_upload');
                } else {
                  /**
                   * Si une erreur survient pendant le téléversement, le statut de chargement est retiré
                   */
                  if (file.status !== 'done') {
                    endLoading('icon_upload');
                  }
                }

                /**
                 * Si le téléversement est réussi, appel le callback post-téléversement
                 */
                if (file.status === 'done' && file?.response) {
                  return onCreate(file.response);
                }
              }}
            >
              <Button block icon={<UploadOutlined />} type="primary" loading={isLoading('icon_upload')}>
                Téléverser une icône
              </Button>
            </Upload>
          </Space>
        </Modal>
      )}
      <Space direction="vertical" style={{ display: 'flex' }}>
        <Space>
          <Typography.Text strong>Icônes</Typography.Text>
          {checkAuthorization(user, 'arinfo', 'interFormations', 'update-icons') && (
            <Button size="small" icon={<SettingOutlined />} onClick={() => setOpen(true)} />
          )}
        </Space>
        <Avatar.Group maxCount={6} maxStyle={{ backgroundColor: '#315397' }}>
          {icons.length === 0 ? (
            <Typography.Text type="secondary" italic>
              Aucune icône
            </Typography.Text>
          ) : (
            icons.map((icon) => (
              <Tooltip key={icon._id} title={icon.alt} destroyTooltipOnHide>
                <Avatar src={icon.image} />
              </Tooltip>
            ))
          )}
        </Avatar.Group>
      </Space>
    </>
  );
};

export default Icons;
