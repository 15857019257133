import { Button, List, Typography, Space, Tooltip } from 'antd';
import { DragOutlined, EyeOutlined, LockOutlined } from '@ant-design/icons';
import { memo, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

const { Item } = List;

const ModuleAiLibraryItem = memo(({ moduleItem, onMove, onDelete, onPreview, disabled }) => {
  const ref = useRef(null);
  const previewRef = useRef(null);

  const [, drop] = useDrop({
    accept: 'CARD',
    canDrop: () => !disabled,
    drop: (item, monitor) => {
      const dragIndex = item.index;
      const hoverIndex = moduleItem.index;

      if ((dragIndex || dragIndex === 0) && (hoverIndex || hoverIndex === 0)) {
        if (dragIndex !== hoverIndex) {
          return onMove(dragIndex, hoverIndex);
        }
      }

      return;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'CARD',
    canDrag: () => !disabled,
    item: { ...moduleItem, remove: () => onDelete(moduleItem.index) },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (dropResult, monitor) => {
      // Appelle la suppression de l'élément s'il est en dehors d'une zone
      if (!monitor.didDrop()) {
        if (onDelete) {
          onDelete(moduleItem.index);
        }
      }
    },
  });

  const opacity = isDragging ? 0.2 : 1;

  drag(ref);
  drop(preview(previewRef));
  return (
    <Item className="editable-fix">
      <div ref={previewRef} className="ordering-tag-container" style={{ opacity }}>
        <Space>
          {moduleItem.disabled && (
            <Tooltip
              title="Ce module est désactivé. Il sera ignoré lors de la génération de prompts. Si vous le retirez de la liste, il ne sera plus sélectionnable"
              destroyTooltipOnHide={{ keepParent: false }}
            >
              <LockOutlined style={{ color: 'red' }} />
            </Tooltip>
          )}
          <Typography.Text>{moduleItem.title}</Typography.Text>
        </Space>
        <Space>
          <Button icon={<EyeOutlined />} onClick={() => onPreview()} disabled={disabled} />
          <Button icon={<DragOutlined />} ref={ref} disabled={disabled} />
        </Space>
      </div>
    </Item>
  );
});

ModuleAiLibraryItem.displayName = 'ModuleAiLibraryItem';
export default ModuleAiLibraryItem;
