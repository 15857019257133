import { CheckCircleFilled, FileExcelOutlined, FilePdfOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Card, Collapse, Input, Modal, Select, Space, Statistic, Typography } from 'antd';
import { useState } from 'react';

const RegistrationsNotice = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Modal
        visible={open}
        footer={false}
        onCancel={() => setOpen(false)}
        onOk={() => setOpen(false)}
        title="Informations sur cette page"
      >
        <Collapse>
          <Collapse.Panel header="Que puis-je trouver sur cette page ?">
            Sur cette page vous trouverez toutes les pré-inscriptions « Confirmées », « Non-traitées » et « Annulées »,
            toutes formations confondues.
          </Collapse.Panel>
          <Collapse.Panel header="Quelles actions sont disponibles ?">
            De multiples action sont disponibles, telles que :
            <ul>
              <li>
                Exporter les pré-inscriptions au format EXCEL via le bouton{' '}
                <Button size="small" icon={<FileExcelOutlined />}>
                  Exporter
                </Button>
                <br />
                <Typography.Text italic type="secondary">
                  Attention ! L'export des données tient compte des filtres actuellement appliqués. Assurez-vous de
                  configurer vos filtres pour exporter uniquement les pré-inscriptions souhaitées.
                </Typography.Text>
              </li>
              <li>
                Téléchargement du résumé d'une pré-inscription au format PDF via le bouton{' '}
                <Button icon={<FilePdfOutlined />} size="small">
                  Télécharger
                </Button>
              </li>
              <li>
                Afficher les informations complètes de la formation et de la date sélectionnée par l'utilisateur via le
                résumé{' '}
                <Button size="small" type="link" style={{ marginLeft: 0, marginRight: 0 }}>
                  ArchiCAD - Inter - 23 nov. 2024 | 03, 05 et 25 déc. 2024
                </Button>
              </li>
            </ul>
          </Collapse.Panel>
          <Collapse.Panel header="Je veux filtrer les données affichées, comment faire ?">
            Il est possible de filtrer l'affichage de plusieurs manières :
            <ul>
              <li>
                En recherchant avec du texte via le champ{' '}
                <Space.Compact size="small" style={{ width: '100%' }}>
                  <Button size="small" icon={<InfoCircleOutlined />} />
                  <Input size="small" placeholder="Rechercher une pré-inscription" />
                </Space.Compact>
              </li>
              <li>
                En filtrant par univers ou formation via le menu déroulant
                <Select size="small" placeholder="Filtrer par univers ou formation" />
              </li>
              <li>
                En filtrant par statut en cliquant sur les blocs de statistiques
                <Card size="small">
                  <Statistic
                    value={0}
                    title={
                      <Space>
                        <CheckCircleFilled style={{ color: '#52c41a' }} />
                        <Typography.Text type="secondary">Confirmées</Typography.Text>
                      </Space>
                    }
                  />
                </Card>
              </li>
            </ul>
          </Collapse.Panel>
        </Collapse>
      </Modal>
      <Button size="small" icon={<InfoCircleOutlined />} onClick={() => setOpen(true)}>
        FAQ
      </Button>
    </>
  );
};

export default RegistrationsNotice;
