import { Col, Form, Input, Row, Space, Typography } from 'antd';
import { Controller, useFormContext, useFormState } from 'react-hook-form';

const UserInputVariable = ({ index, disabled }) => {
  const { control } = useFormContext();
  const { errors } = useFormState({ control });

  return (
    <Row gutter={(24, 24)}>
      <Col span={12}>
        <Space direction="vertical" style={{ display: 'flex' }}>
          <Form.Item
            validateStatus={errors?.variables?.[index]?.userInput?.message && 'error'}
            help={errors?.variables?.[index]?.userInput?.message}
            label="Variable utilisateur"
            required
          >
            <Controller
              name={`variables.[${index}].userInput`}
              control={control}
              render={({ field }) => (
                <Input {...field} disabled={disabled} placeholder="Nom de la variable à appeler" />
              )}
            />
          </Form.Item>
        </Space>
      </Col>
    </Row>
  );
};

export default UserInputVariable;
