import { Modal, Space, Typography, message } from 'antd';

import { aiRoutes } from '../../../../lib/routes';
import useFetch from '../../../../hooks/useFetch';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const DisableSystemModal = ({ system, ids, isOpen, onClose }) => {
  const { patch } = useFetch();

  const onAccept = async () => {
    const results = await patch(aiRoutes.versions + '/disable/' + ids);

    if (results.status === 200) {
      onClose();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }
  };

  return (
    <Modal
      visible={isOpen}
      title={
        <Space>
          <ExclamationCircleOutlined size="large" style={{ color: 'red' }} />
          {`Désactiver le système ${system}`}
        </Space>
      }
      onCancel={onClose}
      onOk={() => onAccept()}
      okButtonProps={{ danger: true }}
      destroyOnClose
    >
      <Typography.Text>{`Etes-vous sûr de vouloir désactiver le système ${system} ? Si le système est désactivé, plus aucune application n'aura accès à ${system} jusqu'à ce que le service soit réactivé.`}</Typography.Text>
    </Modal>
  );
};

export default DisableSystemModal;
