import {
  Button,
  Card,
  Col,
  Divider,
  Input,
  Popover,
  Row,
  Space,
  Statistic,
  Tooltip,
  TreeSelect,
  Typography,
} from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { search as searchFn } from '@i-maginexr/js';
import { CheckCircleFilled, ClockCircleFilled, CloseCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import { debounce } from 'lodash';

const setupTree = (page, formations) => {
  const tree = [];

  if (!page || !page?.content) {
    return tree;
  }

  for (let ci = 0; ci < page.content.categories.length; ci++) {
    const category = page.content.categories[ci];
    const children = (page.content?.formations || [])
      .filter((formation) => formation.category === category._id)
      .flatMap((formation) => {
        const match = formations.find((item) => item._id === formation.formation);

        if (!match) {
          return [];
        }

        return {
          value: match._id,
          title: match.title,
        };
      });

    tree.push({
      value: category._id,
      title: category.name,
      children: children.sort((a, b) => a.title.localeCompare(b.title, 'fr')),
      disabled: children.length === 0,
    });
  }

  return tree.sort((a, b) => a.title.localeCompare(b.title, 'fr'));
};

const defaultValues = {
  search: '',
  filter: undefined,
  status: undefined,
};

const RegistrationsSearch = ({ data, results, page, formations, loading, onChange }) => {
  const [search, setSearch] = useState(defaultValues.search);
  const [searchInput, setSearchInput] = useState(defaultValues.search);
  const [filter, setFilter] = useState(defaultValues.filter);
  const [status, setStatus] = useState(defaultValues.status);
  const filtered = useMemo(() => {
    return (!!search && search?.trim() !== defaultValues.search) || (!!filter && filter !== defaultValues.filter);
  }, [search, filter]);
  const tree = useMemo(() => setupTree(page, formations), [page, formations]);

  const filterDataStatus = (value, data) => {
    return data.filter((item) => item.status === value);
  };

  const filterData = (value, data) => {
    return data.filter((item) => {
      /* Si le filtre correspond à une catégorie */
      if (item.config.category._id === value) {
        return true;
      }

      /* Si le filtre correspond à une formation */
      if (item.config.formation.formation._id === filter) {
        return true;
      }

      return false;
    });
  };

  const searchData = (value, data) => {
    return data.filter((item) => {
      /* Recherche dans le nom de l'entreprise */
      if (searchFn.inString(value, item.form.company.name)) {
        return true;
      }

      /* Recherche dans l'email de contact de l'entreprise */
      if (searchFn.inString(value, item.form.company.email)) {
        return true;
      }

      /* Recherche dans le code postal et la ville de l'entreprise */
      if (searchFn.inString(value, item.form.company.location)) {
        return true;
      }

      /* Recherche dans le code postal et la ville de l'entreprise en remplaçant les tirets par des espaces */
      if (searchFn.inString(value.replaceAll('-', ' '), item.form.company.location.replaceAll('-', ' '))) {
        return true;
      }

      /* Recherche dans le nom du contact */
      if (searchFn.inString(value, item.form.contact.lastName)) {
        return true;
      }

      /* Recherche dans le prénom du contact */
      if (searchFn.inString(value, item.form.contact.firstName)) {
        return true;
      }

      /* Recherche dans le nom et prénom combinés du contact */
      if (searchFn.inString(value, `${item.form.contact.lastName} ${item.form.contact.firstName}`)) {
        return true;
      }

      /* Recherche dans le nom et prénom combinés et inversés du contact */
      if (searchFn.inString(value, `${item.form.contact.firstName} ${item.form.contact.lastName}`)) {
        return true;
      }

      return false;
    });
  };

  useEffect(() => {
    let results = [...data];

    if (!!status && status !== defaultValues.status) {
      results = filterDataStatus(status, results);
    }

    if (!!filter && filter !== defaultValues.filter) {
      results = filterData(filter, results);
    }

    if (!!search && search?.trim() !== defaultValues.search) {
      results = searchData(search, results);
    }

    return onChange(results);
  }, [search, filter, status, data]);

  const debounceHandleSearch = useCallback(
    debounce((value) => {
      setSearch(value);
    }, 200),
    [],
  );

  const handleStatus = (value) => {
    setStatus((prevValue) => {
      if (value === prevValue) {
        return defaultValues.status;
      }

      return value;
    });
  };

  return (
    <>
      <Row gutter={[12, 12]}>
        <Col flex="auto">
          <Space.Compact style={{ width: '100%' }}>
            <Tooltip title="Recherchez parmi le nom de l’entreprise, son email, son code postal, sa ville, ainsi que le nom et le prénom du contact. Les accents ne sont pas pris en compte, et la recherche fonctionne même sur une partie du texte.">
              <Button icon={<InfoCircleOutlined />} />
            </Tooltip>
            <Input
              allowClear
              value={searchInput}
              onChange={(event) => {
                setSearchInput(event.target.value);
                debounceHandleSearch(event.target.value);
              }}
              placeholder="Rechercher une pré-inscription"
              disabled={loading}
            />
          </Space.Compact>
        </Col>
        <Col flex="none">
          <TreeSelect
            {...{ loading }}
            value={filter}
            allowClear
            onChange={(value) => setFilter(value)}
            showSearch
            filterTreeNode={(input, option) => searchFn.inString(input, option.title)}
            treeData={tree}
            placeholder="Filtrer par univers ou formation"
            style={{ width: 250 }}
            disabled={tree.length === 0 || loading}
          />
        </Col>
      </Row>
      <Divider />
      <Row gutter={[24, 24]} style={{ marginBottom: 24 }}>
        <Col span={8}>
          <Card
            size="small"
            onClick={() => handleStatus('Confirmée')}
            className={classnames('custom-inter-intra-stat', {
              ['custom-inter-intra-stat-active']: status === 'Confirmée',
            })}
          >
            <Statistic
              {...{ loading }}
              value={(!filtered ? data || [] : results || []).filter((item) => item.status === 'Confirmée').length}
              title={
                <Space>
                  <CheckCircleFilled style={{ color: '#52c41a' }} />
                  <Typography.Text type="secondary">Confirmées</Typography.Text>
                </Space>
              }
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card
            size="small"
            onClick={() => handleStatus('Non-traitée')}
            className={classnames('custom-inter-intra-stat', {
              ['custom-inter-intra-stat-active']: status === 'Non-traitée',
            })}
          >
            <Statistic
              {...{ loading }}
              value={(!filtered ? data || [] : results || []).filter((item) => item.status === 'Non-traitée').length}
              title={
                <Space>
                  <ClockCircleFilled style={{ color: '#1890ff' }} />
                  <Typography.Text type="secondary">Non-traitées</Typography.Text>
                </Space>
              }
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card
            size="small"
            onClick={() => handleStatus('Annulée')}
            className={classnames('custom-inter-intra-stat', {
              ['custom-inter-intra-stat-active']: status === 'Annulée',
            })}
          >
            <Statistic
              {...{ loading }}
              value={(!filtered ? data || [] : results || []).filter((item) => item.status === 'Annulée').length}
              title={
                <Space>
                  <CloseCircleFilled style={{ color: '#ff4d4f' }} />
                  <Typography.Text type="secondary">Annulées</Typography.Text>
                </Space>
              }
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default RegistrationsSearch;
