import { Alert, Button, Card, List, message, Modal, Popconfirm, Space, Tag } from 'antd';
import useSWR from 'swr';
import { formatDistanceToNowStrict } from 'date-fns';
import axios from 'axios';
import fr from 'date-fns/locale/fr';
import { useState } from 'react';

import tablePagination from '../../../../lib/tablePagination';
import { useAuth } from '../../../../authContext';
import { checkAuthorization } from '../../../../shared/utils';

import TaskCard from './TaskCard';
import HaltModal from './HaltModal';

const SWROptions = {
  revalidateOnReconnect: true,
  revalidateOnFocus: true,
  revalidateOnRefresh: false,
  refreshInterval: 5000,
};

const popconfirmProps = {
  title: 'Êtes-vous sûr ?',
  cancelText: 'Non',
  okText: 'Oui',
  placement: 'left',
};

const queueStatusTag = {
  OFFLINE: {
    label: 'Hors-ligne',
    color: 'error',
  },
  EMPTY: {
    label: 'Aucune tâche',
    color: 'default',
  },
  RUNNING: {
    label: 'En cours',
    color: 'success',
  },
  STOPPED: {
    label: 'Interrompu',
    color: 'warning',
  },
  HALTED: {
    label: 'Interrompu manuellement',
    color: 'error',
  },
};

const haltedQueueMessageStatus = {
  INFO: 'info',
  WARNING: 'warning',
  DANGER: 'error',
};

const ImportExportTaskList = () => {
  const { token, user } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    data: queueData,
    isValidating,
    mutate,
  } = useSWR(`${process.env.REACT_APP_BASE_URL_API_SCORM_URL}/queue`, SWROptions);
  const queueStatus = queueData?.data?.status || 'OFFLINE';
  const queueLastStartTime = queueData?.data?.elapsedTime || null;

  const generateQueueTitle = (status, numberJobs, elapsedTime) => {
    let titleText = "File d'attente";

    if (!['OFFLINE', 'EMPTY'].includes(status)) {
      const timeText = !['STOPPED', 'HALTED'].includes(status)
        ? `, temps écoulé : ${formatDistanceToNowStrict(new Date(elapsedTime), { locale: fr })}`
        : '';
      titleText += ` (${numberJobs} tâches${timeText})`;
    }

    return titleText;
  };

  const restartQueue = async () => {
    if (queueStatus === 'HALTED') {
      message.error("La queue ne peut être redémarrée directement lors d'une interruption manuelle");
      return;
    }

    try {
      const response = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_BASE_URL_API_SCORM_URL}/queue/start`,
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status !== 200) {
        message.error(
          response?.data?.message || "Une erreur est survenue, la file d'attente n'a pas pu être redémarrée",
        );
      } else {
        mutate();
        message.success("La file d'attente a été redémarrée");
      }
    } catch (err) {
      console.log(err?.response?.data?.message || err?.message || err);
      message.error(
        err?.response?.data?.message ||
          err?.message ||
          "Une erreur est survenue, la file d'attente n'a pas pu être redémarrée",
      );
    }
  };

  const forceStartQueue = async () => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_BASE_URL_API_SCORM_URL}/queue/force-start`,
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status !== 200) {
        message.error(
          response?.data?.message || "Une erreur est survenue, la file d'attente n'a pas pu être redémarrée",
        );
      } else {
        mutate();
        message.success("La file d'attente a été redémarrée");
      }
    } catch (err) {
      console.log(err?.response?.data?.message || err?.message || err);
      message.error(
        err?.response?.data?.message ||
          err?.message ||
          "Une erreur est survenue, la file d'attente n'a pas pu être redémarrée",
      );
    }
  };

  const pauseQueue = async () => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_BASE_URL_API_SCORM_URL}/queue/stop`,
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status !== 200) {
        message.error(
          response?.data?.message || "Une erreur est survenue, la file d'attente n'a pas pu être mise en pause",
        );
      } else {
        mutate();
        message.success("La file d'attente a été mise en pause");
      }
    } catch (err) {
      console.log(err?.response?.data?.message || err?.message || err);
      message.error(
        err?.response?.data?.message ||
          err?.message ||
          "Une erreur est survenue, la file d'attente n'a pas pu être mise en pause",
      );
    }
  };

  const dropQueue = async () => {
    try {
      const response = await axios({
        method: 'DELETE',
        url: `${process.env.REACT_APP_BASE_URL_API_SCORM_URL}/queue`,
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status !== 200) {
        message.error(response?.data?.message || "Une erreur est survenue, la file d'attente ne peut pas être vidée");
      } else {
        mutate();
        message.success("La file d'attente a été vidée");
      }
    } catch (err) {
      console.log(err?.response?.data?.message || err?.message || err);
      message.error(
        err?.response?.data?.message ||
          err?.message ||
          "Une erreur est survenue, la tâche ne peut pas être supprimée de la file d'attente",
      );
    }
  };

  return (
    <Space size="middle" direction="vertical" style={{ display: 'flex' }}>
      {queueStatus === 'HALTED' && (
        <Alert
          showIcon
          message={queueData?.data?.message?.text || null}
          type={haltedQueueMessageStatus[queueData?.data?.message?.status] || 'error'}
        />
      )}
      <Card
        size="small"
        bodyStyle={{ padding: 0 }}
        title={
          <Space>
            <Tag color={queueStatusTag[queueStatus].color}>{queueStatusTag[queueStatus].label}</Tag>
            {generateQueueTitle(queueStatus, (queueData?.data?.queue || []).length, queueLastStartTime)}
          </Space>
        }
        extra={
          <Space size="small">
            {queueStatus === 'RUNNING' && (
              <>
                {checkAuthorization(user, 'devTools', 'importExportService', 'pauseQueue') && (
                  <Popconfirm {...popconfirmProps} onConfirm={pauseQueue}>
                    <Button type="link">Mettre la file d'attente en pause</Button>
                  </Popconfirm>
                )}
                {checkAuthorization(user, 'devTools', 'importExportService', 'haltQueue') && (
                  <>
                    <Button type="link" onClick={() => setIsModalOpen(true)}>
                      Interrompre la file d'attente
                    </Button>
                    <HaltModal visible={isModalOpen} onHalt={mutate} onCancel={() => setIsModalOpen(false)} />
                  </>
                )}
              </>
            )}
            {queueStatus === 'STOPPED' && checkAuthorization(user, 'devTools', 'importExportService', 'startQueue') ? (
              <Button type="link" onClick={restartQueue}>
                Redémarrer la file d'attente
              </Button>
            ) : null}
            {queueStatus === 'HALTED' &&
            checkAuthorization(user, 'devTools', 'importExportService', 'forceStartQueue') ? (
              <Popconfirm {...popconfirmProps} onConfirm={forceStartQueue}>
                <Button type="link">Forcer le redémarrage de la file d'attente</Button>
              </Popconfirm>
            ) : null}
            {checkAuthorization(user, 'devTools', 'importExportService', 'dropQueue') && (
              <Popconfirm
                {...popconfirmProps}
                onConfirm={dropQueue}
                disabled={['OFFLINE', 'EMPTY'].includes(queueStatus)}
              >
                <Button type="primary" danger disabled={['OFFLINE', 'EMPTY'].includes(queueStatus)}>
                  Vider la file d'attente
                </Button>
              </Popconfirm>
            )}
          </Space>
        }
      >
        <List
          size="small"
          dataSource={queueData?.data?.queue || []}
          itemLayout="horizontal"
          pagination={{
            ...tablePagination(queueData?.data?.queue || []),
            size: 'small',
            style: { padding: '0 16px 16px 0' },
          }}
          renderItem={(item) => <TaskCard key={item.item} onDelete={mutate} {...{ item }} />}
        />
      </Card>
    </Space>
  );
};

export default ImportExportTaskList;
