import { Button, Modal, Typography, Spin, Divider, Space, Input } from 'antd';
import { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import useSWR from 'swr';

import { aiRoutes } from '../../../../lib/routes';
import { useAuth } from '../../../../authContext';
import { Controller, useForm } from 'react-hook-form';

const getUserInputVariables = (modules) => {
  if (modules.length === 0) {
    return [];
  }

  let variables = [];
  for (let mi = 0; mi < modules.length; mi++) {
    if (!!modules[mi]?.variables) {
      const moduleVariables = modules[mi].variables.filter((item) => item.type === 'USER_INPUT');
      variables = [...variables, ...moduleVariables];
    }
  }

  return variables;
};

const PromptTestModal = ({ activity, isOpen, onClose }) => {
  const [promptMessage, setPromptMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const { token } = useAuth();
  const methods = useForm({ defaultValues: { variables: {} } });
  const { data: modulesResponses } = useSWR(
    isOpen && activity?.modules ? activity?.modules.map((mod) => `${aiRoutes.modules}/${mod}`) : null,
    (...urls) => {
      return Promise.all(
        urls.map((url) =>
          fetch(url, {
            headers: { Authorization: `Bearer ${token}` },
          }).then((res) => res.json()),
        ),
      );
    },
  );
  const modules = useMemo(() => {
    if (Array.isArray(modulesResponses)) {
      return modulesResponses.reduce((acc, curr) => {
        return [...acc, curr?.data];
      }, []);
    }
  }, [modulesResponses]);

  const userInputVariables = useMemo(() => getUserInputVariables(modules || []), [activity, modules]);

  const generatePrompt = async (form) => {
    setLoading(true);
    try {
      // Envoi de la requête
      const response = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_BASE_URL_API_URL}/next/private/ai/prompts/activity/${activity._id}`,
        headers: { Authorization: `Bearer ${token}` },
        data: { variables: form?.variables },
      });

      if (response.status === 200) {
        setPromptMessage(response.data.data);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      generatePrompt();
    } else {
      setPromptMessage('');
    }
  }, [isOpen]);

  return (
    <>
      <Modal
        title={`Test de la génération de prompt pour l'activité «${activity.title}»`}
        visible={isOpen}
        onCancel={onClose}
        width={750}
        footer={[
          <Button key="back" onClick={onClose}>
            Annuler
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={methods.handleSubmit(generatePrompt)}>
            Tester à nouveau
          </Button>,
        ]}
      >
        <Spin spinning={loading}>
          {!!userInputVariables.length && <Typography.Title level={5}>Prompt</Typography.Title>}
          <Typography.Paragraph>
            <span dangerouslySetInnerHTML={{ __html: promptMessage?.replaceAll('\n', '<br/>') }} />
          </Typography.Paragraph>
        </Spin>
        {!!userInputVariables.length && (
          <>
            <Divider
              style={{
                margin: '8px 0',
              }}
            />
            <Typography.Title level={5}>Entrée(s) utilisateur</Typography.Title>
            <Space direction="vertical" style={{ display: 'flex' }}>
              {userInputVariables.map((item) => {
                return (
                  <>
                    <Typography.Text>{item.userInput}</Typography.Text>
                    <Controller
                      name={`variables.${item.variableId}`}
                      control={methods.control}
                      render={({ field }) => <Input {...field} />}
                    />
                  </>
                );
              })}
            </Space>
          </>
        )}
      </Modal>
    </>
  );
};

export default PromptTestModal;
