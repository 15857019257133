import { memo, useMemo, useState } from 'react';
import { DeleteOutlined, EditOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { Button, Card, Image, List, Popconfirm, Popover, Space, Switch, Tooltip, Typography } from 'antd';
import useSWR from 'swr';
import NewApiKeyModal from './NewApiKeyModal';

import NewVersionModal from './NewVersionModal';
import { aiRoutes } from '../../../../lib/routes';
import TestModal from './TestModal';
import useFetch from '../../../../hooks/useFetch';
import { useAuth } from '../../../../authContext';
import { checkAuthorization } from '../../../../shared/utils';
import EditApiKeyModal from './EditApiKeyModal';
import EditVersionModal from './EditVersionModal';
import DisableSystemModal from './DisableSystemModal';
import EnableSystemModal from './EnableSystemModal';

const popconfirmProps = {
  title: 'Êtes-vous sûr ?',
  cancelText: 'Non',
  okText: 'Oui',
  placement: 'left',
};

// TODO: Vérifier le code des modals d'Activation / Désactivation avec Jo

const SystemListItem = memo(({ item }) => {
  const withLogo = !!item?.logo;
  const withDescription = !!item?.description;
  const [disableSystemModalOpen, setDisableSystemModalOpen] = useState(false);
  const [enableSystemModalOpen, setEnableSystemModalOpen] = useState(false);
  const [editVersionModalOpen, setEditVersionModalOpen] = useState(false);
  const [editKeyModalOpen, setEditKeyModalOpen] = useState(false);
  const { user } = useAuth();
  const { patch, remove } = useFetch();
  const {
    data: versions,
    isValidating: isVersionsValidating,
    mutate: mutateVersions,
  } = useSWR(aiRoutes.default + '/' + item.id + '/versions', {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const {
    data: keys,
    isValidating: isKeysValidating,
    mutate: mutateKeys,
  } = useSWR(aiRoutes.default + '/' + item.id + '/keys', {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const [systemEnabled, versionIds] = useMemo(() => {
    let systemEnabled = false;
    let versionIds = '';

    if (versions?.data) {
      for (let i = 0; i < versions.data.length; i++) {
        versionIds = versionIds + versions.data[i]._id + ',';
        if (!versions.data[i]?.disabled) {
          systemEnabled = true;
        }
      }
    }

    return [systemEnabled, versionIds];
  }, [versions?.data]);

  const onEnableVersion = async (id) => {
    const results = await patch(aiRoutes.versions + '/enable/' + id);

    if (results.status === 200) {
      mutateVersions();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }
  };

  const onEnableAllVersions = async () => {
    const results = await patch(aiRoutes.versions + '/enable/' + versionIds);

    if (results.status === 200) {
      mutateVersions();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }
  };

  const onDisableVersion = async (id) => {
    const results = await patch(aiRoutes.versions + '/disable/' + id);

    if (results.status === 200) {
      mutateVersions();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }
  };

  const onDeleteVersion = async (id) => {
    const results = await remove(aiRoutes.versions + '/' + id);

    if (results.status === 200) {
      mutateVersions();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }
  };

  const onDeleteKey = async (id) => {
    const results = await remove(aiRoutes.keys + '/' + id);

    if (results.status === 200) {
      mutateKeys();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }
  };

  return (
    <Card
      bordered
      style={{ marginBottom: 24 }}
      size="small"
      title={
        <Space>
          {withLogo && (
            <div style={{ width: 24, height: 24 }}>
              <Image src={item.logo} preview={false} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
            </div>
          )}
          <Typography.Text>{item.name}</Typography.Text>
        </Space>
      }
      extra={
        <Space>
          {(item?.links || []).map((item, index) => (
            <Typography.Link key={index} href={item.url} target="_blank" rel="noopener noreferrer">
              {item.label}
            </Typography.Link>
          ))}
        </Space>
      }
    >
      {withDescription ? (
        <Typography.Text>{item.description}</Typography.Text>
      ) : (
        <Typography.Text type="secondary">
          <i>Aucune description.</i>
        </Typography.Text>
      )}
      <div style={{ display: 'flex', marginTop: '1em' }}>
        <Space size="small">
          <Typography.Text strong>Etat du système :</Typography.Text>
          <Switch
            checkedChildren="Système actif"
            unCheckedChildren="Système désactivé"
            checked={systemEnabled}
            disabled={(versions?.data?.length || 0) === 0}
            onChange={() => {
              if (systemEnabled) {
                setDisableSystemModalOpen(true);
              } else {
                setEnableSystemModalOpen(true);
              }
            }}
          />
        </Space>
      </div>
      {checkAuthorization(user, 'ai', 'versions', 'search') || checkAuthorization(user, 'ai', 'keys', 'search') ? (
        <div style={{ display: 'flex', marginTop: '1em' }} className="ai-list-compact">
          {checkAuthorization(user, 'ai', 'versions', 'search') && (
            <div style={{ flex: 1, overflow: 'hidden' }}>
              <EditVersionModal
                id={item.id}
                ai={item.name}
                isOpen={!!editVersionModalOpen}
                data={editVersionModalOpen}
                onClose={() => {
                  setEditVersionModalOpen(false);
                  mutateVersions();
                }}
              />
              <DisableSystemModal
                system={item.name}
                ids={versionIds}
                isOpen={disableSystemModalOpen}
                onClose={() => {
                  setDisableSystemModalOpen(false);
                  mutateVersions();
                }}
              />
              <EnableSystemModal
                system={item.name}
                ids={versionIds}
                isOpen={enableSystemModalOpen}
                onClose={() => {
                  setEnableSystemModalOpen(false);
                  mutateVersions();
                }}
              />
              <List
                header={
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography.Text>Versions</Typography.Text>
                    {checkAuthorization(user, 'ai', 'versions', 'create') && (
                      <NewVersionModal id={item.id} ai={item.name} onFinish={mutateVersions} />
                    )}
                  </div>
                }
                bordered
                size="small"
                dataSource={versions?.data}
                loading={isVersionsValidating}
                rowKey={(dataItem) => dataItem._id}
                pagination={{
                  hideOnSinglePage: true,
                  pageSize: 3,
                  size: 'small',
                  showTotal: (total, range) => {
                    return `${range[0]}-${range[1]} de ${total} élément${total > 1 ? 's' : ''}`;
                  },
                }}
                renderItem={(dataItem) => (
                  <List.Item style={{ marginLeft: 0 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Space>
                          {dataItem?.disabled && (
                            <Tooltip title="Cette version est désactivée" destroyTooltipOnHide={{ keepParent: false }}>
                              <LockOutlined style={{ color: 'red' }} />
                            </Tooltip>
                          )}
                          <Typography.Text ellipsis>{dataItem.name}</Typography.Text>
                        </Space>
                        <Space size="small">
                          {dataItem?.disabled
                            ? checkAuthorization(user, 'ai', 'versions', 'enable') && (
                                <Tooltip title="Activer la version" destroyTooltipOnHide>
                                  <Popconfirm {...popconfirmProps} onConfirm={() => onEnableVersion(dataItem._id)}>
                                    <Button size="small" icon={<UnlockOutlined />} />
                                  </Popconfirm>
                                </Tooltip>
                              )
                            : checkAuthorization(user, 'ai', 'versions', 'disable') && (
                                <Tooltip title="Désactiver la version" destroyTooltipOnHide>
                                  <Popconfirm {...popconfirmProps} onConfirm={() => onDisableVersion(dataItem._id)}>
                                    <Button size="small" icon={<LockOutlined />} />
                                  </Popconfirm>
                                </Tooltip>
                              )}
                          {checkAuthorization(user, 'ai', 'versions', 'update') && (
                            <Tooltip title="Modifier la version" destroyTooltipOnHide>
                              <Button
                                key="update"
                                size="small"
                                icon={<EditOutlined />}
                                onClick={() => setEditVersionModalOpen(dataItem)}
                              />
                            </Tooltip>
                          )}
                          {checkAuthorization(user, 'ai', 'versions', 'delete') && (
                            <Tooltip title="Supprimer la version" destroyTooltipOnHide>
                              <Popconfirm {...popconfirmProps} onConfirm={() => onDeleteVersion(dataItem._id)}>
                                <Button size="small" icon={<DeleteOutlined />} />
                              </Popconfirm>
                            </Tooltip>
                          )}
                        </Space>
                      </div>
                      <Typography.Text type="secondary" ellipsis>
                        {dataItem.value}
                      </Typography.Text>
                    </div>
                  </List.Item>
                )}
              />
            </div>
          )}
          {checkAuthorization(user, 'ai', 'keys', 'search') && (
            <div style={{ flex: 1, overflow: 'hidden' }}>
              <EditApiKeyModal
                id={item.id}
                ai={item.name}
                isOpen={!!editKeyModalOpen}
                data={editKeyModalOpen}
                onClose={() => {
                  setEditKeyModalOpen(false);
                  mutateKeys();
                }}
              />
              <List
                header={
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography.Text>Clés API</Typography.Text>
                    {checkAuthorization(user, 'ai', 'keys', 'create') && (
                      <NewApiKeyModal id={item.id} ai={item.name} onFinish={mutateKeys} />
                    )}
                  </div>
                }
                bordered
                size="small"
                dataSource={keys?.data}
                loading={isKeysValidating}
                rowKey={(dataItem) => dataItem._id}
                pagination={{
                  hideOnSinglePage: true,
                  pageSize: 3,
                  size: 'small',
                  showTotal: (total, range) => {
                    return `${range[0]}-${range[1]} de ${total} élément${total > 1 ? 's' : ''}`;
                  },
                }}
                renderItem={(dataItem) => (
                  <List.Item style={{ marginLeft: 0 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Space size="small">
                          <Typography.Text ellipsis>{dataItem.name}</Typography.Text>
                          {!dataItem.restrictions || dataItem.restrictions?.length === 0 ? (
                            <Typography.Text type="secondary">Aucune restriction</Typography.Text>
                          ) : (
                            <Popover
                              content={dataItem.restrictions.map((dataItem) => dataItem?.name || dataItem).join(', ')}
                            >
                              <Typography.Link>
                                {dataItem.restrictions?.length} restriction{dataItem.restrictions?.length >= 2 && 's'}
                              </Typography.Link>
                            </Popover>
                          )}
                        </Space>
                        <Space size="small">
                          {checkAuthorization(user, 'ai', 'keys', 'test') && (
                            <TestModal ai={item} item={dataItem} versions={versions} />
                          )}
                          {checkAuthorization(user, 'ai', 'keys', 'update') && (
                            <Tooltip title="Modifier la clé" destroyTooltipOnHide>
                              <Button
                                size="small"
                                icon={<EditOutlined />}
                                onClick={() => setEditKeyModalOpen(dataItem)}
                              />
                            </Tooltip>
                          )}
                          {checkAuthorization(user, 'ai', 'keys', 'delete') && (
                            <Tooltip title="Supprimer la clé" destroyTooltipOnHide>
                              <Popconfirm {...popconfirmProps} onConfirm={() => onDeleteKey(dataItem._id)}>
                                <Button size="small" icon={<DeleteOutlined />} />
                              </Popconfirm>
                            </Tooltip>
                          )}
                        </Space>
                      </div>
                      <Typography.Text type="secondary" ellipsis>
                        {'*'.repeat(50)}
                      </Typography.Text>
                    </div>
                  </List.Item>
                )}
              />
            </div>
          )}
        </div>
      ) : null}
    </Card>
  );
});

SystemListItem.displayName = 'SystemListItem';
export default SystemListItem;
