import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, List, Row, Space, Typography } from 'antd';
import { memo } from 'react';
import { Controller, useFormContext, useFormState } from 'react-hook-form';

const ElementsListVariableItem = memo(({ item, index, onDelete, onChange, disabled, isDeletable }) => {
  return (
    <List.Item style={{ width: '100%' }}>
      <div style={{ width: '100%', display: 'flex' }}>
        <Typography.Text
          ellipsis
          disabled={disabled}
          style={{ flex: 1, flexShrink: 1 }}
          editable={{
            onChange: (value) => onChange(index, value),
            tooltip: 'Renommer',
          }}
        >
          {item}
        </Typography.Text>
        <Button
          onClick={() => onDelete(index)}
          size="small"
          icon={<DeleteOutlined />}
          disabled={disabled || isDeletable}
          style={{ flexShrink: 0, marginLeft: 8 }}
        />
      </div>
    </List.Item>
  );
});
ElementsListVariableItem.displayName = 'ElementsListVariableItem';

const ElementsListVariable = ({ index, disabled }) => {
  const { control, watch, setValue } = useFormContext();
  const itemList = watch(`variables.[${index}].list`);
  const { errors } = useFormState({ control });

  const addElement = () => {
    setValue(`variables.[${index}].list`, [...itemList, 'Nouvel élément']);
  };

  const editElement = (itemIndex, value) => {
    const modifiedArray = [...itemList];
    modifiedArray[itemIndex] = value;
    setValue(`variables.[${index}].list`, [...modifiedArray]);
  };

  const removeElement = (itemIndex) => {
    if (itemList.length > 2) {
      const modifiedArray = [...itemList];
      modifiedArray.splice(itemIndex, 1);
      setValue(`variables.[${index}].list`, [...modifiedArray]);
    }
  };

  return (
    <Row gutter={(24, 24)} align="bottom">
      <Col span={12}>
        <Space direction="vertical" style={{ display: 'flex' }}>
          <Form.Item
            validateStatus={errors?.variables?.[index]?.list?.message && 'error'}
            help={errors?.variables?.[index]?.list?.message}
            label="Eléments de la liste"
            required
          >
            <Controller
              name={`variables.[${index}].list`}
              control={control}
              render={({ field }) => (
                <List
                  bordered
                  size="small"
                  disabled={disabled}
                  pagination={false}
                  style={{ overflow: 'hidden' }}
                  dataSource={itemList}
                  renderItem={(item, index) => (
                    <ElementsListVariableItem
                      {...{ item, index }}
                      onChange={editElement}
                      onDelete={removeElement}
                      isDeletable={(itemList?.length || []) <= 2}
                      disabled={disabled}
                    />
                  )}
                />
              )}
            />
          </Form.Item>
          <Button onClick={addElement} icon={<PlusOutlined />} style={{ width: '100%' }}>
            Ajouter un élément
          </Button>
        </Space>
      </Col>
    </Row>
  );
};

export default ElementsListVariable;
