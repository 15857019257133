import { Button, Col, Form, message, Modal, Row, Select, TreeSelect } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import qs from 'qs';
import { Controller, FormProvider, useForm, useFormState } from 'react-hook-form';

import { aiRoutes, keysRoutes } from '../../../../lib/routes';
import { useAuth } from '../../../../authContext';
import { checkAuthorization } from '../../../../shared/utils';
import ImageProcessingInput from './components/ImageProcessingInput';
import ImageProcessingOptions from './components/ImageProcessingOptions';
import ImageProcessingViewer from './components/ImageProcessingViewer';

const CreateImageProcessingModal = ({ onFinish }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [displayPrompt, setDisplayPrompt] = useState(false);
  const [refreshConversation, setRefreshConversation] = useState(false);
  const [conversationId, setConversationId] = useState(null);
  const { data: keys, isValidating: isKeysValidating } = useSWR(keysRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: activities, isValidating: isActivitiesValidating } = useSWR(aiRoutes.activities, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: applications, isValidating: isApplicationsValidating } = useSWR(aiRoutes.applications, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: conversation, isValidating: isConversationsValidating } = useSWR(
    conversationId ? `${aiRoutes.images}/${conversationId}` : '',
    {
      revalidateOnReconnect: true,
      revalidateOnFocus: true,
      revalidateOnRefresh: false,
      refreshInterval: refreshConversation ? 1000 : 0,
      keepPreviousData: true,
    },
  );
  const methods = useForm({ defaultValues: { activity: null, prompt: '', numberImages: 1, imageSize: '1024x1024' } });

  const { errors } = useFormState({ control: methods.control });
  const { token, user } = useAuth();
  const activity = methods.watch('activity');

  useEffect(() => {
    if (conversation?.data) {
      setRefreshConversation(!['IN PROGRESS', 'COMPLETED', 'ERRORED'].includes(conversation?.data?.status));
    }
  }, [conversation?.data]);

  const formattedApplications = useMemo(() => {
    if (activities?.data && applications?.data) {
      return applications.data.map((application) => {
        const applicationChildren = activities.data.filter(
          (activity) => activity.application.toString() === application._id.toString(),
        );

        return {
          label: application.title,
          key: application._id,
          value: application._id,
          selectable: false,
          disabled: applicationChildren.length === 0,
          children:
            (applicationChildren || []).map((activity) => ({
              label: activity.title,
              key: activity._id,
              value: activity._id,
            })) || [],
        };
      });
    } else {
      return [];
    }
  }, [activities, activities?.data, applications, applications?.data]);

  const onSubmit = async (form) => {
    setIsProcessing(true);
    setConversationId(null);

    if (!form?.apiKey) {
      methods.setError('apiKey', { type: 'custom', message: 'Ce champ est requis' });

      setIsProcessing(false);
      return;
    }

    // Constructions des data
    const data = {
      activity: !!form.activity ? form.activity : '',
      numberImages: form.numberImages,
      imageSize: form.imageSize,
      imageQuality: form?.imageQuality ? 'hd' : 'standard',
      ...(form.prompt ? { prompt: form.prompt } : {}),
    };

    // Construction des options
    const params = qs.stringify(
      {
        skipQueue: !!form.options?.includes('skipQueue'),
        skipAi: !!form.options?.includes('skipAi'),
        forcePriority: !!form.options?.includes('forcePriority'),
        rejectFromQueue: !!form.options?.includes('rejectFromQueue'),
        rejectFromThread: !!form.options?.includes('rejectFromThread'),
      },
      { addQueryPrefix: true },
    );

    // Envoi de la requête
    const response = await fetch(`${aiRoutes.images}/generation${params}`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        'x-api-key': form.apiKey,
      },
    });

    const results = await response.json();

    if (results.status === 201) {
      onFinish();
      setRefreshConversation(true);

      if (results?.data) {
        setConversationId(results.data._id);
      }
    } else {
      if (results.message) {
        message.error(results.message);
      }

      if (results.data) {
        Object.entries(results.data).forEach(([key, value]) => {
          methods.setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  return (
    <div style={{ textAlign: 'right' }}>
      <Modal
        width={800}
        open={isOpen}
        onCancel={() => {
          methods.reset();
          setConversationId(null);
          setDisplayPrompt(false);
          setIsOpen(false);
        }}
        footer={null}
        destroyOnClose
      >
        <FormProvider {...methods}>
          <Form noValidate layout="vertical" onFinish={methods.handleSubmit(onSubmit)}>
            <Row>
              <Col span={12}>
                <Form.Item
                  label="Clé d'accès"
                  validateStatus={errors?.apiKey?.message && 'error'}
                  help={errors?.apiKey?.message}
                  required
                >
                  <Controller
                    control={methods.control}
                    name="apiKey"
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={(keys?.data || []).map((item) => ({
                          label: item.name,
                          value: item.accessKey,
                          item,
                        }))}
                        loading={isKeysValidating}
                        disabled={isProcessing || conversationId}
                      />
                    )}
                  />
                </Form.Item>
                <Form.Item
                  label="Application et activité"
                  validateStatus={errors?.activity?.message && 'error'}
                  help={errors?.activity?.message}
                  required
                >
                  <Controller
                    control={methods.control}
                    name="activity"
                    render={({ field }) => (
                      <TreeSelect
                        {...field}
                        treeExpandAction="click"
                        multiple={false}
                        treeData={formattedApplications || []}
                        loading={isActivitiesValidating || isApplicationsValidating}
                        disabled={isProcessing || conversationId}
                      />
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="Traitement d'image">
              <ImageProcessingViewer conversation={conversation?.data || {}} displayPrompt={displayPrompt} />
            </Form.Item>
            <ImageProcessingInput
              conversation={conversation?.data || {}}
              activity={activity}
              isProcessing={isProcessing || isConversationsValidating}
            />

            {checkAuthorization(user, 'ai', 'api', 'debug') && (
              <ImageProcessingOptions
                displayPrompt={displayPrompt}
                onDisplayPromptChange={setDisplayPrompt}
                disabled={isProcessing || ['INITIALIZING', 'SUBMITTED', 'ERRORED'].includes(conversation?.data?.status)}
              />
            )}

            <Row style={{ justifyContent: 'flex-end' }}>
              <Button
                type="primary"
                htmlType="submit"
                loading={isProcessing || ['INITIALIZING', 'SUBMITTED'].includes(conversation?.data?.status)}
                disabled={isProcessing || ['INITIALIZING', 'SUBMITTED', 'ERRORED'].includes(conversation?.data?.status)}
              >
                {conversation?.data?.status === 'COMPLETED' ? `Re-générer` : `Générer`}
              </Button>
            </Row>
          </Form>
        </FormProvider>
      </Modal>
      <Button
        icon={<PlusOutlined />}
        className="gray-text"
        onClick={() => setIsOpen(true)}
        disabled={!checkAuthorization(user, 'ai', 'interactions', 'interact')}
      >
        Nouvelle image
      </Button>
    </div>
  );
};

export default CreateImageProcessingModal;
