import { FileExcelOutlined, FilterOutlined, InfoCircleOutlined, MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Collapse, Modal } from 'antd';
import { useState } from 'react';

const Notice = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Modal
        visible={open}
        footer={false}
        onCancel={() => setOpen(false)}
        onOk={() => setOpen(false)}
        title="Informations sur cette page"
      >
        <Collapse>
          <Collapse.Panel header="Que puis-je trouver sur cette page ?">
            Sur cette page vous trouverez la programmation complètes, toutes formations confondues.
          </Collapse.Panel>
          <Collapse.Panel header="Pourquoi certaines formations n'apparaissent pas ?">
            Seules les formations configurées au préalable sont affichées.
          </Collapse.Panel>
          <Collapse.Panel header="Quelles actions sont disponibles ?">
            De multiples action sont disponibles, telles que :
            <ul>
              <li>
                Exportation de la programmation complète au format EXCEL via le bouton{' '}
                <Button icon={<FileExcelOutlined />} size="small">
                  Exporter
                </Button>
                .
              </li>
              <li>
                Ajout d'une programmation via le bouton <Button icon={<PlusOutlined />} size="small" />.
              </li>
              <li>
                Modification d'une programmation via le bouton <Button icon={<MoreOutlined />} size="small" />.
              </li>
              <li>
                Suppression d'une programmation via le bouton <Button icon={<MoreOutlined />} size="small" />.
              </li>
            </ul>
          </Collapse.Panel>
          <Collapse.Panel header="Je veux filtrer les données affichées, comment faire ?">
            Des options de filtrage sont disponible en cliquant sur les icônes <FilterOutlined />.
          </Collapse.Panel>
        </Collapse>
      </Modal>
      <Button size="small" icon={<InfoCircleOutlined />} onClick={() => setOpen(true)}>
        FAQ
      </Button>
    </>
  );
};

export default Notice;
