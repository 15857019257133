import { useState } from 'react';
import { Card, Tabs } from 'antd';
import useSWR from 'swr';

import { aiRoutes } from '../../../lib/routes';
import LogsAiList from './LogsAiList';
import { BookOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;

const UsageAiDisplay = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const { data: interactionLogs, isValidating, mutate } = useSWR(aiRoutes.logs);

  return (
    <Card>
      <Tabs>
        {/* <TabPane
          key="stats"
          tab={
            <>
              <AreaChartOutlined />
              Statistiques d'utilisation
            </>
          }
        >
          <StatisticsAiInterface interactionLogs={interactionLogs?.data || []} />
        </TabPane> */}
        <TabPane
          key="logs"
          tab={
            <>
              <BookOutlined />
              Journaux
            </>
          }
        >
          <LogsAiList
            interactionLogs={interactionLogs?.data || []}
            isValidating={isValidating}
            isProcessing={isProcessing}
            onProcessing={setIsProcessing}
            onMutate={mutate}
          />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default UsageAiDisplay;
