import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Modal, Space, Typography } from 'antd';
import { memo, useEffect, useState } from 'react';
import { useController, useForm, useFormState } from 'react-hook-form';
import useFetch from '../../../../../hooks/useFetch';
import { useAuth } from '../../../../../authContext';
import { checkAuthorization } from '../../../../../shared/utils';

const PAGE_URL = `${process.env.REACT_APP_BASE_URL_API_URL}/next/private/arinfo/pages/inter-intra`;

const ProgramItemComment = memo(({ formationId, onFinish, record, expanded, onCreateCancel, onDelete }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [editing, setEditing] = useState(false);
  const { control, handleSubmit, setError, reset } = useForm({ defaultValues: { note: record?.note || '' } });
  const { field } = useController({ control, name: 'note' });
  const { errors } = useFormState({ control });
  const { patch } = useFetch();
  const { user } = useAuth();

  useEffect(() => {
    if (expanded && !record?.note) {
      setEditing(true);
      reset({ note: record?.note || '' });
    } else {
      setEditing(false);
    }
  }, [expanded, record]);

  const onSubmit = async (form, mode) => {
    const results = await patch(
      `${PAGE_URL}/formations/${formationId}/schedule/${record._id}/note`,
      JSON.stringify(form),
    );

    if (results.status === 200) {
      setIsProcessing(false);

      if (mode === 'DELETE') {
        reset({ note: '' });
        message.success('Note supprimée');
      } else {
        reset({ note: form?.note || '' });

        if (!record?.note) {
          message.success('Note créée');
        } else {
          message.success('Note modifiée');
        }
      }

      setEditing(false);

      if (mode === 'DELETE') {
        return onDelete();
      } else {
        return onFinish();
      }
    } else {
      setIsProcessing(false);

      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          setError(key, { type: 'manual', message: value });
        });
      }
    }
  };

  if (editing) {
    return (
      <Form noValidate layout="vertical">
        <Space direction="vertical" style={{ display: 'flex' }}>
          <Form.Item
            style={{ marginBottom: 0 }}
            validateStatus={errors?.note?.message && 'error'}
            help={errors.note?.message}
          >
            <Input.TextArea {...field} disabled={isProcessing} rows={4} />
          </Form.Item>
          <Space style={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              size="small"
              icon={<CloseOutlined />}
              onClick={() => {
                reset();
                setEditing(false);

                if (!record?.note) {
                  onCreateCancel();
                }
              }}
            />
            <Button
              size="small"
              type="primary"
              icon={<CheckOutlined />}
              onClick={handleSubmit(onSubmit)}
              loading={isProcessing}
            />
          </Space>
        </Space>
      </Form>
    );
  }

  return (
    <Space direction="vertical" style={{ display: 'flex' }}>
      <Typography.Text style={{ whiteSpace: 'pre-wrap' }}>{field.value}</Typography.Text>
      {checkAuthorization(user, 'arinfo', 'interFormations', 'manage-schedule-note') && (
        <Space style={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            size="small"
            icon={<DeleteOutlined />}
            onClick={() => {
              return Modal.confirm({
                title: 'Voulez-vous vraiment supprimer cette note ?',
                content: 'Cette action est irréversible.',
                okText: 'Confirmer',
                okType: 'danger',
                onOk: async () => {
                  await onSubmit({ note: null }, 'DELETE');
                },
              });
            }}
          />
          <Button size="small" icon={<EditOutlined />} onClick={() => setEditing(true)} />
        </Space>
      )}
    </Space>
  );
});

ProgramItemComment.displayName = 'ProgramItemComment';
export default ProgramItemComment;
