import { message } from 'antd';
import { useAuth } from '../authContext';

const useFetch = () => {
  const { token } = useAuth();

  const request = async (url, method, data = {}, dataType = 'application/json', showMessage = true) => {
    const results = { status: null, message: null, data: {}, errors: {} };

    try {
      const res = await fetch(url, {
        method,
        ...(dataType === 'multipart/form-data' || Object.keys(data).length !== 0 ? { body: data } : {}),
        headers: {
          Authorization: `Bearer ${token}`,
          ...(dataType !== 'multipart/form-data' && { 'Content-Type': dataType }),
        },
      });

      const json = await res.json();

      results.status = json.status || null;

      if (json.status === 200 || json.status === 201) {
        if (json.message) {
          if (showMessage) {
            message.success(json.message);
          }

          results.message = json.message || null;
        }

        results.data = json.data || {};
      } else {
        results.message = json.message || null;
        results.errors = json.data || {};
      }
    } catch (error) {
      console.error(error);
    } finally {
      return results;
    }
  };

  const get = async (url) => await request(url, 'GET');

  const post = async (url, data, dataType, showMessage) => await request(url, 'POST', data, dataType, showMessage);

  const put = async (url, data, dataType, showMessage) => await request(url, 'PUT', data, dataType, showMessage);

  const patch = async (url, data, dataType, showMessage) => await request(url, 'PATCH', data, dataType, showMessage);

  const remove = async (url, showMessage) => await request(url, 'DELETE', {}, null, showMessage);

  return { get, post, put, patch, remove };
};

export default useFetch;
