import { useState } from 'react';
import { Button, Card, Tabs } from 'antd';
import useSWR from 'swr';

import { aiRoutes } from '../../../lib/routes';
import ImageGenerationAiTable from './ImageGenerationAiTable';
import ConversationsAiTable from './ConversationsAiTable';
import CreateConversationModal from './modals/CreateConversationModal';
import CreateImageProcessingModal from './modals/CreateImageProcessingModal';

const { TabPane } = Tabs;

const InteractionsAiList = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [tab, setTab] = useState('conversation');
  const { data: conversations, isValidating, mutate: inConversationMutating } = useSWR(aiRoutes.conversations);
  const {
    data: imageInteractions,
    isValidating: isImageInteractionValidating,
    mutate: onImageInteractionsMutate,
  } = useSWR(aiRoutes.images);

  return (
    <Card>
      {tab === 'conversation' && <CreateConversationModal onFinish={inConversationMutating} />}
      {tab === 'imageProcessing' && <CreateImageProcessingModal onFinish={onImageInteractionsMutate} />}

      <Tabs activeKey={tab} onChange={setTab}>
        <TabPane key="conversation" tab={`Conversations (${conversations?.data?.length || 0})`}>
          <ConversationsAiTable
            conversations={conversations?.data || []}
            onFinish={inConversationMutating}
            isValidating={isValidating}
            isProcessing={isProcessing}
            onProcessing={setIsProcessing}
          />
        </TabPane>
        <TabPane key="imageProcessing" tab={`Traitement d'image (${imageInteractions?.data?.length || 0})`}>
          <ImageGenerationAiTable
            imageGenerations={imageInteractions?.data || []}
            onFinish={onImageInteractionsMutate}
            isValidating={isImageInteractionValidating}
            isProcessing={isProcessing}
            onProcessing={setIsProcessing}
          />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default InteractionsAiList;
