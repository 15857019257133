import { Card, Checkbox, Typography } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';

export default function AdditionalConfig() {
  const { control, disabled } = useFormContext();

  return (
    <Card size="small" title={<Typography.Title level={5}>Autres</Typography.Title>}>
      <Controller
        {...{ control }}
        name="extras.certified"
        render={({ field }) => (
          <Checkbox
            {...{ ...field, disabled }}
            checked={field.value}
            onChange={(e) => field.onChange(e.target.checked)}
          >
            Formation certifiante
          </Checkbox>
        )}
      />
      <br />
      <Controller
        {...{ control }}
        name="extras.favorite"
        render={({ field }) => (
          <Checkbox
            {...{ ...field, disabled }}
            checked={field.value}
            onChange={(e) => field.onChange(e.target.checked)}
          >
            Formation coup de coeur
          </Checkbox>
        )}
      />
    </Card>
  );
}
