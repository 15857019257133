import { Mark, mergeAttributes } from '@tiptap/core';

export const Variable = Mark.create({
  name: 'variable',
  priority: 1000,
  keepOnSplit: false,
  exitable: true,
  inclusive: false,
  spanning: false,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },
  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-variable-id'),
        renderHTML: (attributes) => {
          if (!attributes.id) {
            return {};
          }
          return {
            'data-variable-id': attributes.id,
          };
        },
      },
      ref: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-variable-ref'),
        renderHTML: (attributes) => {
          if (!attributes.ref) {
            return {};
          }
          return {
            'data-variable-ref': attributes.ref,
          };
        },
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: `span[data-type="${this.name}"]`,
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes({ 'data-type': this.name }, this.options.HTMLAttributes, HTMLAttributes), 0];
  },
  addCommands() {
    return {
      setVariable:
        (attributes) =>
        ({ chain }) => {
          return chain().focus().setMark(this.name, attributes).run();
        },
      unsetVariable:
        () =>
        ({ editor, tr, chain }) => {
          const { id, ref } = editor.getAttributes(this.name);

          if (!ref) {
            const mark = editor.state.schema.marks[this.name];

            if (mark) {
              tr.doc.nodesBetween(0, tr.doc.content.size, (node, pos) => {
                if (mark.isInSet(node.marks) && node?.marks?.some(({ attrs }) => attrs?.ref === id)) {
                  tr.removeMark(pos, pos + node.nodeSize, mark.type);
                }
              });
            }
          }

          return chain().focus().extendMarkRange(this.name).unsetMark(this.name).run();
        },
    };
  },
});
