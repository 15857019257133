import { Button, Modal, Typography, Spin, Divider, Space, Input } from 'antd';
import { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import useSWR from 'swr';
import { Controller, useForm } from 'react-hook-form';

import { aiRoutes } from '../../../../lib/routes';
import { useAuth } from '../../../../authContext';

const getUserInputVariables = (variables) => {
  if (variables.length === 0) {
    return [];
  }

  return variables.filter((item) => item.type === 'USER_INPUT');
};

const PromptTestModal = ({ module, isOpen, onClose }) => {
  const [promptMessage, setPromptMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const { token } = useAuth();
  const { control, handleSubmit } = useForm({ defaultValues: { variables: {} } });
  const { data: moduleData, isValidating } = useSWR(`${aiRoutes.modules}/${module._id}`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    keepPreviousData: false,
  });

  const userInputVariables = useMemo(
    () => getUserInputVariables(moduleData?.data?.variables || []),
    [moduleData, moduleData?.data?.variables],
  );

  const generatePrompt = async (form) => {
    setLoading(true);

    try {
      const response = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_BASE_URL_API_URL}/next/private/ai/prompts/modules/${module._id}`,
        headers: { Authorization: `Bearer ${token}` },
        data: { variables: form?.variables },
      });

      if (response.status === 200) {
        setPromptMessage(response.data.data.toString());
        setLoading(false);
      }
    } catch (err) {
      console.log(err?.response?.data?.message || err?.message || err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      generatePrompt();
    } else {
      setPromptMessage('');
    }
  }, [isOpen]);

  return (
    <>
      <Modal
        title={`Test de la génération de prompt pour le module «${module.title}»`}
        visible={isOpen}
        onCancel={onClose}
        width={750}
        footer={[
          <Button key="back" onClick={onClose}>
            Annuler
          </Button>,
          <Button key="submit" type="primary" loading={loading || isValidating} onClick={handleSubmit(generatePrompt)}>
            Tester à nouveau
          </Button>,
        ]}
      >
        <Spin spinning={loading}>
          {!!userInputVariables.length && <Typography.Title level={5}>Prompt</Typography.Title>}
          <Typography.Paragraph>
            <span dangerouslySetInnerHTML={{ __html: promptMessage?.replaceAll('\n', '<br/>') }} />
          </Typography.Paragraph>
        </Spin>
        {!!userInputVariables.length && (
          <>
            <Divider
              style={{
                margin: '8px 0',
              }}
            />
            <Typography.Title level={5}>Entrée(s) utilisateur</Typography.Title>
            <Space direction="vertical" style={{ display: 'flex' }}>
              {moduleData?.data?.variables.map((item, index) => {
                if (item.type === 'USER_INPUT') {
                  return (
                    <>
                      <Typography.Text>{item.userInput}</Typography.Text>
                      <Controller
                        name={`variables.${item.variableId}`}
                        control={control}
                        render={({ field }) => <Input {...field} disabled={loading || isValidating} />}
                      />
                    </>
                  );
                }
              })}
            </Space>
          </>
        )}
      </Modal>
    </>
  );
};

export default PromptTestModal;
