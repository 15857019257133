import { useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Dropdown, Empty, Form, Input, Row, Space, Typography, message } from 'antd';
import { Controller, FormProvider, useFieldArray, useForm, useFormState, useWatch } from 'react-hook-form';
import { Link, useParams, useHistory } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import useSWR from 'swr';
import AIBlock from './components/AIBlock';
import AIApplicationBlock from './components/AIApplicationBlock';

import useFetch from '../../../hooks/useFetch';
import { keysRoutes } from '../../../lib/routes';

const filterAuthorizations = (authorizations) => {
  const aiAuthorizations = [];
  const aiApplicationAuthorizations = [];

  for (let i = 0; i < (authorizations || []).length; i++) {
    switch (authorizations[i].type) {
      case 'aiAuthorization':
        aiAuthorizations.push(authorizations[i].system);
      case 'aiApplication':
        aiApplicationAuthorizations.push(authorizations[i].application);
      default:
        continue;
    }
  }

  return { aiAuthorizations, aiApplicationAuthorizations };
};

const KeyUpdate = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const { id } = useParams();
  const methods = useForm();
  const history = useHistory();
  const { errors } = useFormState({ control: methods.control });
  const { fields, append, remove } = useFieldArray({ control: methods.control, name: 'authorizations' });
  const authorizations = useWatch({ control: methods.control, name: 'authorizations' });
  const filteredAuthorizations = useMemo(() => filterAuthorizations(authorizations), [authorizations]);
  const { put } = useFetch();
  const { data: key, isValidating } = useSWR(keysRoutes.default + '/' + id, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  useEffect(() => {
    if (key && key.data) {
      methods.setValue('name', key.data.name);
      methods.setValue(
        'authorizations',
        key.data.authorizations.map((item) => {
          if (item.type === 'aiAuthorization') {
            return { ...item, system: item.system };
          }

          return item;
        }),
      );
    }
  }, [key]);

  const handleOptionSelect = (value) => {
    append({ type: value });
  };

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const results = await put(keysRoutes.default + '/' + id, JSON.stringify(form));

    if (results.status === 200) {
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          methods.setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  const onSubmitAndLeave = async (form) => {
    setIsProcessing(true);

    const results = await put(keysRoutes.default + '/' + id, JSON.stringify(form));

    if (results.status === 200) {
      history.push('/cles');
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          methods.setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  return (
    <Card>
      <FormProvider {...{ ...methods }} disabled={!key || isValidating || isProcessing}>
        <Form noValidate layout="vertical">
          <Typography.Paragraph type="secondary">
            <blockquote style={{ marginTop: 0 }}>
              <ul>
                <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
              </ul>
            </blockquote>
          </Typography.Paragraph>
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <Form.Item
                label="Intitulé (Application, Site web...)"
                required
                validateStatus={errors?.name?.message && 'error'}
                help={errors?.name?.message}
              >
                <Controller
                  control={methods.control}
                  name="name"
                  render={({ field }) => <Input {...field} disabled={!key || isValidating || isProcessing} />}
                />
              </Form.Item>
              <Form.Item label="Autorisations">
                <Dropdown
                  trigger={['click']}
                  menu={{
                    items: [
                      {
                        key: 0,
                        label: 'Accès à un agent IA',
                        onClick: () => handleOptionSelect('aiAuthorization'),
                      },
                      {
                        key: 1,
                        label: 'Accès à une application IA',
                        onClick: () => handleOptionSelect('aiApplication'),
                      },
                    ],
                  }}
                >
                  <Form.Item
                    validateStatus={errors?.authorizations?.message && 'error'}
                    help={errors?.authorizations?.message}
                  >
                    <Button
                      block
                      danger={!!errors?.authorizations?.message}
                      disabled={!key || isValidating || isProcessing}
                    >
                      Ajouter une autorisation de la liste <DownOutlined />
                    </Button>
                  </Form.Item>
                </Dropdown>
              </Form.Item>
              {fields.length === 0 ? (
                <Empty />
              ) : (
                <Space direction="vertical" style={{ display: 'flex' }}>
                  {fields.map((field, index) => {
                    if (field.type === 'aiAuthorization') {
                      return (
                        <AIBlock
                          key={field.id}
                          index={index}
                          data={field}
                          onRemove={() => remove(index)}
                          authorizations={filteredAuthorizations.aiAuthorizations}
                        />
                      );
                    }

                    if (field.type === 'aiApplication') {
                      return (
                        <AIApplicationBlock
                          key={field.id}
                          index={index}
                          data={field}
                          onRemove={() => remove(index)}
                          authorizations={filteredAuthorizations.aiApplicationAuthorizations}
                        />
                      );
                    }

                    return null;
                  })}
                </Space>
              )}
            </Col>
          </Row>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 24 }}>
            <Button className="gray-text" disabled={!key || isValidating || isProcessing}>
              <Link to="/cles">Annuler</Link>
            </Button>
            <Space>
              <Button
                onClick={methods.handleSubmit(onSubmit)}
                htmlType="submit"
                loading={!key || isValidating || isProcessing}
              >
                Enregistrer
              </Button>
              <Button
                onClick={methods.handleSubmit(onSubmitAndLeave)}
                type="primary"
                loading={!key || isValidating || isProcessing}
              >
                Enregistrer et quitter
              </Button>
            </Space>
          </div>
        </Form>
      </FormProvider>
    </Card>
  );
};

export default KeyUpdate;
