import { useMemo } from 'react';
import { Card, Image, Space, Typography, Tooltip, Row, Tag } from 'antd';
import { Link } from 'react-router-dom';
import { LockOutlined } from '@ant-design/icons';

import { getImageUrl } from '../../../../lib/cloudinary';

const ApplicationAiSelectionCard = ({ title, href, description, image, onClick, disabled, versions, aiTagColors }) => {
  const imageUrl = useMemo(() => (image ? getImageUrl(image) : null), [image]);

  return (
    <Link to={href}>
      <Card
        className="aiAppSelection"
        onClick={onClick}
        size="small"
        style={{ height: '100%' }}
        bodyStyle={{ height: '100%' }}
      >
        <div style={{ display: 'flex', alignItems: 'start', columnGap: 12, height: '100%' }}>
          {!!imageUrl && (
            <Image
              preview={false}
              width={50}
              height={50}
              style={{
                objectFit: 'cover',
                width: 50,
                height: 50,
                minHeight: 50,
                maxHeight: 50,
                minWidth: 50,
                maxWidth: 50,
              }}
              src={imageUrl}
            />
          )}
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: 6, height: '100%' }}>
            <Space>
              {disabled && (
                <Tooltip title="Cette application est désactivée" destroyTooltipOnHide={{ keepParent: false }}>
                  <LockOutlined style={{ color: 'red' }} />
                </Tooltip>
              )}
              <Typography.Text>{title}</Typography.Text>
            </Space>
            <Typography.Text type="secondary">{description}</Typography.Text>
            {versions?.length !== 0 && (
              <Row style={{ marginTop: 'auto' }}>
                {versions?.map((version) => (
                  <Tooltip title={version?.version?.value}>
                    <Tag color={aiTagColors[version.version.ai]}>{version?.version?.name}</Tag>
                  </Tooltip>
                ))}
              </Row>
            )}
          </div>
        </div>
      </Card>
    </Link>
  );
};

export default ApplicationAiSelectionCard;
