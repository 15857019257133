import { message } from 'antd';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { formatScheduleItem } from './format-schedule-item';

const INTER_COLOR = 'ffea4335';
const INTRA_COLOR = 'ff108ee9';
const TUTORAT_COLOR = 'ff5b8c00';

const goalColors = {
  Opérationnel: {
    fgColor: 'ff315397',
    color: 'ffffffff',
  },
  Perfectionnement: {
    fgColor: 'ffffed00',
    color: '00000000',
  },
  Complet: {
    fgColor: 'ff34a853',
    color: 'ffffffff',
  },
  Spécialisation: {
    fgColor: 'ffed6d63',
    color: 'ffffffff',
  },
  'Remise à niveau': {
    fgColor: 'ff9254de',
    color: 'ffffffff',
  },
  'Entrée/Sortie': {
    fgColor: 'ff13c2c2',
    color: 'ffffffff',
  },
};

const START_COLUMN = 'B';
const END_COLUMN = 'I';

const alignLeft = {
  vertical: 'middle',
  horizontal: 'left',
};

const alignCenter = {
  vertical: 'middle',
  horizontal: 'center',
};

const alignRight = {
  vertical: 'middle',
  horizontal: 'right',
};

const titleStyle = (color) => ({
  font: {
    size: 12,
    bold: true,
    color: {
      argb: 'ffffffff',
    },
  },
  background: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: color,
    },
  },
});

const headerStyle = (color) => ({
  font: {
    bold: true,
    size: 10,
    color: {
      argb: 'ff315397',
    },
  },
  background: {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'fff3f3f3',
    },
  },
});

const borders = ({ type, column, color, last }) => {
  const customBorder = { style: 'medium', color: { argb: color } };
  const borders = {
    top: { style: 'thin', color: { argb: '00000000' } },
    bottom: { style: 'thin', color: { argb: '00000000' } },
    left: { style: 'thin', color: { argb: '00000000' } },
    right: { style: 'thin', color: { argb: '00000000' } },
  };

  if (type === 'HEADER') {
    borders.top = customBorder;

    if (column === START_COLUMN) {
      borders.left = customBorder;
    }

    if (column === END_COLUMN) {
      borders.right = customBorder;
    }
  }

  if (type === 'ROW') {
    if (column === START_COLUMN) {
      borders.left = customBorder;
    }

    if (column === END_COLUMN) {
      borders.right = customBorder;
    }

    if (last) {
      borders.bottom = customBorder;
    }
  }

  if (type === 'DIVIDER') {
    borders.left = customBorder;
    borders.right = customBorder;
  }

  return borders;
};

/* Prépare les données pour une génération plus simple du fichier EXCEL */
const setupData = (pageData, config) => {
  /* Initialisation des feuilles */
  /* prettier-ignore */
  const sheets = [
    { name: 'inter_CAO', mode: 'Inter', value: 'architecture-et-batiment', color: INTER_COLOR, formations: [] },
    { name: 'inter_PAO', mode: 'Inter', value: 'arts-appliques', color: INTER_COLOR, formations: [] },
    { name: 'inter_bureautique', mode: 'Inter', value: 'numerique-et-bureautique', color: INTER_COLOR, formations: [] },
    { name: 'inter_industrie', mode: 'Inter', value: 'industrie', color: INTER_COLOR, formations: [] },
    { name: 'inter_web', mode: 'Inter', value: 'web-et-logiciels', color: INTER_COLOR, formations: [] },
    { name: 'intra_CAO', mode: 'Intra', value: 'architecture-et-batiment', color: INTRA_COLOR, formations: [] },
    { name: 'intra_PAO', mode: 'Intra', value: 'arts-appliques', color: INTRA_COLOR, formations: [] },
    { name: 'intra_bureautique', mode: 'Intra', value: 'numerique-et-bureautique', color: INTRA_COLOR, formations: [] },
    { name: 'intra_industrie', mode: 'Intra', value: 'industrie', color: INTRA_COLOR, formations: [] },
    { name: 'intra_web', mode: 'Intra', value: 'web-et-logiciels', color: INTRA_COLOR, formations: [] },
    { name: 'tutorat_CAO', mode: 'Tutorat', value: 'architecture-et-batiment', color: TUTORAT_COLOR, formations: [] },
    { name: 'tutorat_PAO', mode: 'Tutorat', value: 'arts-appliques', color: TUTORAT_COLOR, formations: [] },
    { name: 'tutorat_bureautique', mode: 'Tutorat', value: 'numerique-et-bureautique', color: TUTORAT_COLOR, formations: [] },
    { name: 'tutorat_industrie', mode: 'Tutorat', value: 'industrie', color: TUTORAT_COLOR, formations: [] },
    { name: 'tutorat_web', mode: 'Tutorat', value: 'web-et-logiciels', color: TUTORAT_COLOR, formations: [] },
  ];

  /* Boucle sur toutes les formations configurées */
  for (let i = 0; i < pageData.formations.length; i++) {
    /* Recherche la formation correspondante */
    const formationMatch = config.formations.find((item) => item._id === pageData.formations[i].formation);
    /* Recherche la catégorie correspondante */
    const categoryMatch = pageData.categories.find((item) => item._id === pageData.formations[i].category);

    /* Si la catégorie et la formation sont trouvées */
    if (formationMatch && categoryMatch) {
      /* Boucle sur les modes Inter/Intra/Tutorat */
      Object.entries(pageData.formations[i].modes).forEach(([key, value]) => {
        /* Si le mode n'est pas désactivé et que la programmation n'est pas vide */
        if (value?.schedules?.length !== 0) {
          /* Recherche la feuille dans laquelle envoyer la programmation */
          const sheetIndex = sheets.findIndex((item) => item.mode === key && item.value === categoryMatch.value);

          /* Si une feuille correspondante est trouvée */
          if (sheetIndex !== -1) {
            /* Recherche si la formation est déjà présente dans la feuille */
            const sheetFormationIndex = sheets[sheetIndex].formations.findIndex(
              (item) => item._id === formationMatch._id,
            );

            /* Si la formation n'est pas présente dans la feuille */
            if (sheetFormationIndex === -1) {
              /* Ajoute la nouvelle formation et sa programmation dans la feuille */
              sheets[sheetIndex].formations.push({
                _id: formationMatch._id,
                title: formationMatch.title,
                schedules: (value?.schedules || []).map((item) => formatScheduleItem(item, config)),
              });
            } else {
              /* Ajoute la programmation à la formation trouvée dans la feuille */
              sheets[sheetIndex].formations[sheetFormationIndex].schedules = sheets[sheetIndex].formations[
                sheetFormationIndex
              ].schedules.concat((value?.schedules || []).map((item) => formatScheduleItem(item, config)));
            }
          }
        }
      });
    }
  }

  return sheets.map((sheet) => ({
    ...sheet,
    formations: sheet.formations.sort((a, b) => a.title.localeCompare(b.title, 'fr')),
  }));
};

const exportFormations = async (pageData, config) => {
  const workbook = new ExcelJS.Workbook();
  const sheets = setupData(pageData, config);

  for (let i = 0; i < sheets.length; i++) {
    const color = sheets[i].color;
    const sheet = workbook.addWorksheet(sheets[i].name);

    /* Configuration de la feuille */
    sheet.views = [{ showGridLines: false }];
    sheet.properties.tabColor = { argb: color };

    sheet.getColumn('A').width = 5;
    sheet.getColumn('B').width = 40;
    sheet.getColumn('C').width = 40;
    sheet.getColumn('D').width = 20;
    sheet.getColumn('E').width = 25;
    sheet.getColumn('F').width = 25;
    sheet.getColumn('G').width = 25;
    sheet.getColumn('H').width = 25;
    sheet.getColumn('I').width = 40;

    let nextIndex = 1;

    for (let fi = 0; fi < sheets[i].formations.length; fi++) {
      const startIndex = nextIndex;
      const formation = sheets[i].formations[fi];

      /* Titre du bloc (formation + mode) */
      sheet.getRow(nextIndex).height = 25;

      sheet.mergeCells(`${START_COLUMN}${nextIndex}:H${nextIndex}`);
      sheet.getCell(`${START_COLUMN}${nextIndex}`).value = `Formation ${formation.title} - En ${sheets[i].mode}`;
      sheet.getCell(`${START_COLUMN}${nextIndex}`).alignment = alignLeft;
      sheet.getCell(`${START_COLUMN}${nextIndex}`).font = titleStyle(color).font;
      sheet.getCell(`${START_COLUMN}${nextIndex}`).fill = titleStyle(color).background;

      sheet.getCell(`${END_COLUMN}${nextIndex}`).value = 'www.arinfo.fr';
      sheet.getCell(`${END_COLUMN}${nextIndex}`).alignment = alignRight;
      sheet.getCell(`${END_COLUMN}${nextIndex}`).font = titleStyle(color).font;
      sheet.getCell(`${END_COLUMN}${nextIndex}`).fill = titleStyle(color).background;

      nextIndex++;

      /* En tête du tableau */
      sheet.getRow(nextIndex).height = 25;

      sheet.getCell(`B${nextIndex}`).value = 'Dates';
      sheet.getCell(`B${nextIndex}`).alignment = alignCenter;
      sheet.getCell(`B${nextIndex}`).border = borders({ type: 'HEADER', column: 'B', color });
      sheet.getCell(`B${nextIndex}`).font = headerStyle(color).font;
      sheet.getCell(`B${nextIndex}`).fill = headerStyle(color).background;

      sheet.getCell(`C${nextIndex}`).value = 'Objectif';
      sheet.getCell(`C${nextIndex}`).alignment = alignCenter;
      sheet.getCell(`C${nextIndex}`).border = borders({ type: 'HEADER', column: 'C', color });
      sheet.getCell(`C${nextIndex}`).font = headerStyle(color).font;
      sheet.getCell(`C${nextIndex}`).fill = headerStyle(color).background;

      sheet.getCell(`D${nextIndex}`).value = 'Durée';
      sheet.getCell(`D${nextIndex}`).alignment = alignCenter;
      sheet.getCell(`D${nextIndex}`).border = borders({ type: 'HEADER', column: 'D', color });
      sheet.getCell(`D${nextIndex}`).font = headerStyle(color).font;
      sheet.getCell(`D${nextIndex}`).fill = headerStyle(color).background;

      sheet.getCell(`E${nextIndex}`).value = 'Lieu';
      sheet.getCell(`E${nextIndex}`).alignment = alignCenter;
      sheet.getCell(`E${nextIndex}`).border = borders({ type: 'HEADER', column: 'E', color });
      sheet.getCell(`E${nextIndex}`).font = headerStyle(color).font;
      sheet.getCell(`E${nextIndex}`).fill = headerStyle(color).background;

      sheet.getCell(`F${nextIndex}`).value = 'Complet ?';
      sheet.getCell(`F${nextIndex}`).alignment = alignCenter;
      sheet.getCell(`F${nextIndex}`).border = borders({ type: 'HEADER', column: 'F', color });
      sheet.getCell(`F${nextIndex}`).font = headerStyle(color).font;
      sheet.getCell(`F${nextIndex}`).fill = headerStyle(color).background;

      sheet.getCell(`G${nextIndex}`).value = 'Tarif (en TTC)';
      sheet.getCell(`G${nextIndex}`).alignment = alignCenter;
      sheet.getCell(`G${nextIndex}`).border = borders({ type: 'HEADER', column: 'G', color });
      sheet.getCell(`G${nextIndex}`).font = headerStyle(color).font;
      sheet.getCell(`G${nextIndex}`).fill = headerStyle(color).background;

      sheet.getCell(`H${nextIndex}`).value = 'Formateur';
      sheet.getCell(`H${nextIndex}`).alignment = alignCenter;
      sheet.getCell(`H${nextIndex}`).border = borders({ type: 'HEADER', column: 'H', color });
      sheet.getCell(`H${nextIndex}`).font = headerStyle(color).font;
      sheet.getCell(`H${nextIndex}`).fill = headerStyle(color).background;

      sheet.getCell(`I${nextIndex}`).value = 'Note';
      sheet.getCell(`I${nextIndex}`).alignment = alignCenter;
      sheet.getCell(`I${nextIndex}`).border = borders({ type: 'HEADER', column: 'I', color });
      sheet.getCell(`I${nextIndex}`).font = headerStyle(color).font;
      sheet.getCell(`I${nextIndex}`).fill = headerStyle(color).background;

      nextIndex++;

      /* Rangées du tableau */
      for (let si = 0; si < formation.schedules.length; si++) {
        const isLastRow = si === formation.schedules.length - 1;
        const schedule = formation.schedules[si];
        const goalConfig = goalColors[schedule.goal.type];

        sheet.getCell(`B${nextIndex}`).value = schedule.date;
        sheet.getCell(`B${nextIndex}`).alignment = alignLeft;
        sheet.getCell(`B${nextIndex}`).border = borders({ type: 'ROW', column: 'B', color, last: isLastRow });
        sheet.getCell(`B${nextIndex}`).font = { bold: true, size: 10 };

        if (schedule.goal.type === 'Spécialisation') {
          sheet.getCell(`C${nextIndex}`).value = `${schedule.goal.type} ${schedule.goal.specialization}`;
        } else {
          sheet.getCell(`C${nextIndex}`).value = schedule.goal.type;
        }
        sheet.getCell(`C${nextIndex}`).alignment = alignCenter;
        sheet.getCell(`C${nextIndex}`).border = borders({ type: 'ROW', column: 'C', color, last: isLastRow });
        sheet.getCell(`C${nextIndex}`).font = { bold: true, size: 10, color: { argb: goalConfig.color } };
        sheet.getCell(`C${nextIndex}`).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: goalConfig.fgColor },
        };

        sheet.getCell(`D${nextIndex}`).value = schedule.duration;
        sheet.getCell(`D${nextIndex}`).alignment = alignCenter;
        sheet.getCell(`D${nextIndex}`).border = borders({ type: 'ROW', column: 'D', color, last: isLastRow });
        sheet.getCell(`D${nextIndex}`).font = { bold: true, size: 10 };

        sheet.getCell(`E${nextIndex}`).value = schedule.location;
        sheet.getCell(`E${nextIndex}`).alignment = alignCenter;
        sheet.getCell(`E${nextIndex}`).border = borders({ type: 'ROW', column: 'E', color, last: isLastRow });
        sheet.getCell(`E${nextIndex}`).font = { bold: true, size: 10 };

        sheet.getCell(`F${nextIndex}`).value = !!schedule.originalItem.places?.full ? 'Oui' : 'Non';
        sheet.getCell(`F${nextIndex}`).alignment = alignCenter;
        sheet.getCell(`F${nextIndex}`).border = borders({ type: 'ROW', column: 'F', color, last: isLastRow });
        sheet.getCell(`F${nextIndex}`).font = { bold: true, size: 10 };

        if (schedule.price.type === 'startAt') {
          if (schedule.price?.mode === 'day') {
            sheet.getCell(`G${nextIndex}`).value = `À partir de ${schedule.price.includingTaxes} € / jour`;
          } else {
            sheet.getCell(`G${nextIndex}`).value = `À partir de ${schedule.price.includingTaxes} €`;
          }
        } else {
          if (schedule.price?.mode === 'day') {
            sheet.getCell(`G${nextIndex}`).value = `${schedule.price.includingTaxes} € / jour`;
          } else {
            sheet.getCell(`G${nextIndex}`).value = `${schedule.price.includingTaxes} €`;
          }
        }
        sheet.getCell(`G${nextIndex}`).alignment = alignCenter;
        sheet.getCell(`G${nextIndex}`).border = borders({ type: 'ROW', column: 'G', color, last: isLastRow });
        sheet.getCell(`G${nextIndex}`).font = { bold: true, size: 10 };

        sheet.getCell(`H${nextIndex}`).value = schedule?.former ? schedule.former : '';
        sheet.getCell(`H${nextIndex}`).alignment = alignLeft;
        sheet.getCell(`H${nextIndex}`).border = borders({ type: 'ROW', column: 'H', color, last: isLastRow });
        sheet.getCell(`H${nextIndex}`).font = { bold: true, size: 10 };

        sheet.getCell(`I${nextIndex}`).value = schedule?.note ? schedule.note : '';
        sheet.getCell(`I${nextIndex}`).alignment = { ...alignLeft, wrapText: true };
        sheet.getCell(`I${nextIndex}`).border = borders({ type: 'ROW', column: 'I', color, last: isLastRow });
        sheet.getCell(`I${nextIndex}`).font = { bold: true, size: 10 };

        if (!isLastRow) {
          nextIndex++;

          sheet.getRow(nextIndex).height = 5;

          sheet.mergeCells(`B${nextIndex}:F${nextIndex}`);
          sheet.getCell(`F${nextIndex}`).border = borders({ type: 'DIVIDER', color });

          nextIndex++;
        }
      }

      sheet.mergeCells(`A${startIndex}:A${nextIndex}`);
      sheet.getCell(`A${startIndex}`).fill = titleStyle(color).background;

      nextIndex += 2;
    }
  }

  try {
    const buffer = await workbook.xlsx.writeBuffer();
    const options = { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' };

    saveAs(new Blob([buffer], options), 'inter-intra.xlsx');
  } catch (error) {
    message.error(error.message);
  }
};

export default exportFormations;
