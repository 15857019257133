import { memo, useMemo, useState } from 'react';
import { Cloudinary } from '@cloudinary/url-gen';
import { Button, Col, Row, Space, Typography, Image, Upload, message, Empty } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import useFetch from '../../../../../hooks/useFetch';
import useLoading from '../../../../../hooks/useLoading';
import useInterval from '../../../../../hooks/useInterval';
import CoverConfigModal from './CoverConfigModal';
import Formations from './Formations';
import Icons from './Icons';
import { useAuth } from '../../../../../authContext';
import { checkAuthorization } from '../../../../../shared/utils';

const PAGE_URL = `${process.env.REACT_APP_BASE_URL_API_URL}/next/private/arinfo/pages/inter-intra`;
const CLOUDINARY_URL = `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`;
const MEDIAS_URL = `${process.env.REACT_APP_BASE_URL_API_URL}/next/private/global/medias`;

const cld = new Cloudinary({
  cloud: { cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME },
  url: { secure: true },
});

const Category = memo(({ data, onChange }) => {
  const [coverAlertTimeout, setCoverAlertTimeout] = useState(0);
  const { isLoading, startLoading, endLoading } = useLoading();
  const { remove, post, patch } = useFetch();
  const { user } = useAuth();
  const image = useMemo(() => (data?.cover?.image ? cld.image(data.cover.image)?.toURL() : null), [data]);

  useInterval(() => {
    if (coverAlertTimeout - 1 < 0) {
      return null;
    }

    return setCoverAlertTimeout(coverAlertTimeout - 1);
  }, 1000);

  /**
   * Récupère une signature de téléversement pour Cloudinary
   * @param {File} file
   * @returns La signature générée avec ses métadonnées
   */
  const getSignature = async (file) => {
    const url = `${process.env.REACT_APP_BASE_URL_API_URL}/v2/medias/videos/signature`;
    const response = await post(url);

    if (response.status === 200) {
      const { timestamp, signature } = response.data;

      return {
        file: file,
        api_key: process.env.REACT_APP_CLOUDINARY_API_KEY,
        timestamp,
        signature,
      };
    }
  };

  /**
   * Vérifie si le fichier est une image et s'il ne dépasse pas la limite de 5 Mo
   * @param {File} file Fichier à téléverser
   * @param {object} [config] Configuration de la validation
   * @param {number} [config] Taille max du fichier autorisée
   * @returns `false` si le fichier ne rempli pas les conditions
   */
  const validateFile = async (file, config) => {
    const fileSize = file.size / 1024 / 1024;
    const maxUploadSize = config?.maxSize || 5;

    /**
     * Vérifie si le fichier ne dépasse pas la limite de 5 Mo
     */
    if (fileSize > maxUploadSize) {
      message.error(`Fichier trop lourd, maximum autorisé : ${maxUploadSize} Mo`);
      return false;
    }

    /**
     * Vérifie si le fichier est une image
     */
    if (!file.type.startsWith('image/')) {
      message.error('Fichier invalide, autorisé : Image');
      return false;
    }
  };

  /**
   * Téléverse le fichier
   * @param {any} file
   * @returns Le callback a exécuté après un téléversement réussi
   */
  const handleUpload = (file, loadingKey, callback) => {
    if (file.status === 'uploading') {
      /**
       * Passe le statut de la clé en chargement
       */
      startLoading(loadingKey);
    } else {
      /**
       * Si une erreur survient pendant le téléversement, le statut de chargement est retiré
       */
      if (file.status !== 'done') {
        endLoading(loadingKey);
      }
    }

    /**
     * Si le téléversement est réussi, appel le callback post-téléversement
     */
    if (file.status === 'done' && file?.response) {
      return callback(file.response);
    }
  };

  /**
   * Sauvegarde l'image de couverture téléversée dans la DB
   * @param {any} file Données de l'image téléversée reçues par Cloudinary
   */
  const onCoverUpload = async (file) => {
    // Si une image de couverture est déjà présente
    if (data?.cover) {
      /**
       * Supprime l'image de Cloudinary
       */
      await remove(`${MEDIAS_URL}/${data.cover}`, false);
    }

    /**
     * Sauvegarde l'image de couverture dans la DB
     */
    const body = JSON.stringify({ cover: file.public_id });
    const response = await patch(`${PAGE_URL}/categories/${data._id}/cover`, body);

    /**
     * Met à jour l'affichage
     */
    if (response.status === 200) {
      setCoverAlertTimeout(5);
      onChange();
    }

    endLoading('cover_upload');
  };

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <Row gutter={[12, 12]} wrap={false}>
        <Col flex="none">
          <Space.Compact direction="vertical" style={{ display: 'flex' }}>
            {image ? (
              <Image
                width={150}
                height={100}
                style={{
                  objectFit: 'cover',
                  borderTop: '1px solid #d9d9d9',
                  borderLeft: '1px solid #d9d9d9',
                  borderRight: '1px solid #d9d9d9',
                  borderTopLeftRadius: 2,
                  borderTopRightRadius: 2,
                  overflow: 'hidden',
                }}
                src={image}
              />
            ) : (
              <div
                style={{
                  height: 100,
                  width: 150,
                  borderTop: '1px solid #d9d9d9',
                  borderLeft: '1px solid #d9d9d9',
                  borderRight: '1px solid #d9d9d9',
                  borderTopLeftRadius: 2,
                  borderTopRightRadius: 2,
                  overflow: 'hidden',
                }}
              >
                <Empty
                  description={false}
                  style={{ height: '100%', width: '100%' }}
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              </div>
            )}
            {checkAuthorization(user, 'arinfo', 'interFormations', 'update-cover') && (
              <Button.Group>
                <CoverConfigModal
                  {...{ data }}
                  alertTimeout={coverAlertTimeout}
                  onFinish={onChange}
                  disabled={!image}
                />
                <Upload
                  name="cover"
                  className="ant-upload-block"
                  action={CLOUDINARY_URL}
                  showUploadList={false}
                  multiple={false}
                  accept="image/*"
                  disabled={isLoading('cover_upload')}
                  data={getSignature}
                  beforeUpload={validateFile}
                  onChange={({ file }) => handleUpload(file, 'cover_upload', onCoverUpload)}
                >
                  <Button block icon={<UploadOutlined />} loading={isLoading('cover_upload')}>
                    {image ? 'Remplacer' : 'Téléverser'}
                  </Button>
                </Upload>
              </Button.Group>
            )}
          </Space.Compact>
        </Col>
        <Col flex="auto">
          <Space direction="vertical" style={{ display: 'flex' }}>
            <Typography.Title level={5} strong style={{ margin: 0 }}>
              {data.name}
            </Typography.Title>
            <Icons {...{ data, getSignature, validateFile }} onFinish={onChange} />
          </Space>
        </Col>
      </Row>
      <Formations category={data} />
    </Space>
  );
});

Category.displayName = 'Category';
export default Category;
