import { Button, Card, Col, Collapse, List, message, Row, Select, Skeleton, Space, Tooltip, Typography } from 'antd';
import { memo, useMemo, useState } from 'react';
import useSWR from 'swr';
import { format as dateFormat, subDays, isAfter, formatDistanceToNowStrict } from 'date-fns';
import fr from 'date-fns/locale/fr';
import { format } from '@i-maginexr/js';
import {
  CheckCircleFilled,
  ClockCircleFilled,
  CloseCircleFilled,
  EnvironmentOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
  InfoCircleOutlined,
  MailOutlined,
  PhoneOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import RegistrationsSearch from './RegistrationsSearch';
import RegistrationsNotice from './RegistrationsNotice';
import useFetch from '../../../../../hooks/useFetch';
import exportRegistration from '../lib/export-registration';
import exportRegistrations from '../lib/export-registrations';
import RegistrationsInfo from './RegistrationsInfo';
import { useAuth } from '../../../../../authContext';
import { checkAuthorization } from '../../../../../shared/utils';

const FORMATIONS_URL = `${process.env.REACT_APP_BASE_URL_API_URL}/next/private/arinfo/trainings`;
const PAGE_URL = `${process.env.REACT_APP_BASE_URL_API_URL}/next/private/arinfo/pages/inter-intra`;
const REGISTRATIONS_URL = `${PAGE_URL}/registrations`;

const SWROptions = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

const getCreatedDate = (date) => {
  date = new Date(date);

  // Si la date de création est dans les 7 derniers jours
  if (isAfter(date, subDays(new Date(), 7))) {
    return format.capitalize(formatDistanceToNowStrict(date, { addSuffix: true, locale: fr }));
  }

  // Si la date de création est antérieur à 7 jours
  return dateFormat(new Date(date), 'dd/MM/yyyy');
};

const setupData = (registrations, page, formations) => {
  if (!registrations?.data || !page?.data || !formations?.data) {
    return [];
  }

  return registrations.data.flatMap((item, index) => {
    /* Recherche la catégorie */
    const category = page.data.content.categories.find((category) => {
      return category._id === item.config.category;
    });

    /* Recherche la formation */
    let formation = (page.data.content?.formations || []).find((formation) => {
      return formation._id === item.config.formation && formation.category === item.config.category;
    });

    if (formation) {
      const origin = formations.data.find((item) => item._id === formation.formation);

      if (!origin) {
        formation = null;
      } else {
        formation = {
          ...formation,
          formation: origin,
        };
      }
    }

    /* Recherche la date */
    let date = null;

    for (const mode of Object.keys(formation?.modes || {})) {
      const match = formation.modes[mode].schedules.find((schedule) => schedule._id === item.config.date);

      if (match) {
        date = {
          ...match,
          mode,
        };
      }
    }

    /* S'il manque des données, la pré-inscription n'est pas affichée */
    if (!category || !formation || !date) {
      return [];
    }

    return {
      ...item,
      config: {
        category,
        formation,
        date,
      },
    };
  });
};

export const renderDays = (days) => {
  const groups = days.reduce((acc, curr) => {
    const group = dateFormat(new Date(curr), 'MM/yyyy');
    const day = dateFormat(new Date(curr), 'dd');

    if (group in acc) {
      acc[group].push(day);
    } else {
      acc[group] = [day];
    }

    return acc;
  }, {});

  return Object.entries(groups || {})
    .sort((a, b) => {
      const aDate = a?.[0]?.split('/');
      const bDate = b?.[0]?.split('/');

      return new Date(aDate[1], aDate[0] - 1, 1) - new Date(bDate[1], bDate[0] - 1, 1);
    })
    .map(([key, value], index) => {
      const [month, year] = key.split('/');
      const monthName = fr.localize.month(month - 1, { width: 'abbreviated' });

      return `${value
        .sort((a, b) => Number(a) - Number(b))
        .join(', ')
        .replace(/, ([^,]*)$/, ' et $1')} ${monthName} ${year}${
        index < Object.keys(groups || {}).length - 1 ? ' | ' : ''
      }`;
    })
    .join('');
};

const bgColors = {
  Confirmée: '#f6ffed',
  'Non-traitée': '#e6f4ff',
  Annulée: '#fff1f0',
};

// TODO : Gérer les éléments introuvables (formation, catégorie, date...)
const RegistrationItem = memo(({ item, onChange }) => {
  const [processing, setProcessing] = useState(false);
  const createdAt = useMemo(() => getCreatedDate(item.createdAt), [item]);
  const { firstName, lastName } = format.toFullName(item.form.contact.firstName, item.form.contact.lastName);
  const { patch } = useFetch();
  const { user } = useAuth();

  /* Met à jour le statut d'une pré-inscription */
  const onStatusChange = async (value) => {
    setProcessing(true);

    const results = await patch(`${REGISTRATIONS_URL}/${item._id}/status`, JSON.stringify({ status: value }));

    if (results.status === 200) {
      setProcessing(false);
      message.success('Statut modifié');
      return onChange();
    } else {
      setProcessing(false);

      if (results.message) {
        message.error(results.message);
      }
    }
  };

  return (
    <List.Item className="custom-list-item-inter-intra" style={{ padding: 0 }}>
      <div style={{ width: '100%', border: '1px solid #d9d9d9', borderRadius: 4, overflow: 'hidden' }}>
        <div
          style={{
            width: '100%',
            backgroundColor: bgColors[item.status] || '#fafafa',
            padding: '8px',
            borderBottom: '1px solid #d9d9d9',
            transition: 'background-color 0.2s',
          }}
        >
          <Row wrap={false} gutter={[12, 12]}>
            <Col flex="auto">
              <Space size="small" direction="vertical" style={{ display: 'flex' }}>
                <Typography.Text>{item.config.category.name}</Typography.Text>
                <Tooltip title="Plus d'informations">
                  <RegistrationsInfo {...{ item }}>
                    {item.config.formation.formation.title}&nbsp;-&nbsp;
                    {item.config.date.mode}&nbsp;-&nbsp;
                    {item.config.date.type === 'Récurrent' && `Tous les ${item.config.date.config.day.toLowerCase()}s`}
                    {item.config.date.type === 'Période' &&
                      `Du ${dateFormat(new Date(item.config.date.config.range[0]), 'dd/MM/yyyy')} au ${dateFormat(
                        new Date(item.config.date.config.range[1]),
                        'dd/MM/yyyy',
                      )}`}
                    {item.config.date.type === 'Jours datés' && `${renderDays(item.config.date.config.days)}`}
                    {item.config.date.type === 'Personnalisé' && `${item.config.date.config.custom}`}
                  </RegistrationsInfo>
                </Tooltip>
              </Space>
            </Col>
            <Col flex="none">
              <Space>
                <Tooltip title={dateFormat(new Date(item.createdAt), 'dd/MM/yyyy HH:mm')}>
                  <Typography.Text type="secondary">{createdAt}</Typography.Text>
                </Tooltip>
                {checkAuthorization(user, 'arinfo', 'interFormations', 'export-registrations-pdf') && (
                  <Button size="small" icon={<FilePdfOutlined />} onClick={() => exportRegistration(item)}>
                    Télécharger
                  </Button>
                )}
                {}
                <Select
                  size="small"
                  value={item.status}
                  loading={processing}
                  disabled={processing}
                  onChange={(value) => {
                    if (checkAuthorization(user, 'arinfo', 'interFormations', 'update-registration-status')) {
                      onStatusChange(value);
                    } else {
                      message.error("Vous n'avez pas la permission de modifier cet élément.");
                    }
                  }}
                  options={[
                    {
                      label: (
                        <Space>
                          <ClockCircleFilled style={{ color: '#1890ff' }} />
                          Non-traitée
                        </Space>
                      ),
                      value: 'Non-traitée',
                    },
                    {
                      label: (
                        <Space>
                          <CheckCircleFilled style={{ color: '#52c41a' }} />
                          Confirmée
                        </Space>
                      ),
                      value: 'Confirmée',
                    },
                    {
                      label: (
                        <Space>
                          <CloseCircleFilled style={{ color: '#ff4d4f' }} />
                          Annulée
                        </Space>
                      ),
                      value: 'Annulée',
                    },
                  ]}
                />
              </Space>
            </Col>
          </Row>
        </div>
        <div style={{ width: '100%', padding: '8px' }}>
          <Space direction="vertical" style={{ display: 'flex', width: '100%' }}>
            <div style={{ display: 'flex', height: '100%' }}>
              <div style={{ flex: 1 }}>
                <Typography.Title level={5} style={{ marginBottom: 0 }}>
                  Combien de personnes ?
                </Typography.Title>
                <Space direction="vertical" style={{ display: 'flex' }}>
                  <Space>
                    <TeamOutlined />
                    <Typography.Text>
                      {item.form.persons} personne{item.form.persons > 1 ? 's' : ''}
                    </Typography.Text>
                  </Space>
                </Space>
              </div>
              <div style={{ display: 'flex', flex: '0 0 1px', backgroundColor: '#d9d9d9', margin: '0 10px' }} />
              <div style={{ flex: 1 }}>
                <Typography.Title level={5} style={{ marginBottom: 0 }}>
                  Contact
                </Typography.Title>
                <Space direction="vertical" style={{ display: 'flex' }}>
                  <Space>
                    <UserOutlined />
                    <Typography.Text>{`${lastName} ${firstName}`}</Typography.Text>
                  </Space>
                </Space>
              </div>
              <div style={{ display: 'flex', flex: '0 0 1px', backgroundColor: '#d9d9d9', margin: '0 10px' }} />
              <div style={{ flex: 1 }}>
                <Typography.Title level={5} style={{ marginBottom: 0 }}>
                  Entreprise
                </Typography.Title>
                <Space direction="vertical" style={{ display: 'flex' }}>
                  <Space>
                    <InfoCircleOutlined />
                    <Typography.Text>{item.form.company.name.toUpperCase()}</Typography.Text>
                  </Space>
                  <Space>
                    <MailOutlined />
                    <Typography.Text copyable>{item.form.company.email}</Typography.Text>
                  </Space>
                  <Space>
                    <PhoneOutlined />
                    <Typography.Text copyable>{item.form.company.phone}</Typography.Text>
                  </Space>
                  <Space>
                    <EnvironmentOutlined />
                    <Typography.Text>{item.form.company.location}</Typography.Text>
                  </Space>
                </Space>
              </div>
            </div>
            <Collapse>
              <Collapse.Panel
                header="Description du projet"
                collapsible={!item.form?.message || item?.form?.message === '' ? 'disabled' : null}
              >
                <Typography.Text style={{ whiteSpace: 'pre-wrap' }}>{item.form.message}</Typography.Text>
              </Collapse.Panel>
            </Collapse>
          </Space>
        </div>
      </div>
    </List.Item>
  );
});

RegistrationItem.displayName = 'RegistrationItem';

const Registrations = () => {
  const [results, setResults] = useState([]);
  const { user } = useAuth();
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const { data: formations, isValidating: isFormationsValidating } = useSWR(FORMATIONS_URL, SWROptions);
  const { data: page, isValidating: isPageValidating } = useSWR(PAGE_URL, SWROptions);
  const {
    data: registrations,
    isValidating: isRegistrationsValidating,
    mutate,
  } = useSWR(REGISTRATIONS_URL, SWROptions);
  const data = useMemo(() => setupData(registrations, page, formations), [page, registrations, formations]);
  const isLoading =
    (isFormationsValidating && !formations?.data) ||
    (isPageValidating && !page?.data) ||
    (isRegistrationsValidating && !registrations?.data);

  return (
    <Card
      size="small"
      title={<Typography.Title level={5}>Pré-inscriptions</Typography.Title>}
      extra={
        <Space>
          {checkAuthorization(user, 'arinfo', 'interFormations', 'export-registrations-excel') && (
            <Button
              size="small"
              icon={<FileExcelOutlined />}
              onClick={() => exportRegistrations(results.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)))}
              disabled={!results || (results || []).length === 0 || isLoading}
            >
              Exporter
            </Button>
          )}
          <RegistrationsNotice />
        </Space>
      }
    >
      <RegistrationsSearch
        {...{ data, results }}
        page={page?.data || {}}
        formations={formations?.data || []}
        loading={isLoading}
        onChange={(results) => {
          setPagination((rest) => ({ ...rest, page: 1 }));
          setResults(results);
        }}
      />
      {isLoading ? (
        <Space size="large" direction="vertical" style={{ display: 'flex' }}>
          <Skeleton.Button style={{ height: 278.8 }} block active />
          <Skeleton.Button style={{ height: 278.8 }} block active />
          <Skeleton.Button style={{ height: 278.8 }} block active />
        </Space>
      ) : (
        <List
          size="small"
          dataSource={(results || []).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))}
          loading={isLoading || isRegistrationsValidating}
          rowKey={(item) => item?._id}
          pagination={{
            current: pagination.page,
            pageSize: pagination.pageSize,
            onChange: (page, pageSize) => setPagination({ page, pageSize }),
            hideOnSinglePage: true,
          }}
          renderItem={(item, index) => {
            return <RegistrationItem key={item._id} {...{ item }} onChange={mutate} />;
          }}
        />
      )}
    </Card>
  );
};

export default Registrations;
