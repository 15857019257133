import { Checkbox, Form, Input, InputNumber, Select, Space } from 'antd';
import { useFormContext, useFormState, Controller } from 'react-hook-form';
import { memo } from 'react';

const SELECT_IMAGE_SIZE_OPTIONS = [
  {
    value: '1024x1792',
    label: '1024x1792px',
  },
  {
    value: '1792x1024',
    label: '1792x1024px',
  },
  {
    value: '1024x1024',
    label: '1024px',
  },
  {
    value: '512x512',
    label: '512px',
  },
  {
    value: '256x256',
    label: '256px',
  },
];

const ImageProcessingInput = memo(({ conversation, activity, isProcessing }) => {
  const { control } = useFormContext();
  const { errors } = useFormState({ control });

  return (
    <Space size="small" direction="vertical" style={{ display: 'flex' }}>
      <Form.Item label="Prompt" validateStatus={errors?.prompt?.message && 'error'} help={errors?.prompt?.message}>
        <Controller
          control={control}
          name="prompt"
          render={({ field }) => (
            <Input.TextArea
              {...field}
              autoSize
              style={{ minHeight: 100 }}
              disabled={
                isProcessing || !activity || ['INITIALIZING', 'SUBMITTED', 'ERRORED'].includes(conversation?.status)
              }
            />
          )}
        />
      </Form.Item>
      <Space size="large">
        <Form.Item
          label="Nombre d'images"
          validateStatus={errors?.numberImages?.message && 'error'}
          help={errors?.numberImages?.message}
          tooltip="Le modele Dall-E 3 ne supporte que la génération d'une image a la fois"
        >
          <Controller
            control={control}
            name="numberImages"
            render={({ field }) => (
              <InputNumber
                {...field}
                disabled={
                  isProcessing || !activity || ['INITIALIZING', 'SUBMITTED', 'ERRORED'].includes(conversation?.status)
                }
                min={1}
                max={10}
              />
            )}
          />
        </Form.Item>
        <Form.Item
          label="Taille"
          validateStatus={errors?.imageSize?.message && 'error'}
          help={errors?.imageSize?.message}
          tooltip="Dall-E 2 supporte 256px, 512px et 1024px. Dall-E 3 supporte 1024px, 1792x1024px et 1024x1792px"
        >
          <Controller
            control={control}
            name="imageSize"
            render={({ field }) => (
              <Select
                {...field}
                disabled={
                  isProcessing || !activity || ['INITIALIZING', 'SUBMITTED', 'ERRORED'].includes(conversation?.status)
                }
                options={SELECT_IMAGE_SIZE_OPTIONS}
              />
            )}
          />
        </Form.Item>
        <Form.Item
          label="Qualité"
          validateStatus={errors?.imageQuality?.message && 'error'}
          help={errors?.imageQuality?.message}
          tooltip="Le modele Dall-E 2 ne supporte pas la génération d'image HD."
        >
          <Controller
            control={control}
            name="imageQuality"
            render={({ field }) => (
              <Checkbox
                disabled={
                  isProcessing || !activity || ['INITIALIZING', 'SUBMITTED', 'ERRORED'].includes(conversation?.status)
                }
                {...field}
              >
                Image HD
              </Checkbox>
            )}
          />
        </Form.Item>
      </Space>
    </Space>
  );
});

ImageProcessingInput.displayName = 'ImageProcessingInput';
export default ImageProcessingInput;
