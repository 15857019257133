import { format as dateFormat } from 'date-fns';
import { format } from '@i-maginexr/js';
import fr from 'date-fns/locale/fr';

export const renderDays = (days) => {
  const groups = days.reduce((acc, curr) => {
    const group = dateFormat(new Date(curr), 'MM/yyyy');
    const day = dateFormat(new Date(curr), 'dd');

    if (group in acc) {
      acc[group].push(day);
    } else {
      acc[group] = [day];
    }

    return acc;
  }, {});

  return Object.entries(groups || {})
    .sort((a, b) => {
      const aDate = a?.[0]?.split('/');
      const bDate = b?.[0]?.split('/');

      return new Date(aDate[1], aDate[0] - 1, 1) - new Date(bDate[1], bDate[0] - 1, 1);
    })
    .map(([key, value], index) => {
      const [month, year] = key.split('/');
      const monthName = fr.localize.month(month - 1, { width: 'abbreviated' });

      return `${value
        .sort((a, b) => Number(a) - Number(b))
        .join(', ')
        .replace(/, ([^,]*)$/, ' et $1')} ${monthName} ${year}${
        index < Object.keys(groups || {}).length - 1 ? ' | ' : ''
      }`;
    })
    .join('');
};

export const formatScheduleItem = (item, config) => {
  let date = null;
  let duration = `${item.config.duration} jour${Number(item.config.duration) > 1 ? 's' : ''}`;
  let location = null;
  let former = null;

  /* Préparation de la date */
  if (item.type === 'Récurrent') {
    date = `Tous les ${item.config.day.toLowerCase()}s`;
  }

  if (item.type === 'Période') {
    const startDate = dateFormat(new Date(item.config.range[0]), 'dd/MM/yyyy');
    const endDate = dateFormat(new Date(item.config.range[1]), 'dd/MM/yyyy');

    date = `Du ${startDate} au ${endDate}`;
  }

  if (item.type === 'Jours datés') {
    date = renderDays(item.config.days);
  }

  if (item.type === 'Personnalisé') {
    date = item.config.custom;
  }

  /* Préparation du lieu */
  if (item.accessMode === 'Présentiel') {
    const locationItem = config.locations.find((location) => location._id === item.location);

    if (locationItem) {
      location = locationItem.city;
    }
  }

  if (item.accessMode === 'Distanciel') {
    location = 'À distance';
  }

  if (!!item?.former) {
    const formerItem = config.users.find((user) => user._id === item.former);

    if (formerItem) {
      const { firstName, lastName } = format.toFullName(formerItem.first_name, formerItem.last_name);

      former = `${lastName} ${firstName}`;
    }
  }

  return {
    _id: item._id,
    date,
    location,
    duration,
    former,
    price: item.price,
    goal: item.goal,
    type: item.type,
    note: item?.note || null,
    createdAt: item.createdAt,
    originalItem: item,
  };
};
