import { Button, Col, Form, Input, Modal, Popover, Row, Space, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { ExclamationCircleFilled, SettingOutlined } from '@ant-design/icons';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { validator } from '@i-maginexr/js';
import { Prompt } from 'react-router-dom';
import useFetch from '../../../../../hooks/useFetch';
import useLoading from '../../../../../hooks/useLoading';

const PAGE_URL = `${process.env.REACT_APP_BASE_URL_API_URL}/next/private/arinfo/pages/inter-intra`;

const defaultValues = {
  alt: '',
};

const CoverConfigModal = ({ data, onFinish, alertTimeout, disabled }) => {
  const { startLoading, endLoading, isLoading } = useLoading();
  const [open, setOpen] = useState(false);
  const { control, reset, handleSubmit, setError } = useForm({ defaultValues });
  const { isDirty, errors } = useFormState({ control });
  const { patch } = useFetch();

  useEffect(() => {
    if (open) {
      reset({
        ...defaultValues,
        alt: data?.cover?.alt || defaultValues.alt,
      });
    }
  }, [open]);

  const onCancel = () => {
    setOpen(false);
  };

  const onSubmit = async (form) => {
    startLoading('cover_config');

    // Sauvegarde la configuration dans la DB
    const body = JSON.stringify(form);
    const response = await patch(`${PAGE_URL}/categories/${data._id}/cover/config`, body);

    if (response.status === 200) {
      endLoading('cover_config');
      setOpen(false);
      // Met à jour le bloc
      return onFinish();
    }

    if (response?.errors && validator.isStrictObject(response.errors)) {
      Object.entries(response.errors).forEach(([key, value]) => {
        setError(key, { type: 'manual', message: value });
      });
    }

    endLoading('cover_config');
  };

  return (
    <>
      <Modal
        visible={open}
        destroyOnClose
        title="Modifier les métadonnées de l'image"
        okText="Continuer"
        okButtonProps={{ disabled: !isDirty, loading: isLoading('cover_config') }}
        cancelButtonProps={{ disabled: isLoading('cover_config') }}
        onCancel={onCancel}
        onOk={handleSubmit(onSubmit)}
      >
        <Prompt
          when={isDirty}
          message="Vous n'avez pas sauvegardé vos modifications, voulez-vous vraiment quitter cette page ?"
        />
        <Form noValidate layout="vertical">
          <Form.Item
            label="Texte alternatif"
            required
            tooltip="Le texte alternatif d'une image est une description courte qui explique ce que l'image montre. Ce texte est utile pour les personnes qui ne peuvent pas voir l'image (par exemple, les personnes malvoyantes qui utilisent un lecteur d'écran), et il aide aussi les moteurs de recherche à comprendre le contenu de l'image."
            validateStatus={errors?.alt?.message && 'error'}
            help={errors.alt?.message}
          >
            <Controller {...{ control }} name="alt" render={({ field }) => <Input {...field} />} />
          </Form.Item>
        </Form>
      </Modal>
      <Popover
        trigger={[]}
        visible={alertTimeout >= 1}
        placement="bottom"
        style={{ maxWidth: 150 }}
        zIndex={50}
        title={
          <Row>
            <Col flex="auto">
              <Space>
                <ExclamationCircleFilled style={{ color: '#faad14' }} />
                <Typography.Text>Attention</Typography.Text>
              </Space>
            </Col>
            <Col flex="none">
              <Typography.Text type="secondary">{alertTimeout}s</Typography.Text>
            </Col>
          </Row>
        }
        content={
          <div style={{ maxWidth: 200 }}>
            <Typography.Text>
              Vous avez modifié l'image de couverture, assurez-vous que la configuration reste valide.
            </Typography.Text>
          </div>
        }
      >
        <Button
          {...{ disabled }}
          size="small"
          icon={<SettingOutlined />}
          onClick={(event) => setOpen(true)}
          style={{ flexShrink: 0 }}
        />
      </Popover>
    </>
  );
};

export default CoverConfigModal;
