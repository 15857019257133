import { Button, Col, Form, message, Modal, Row, Select } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import qs from 'qs';
import { Controller, FormProvider, useForm, useFormState } from 'react-hook-form';

import { aiRoutes, keysRoutes } from '../../../../lib/routes';
import { useAuth } from '../../../../authContext';
import { checkAuthorization } from '../../../../shared/utils';
import ConversationViewer from './components/ConversationViewer';
import ConversationInputs from './components/ConversationInputs';
import ConversationOptions from './components/ConversationOptions';

const getDefaultValues = (conversation) => {
  if (!conversation || !conversation?.data) {
    return {};
  }

  // Construis la liste des options
  const options = [
    ...(conversation.data?.options?.skipQueue ? ['skipQueue'] : []),
    ...(conversation.data?.options?.skipAi ? ['skipAi'] : []),
    ...(conversation.data?.options?.forcePriority ? ['forcePriority'] : []),
    ...(conversation.data?.options?.forceContinue ? ['forceContinue'] : []),
    ...(conversation.data?.options?.rejectFromQueue ? ['rejectFromQueue'] : []),
    ...(conversation.data?.options?.rejectFromThread ? ['rejectFromThread'] : []),
  ];

  return {
    message: '',
    options,
  };
};

const ViewConversationModal = ({ conversationId, isOpen, onClose, onFinish }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [displayPrompt, setDisplayPrompt] = useState(false);
  const [refreshConversation, setRefreshConversation] = useState(true);
  const { data: keys, isValidating: isKeysValidating } = useSWR(keysRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: conversation, isValidating: isConversationsValidating } = useSWR(
    `${aiRoutes.conversations}/${conversationId}`,
    {
      revalidateOnReconnect: true,
      revalidateOnFocus: true,
      revalidateOnRefresh: false,
      refreshInterval: refreshConversation ? 1000 : 0,
      keepPreviousData: true,
    },
  );
  const defaultValues = useMemo(() => getDefaultValues(conversation), [conversation?.data]);
  const methods = useForm({ defaultValues });
  const { errors } = useFormState({ control: methods.control });
  const { token, user } = useAuth();
  const currentOptions = methods.watch('options');

  useEffect(() => {
    if (conversation?.data) {
      setRefreshConversation(!['IN PROGRESS', 'COMPLETED', 'ERRORED'].includes(conversation?.data?.status));
    }
  }, [conversation?.data]);

  const onSubmit = async (form) => {
    setIsProcessing(true);

    if (!form?.apiKey) {
      methods.setError('apiKey', { type: 'custom', message: 'Ce champ est requis' });
      setIsProcessing(false);
      return;
    }

    // Construction du message utilisateur (si présent)
    const userMessage = {
      ...(form.message || form.image ? { role: 'user' } : {}),
      ...(form.message ? { content: form.message } : {}),
      ...(form.image ? { imageUrl: form.image } : {}),
    };

    // Constructions des data
    const data = {
      ...(!!userMessage.role ? { messages: [userMessage] } : {}),
    };

    // Construction des options
    const params = qs.stringify(
      {
        skipQueue: !!form.options?.includes('skipQueue'),
        skipAi: !!form.options?.includes('skipAi'),
        forcePriority: !!form.options?.includes('forcePriority'),
        forceContinue: !!form.options?.includes('forceContinue'),
        rejectFromQueue: !!form.options?.includes('rejectFromQueue'),
        rejectFromThread: !!form.options?.includes('rejectFromThread'),
      },
      { addQueryPrefix: true },
    );

    // Envoi de la requête
    const response = await fetch(`${aiRoutes.conversations}/${conversationId}/send${params}`, {
      method: 'PATCH',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        'x-api-key': form.apiKey,
      },
    });

    const results = await response.json();

    if (results.status === 200) {
      onFinish();
      setRefreshConversation(true);
      methods.setValue('message', '');
      methods.setValue('image', '');
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.data).forEach(([key, value]) => {
          methods.setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  return (
    <Modal
      open={isOpen}
      onCancel={() => {
        methods.reset();
        setDisplayPrompt(false);
        onClose();
      }}
      footer={null}
      width={800}
      destroyOnClose
    >
      <FormProvider {...methods}>
        <Form noValidate layout="vertical" onFinish={methods.handleSubmit(onSubmit)}>
          <Row>
            <Col span={12}>
              <Form.Item
                label="Clé d'accès"
                validateStatus={errors?.apiKey?.message && 'error'}
                help={errors?.apiKey?.message}
                required
              >
                <Controller
                  control={methods.control}
                  name="apiKey"
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={(keys?.data || []).map((item) => ({
                        label: item.name,
                        value: item.accessKey,
                        item,
                      }))}
                      loading={isKeysValidating}
                      disabled={isProcessing}
                    />
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Conversation">
            <ConversationViewer conversation={conversation?.data || {}} displayPrompt={displayPrompt} />
          </Form.Item>

          {checkAuthorization(user, 'ai', 'interactions', 'interact') && (
            <ConversationInputs
              conversation={conversation?.data || {}}
              activity={true}
              isProcessing={isProcessing || isConversationsValidating}
            />
          )}

          {checkAuthorization(user, 'ai', 'api', 'debug') && (
            <ConversationOptions
              displayPrompt={displayPrompt}
              onDisplayPromptChange={setDisplayPrompt}
              disabled={isProcessing || ['INITIALIZING', 'SUBMITTED', 'ERRORED'].includes(conversation?.data?.status)}
            />
          )}

          {checkAuthorization(user, 'ai', 'interactions', 'interact') && (
            <Row style={{ justifyContent: 'flex-end' }}>
              <Button
                type="primary"
                htmlType="submit"
                loading={isProcessing || ['INITIALIZING', 'SUBMITTED'].includes(conversation?.data?.status)}
                disabled={
                  isProcessing ||
                  ['INITIALIZING', 'SUBMITTED', 'ERRORED'].includes(conversation?.data?.status) ||
                  (conversation?.data?.status === 'COMPLETED' && !(currentOptions || []).includes('forceContinue'))
                }
              >
                Envoyer
              </Button>
            </Row>
          )}
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default ViewConversationModal;
