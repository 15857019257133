import { useState } from 'react';
import { Col, Row, Segmented } from 'antd';
import { BarsOutlined, SettingOutlined, FormOutlined } from '@ant-design/icons';
import Catalog from './components/Catalog';
import Categories from './components/Categories';
import Seo from './components/Seo';
import Layout from '../components/Layout';
import Program from './components/Program';
import Registrations from './components/Registrations';
import { checkAuthorization } from '../../../../shared/utils';
import { useAuth } from '../../../../authContext';

// TODO : Ajouter les données structurées
const Home = () => {
  const [view, setView] = useState('configuration');
  const { user } = useAuth();

  const options = [
    {
      label: 'Configuration',
      value: 'configuration',
      icon: <SettingOutlined />,
    },
    ...(checkAuthorization(user, 'arinfo', 'interFormations', 'access-schedule')
      ? [
          {
            label: 'Programmation',
            value: 'programmation',
            icon: <BarsOutlined />,
          },
        ]
      : []),
    ...(checkAuthorization(user, 'arinfo', 'interFormations', 'access-registrations')
      ? [
          {
            label: 'Pré-inscriptions',
            value: 'inscriptions',
            icon: <FormOutlined />,
          },
        ]
      : []),
  ];

  return (
    <Layout title="Gestion Inter/Intra">
      {options.length > 1 && (
        <Col span={24}>
          <Segmented {...{ options }} value={view} onChange={setView} />
        </Col>
      )}
      {view === 'configuration' && (
        <>
          <Col span={24} xl={16}>
            <Categories />
          </Col>
          <Col span={24} xl={8}>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Catalog />
              </Col>
              {checkAuthorization(user, 'arinfo', 'interFormations', 'update-seo') && (
                <Col span={24}>
                  <Seo />
                </Col>
              )}
            </Row>
          </Col>
        </>
      )}
      {view === 'inscriptions' && checkAuthorization(user, 'arinfo', 'interFormations', 'access-registrations') ? (
        <Col span={24}>
          <Registrations />
        </Col>
      ) : null}
      {view === 'programmation' && checkAuthorization(user, 'arinfo', 'interFormations', 'access-schedule') ? (
        <Col span={24}>
          <Program />
        </Col>
      ) : null}
    </Layout>
  );
};

export default Home;
