import { FileExcelOutlined } from '@ant-design/icons';
import { Card, Typography, Collapse, Skeleton, Spin, Tabs, Button, Space, Empty } from 'antd';
import { useMemo, useState } from 'react';
import useSWR from 'swr';
import ProgramItem from './ProgramItem';
import CreateOrUpdateSchedule from './CreateOrUpdateSchedule';
import Notice from './Notice';
import exportFormations from '../lib/export-formations';
import { formatScheduleItem } from '../lib/format-schedule-item';
import { useAuth } from '../../../../../authContext';
import { checkAuthorization } from '../../../../../shared/utils';

const PAGE_URL = `${process.env.REACT_APP_BASE_URL_API_URL}/next/private/arinfo/pages/inter-intra`;
const FORMATIONS_URL = `${process.env.REACT_APP_BASE_URL_API_URL}/next/private/arinfo/trainings`;
const LOCATIONS_URL = `${process.env.REACT_APP_BASE_URL_API_URL}/next/private/arinfo/agencies`;
const USERS_URL = `${process.env.REACT_APP_BASE_URL_API_URL}/next/private/global/users`;

const SWROptions = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

const Program = () => {
  const [dateScheduleForm, setDateScheduleForm] = useState({ open: false, action: null, item: null });
  const { user } = useAuth();
  const { data: users, isValidating: isUsersValidating } = useSWR(USERS_URL, SWROptions);
  const { data: formations, isValidating: isFormationsValidating } = useSWR(FORMATIONS_URL, SWROptions);
  const { data: page, isValidating: isPageValidating, mutate } = useSWR(PAGE_URL, SWROptions);
  const { data: locations, isValidating: isLocationsValidating } = useSWR(LOCATIONS_URL, SWROptions);
  const isLoading =
    (isFormationsValidating && !formations) ||
    (isPageValidating && !page) ||
    (isLocationsValidating && !locations) ||
    (isUsersValidating && !users);

  const categoryItems = useMemo(() => {
    if (!page?.data || !formations?.data || !locations?.data || !users?.data) {
      return [];
    }

    return (page.data?.content?.categories || [])
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((item) => ({
        ...item,
        formations: (page.data?.content?.formations || [])
          .filter((formation) => formation.category === item._id)
          .reduce((acc, curr) => {
            if (curr.category === item._id) {
              const index = acc.findIndex((accItem) => accItem.formationId === curr.formation);
              const schedule = Object.entries(curr?.modes || {}).flatMap(([key, value]) => {
                return value.schedules.map((schedule) => ({
                  ...formatScheduleItem(schedule, { locations: locations.data, users: users.data }),
                  mode: key,
                  disabled: value.disabled,
                }));
              });

              if (index === -1) {
                const formation = formations.data.find((formation) => formation._id === curr.formation);

                if (formation) {
                  acc.push({
                    _id: curr._id,
                    formationId: formation._id,
                    title: formation.title,
                    schedule,
                    originalItem: formation,
                    mode: curr,
                  });
                }
              } else {
                acc[index].schedule = acc[index].schedule.concat(schedule);
              }
            }

            return acc;
          }, []),
      }));
  }, [page, formations, locations, users]);

  console.log(page?.data?.content);
  return (
    <Card
      size="small"
      title={<Typography.Title level={5}>Programmation</Typography.Title>}
      extra={
        <Space>
          {checkAuthorization(user, 'arinfo', 'interFormations', 'export-schedule-excel') && (
            <Button
              size="small"
              icon={<FileExcelOutlined />}
              disabled={
                !page?.data?.content ||
                !page?.data?.content?.formations ||
                !page?.data?.content?.formations?.length ||
                !formations?.data ||
                !locations?.data ||
                !users?.data
              }
              onClick={() =>
                exportFormations(page?.data?.content, {
                  users: users?.data,
                  formations: formations?.data,
                  locations: locations?.data,
                })
              }
            >
              Exporter
            </Button>
          )}
          <Notice />
        </Space>
      }
    >
      <CreateOrUpdateSchedule
        {...{ ...dateScheduleForm }}
        onFinish={() => {
          setDateScheduleForm({ open: false, action: null, item: null });
          mutate();
        }}
        onCancel={() => setDateScheduleForm({ open: false, action: null, item: null })}
      />
      {isLoading ? (
        Array.from(Array(10).keys()).map((item) => (
          <Skeleton.Button key={item} active block style={{ height: 24, margin: '12px 0' }} />
        ))
      ) : (
        <Spin className="ant-spin-large" spinning={isFormationsValidating || isPageValidating}>
          <Tabs>
            {categoryItems.map((category) => {
              const totalFormations =
                category.formations
                  .map((item) => item.schedule.length)
                  .reduce((sum, a) => Number(sum) + Number(a), 0) || 0;

              return (
                <Tabs.TabPane key={category._id} tab={`${category.name} (${totalFormations})`} tabKey={category._id}>
                  {category.formations.length === 0 ? (
                    <Empty />
                  ) : (
                    <Collapse ghost className="inter-intra-collapse">
                      {category.formations.map((formation) => (
                        <ProgramItem
                          key={formation._id}
                          data={formation}
                          isLoading={isFormationsValidating || isPageValidating}
                          onPlacesUpdate={mutate}
                          onNoteUpdate={mutate}
                          onCreate={() =>
                            setDateScheduleForm({
                              open: true,
                              action: 'CREATE',
                              item: {
                                formation,
                              },
                            })
                          }
                          onEdit={(item) =>
                            setDateScheduleForm({
                              open: true,
                              action: 'EDIT',
                              item: {
                                formation,
                                schedule: item,
                              },
                            })
                          }
                          onDelete={mutate}
                        />
                      ))}
                    </Collapse>
                  )}
                </Tabs.TabPane>
              );
            })}
          </Tabs>
        </Spin>
      )}
    </Card>
  );
};

export default Program;
