import { memo, useState } from 'react';
import { Button, Table, Tabs, Menu, Popconfirm, Space, Tooltip, Dropdown, Tag, Checkbox, Typography } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, MoreOutlined, RobotOutlined, UserOutlined } from '@ant-design/icons';
import { format } from 'date-fns';
import { capitalize } from 'lodash';

import { aiRoutes } from '../../../lib/routes';
import tablePagination from '../../../lib/tablePagination';
import { checkAuthorization, sort } from '../../../shared/utils';
import useColumnSearch from '../../../hooks/useColumnSearch';
import useFetch from '../../../hooks/useFetch';
import { useAuth } from '../../../authContext';
import ViewImageProcessingModal from './modals/ViewImageProcessingModal';

const { Item } = Menu;

const popconfirmProps = {
  title: 'Êtes-vous sûr ?',
  cancelText: 'Non',
  okText: 'Oui',
  placement: 'left',
};

const JOB_AI_LABELS = {
  chatgpt: 'ChatGPT',
  mistral: 'Mistral AI',
};

const JOB_AI_TAG_SELECTION = {
  chatgpt: {
    label: JOB_AI_LABELS['chatgpt'],
    color: '#74AA9C',
  },
  mistral: {
    label: JOB_AI_LABELS['mistral'],
    color: '#ff7404',
  },
};

const STATUS_LABELS = {
  INITIALIZING: 'En cours de démarrage',
  SUBMITTED: "En attente de l'IA",
  'IN PROGRESS': "En attente de l'utilisateur",
  COMPLETED: 'Terminée',
  ERRORED: 'Erreur',
};

const STATUS_TAG_SELECTION = {
  INITIALIZING: {
    label: STATUS_LABELS['INITIALIZING'],
    icon: <RobotOutlined />,
    color: 'blue',
  },
  SUBMITTED: {
    label: STATUS_LABELS['SUBMITTED'],
    icon: <RobotOutlined />,
    color: 'purple',
  },
  'IN PROGRESS': {
    label: STATUS_LABELS['IN PROGRESS'],
    icon: <UserOutlined />,
    color: 'magenta',
  },
  COMPLETED: {
    label: STATUS_LABELS['COMPLETED'],
    icon: <CheckCircleOutlined />,
    color: 'success',
  },
  ERRORED: {
    label: STATUS_LABELS['ERRORED'],
    icon: <CloseCircleOutlined />,
    color: 'error',
  },
};

const ImageGenerationAiTable = ({ imageGenerations, onFinish, isValidating, isProcessing, onProcessing }) => {
  const { user } = useAuth();
  const [viewImageProcessingModalOpen, setViewImageProcessingModalOpen] = useState(false);
  const { getColumnSearchProps } = useColumnSearch();
  const { patch, remove } = useFetch();

  const deleteInteraction = async (interactionId) => {
    onProcessing(true);

    const results = await remove(`${aiRoutes.interactions}/${interactionId}`);

    if (results.status === 200) {
      onFinish();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    onProcessing(false);
  };

  const Actions = memo(({ record }) => {
    return (
      <Menu key={`menu-${record._id}`}>
        <Item key="complete" onClick={() => setViewImageProcessingModalOpen(record._id)}>
          Consulter
        </Item>

        {checkAuthorization(user, 'ai', 'interactions', 'delete') && (
          <Item key="delete">
            <Popconfirm {...popconfirmProps} onConfirm={() => deleteInteraction(record._id)}>
              Supprimer
            </Popconfirm>
          </Item>
        )}
      </Menu>
    );
  });

  const columns = [
    {
      title: 'Utilisateur',
      dataIndex: 'user',
      key: 'user',
      sorter: (a, b) => sort(a, b, 'user.last_name'),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('user.last_name'),
      render: (value) => `${capitalize(value.first_name)} ${value.last_name.toUpperCase()}`,
    },
    {
      title: 'Application',
      dataIndex: ['activity', 'application', 'title'],
      key: 'application',
      sorter: (a, b) => sort(a, b, 'activity.application.title'),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('activity.application.title'),
    },
    {
      title: 'Activité',
      dataIndex: ['activity', 'title'],
      key: 'activity',
      sorter: (a, b) => sort(a, b, 'activity.title'),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('activity.title'),
    },
    {
      title: 'IA',
      dataIndex: 'ai',
      key: 'ai',
      width: 100,
      filters: [
        { text: JOB_AI_LABELS['chatgpt'], value: 'chatgpt' },
        { text: JOB_AI_LABELS['mistral'], value: 'mistral' },
      ],
      onFilter: (value, record) => record.ai.system === value,
      render: (value) => (
        <>
          {value?.release ? (
            <Tooltip title={value.release} destroyTooltipOnHide={{ keepParent: false }}>
              <Tag color={JOB_AI_TAG_SELECTION[value.system].color}>{JOB_AI_TAG_SELECTION[value.system].label}</Tag>
            </Tooltip>
          ) : (
            <>--</>
          )}
        </>
      ),
    },
    {
      title: 'Démarrée le',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
      align: 'center',
      render: (record) => format(new Date(record), 'dd/MM/yyyy à HH:mm'),
      sorter: (a, b) => sort(a, b, 'createdAt'),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Dernière mise à jour le',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 200,
      align: 'center',
      render: (record) => format(new Date(record), 'dd/MM/yyyy à HH:mm'),
      sorter: (a, b) => sort(a, b, 'updatedAt'),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 200,
      filters: [
        { text: STATUS_LABELS['INITIALIZING'], value: 'INITIALIZING' },
        { text: STATUS_LABELS['SUBMITTED'], value: 'SUBMITTED' },
        { text: STATUS_LABELS['IN PROGRESS'], value: 'IN PROGRESS' },
        { text: STATUS_LABELS['COMPLETED'], value: 'COMPLETED' },
        { text: STATUS_LABELS['ERRORED'], value: 'ERRORED' },
      ],
      onFilter: (value, record) => record.status === value,
      render: (value, record) => (
        <>
          {value === 'ERRORED' ? (
            <Tooltip title={record.error}>
              <Tag
                style={{ width: '100%', textAlign: 'center' }}
                color={STATUS_TAG_SELECTION[value]?.color || 'default'}
                icon={STATUS_TAG_SELECTION[value]?.icon || null}
              >
                {STATUS_TAG_SELECTION[value]?.label || ''}
              </Tag>
            </Tooltip>
          ) : (
            <Tag
              style={{ width: '100%', textAlign: 'center' }}
              color={STATUS_TAG_SELECTION[value]?.color || 'default'}
              icon={STATUS_TAG_SELECTION[value]?.icon || null}
            >
              {STATUS_TAG_SELECTION[value]?.label || ''}
            </Tag>
          )}
        </>
      ),
    },
    {
      title: '',
      key: 'actions',
      width: 50,
      align: 'center',
      fixed: 'right',
      render: (record) => (
        <Dropdown overlay={<Actions record={record} />} placement="bottomRight" trigger={['click']}>
          <Button icon={<MoreOutlined />} disabled={isProcessing} />
        </Dropdown>
      ),
    },
  ];

  return (
    <>
      <ViewImageProcessingModal
        conversationId={viewImageProcessingModalOpen}
        isOpen={!!viewImageProcessingModalOpen}
        onClose={() => setViewImageProcessingModalOpen(false)}
        onFinish={onFinish}
      />
      <Table
        loading={!imageGenerations || isValidating || isProcessing}
        dataSource={imageGenerations}
        columns={columns}
        bordered
        size="small"
        rowKey={(row) => row._id}
        pagination={tablePagination(imageGenerations)}
        expandable={{
          expandedRowRender: (record) => {
            return (
              <Space direction="vertical" style={{ display: 'flex' }}>
                <Typography.Text>Options de débogage</Typography.Text>
                <Checkbox checked={record?.options?.skipQueue || false}>Exécution directe de la tâche</Checkbox>
                <Checkbox checked={record?.options?.skipAi || false}>
                  Génère une réponse automatique au lieu d'interroger l'IA
                </Checkbox>
                <Checkbox checked={record?.options?.forcePriority || false}>
                  Force la priorité dans la file d'attente
                </Checkbox>
                <Checkbox checked={record?.options?.rejectFromQueue || false}>
                  Rejette la tâche à l'entrée de la file d'attente
                </Checkbox>
                <Checkbox checked={record?.options?.rejectFromThread || false}>
                  Rejette la tâche à la sortie de la file d'attente
                </Checkbox>
              </Space>
            );
          },
        }}
      />
    </>
  );
};

export default ImageGenerationAiTable;
